/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SectionBar from '../Others/SectionBar';
import SkeletonLoading from '../Others/SkeletonLoading';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import { TableItemWidth3, TableItemWidth5 } from '../TableSpacing/TableSpacingStyles';
import HeadingOne from '../TypoGraphy/HeadingOne';
import * as TimeReportsActions from '../../redux/actions/time-reports-actions-v2';
import TableRowItem from '../Others/TableRowItem';
import TableRowButton from '../Others/TableRowButton';
import { useHistory, useLocation } from 'react-router';
import { convertDecimalToTime } from '../../utilities/utilities';

function TimeReportsClientsV2({ sectionId }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	const loading = useSelector((state) => state.TimeReportsV2.loading);
	const clients = useSelector((state) => state.TimeReportsV2.clients);

	const start_date = searchParams.get('start_date');
	const end_date = searchParams.get('end_date');

	const navigateToClient = (client) => {
		history.push(`/time-reports-client/${client.client_id}`);
	};

    const navigateToUsersLoggedTimeForClientsExport = () => {
        history.push(`/client-users-logged-time-export`);
    };

	useEffect(() => {
		if (start_date && end_date)
			dispatch(TimeReportsActions.getClientStats(start_date, end_date));
	}, [dispatch, start_date, end_date]);

	return (
		<div className='profile-section' id={sectionId}>
			<SectionBar header={<HeadingOne>Clients</HeadingOne>} headerActions={
					<SkeletonLoading loading={loading} variant='text' width={100} height={50}>
						<button onClick={navigateToUsersLoggedTimeForClientsExport} className='rounded-button-2'>
							Users Logged Time Export
						</button>
					</SkeletonLoading>
				} />

			<div className='profile-section-body border'>
				<div className='team-members-body'>
					<SkeletonLoading loading={loading} variant='text' width='100%' height={45}>
						<TableHeader ClassName='new-table-header' border={false}>
							<TableHeaderItem
								labelClass='BodyTwoLight'
								label='User Name'
								width={TableItemWidth3}
							/>
							<TableHeaderItem
								labelClass='BodyTwoLight'
								label='Budgeted Hours'
								width={TableItemWidth5}
							/>
							<TableHeaderItem
								labelClass='BodyTwoLight'
								label='Logged Time'
								width={TableItemWidth5}
							/>
							<TableHeaderItem
								labelClass='BodyTwoLight'
								label='Capacity'
								width={TableItemWidth5}
							/>
							<TableHeaderItem
								labelClass='BodyTwoLight'
								label='Details'
								width={TableItemWidth5}
							/>
						</TableHeader>
					</SkeletonLoading>
					<div className='ListingLoading scroll'>
						{clients?.length == 0 ? (
							<SkeletonLoading
								loading={loading}
								variant='text'
								width='100%'
								height={45}
							>
								<div className='TableRow w-100 d-flex align-items-center justify-content-center'>
									<h4 className='BodyTwoLight'>No Clients</h4>
								</div>
							</SkeletonLoading>
						) : (
							clients?.map((client) => {
								return (
									<SkeletonLoading
										loading={loading}
										variant='text'
										width='100%'
										height={45}
										key={client.id}
									>
										<div className='new-table-row d-flex align-items-center'>
											<TableRowItem
												ClassName='text-left'
												textClass='BodyTwoLight'
												label={
													client?.dba?.trim() == ''
														? client.company_name
														: client.dba
												}
												width={TableItemWidth3}
												input={false}
											/>
											<TableRowItem
												ClassName='text-left'
												textClass='BodyTwoLight'
												label={convertDecimalToTime(client?.budgeted_hours)}
												width={TableItemWidth5}
												input={false}
											/>
											<TableRowItem
												ClassName='text-left'
												textClass='BodyTwoLight'
												label={convertDecimalToTime(
													client.consumed_time || 0,
												)}
												width={TableItemWidth5}
												input={false}
											/>
											<TableRowItem
												ClassName='text-left'
												textClass={`BodyTwoLight ${
													(client?.budgeted_hours
														? client?.budgeted_hours
														: 0) -
														(client.consumed_time
															? client?.consumed_time
															: 0) <
													0
														? 'red-text'
														: ''
												}`}
												label={convertDecimalToTime(
													(client?.budgeted_hours
														? client?.budgeted_hours
														: 0) -
														(client?.consumed_time
															? client?.consumed_time
															: 0),
												)}
												width={TableItemWidth5}
												input={false}
												toolTipText='Budgeted Hours - Logged Hours'
											/>
											<TableRowButton
												ClassName='text-left pointer primary-text'
												textClass='BodyTwoLight primary-text'
												label='View'
												width={TableItemWidth5}
												clickHandler={() => navigateToClient(client)}
												input={false}
											/>
										</div>
									</SkeletonLoading>
								);
							})
						)}
					</div>
				</div>
			</div>
		</div>
	);
}

export default TimeReportsClientsV2;
