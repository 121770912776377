import { memo, useState } from 'react';
import { ReactComponent as TaskIcon } from '../../assets/icons/Chats.svg';
import { ReactComponent as AgendaIcon } from '../../assets/icons/invoice-generate.svg';
import { ReactComponent as NoteIcon } from '../../assets/icons/NotePencil.svg';
import { ReactComponent as LinkIcon } from '../../assets/icons/External Link.svg';
import { ReactComponent as HoursIcon } from '../../assets/icons/Clock primary.svg';
import { ReactComponent as GuestIcon } from '../../assets/icons/Users.svg';
import useModal from '../Modal/useModal';
import { Avatar, AvatarGroup, Modal, Tooltip } from '@mui/material';
import DummyModal from '../Modal/DummyModal';
import TaskDescriptionModal from './Modals/TaskDescriptionModal';
import {
	convertDecimalToTime,
	getAvatarLabel,
	standardPermissions,
	truncateString,
} from '../../utilities/utilities';
import TaskGuestsList from './TaskGuestsList';
import Restricted from '../Permissions/Restricted';
import NewMyTaskDeleteAlert from '../Alerts/NewMyTaskDeleteAlert';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/Trash-Light.svg';
import EditMeetingMyTaskModal from '../WorkSpace/Task Modal/EditMeetingMyTaskModal';
import { useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import * as WorkSpaceActions from '../../redux/actions/dashboard/workspaces-actions';
import * as clientActions from '../../redux/actions/client-actions';
import PreviewRichText from '../Inputs/PreviewRichText';

const avatarStyle = {
	bgcolor: `#FEF2E7`,
	color: `#333`,
	height: '23px',
	width: '23px',
	fontSize: '0.8rem',
};

function ClientMeetingTask({ meeting_task, is_creator, user_team_role }) {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState();
	const updateCardLoading = useSelector((state) => state.workSpaceReducer.updateCardLoading);
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const onEdit = () => {
		ModalType('EDIT_MY_TASK_MODAL');
		toggleModal();
	};

	const handleDeleteTask = () => {
		ModalType('DELETE_MY_TASK');
		toggleModal();
	};

	const deleteTask = ({ delete_future }) => {
		setLoading(true);
		dispatch(clientActions.deleteClientMeetingTask(meeting_task?.id, delete_future)).then(
			() => {
				toggleModal();
				setLoading(false);
				dispatch(WorkSpaceActions.setSnackBarIsOpen(true, true, 'Task Deleted'));
			},
		);
	};

	return (
		<div className='client-meeting-task'>
			<div className='w-100 text-left d-flex align-items-center justify-content-between'>
				<span className='d-flex align-items-center' style={{ gap: '24px' }}>
					<span className='d-flex' style={{ gap: '12px' }}>
						<TaskIcon />
						<h3 className='HeadlineThreeBold'>{meeting_task.title}</h3>
					</span>
				</span>

				<Restricted AllowedUsers={standardPermissions} userTeamRole={[user_team_role]}>
					<span
						className='d-flex justify-content-between align-items-center'
						style={{ gap: '12px' }}
					>
						<Tooltip title='Delete Task'>
							<DeleteIcon className='pointer' onClick={handleDeleteTask} />
						</Tooltip>
						<Tooltip title='Edit Task'>
							<EditIcon className='pointer' onClick={onEdit} />
						</Tooltip>
					</span>
				</Restricted>
			</div>
			<div className='meeting-task-details text-left'>
				<span className='d-flex' style={{ gap: '8px' }}>
					<AgendaIcon />
					<span className='BodyTwoBold pt-1'>Agenda:</span>
					<span className='BodyTwoLight pt-1'>
						{meeting_task?.agenda?.length < 1 ||
						meeting_task?.agenda == null ||
						meeting_task?.agenda?.replace(/<[^>]+>/g, '')?.trim()?.length == 0 ? (
							'-'
						) : (
							<div
								style={{ minHeight: '14px' }}
								className='d-flex justify-content-between position-relative'
							>
								<PreviewRichText
									content={truncateString(meeting_task?.agenda, 500)}
									classNames={['BodytwoLight', 'dark-text', 'm-0', 'text-break']}
								/>

								{meeting_task?.agenda?.length > 500 && (
									<span
										onClick={() => {
											ModalType('TASK_MEETING_AGENDA');
											toggleModal();
										}}
										className='primary-text BodyThreeLight m-0 ml-1 pointer'
									>
										Read More
									</span>
								)}
							</div>
						)}
					</span>
				</span>
				<span className='d-flex' style={{ gap: '8px' }}>
					<NoteIcon style={{stroke: '#FB7A03'}}/>
					<span className='BodyTwoBold pt-1'>Notes:</span>
					<span className='BodyTwoLight pt-1'>
						{meeting_task?.notes?.length < 1 ||
						meeting_task?.notes == null ||
						meeting_task?.notes?.replace(/<[^>]+>/g, '')?.trim()?.length == 0 ? (
							'-'
						) : (
							<div
								// style={{ minHeight: '14px' }}
								className='d-flex justify-content-between position-relative'
							>
								<PreviewRichText
									content={truncateString(meeting_task?.notes, 500)}
									classNames={['BodytwoLight', 'dark-text', 'm-0', 'text-break']}
								/>

								{meeting_task?.notes?.length > 500 && (
									<span
										onClick={() => {
											ModalType('TASK_NOTES');
											toggleModal();
										}}
										className='primary-text BodyThreeLight m-0 ml-1 pointer'
									>
										Read More
									</span>
								)}
							</div>
						)}
					</span>
				</span>
				<span className='d-flex' style={{ gap: '8px' }}>
					<LinkIcon />
					<span className='BodyTwoBold pt-1'>Meeting Link:</span>
					<a
						className='BodyTwoLight pt-1 primary-text'
						target='_blank'
						rel='noreferrer'
						href={meeting_task?.metadata?.meeting_link}
						style={{
							display: 'inline-block',
							maxWidth: '150px',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
							// border: '2px solid red'
						}}
					>
						{meeting_task?.metadata?.meeting_link
							? meeting_task?.metadata?.meeting_link
							: '-'}
					</a>
				</span>
				<span className='d-flex' style={{ gap: '8px' }}>
					<HoursIcon />
					<span className='BodyTwoBold pt-1'>Budgeted Hours:</span>
					<span className='BodyTwoLight pt-1'>
						{convertDecimalToTime(meeting_task.budgeted_hours)}
					</span>
				</span>
				<span
					className='d-flex align-items-center justify-content-center'
					style={{ gap: '8px' }}
				>
					<GuestIcon />
					<span className='BodyTwoBold pt-1'>Guests:</span>
					{meeting_task.assignee_users?.lenth == 0 ? (
						<span className='BodyTwoLight pt-1'>No Guests</span>
					) : (
						<span className='BodyTwoLight pt-1 d-flex align-items-center'>
							<AvatarGroup max={2}>
								{meeting_task?.assignee_users?.length > 0 && (
									<Avatar
										sx={avatarStyle}
										alt={meeting_task?.assignee_users[0]?.full_name}
										src={`${meeting_task?.assignee_users[0]?.image_url}`}
									>
										{getAvatarLabel(meeting_task?.assignee_users[0]?.full_name)}
									</Avatar>
								)}
								{meeting_task?.assignee_users?.length > 1 && (
									<Avatar
										sx={avatarStyle}
										alt={meeting_task?.assignee_users[1]?.full_name}
										src={`${meeting_task?.assignee_users[1]?.image_url}`}
									>
										{getAvatarLabel(meeting_task?.assignee_users[1]?.full_name)}
									</Avatar>
								)}
							</AvatarGroup>

							{meeting_task?.assignee_users?.length > 2 && (
								<span
									className='BodyTwoLight m-0 primary-text ml-2 pointer'
									onClick={() => {
										ModalType('MY_TASK_GUEST_MODAL');
										toggleModal();
									}}
								>
									View all
								</span>
							)}
						</span>
					)}
				</span>
			</div>

			{modalIsOpen && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<>
						{ModalName == 'DELETE_MY_TASK' && (
							<NewMyTaskDeleteAlert
								open={modalIsOpen}
								taskType={meeting_task?.task_type_id}
								loading={loading}
								closeHandler={toggleModal}
								submitHandler={deleteTask}
							/>
						)}
						{ModalName == 'EDIT_MY_TASK_MODAL' && (
							<EditMeetingMyTaskModal
								clientId={id}
								updateCardLoading={updateCardLoading}
								cancelButtonClick={toggleModal}
								taskDetails={meeting_task}
								handleModalClose={toggleModal}
							/>
						)}
						{ModalName == 'TASK_MEETING_AGENDA' && (
							<DummyModal title='Agenda' onClose={toggleModal} height='400px'>
								<TaskDescriptionModal
									type={'text'}
									value={meeting_task.agenda}
									Edit={false}
									OnCancel={() => toggleModal()}
									richTextStyle={{ height: '250px' }}
								/>
							</DummyModal>
						)}
						{ModalName == 'MY_TASK_GUEST_MODAL' && (
							<TaskGuestsList
								guestList={meeting_task?.assignee_users
									?.sort((a, b) => a.full_name.localeCompare(b.full_name))
									?.map((assignee) => assignee?.id)}
								users={meeting_task?.assignee_users}
								toggleModal={toggleModal}
							/>
						)}
						{ModalName == 'TASK_NOTES' && (
							<DummyModal title='Notes' onClose={toggleModal} height='400px'>
								<TaskDescriptionModal
									type={'text'}
									value={meeting_task.notes}
									Edit={false}
									OnCancel={() => toggleModal()}
									richTextStyle={{ height: '250px' }}
								/>
							</DummyModal>
						)}
					</>
				</Modal>
			)}
		</div>
	);
}

export default memo(ClientMeetingTask);
