import { secure_instance } from '../../axios/axios-config';
import { CustomError, workflowReusableUtils } from '../../utilities/utilities';
import { setSnackBarIsOpen } from './dashboard/workspaces-actions';

export const ARCHIEVED_WORKFLOW = ['ARCHIEVED_WORKFLOW'];
export const RETRIEVE_WORKFLOW = ['RETRIEVE_WORKFLOW'];
export const SET_ALL_ARCHIEVED_WORKFLOWS = ['SET_ALL_ARCHIEVED_WORKFLOWS'];
export const SET_TASK_TEMPLATES_ARRAY = ['SET_TASK_TEMPLATES_ARRAY'];
export const TASK_TEMPLATES_CHANGE_HANDLER = ['TASK_TEMPLATES_CHANGE_HANDLER'];
export const UPDATE_TASK_TEMPLATE = ['UPDATE_TASK_TEMPLATE'];

export const REMOVE_FROM_TASK_TASK_TEMPLATE = ['REMOVE_FROM_TASK_TASK_TEMPLATE'];
export const SET_ERROR = ['SET_ERROR'];
export const SET_WORKFLOW_FORM = ['SET_WORKFLOW_FORM'];
export const WORKFLOW_FORM_CHANGE_HANDLER = ['WORKFLOW_FORM_CHANGE_HANDLER'];
export const SET_SELECTED_COMPONENT = ['WORKFLOW SET_SELECTED_COMPONENT'];
export const SET_SELECTED_WORKFLOW = ['WORKFLOW SET_SELECTED_WORKFLOW'];
export const UPDATE_SELECTED_WORKFLOW = ['WORKFLOW UPDATE_SELECTED_WORKFLOW'];
export const UPDATE_SELECTED_COMPONENT = ['WORKFLOW UPDATE_SELECTED_COMPONENT'];
export const UPDATE_WORKFLOW_COMPONENT = ['WORKFLOW UPDATE_WORKFLOW_COMPONENT'];
export const SWAP_COMPONENT = ['WORKFLOW SWAP_COMPONENT'];
export const REMOVE_COMPONENT = ['WORKFLOW REMOVE_COMPONENT'];
export const UPDATE_COMPONENT_OPTIONS = ['WORKFLOW UPDATE_COMPONENT_OPTIONS'];
export const ADD_COMPONENT_OPTIONS = ['WORKFLOW ADD_COMPONENT_OPTIONS'];
export const DELETE_COMPONENT_OPTIONS = ['WORKFLOW DELETE_COMPONENT_OPTIONS'];
export const SET_ACCOUNTS_COMPONENT_VALUE = ['WORKFLOW SET_ACCOUNTS_COMPONENT_VALUE'];
export const SET_WORKFLOW_COMPONENT_VALUE = ['WORKFLOW SET_WORKFLOW_COMPONENT_VALUE'];
export const SET_WORKFLOW_ACCOUNTS = ['WORKFLOW SET_WORKFLOW_ACCOUNTS'];
export const GET_ALL_WORKFLOWS = ['WORKFLOW GET_ALL_WORKFLOWS'];
export const UPDATE_WORKFLOWS = ['WORKFLOW UPDATE_WORKFLOWS'];
export const DELETE_WORKFLOW_TASK = ['WORKFLOW DELETE_WORKFLOW_TASK'];
export const ADD_NEW_TABLE_ROW = ['WORKFLOW ADD_NEW_TABLE_ROW'];
export const ADD_NEW_TABLE_WORKFLOW_ROW = ['WORKFLOW ADD_NEW_TABLE_WORKFLOW_ROW'];
export const CHANGE_TABLE_VALUE = ['WORKFLOW CHANGE_TABLE_VALUE'];
export const CHANGE_WORKFLOW_TABLE_VALUE = ['WORKFLOW CHANGE_WORKFLOW_TABLE_VALUE'];
export const DRAFT_WORKFLOW_DELETE_START = ['WORKFLOW DRAFT_WORKFLOW_DELETE_START'];
export const DRAFT_WORKFLOW_DELETE_END = ['WORKFLOW DRAFT_WORKFLOW_DELETE_END'];
export const DRAFT_WORKFLOW_DELETE_FAILED = ['WORKFLOW DRAFT_WORKFLOW_DELETE_FAILED'];
export const UPDATE_WORKFLOW_VALUES = ['WORKFLOW UPDATE_WORKFLOW_VALUES'];
export const RESET_WORKFLOW_STATE = ['WORKFLOW RESET_WORKFLOW_STATE'];
export const ADD_WORKFLOW_TASK = ['WORKFLOW ADD_WORKFLOW_TASK'];
export const EDIT_WORKFLOW_TASK = ['WORKFLOW EDIT_WORKFLOW_TASK'];
export const GET_ALL_WORKFLOW_TASKS = ['WORKFLOW GET_ALL_WORKFLOW_TASKS'];
export const WORKFLOW_TASK_CHANGE_HANDLER = ['WORKFLOW WORKFLOW_TASK_CHANGE_HANDLER'];
export const EDIT_WORKFLOW_TASK_HANDLER = ['WORKFLOW EDIT_WORKFLOW_TASK_HANDLER'];
export const WORKFLOW_TABLE_VALUE_CHANGE_HANDLER = ['WORKFLOW WORKFLOW_TABLE_VALUE_CHANGE_HANDLER'];
export const CLEAR_WORKFLOW_TASK = ['WORKFLOW CLEAR_WORKFLOW_TASK'];
export const DELETE_TABLE_ROW = ['WORKFLOW DELETE_TABLE_ROW'];
export const DELETE_WORKFLOW_TABLE_ROW = ['WORKFLOW DELETE_WORKFLOW_TABLE_ROW'];
export const SET_TASK_RECURRENCE = ['WORKFLOW SET_TASK_RECURRENCE'];
export const SET_MANAGE_WORKFLOWS = ['WORKFLOW SET_MANAGE_WORKFLOWS'];
export const GET_ACTIVE_WORKFLOWS = ['WORKFLOW GET_ACTIVE_WORKFLOWS'];
export const ADD_WORKFLOWS = ['WORKFLOW ADD_WORKFLOWS'];
export const SET_WORKFLOW_DATA = ['WORKFLOW SET_WORKFLOW_DATA'];
export const SET_ALL_WORKFLOW_TASKS = ['WORKFLOW SET_ALL_WORKFLOW_TASKS'];
export const HANDLE_TASK_DETAILS_CHANGE = ['WORKFLOW HANDLE_TASK_DETAILS_CHANGE'];
export const SET_CLIENTS_FOR_WORKFLOW = ['WORKFLOW SET_CLIENTS_FOR_WORKFLOW'];
export const SET_RECURRENCE_DATA = ['WORKFLOW SET_RECURRENCE_DATA'];
export const ADD_WORKFLOWS_START = ['WORKFLOW ADD_WORKFLOWS_START'];
export const ADD_WORKFLOWS_FAILED = ['WORKFLOW ADD_WORKFLOWS_FAILED'];
export const SET_WORKFLOW_TASK = ['WORKFLOW SET_WORKFLOW_TASK'];
export const REMOVE_WORKFLOW_TASK = ['WORKFLOW REMOVE_WORKFLOW_TASK'];
export const BUILDER_SAVE_LOAD = ['WORKFLOW BUILDER_SAVE_LOAD'];
export const WORKFLOW_ACTIVATE_START = ['WORKFLOW WORKFLOW_ACTIVATE_START'];
export const WORKFLOW_ACTIVATE_END = ['WORKFLOW WORKFLOW_ACTIVATE_END'];
export const WORKFLOW_ACTIVATE_FAILED = ['WORKFLOW WORKFLOW_ACTIVATE_FAILED'];
export const UPDATE_DRAFT_WORKFLOW_DISPLAY_NAME = ['WORKFLOW UPDATE_DRAFT_WORKFLOW_DISPLAY_NAME'];

export const REMOVE_COLUMN = ['WORKFLOW REMOVE_COLUMN'];
export const SET_SELECTED_WORKFLOWS = ['WORKFLOW SET_SELECTED_WORKFLOWS'];
export const RESET_SELECTED_WORKFLOWS = ['WORKFLOW RESET_SELECTED_WORKFLOWS'];
export const DEACTIVATE_WORKFLOW_START = ['WORKFLOW DEACTIVATE_WORKFLOW_START'];
export const DEACTIVATE_WORKFLOW_END = ['WORKFLOW DEACTIVATE_WORKFLOW_END'];
export const DEACTIVATE_WORKFLOW_FAILED = ['WORKFLOW DEACTIVATE_WORKFLOW_FAILED'];
export const GET_PREBUILT_WORKFLOWS = ['WORKFLOW GET_PREBUILT_WORKFLOWS'];
export const GET_COMPANY_WORKFLOWS = ['WORKFLOW GET_COMPANY_WORKFLOWS'];
// export const GET_DRAFT_WORKFLOWS = ['WORKFLOW GET_DRAFT_WORKFLOWS'];
export const MOVE_WORKFLOWS_TO_DRAFT_LOADING = ['WORKFLOW MOVE_WORKFLOWS_TO_DRAFT_LOADING'];
export const WORKFLOW_LIBRARY_LOADING = ['WORKFLOW WORKFLOW_LIBRARY_LOADING'];
export const GET_SPECIFIC_WORKFLOW = ['WORKFLOW GET_SPECIFIC_WORKFLOW'];
export const RESET_WORKFLOW_FORM = ['WORKFLOW RESET_WORKFLOW_FORM'];
export const GET_TASK_TEMPLATES = ['WORKFLOW GET_TASK_TEMPLATES'];
export const GET_WORKFLOW_BUILDER_DATA = ['WORKFLOW GET_WORKFLOW_BUILDER_DATA'];

export const GET_ACTIVE_CLIENT_WORKFLOW = ['WORKFLOW GET_ACTIVE_CLIENT_WORKFLOW'];

export const WORKFLOW_NAME_CHANGE_HANDLER = ['WORKFLOW WORKFLOW_NAME_CHANGE_HANDLER'];
export const COLUMN_TITLE_CHANGE_HANDLER = ['WORKFLOW COLUMN_TITLE_CHANGE_HANDLER'];
export const UPDATE_CLIENT_WORKFLOW_TASKS = ['WORKFLOW UPDATE_CLIENT_WORKFLOW_TASKS'];
export const CHANGE_DEACTIVATE_WORKFLOW_TABLE_VALUE = ['CHANGE_DEACTIVATE_WORKFLOW_TABLE_VALUE'];
export const DEACTIVATE_WORKFLOW_TABLE_VALUE_CHANGE_HANDLER = [
	'DEACTIVATE_WORKFLOW_TABLE_VALUE_CHANGE_HANDLER',
];
export const ADD_NEW_TABLE_ROW_DEACTIVATE_WORKFLOW = ['ADD_NEW_TABLE_ROW_DEACTIVATE_WORKFLOW'];
export const DELETE_DEACTIVATE_TABLE_ROW = ['DELETE_DEACTIVATE_TABLE_ROW'];
export const SET_DUPLICATE_WORKFLOW_COMPONENT_VALUE = ['SET_DUPLICATE_WORKFLOW_COMPONENT_VALUE'];
export const DELETE_DEACTIVATED_WORKFLOW_TASK = ['DELETE_DEACTIVATED_WORKFLOW_TASK'];
export const SET_DEACTIVATED_WORKFLOW_DATA = ['SET_DEACTIVATED_WORKFLOW_DATA'];
export const SET_WORKFLOW_CATEGORIES = ['SET_WORKFLOW_CATEGORIES'];
export const SET_ASSIGNED_WORKFLOW_CATEGORIES = ['SET_ASSIGNED_WORKFLOW_CATEGORIES'];

export const WORKFLOW_BUILDER_LOADING = ['WORKFLOW_BUILDER_LOADING'];
export const PUBLISH_BUILDER_DATA_LOADING = ['PUBLISH_BUILDER_DATA_LOADING'];

export const UPLOAD_WORKFLOW_ICON_START = 'UPLOAD_WORKFLOW_ICON_START';
export const UPDATE_WORKFLOW_START = ['WORKFLOW UPDATE_WORKFLOW_START'];
export const UPDATE_WORKFLOW_END = ['WORKFLOW UPDATE_WORKFLOW_END'];
export const UPDATE_WORKFLOW_SUBMISSION_DATA = ['WORKFLOW UPDATE_WORKFLOW_SUBMISSION_DATA'];
export const UPDATE_WORKFLOW_FAILED = ['WORKFLOW UPDATE_WORKFLOW_FAILED'];
export const UPLOAD_WORKFLOW_ICON_FAILED = 'UPLOAD_WORKFLOW_ICON_FAILED';
export const UPLOAD_WORKFLOW_ICON = 'UPLOAD_WORKFLOW_ICON';
export const SET_ACTIVE_TAB = 'SET_ACTIVE_TAB';

export const SWAP_WORKFLOW_TASKS = 'SWAP_WORKFLOW_TASKS';
export const SWAP_WORKFLOW_TASKS_IN_CLIENT_PROFILE = 'SWAP_WORKFLOW_TASKS_IN_CLIENT_PROFILE';
export const SWAP_WORKFLOW_TASKS_IN_WORKFLOW_BUILDER = 'SWAP_WORKFLOW_TASKS_IN_WORKFLOW_BUILDER';
export const SET_CLIENT_WORKFLOW = 'SET_CLIENT_WORKFLOW';
export const SET_NEW_WORKFLOW_SUBMISSION = 'SET_NEW_WORKFLOW_SUBMISSION';
export const UPDATE_CLIENT_WORKFLOW_RECURRENCE = 'UPDATE_CLIENT_WORKFLOW_RECURRENCE';
export const SET_CLIENT_WORKFLOW_LOADING = 'SET_CLIENT_WORKFLOW_LOADING';
export const UPDATE_CLIENT_WORKFLOWS_LIST = 'UPDATE_CLIENT_WORKFLOWS_LIST';
export const REMOVE_WORKFLOW = 'REMOVE_WORKFLOW';
export const SET_WORKFLOWS_TO_ACTIVATE = 'SET_WORKFLOWS_TO_ACTIVATE';
export const SET_CLIENT_FOR_WORKFLOW_ACTIVATION = 'SET_CLIENT_FOR_WORKFLOW_ACTIVATION';
export const SET_WORKFLOWS_TO_DRAFT = 'SET_WORKFLOWS_TO_DRAFT';
export const HANDLE_WORKFLOW_ATTACHMENTS = 'HANDLE_WORKFLOW_ATTACHMENTS';
export const UPDATE_COMPANY_WORKFLOWS = 'UPDATE_COMPANY_WORKFLOWS';
export const FILTER_COMPANY_WORKFLOWS = 'FILTER_COMPANY_WORKFLOWS';
export const SET_CLIENT_WORKFLOW_TASK = 'SET_CLIENT_WORKFLOW_TASK';

export const updateWorkflows = (clientID, updatedWorkflow, callback) => {
	return async (dispatch) => {
		const newWorkflowBuilder = {
			componentName: updatedWorkflow?.data?.componentName,
			displayName: updatedWorkflow?.data?.displayName,
			props: updatedWorkflow?.data?.props,
			propsTypes: updatedWorkflow?.data?.propsTypes,
		};

		const newObj = updatedWorkflow;
		delete newObj.category;
		newObj.id = updatedWorkflow?.data?.workflow_id;

		const array = [newWorkflowBuilder];

		try {
			dispatch(setBuilderSaveLoad(true));

			const request = await secure_instance.request({
				url: '/v1/workflow_builder/' + updatedWorkflow?.data?._id,
				method: 'PATCH',
				data: {
					...updatedWorkflow,
					id: updatedWorkflow?.data?.workflow_id,
					data: array,
				},
			});

			dispatch(setBuilderSaveLoad(false));

			dispatch({
				type: UPDATE_WORKFLOWS,
			});

			callback();
		} catch (e) {
			//
			dispatch(setBuilderSaveLoad(false));
			if (e?.response) {
				alert(e?.response?.data?.message);
			}
		}
	};
};

export const updateWorkflowBuilderData = (updatedWorkflow) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/workflow_builder/' + updatedWorkflow?._id,
				method: 'PATCH',
				data: {
					id: updatedWorkflow?.workflow_id,
					data: [updatedWorkflow?.data],
				},
			});

			dispatch({
				type: UPDATE_WORKFLOWS,
			});
			return request?.data;
		} catch (e) {
			throw new Error(e?.response?.data?.message || e?.message || 'Something went wrong');
		}
	};
};

export const setBuilderSaveLoad = (state) => {
	return (dispatch) => {
		dispatch({ type: BUILDER_SAVE_LOAD, payload: state });
	};
};

export const publishBuilderDataLoading = (state) => {
	return (dispatch) => {
		dispatch({ type: PUBLISH_BUILDER_DATA_LOADING, payload: state });
	};
};

export const updateWorkflowValues = (clientID, workflowID, workflow, setLoading) => {
	return async (dispatch) => {
		try {
			const newWorkflowSubmission = {
				componentName: workflow.componentName,
				displayName: workflow.displayName,
				props: workflow.props,
				propsTypes: workflow.propsTypes,
			};

			const request = await secure_instance.request({
				url: '/v1/workflow_submissions/' + workflowID,
				method: 'patch',
				data: { data: newWorkflowSubmission },
			});

			dispatch({
				type: UPDATE_WORKFLOW_SUBMISSION_DATA,
				payload: request.data,
			});

			setLoading && setLoading(false);
		} catch (e) {
			dispatch({
				type: UPDATE_WORKFLOW_FAILED,
			});
		}
	};
};

export const getAllWorkflows = (clientID) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/workflow_submissions/?client_id=' + clientID,
				method: 'Get',
			});

			dispatch({
				type: GET_ALL_WORKFLOWS,
				payload: request.data?.map((item) => {
					const obj = item.data[0];
					return {
						...obj,
						_id: item?._id,
						workflow_id: item?.workflow_id,
						v: item?.version,
					};
				}),
			});
		} catch (e) {
			//
			dispatch({
				type: GET_ALL_WORKFLOWS,
				payload: [],
			});
		}
	};
};

export const setComponent = (data) => {
	return async (dispatch) => {
		dispatch({
			type: SET_SELECTED_COMPONENT,
			payload: data,
		});
	};
};

export const resetWorkflowState = () => {
	return async (dispatch) => {
		dispatch({
			type: RESET_WORKFLOW_STATE,
		});
	};
};

export const resetWorkflowForm = () => {
	return async (dispatch) => {
		dispatch({
			type: RESET_WORKFLOW_FORM,
		});
	};
};

export const updateComponent = (data) => {
	return async (dispatch) => {
		dispatch({
			type: UPDATE_SELECTED_COMPONENT,
			payload: data,
		});
		dispatch({
			type: UPDATE_WORKFLOW_COMPONENT,
		});
	};
};

export const updateComponentOptions = (index, value) => {
	return async (dispatch) => {
		dispatch({
			type: UPDATE_COMPONENT_OPTIONS,
			payload: { index: index, value: value },
		});
		dispatch({
			type: UPDATE_WORKFLOW_COMPONENT,
		});
	};
};

export const setWorkflow = (data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/workflow_builder/' + data?.workflow_id,
				method: 'Get',
			});
			dispatch({
				type: SET_SELECTED_WORKFLOW,
				payload: {
					...request?.data?.data[0],
					_id: request?.data?._id,
					workflow_id: request?.data?.workflow_id,
				},
			});
		} catch (e) {
			//
		}
	};
};

export const setSelectedWorkflow = (workflow) => {
	return (dispatch) => {
		dispatch({
			type: SET_SELECTED_WORKFLOW,
			payload: workflow,
		});
	};
};

export const updateWorkflow = (component, colIndex, secIndex, withinColumnIndex) => {
	return async (dispatch) => {
		dispatch({
			type: UPDATE_SELECTED_WORKFLOW,
			payload: {
				Component: component,
				columnIndex: colIndex,
				sectionIndex: secIndex,
				withinColumnIndex: withinColumnIndex,
			},
		});
		dispatch({
			type: SET_SELECTED_COMPONENT,
			payload: component,
		});
	};
};

export const swapComponent = (id, newIndex) => {
	return async (dispatch) => {
		dispatch({
			type: REMOVE_COMPONENT,
			payload: { componentId: id },
		});
		dispatch({
			type: SWAP_COMPONENT,
			payload: { newIndex: newIndex },
		});
		dispatch({
			type: UPDATE_WORKFLOW_COMPONENT,
		});
	};
};

export const deleteComponent = (id) => {
	return async (dispatch) => {
		dispatch({
			type: REMOVE_COMPONENT,
			payload: { componentId: id },
		});
		dispatch({
			type: SET_SELECTED_COMPONENT,
			payload: undefined,
		});
		dispatch({
			type: UPDATE_WORKFLOW_COMPONENT,
		});
	};
};

export const addComponentOption = (data) => {
	return async (dispatch) => {
		dispatch({
			type: ADD_COMPONENT_OPTIONS,
			payload: data,
		});
		dispatch({
			type: UPDATE_WORKFLOW_COMPONENT,
		});
	};
};

export const deleteComponentOption = (index) => {
	return async (dispatch) => {
		dispatch({
			type: DELETE_COMPONENT_OPTIONS,
			payload: index,
		});
		dispatch({
			type: UPDATE_WORKFLOW_COMPONENT,
		});
	};
};

export const setAccountsComponentValue = (newWorkflowSubmission, workflowSubmissionId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/workflow_submissions/' + workflowSubmissionId,
				method: 'patch',
				data: { data: newWorkflowSubmission },
			});

			dispatch({
				type: SET_NEW_WORKFLOW_SUBMISSION,
				payload: [request.data.data[0]],
			});

			return request.data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || 'Something went wrong');
		}
	};
};

export const setDuplicateWorkflowComponentValue = (item) => {
	return async (dispatch) => {
		dispatch({
			type: SET_DUPLICATE_WORKFLOW_COMPONENT_VALUE,
			payload: item,
		});
	};
};

export const setWorkflowComponentValue = (item) => {
	return async (dispatch) => {
		dispatch({
			type: SET_WORKFLOW_COMPONENT_VALUE,
			payload: item,
		});
	};
};
export const setWorkflowAccounts = (data) => {
	return async (dispatch) => {
		dispatch({
			type: SET_WORKFLOW_ACCOUNTS,
			payload: data,
		});
	};
};

export const addNewTableRow = (clientWorkflowId, sectionIndex, data) => {
	return async (dispatch) => {
		dispatch({
			type: ADD_NEW_TABLE_ROW,
			payload: {
				clientWorkflowId: clientWorkflowId,
				sectionIndex: sectionIndex,
				data: data,
			},
		});
	};
};

export const addNewTableRowToDeActivateWorkflow = (workflowName, sectionIndex, data) => {
	return async (dispatch) => {
		dispatch({
			type: ADD_NEW_TABLE_ROW_DEACTIVATE_WORKFLOW,
			payload: {
				workflowName: workflowName,
				sectionIndex: sectionIndex,
				data: data,
			},
		});
	};
};

export const addNewTableWorkflowRow = (workflowName, sectionIndex, data) => {
	console.log(data, workflowName, sectionIndex, 'first step');
	return async (dispatch) => {
		dispatch({
			type: ADD_NEW_TABLE_WORKFLOW_ROW,
			payload: {
				workflowName: workflowName,
				sectionIndex: sectionIndex,
				data: data,
			},
		});
	};
};

export const changeTableValue = (
	value,
	clientWorkflowId,
	sectionIndex,
	rowIndex,
	withinRowIndex,
) => {
	return async (dispatch) => {
		dispatch({
			type: CHANGE_TABLE_VALUE,
			payload: {
				value: value,
				clientWorkflowId: clientWorkflowId,
				sectionIndex: sectionIndex,
				rowIndex: rowIndex,
				withinRowIndex: withinRowIndex,
			},
		});
	};
};

export const changeDeactivateWorkflowTableValue = (
	value,
	workflowName,
	sectionIndex,
	rowIndex,
	withinRowIndex,
) => {
	return async (dispatch) => {
		dispatch({
			type: CHANGE_DEACTIVATE_WORKFLOW_TABLE_VALUE,
			payload: {
				value: value,
				workflowName: workflowName,
				sectionIndex: sectionIndex,
				rowIndex: rowIndex,
				withinRowIndex: withinRowIndex,
			},
		});
	};
};

export const changeWorkflowTableValue = (
	value,
	workflowName,
	sectionIndex,
	rowIndex,
	withinRowIndex,
) => {
	return async (dispatch) => {
		dispatch({
			type: CHANGE_WORKFLOW_TABLE_VALUE,
			payload: {
				value: value,
				workflowName: workflowName,
				sectionIndex: sectionIndex,
				rowIndex: rowIndex,
				withinRowIndex: withinRowIndex,
			},
		});
	};
};

export const deleteTableRow = (clientWorkflowId, sectionIndex, rowIndex) => {
	return async (dispatch) => {
		dispatch({
			type: DELETE_TABLE_ROW,
			payload: {
				clientWorkflowId: clientWorkflowId,
				sectionIndex: sectionIndex,
				rowIndex: rowIndex,
			},
		});
	};
};

export const deleteDeactivateTableRow = (workflowName, sectionIndex, rowIndex) => {
	return async (dispatch) => {
		dispatch({
			type: DELETE_DEACTIVATE_TABLE_ROW,
			payload: {
				workflowName: workflowName,
				sectionIndex: sectionIndex,
				rowIndex: rowIndex,
			},
		});
	};
};

export const deleteWorkflowTableRow = (workflowName, sectionIndex, rowIndex) => {
	return async (dispatch) => {
		dispatch({
			type: DELETE_WORKFLOW_TABLE_ROW,
			payload: {
				workflowName: workflowName,
				sectionIndex: sectionIndex,
				rowIndex: rowIndex,
			},
		});
	};
};

export const getAllWorkflowTasks = (clientID) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/client_workflows/?client_id=` + Number(clientID),
				method: 'Get',
			});

			dispatch({
				type: GET_ALL_WORKFLOW_TASKS,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const getActiveClientWorkflows = (clientID) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/clients/active_workflows/?client_id=${clientID}`,
				method: 'Get',
			});

			console.log(request, 'req active');
			dispatch({
				type: GET_ACTIVE_CLIENT_WORKFLOW,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const addWorkflowTask = (task) => {
	return async (dispatch) => {
		try {
			// Create Unique Id
			task.id = 'new' + Math.floor(new Date().getTime() / 10000);

			// console.info(task);
			// console.log('🚀 ~ file: workflow-builder-actions.js ~ line 598 ~ return ~ task', task);

			dispatch({
				type: ADD_WORKFLOW_TASK,
				payload: task,
			});

			dispatch({
				type: CLEAR_WORKFLOW_TASK,
			});
		} catch (e) {
			//
		}
	};
};

export const editWorkflowTask = (task) => {
	return async (dispatch) => {
		try {
			// console.info(task);
			// console.log('🚀 ~ file: workflow-builder-actions.js ~ line 598 ~ return ~ task', task);

			dispatch({
				type: EDIT_WORKFLOW_TASK,
				payload: task,
			});

			dispatch({
				type: CLEAR_WORKFLOW_TASK,
			});
		} catch (e) {
			//
		}
	};
};

export const setWorkflowTask = (task) => {
	return async (dispatch) => {
		dispatch({
			type: SET_WORKFLOW_TASK,
			payload: task,
		});
	};
};

export const updateDraftWorkflowDisplayName = (clientWorkflowId, barTextValue) => {
	console.log(clientWorkflowId, barTextValue, 'inactionfunction');
	return async (dispatch) => {
		dispatch({
			type: UPDATE_DRAFT_WORKFLOW_DISPLAY_NAME,
			payload: {
				clientWorkflowId: clientWorkflowId,
				barTextValue: barTextValue,
			},
		});
	};
};

export const clearWorkflowTaskValues = () => {
	return async (dispatch) => {
		dispatch({
			type: CLEAR_WORKFLOW_TASK,
		});
	};
};

export const deleteWorkflowTask = (clientWorkflowTaskId, clientID) => {
	return async (dispatch) => {
		dispatch({
			type: DELETE_WORKFLOW_TASK,
			payload: clientWorkflowTaskId,
		});
	};
};

export const deleteDraftWorkflow = (client_workflow_id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/client_workflows/${client_workflow_id}`,
				method: 'delete',
			});

			dispatch({
				type: REMOVE_WORKFLOW,
				payload: client_workflow_id,
			});

			return request.data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || 'Something went wrong');
		}
	};
};

export const deactivatetWorkflow = (client_workflow_id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/client_workflows/${client_workflow_id}/deactivate`,
				method: 'patch',
			});

			dispatch({
				type: REMOVE_WORKFLOW,
				payload: client_workflow_id,
			});

			return request.data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || 'Something went wrong');
		}
	};
};

export const deletedDeactivatedWorkflowTask = (clientWorkflowTaskId, clientID) => {
	return async (dispatch) => {
		dispatch({
			type: DELETE_DEACTIVATED_WORKFLOW_TASK,
			payload: clientWorkflowTaskId,
		});
	};
};

export const deleteWorkflowTaskRow = (index, clientWorkflowTaskId, workflowID) => {
	return async (dispatch) => {
		dispatch({
			type: REMOVE_WORKFLOW_TASK,
			payload: {
				taskIndex: index,
				taskId: clientWorkflowTaskId,
				workflowId: workflowID,
			},
		});
	};
};

export const workflowTaskChangeHandler = (data) => {
	return async (dispatch) => {
		dispatch({
			type: WORKFLOW_TASK_CHANGE_HANDLER,
			payload: {
				name: data.name,
				value: data.value,
			},
		});
	};
};

export const editWorkflowTaskHandler = (data) => {
	return async (dispatch) => {
		dispatch({
			type: EDIT_WORKFLOW_TASK_HANDLER,
			payload: data,
		});
	};
};

export const updateWorkflowTableValue = (taskID, value, indexName, client_workflow_id) => {
	return async (dispatch) => {
		dispatch({
			type: WORKFLOW_TABLE_VALUE_CHANGE_HANDLER,
			payload: {
				id: taskID,
				value: value,
				indexName: indexName,
				client_workflow_id,
			},
		});
	};
};

export const updateDeactivateWorkflowTableValue = (taskID, value, indexName) => {
	return async (dispatch) => {
		dispatch({
			type: DEACTIVATE_WORKFLOW_TABLE_VALUE_CHANGE_HANDLER,
			payload: {
				id: taskID,
				value: value,
				indexName: indexName,
			},
		});
	};
};

export const updateWorkflowTableValues = (clientWorkflow, keepTasks, setLoading) => {
	return async (dispatch) => {
		if (clientWorkflow.workflow_builder) {
			delete clientWorkflow.workflow_builder;
			delete clientWorkflow.workflow_submission;
		}

		try {
			dispatch({
				type: WORKFLOW_ACTIVATE_START,
			});

			await secure_instance.request({
				url: `/v1/client_workflows/${clientWorkflow?.id}/?keep_task=${keepTasks}`,
				method: 'patch',
				data: clientWorkflow,
			});

			dispatch(getAllWorkflowTasks(clientWorkflow?.client_id));
			dispatch(getAllWorkflows(clientWorkflow?.client_id));
		} catch (e) {
			if (e?.response) {
				alert(e?.response?.data?.message);
				dispatch({ type: WORKFLOW_ACTIVATE_FAILED });
			}
		}
	};
};

export const changeClientWorkflowTaskRecurrence = (clientWorkflow, callback) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_CLIENT_WORKFLOW_TASKS,
			payload: clientWorkflow,
		});
		callback && callback();
	};
};

export const setTaskRecurrence = (workflowID, taskRecurrence) => {
	return async (dispatch) => {
		dispatch({
			type: SET_TASK_RECURRENCE,
			payload: { taskRecurrence: taskRecurrence, workflowID: workflowID },
		});
	};
};

export const getWorkflowsList = (clientId) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: ADD_WORKFLOWS_START,
			});

			const result = await secure_instance.request({
				url: `/v1/client_workflows/workflow_tasks${
					clientId ? '?client_id=' + clientId : ''
				}`,
				method: 'Get',
			});
			dispatch({
				type: SET_MANAGE_WORKFLOWS,
				payload: result.data,
			});

			dispatch({
				type: ADD_WORKFLOWS_FAILED,
			});
		} catch {
			//
			dispatch({
				type: ADD_WORKFLOWS_FAILED,
			});
		}
	};
};

export const getActiveWorkflowsList = (clientId) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: ADD_WORKFLOWS_START,
			});
			const result = await secure_instance.request({
				url: `/v1/client_workflows/?client_id=${clientId}&expand=[workflow.category,client_workflow_tasks(defaultOrder)]`,
				method: 'Get',
			});
			console.log(result, 'results');
			dispatch({
				type: GET_ACTIVE_WORKFLOWS,
				payload: result.data,
			});
		} catch {
			//
		}
	};
};

export const activateWorkflow = (data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: ADD_WORKFLOWS_START,
			});
			dispatch(setBuilderSaveLoad(true));
			const result = await secure_instance.request({
				url: `/v1/client_workflows/`,
				method: 'Post',
				data: data,
			});

			dispatch({
				type: ADD_WORKFLOWS,
				payload: result.data,
			});
			dispatch(setBuilderSaveLoad(false));

			return result.data;
		} catch (error) {
			dispatch({
				type: ADD_WORKFLOWS_FAILED,
			});
			dispatch(setBuilderSaveLoad(false));

			if (error?.response?.data?.duplicate_workflows?.length !== 0) {
				const requestData = {
					duplicate_workflows: error?.response?.data?.duplicate_workflows,
					valid_workflows: [],
				};
				dispatch(setWorkflowsToActivate(requestData));
			} else {
				dispatch(setWorkflowsToActivate());
			}

			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong!',
			);
		}
	};
};

export const setWorkflowsToActivate = (
	data = {
		duplicate_workflows: [],
		valid_workflows: [],
	},
) => {
	return (dispatch) => {
		dispatch({
			type: SET_WORKFLOWS_TO_ACTIVATE,
			payload: data,
		});
	};
};

export const setWorkflowsToDraft = (
	data = {
		draft_duplicate_workflows: [],
		draft_valid_workflows: [],
	},
) => {
	return (dispatch) => {
		dispatch({
			type: SET_WORKFLOWS_TO_DRAFT,
			payload: data,
		});
	};
};

export const setClientSelectedForWorkflowActivation = (selectedClients) => {
	return (dispatch) => {
		dispatch({
			type: SET_CLIENT_FOR_WORKFLOW_ACTIVATION,
			payload: selectedClients,
		});
	};
};

export const setWorkflowData = (data) => {
	return async (dispatch) => {
		dispatch({
			type: SET_WORKFLOW_DATA,
			payload: data,
		});
	};
};
export const setAllWorkflowTasks = (data) => {
	return async (dispatch) => {
		dispatch({
			type: SET_ALL_WORKFLOW_TASKS,
			payload: data,
		});
	};
};
export const setDeactivatedWorkflowData = (data) => {
	return async (dispatch) => {
		dispatch({
			type: SET_DEACTIVATED_WORKFLOW_DATA,
			payload: data,
		});
	};
};

export const setSelectedWorkflows = (data) => {
	return async (dispatch) => {
		dispatch({
			type: SET_SELECTED_WORKFLOWS,
			payload: data,
		});
	};
};

export const resetSelectedWorkflows = (data) => {
	return async (dispatch) => {
		dispatch({
			type: RESET_SELECTED_WORKFLOWS,
			payload: data,
		});
	};
};

export const handleTaskDetailsChange = (data) => {
	return async (dispatch) => {
		dispatch({
			type: HANDLE_TASK_DETAILS_CHANGE,
			payload: data,
		});
	};
};

export const toggleWorkflow = (id, clientID, isActive, setLoadingItems, loadingItems) => {
	return async (dispatch) => {
		try {
			const result = await secure_instance.request({
				url: `/v1/client_workflows/${id}`,
				method: 'patch',
				data: {
					is_active: !isActive,
				},
			});
			dispatch(getWorkflowsList(clientID));
			setLoadingItems(loadingItems.filter((items) => items != id));
		} catch {
			//
			setLoadingItems([]);
		}
	};
};

export const handleColumnTitleChangeHanlder = (value, columnIndex) => {
	return (dispatch) => {
		dispatch({
			type: COLUMN_TITLE_CHANGE_HANDLER,
			payload: {
				value,
				columnIndex,
			},
		});
	};
};

export const removeColumn = (columnIndex) => {
	return (dispatch) => {
		dispatch({
			type: REMOVE_COLUMN,
			payload: { columnIndex },
		});
	};
};

export const workflowNameChangeHandler = (value) => {
	return (dispatch) => {
		dispatch({
			type: WORKFLOW_NAME_CHANGE_HANDLER,
			payload: {
				value,
			},
		});
	};
};

export const createNewWorkflow = (workflow, callBack) => {
	return async (dispatch) => {
		try {
			const workflowData = { ...workflow };

			delete workflowData?.data?.workflow_id;
			delete workflowData?.data?.duplicate;
			delete workflowData?.data?.duplicateWorkflowId;
			delete workflowData?.category;

			dispatch(setBuilderSaveLoad(true));

			const workflowId = workflow?.data?.duplicateWorkflowId || workflow?.data?.workflow_id;

			const result = await secure_instance.request({
				url: `/v1/workflows/create_custom_workflow${
					workflow.duplicate ? '?duplicate=true&workflow_id=' + workflowId : ''
				}`,
				method: 'POST',
				data: workflowData,
			});

			setTimeout(() => {
				dispatch(setBuilderSaveLoad(false));
				callBack();
			}, 2000);
		} catch (error) {
			//
			dispatch(setBuilderSaveLoad(false));
			if (error?.response) {
				alert(error?.response?.data?.message);
			}
		}
	};
};

export const getClientsForWorkflow = (workflowId) => {
	return async (dispatch) => {
		try {
			const result = await secure_instance.request({
				url: `/v1/client_workflows/workflow_client_count/${workflowId}`,
				method: 'get',
			});

			dispatch({
				type: SET_CLIENTS_FOR_WORKFLOW,
				payload: result.data.length,
			});
		} catch (error) {
			dispatch({
				type: SET_CLIENTS_FOR_WORKFLOW,
				payload: 0,
			});
		}
	};
};

export const workflowFormChangeHanlder = (name, value) => {
	return (dispatch) => {
		dispatch({
			type: WORKFLOW_FORM_CHANGE_HANDLER,
			payload: { value, name },
		});
	};
};

export const setWorkflowForm = (form) => {
	return (dispatch) => {
		dispatch({
			type: SET_WORKFLOW_FORM,
			payload: form,
		});
	};
};

export const setFormError = (error) => {
	return (dispatch) => {
		dispatch({
			type: SET_ERROR,
			payload: error,
		});
	};
};

export const deActivateWorkflow = (clientWorkflowId, clientId) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: DEACTIVATE_WORKFLOW_START,
			});
			const result = await secure_instance.request({
				url: `/v1/client_workflows/deactivate_client_workflow/${clientWorkflowId}`,
				method: 'get',
			});

			dispatch({
				type: DEACTIVATE_WORKFLOW_END,
			});

			console.log('result', result);
			dispatch(getActiveWorkflowsList(clientId));
			dispatch(getWorkflowsList(clientId));
			dispatch(getAllWorkflows(clientId));
			dispatch(getAllWorkflowTasks(clientId));
		} catch (error) {
			dispatch({
				type: DEACTIVATE_WORKFLOW_FAILED,
			});
		}
	};
};

export const reActivateWorkflow = (clientWorkflowData, clientId, setSelected) => {
	return async (dispatch) => {
		delete clientWorkflowData.workflow;
		delete clientWorkflowData.workflow_builder;

		console.info('clientWorkflowData', clientWorkflowData);
		try {
			const result = await secure_instance.request({
				url: `/v1/client_workflows/reactivate_client_workflow/${clientWorkflowData.id}`,
				method: 'patch',
				data: clientWorkflowData,
			});
			dispatch(getActiveWorkflowsList(clientId));
			dispatch(getWorkflowsList(clientId));
			dispatch(getAllWorkflows(clientId));
			dispatch(getAllWorkflowTasks(clientId));
			setSelected && setSelected(false);
		} catch (e) {
			if (e?.response) {
				alert(e?.response?.data?.message);
			}
		}
	};
};

export const setTaskTemplatesArray = (taskArray) => {
	return (dispatch) => {
		dispatch({
			type: SET_TASK_TEMPLATES_ARRAY,
			payload: taskArray,
		});
	};
};

export const removeTasksInTaskTemplates = (taskId) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/task_templates/${taskId}`,
				method: 'DELETE',
			});
			return request?.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const taskTemplatesChangeHandler = (name, value, taskId) => {
	return (dispatch) => {
		dispatch({
			type: TASK_TEMPLATES_CHANGE_HANDLER,
			payload: { name, value, taskId },
		});
	};
};

export const updateTaskTemplate = (taskId, data) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/task_templates/${taskId}`,
				method: 'PATCH',
				data: data,
			});

			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const addTaskTemplate = (data) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/task_templates/`,
				method: 'POST',
				data: data,
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const getWorkflowCategories = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/categories/?expand=[workflows]',
				method: 'get',
			});

			dispatch({
				type: SET_WORKFLOW_CATEGORIES,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const getPreBuiltWorkflows = () => {
	return async (dispatch) => {
		try {
			dispatch(workflowLibraryLoading(true));
			const request = await secure_instance.request({
				url: '/v1/workflow_libraries/',
				method: 'Get',
			});

			dispatch({
				type: GET_PREBUILT_WORKFLOWS,
				payload: request.data,
			});
			dispatch(workflowLibraryLoading(false));
		} catch (error) {
			dispatch(workflowLibraryLoading(false));
		}
	};
};

export const updateWorkflowfromBuilder = (workflowId, data) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/workflows/${workflowId}`,
				method: 'PATCH',
				data: data,
			});
			return request?.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const getCompanyWorkflows = () => {
	return async (dispatch) => {
		try {
			dispatch(workflowLibraryLoading(true));
			const request = await secure_instance.request({
				url: '/v1/workflows/company_workflows',
				method: 'Get',
			});

			dispatch({
				type: GET_COMPANY_WORKFLOWS,
				payload: request.data,
			});
			dispatch(workflowLibraryLoading(false));
		} catch (error) {
			dispatch(workflowLibraryLoading(false));
		}
	};
};

export const updateCompanyWorkflow = (data) => {
	return {
		type: UPDATE_COMPANY_WORKFLOWS,
		payload: data,
	};
};

export const filterCompanyWorkflow = (data) => {
	return {
		type: FILTER_COMPANY_WORKFLOWS,
		payload: data,
	};
};

// export const getDraftWorkflows = () => {
// 	return async (dispatch) => {
// 		try {
// 			dispatch(workflowLibraryLoading(true));
// 			const request = await secure_instance.request({
// 				url: '/v1/workflows/company_workflows?status=DRAFT',
// 				method: 'Get',
// 			});

// 			dispatch({
// 				type: GET_DRAFT_WORKFLOWS,
// 				payload: request.data,
// 			});
// 			dispatch(workflowLibraryLoading(false));

// 		} catch (error) {
// 			dispatch(workflowLibraryLoading(false));

// 		}
// 	};
// };

export const getSpecificWorkflowById = (workflowId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/workflows/${workflowId}`,
				method: 'Get',
			});

			dispatch({
				type: GET_SPECIFIC_WORKFLOW,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const getWorkflowBuilderData = (workflowId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/workflow_builder/?workflow_id=${workflowId}`,
				method: 'Get',
			});

			console.log(request, 'request123123');
			dispatch({
				type: GET_WORKFLOW_BUILDER_DATA,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const getTaskTemplates = (workflowId) => {
	return async (dispatch) => {
		try {
			dispatch(workflowLibraryLoading(true));
			const request = await secure_instance.request({
				url: `/v1/task_templates/?workflow_id=${workflowId}`,
				method: 'Get',
			});

			dispatch({
				type: GET_TASK_TEMPLATES,
				payload: request.data,
			});
			dispatch(workflowLibraryLoading(false));
		} catch (error) {
			dispatch(workflowLibraryLoading(false));
		}
	};
};

export const setActiveTab = (id) => {
	return async (dispatch) =>
		dispatch({
			type: SET_ACTIVE_TAB,
			payload: id,
		});
};

export const workflowLibraryLoading = (state) => {
	return (dispatch) => {
		dispatch({ type: WORKFLOW_LIBRARY_LOADING, payload: state });
	};
};

export const workflowBuilderLoading = (state) => {
	return (dispatch) => {
		dispatch({ type: WORKFLOW_BUILDER_LOADING, payload: state });
	};
};

export const createNewWorkflowFromLibrary = (history, clientForbuilder) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/workflows/',
				method: 'Post',
			});
			if (request?.data && history) {
				const workflowId = request?.data?.id;
				history.push(`/workflow-editor/${workflowId}`, clientForbuilder);
			}
			return request.data;
		} catch (error) {
			throw new Error(error);
		}
	};
};

export const moveWorkflowsToDraft = (data) => {
	return async (dispatch) => {
		try {
			dispatch(workflowLibraryLoading(true));
			dispatch(setBuilderSaveLoad(true));
			const request = await secure_instance.request({
				url: '/v1/workflows/draft',
				method: 'POST',
				data: data,
			});

			dispatch(workflowLibraryLoading(false));
			dispatch(setBuilderSaveLoad(false));
			// dispatch(
			// 	setSnackBarIsOpen(
			// 		true,
			// 		true,
			// 		request?.data.length > 1
			// 			? "Selected workflows are successfully moved to Company's Library"
			// 			: "Workflow is moved to Company's Library",
			// 	),
			// );
			dispatch(getPreBuiltWorkflows());
			dispatch(getCompanyWorkflows());
			return request.data;
		} catch (error) {
			if (error?.response?.data?.duplicateWorkflows?.length > 0) {
				dispatch({
					type: SET_WORKFLOWS_TO_DRAFT,
					payload: {
						draft_duplicate_workflows: error?.response?.data?.duplicateWorkflows,
						draft_valid_workflows: error?.response?.data?.saved_workflows || [],
					},
				});
			}
			dispatch(setBuilderSaveLoad(false));
			dispatch(workflowLibraryLoading(false));
			throw new Error('Something went wrong.');
			// dispatch(setSnackBarIsOpen(false, true, "Already added to Company's Library"));
		}
	};
};

export const publishWorkflowsFromDraft = (data) => {
	return async (dispatch) => {
		try {
			dispatch(setBuilderSaveLoad(true));
			const request = await secure_instance.request({
				url: '/v1/workflows/publish',
				method: 'POST',
				data: data,
			});

			dispatch(setBuilderSaveLoad(false));
			dispatch(
				setSnackBarIsOpen(
					true,
					true,
					request?.data?.length > 1
						? 'Selected workflows are successfully published'
						: 'Workflow is successfully published',
				),
			);
			dispatch(getCompanyWorkflows());
		} catch (error) {
			console.log(error, 'error1234');
			dispatch(setBuilderSaveLoad(false));
			dispatch(setSnackBarIsOpen(false, true, 'Failed to Publish the workflow'));
		}
	};
};

export const archiveWorkflow = (workflowId) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/workflows/${workflowId}`,
				method: 'DELETE',
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const unArchiveWorkflow = (workflowId) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `v1/workflows/${workflowId}/unarchive`,
				method: 'PATCH',
			});

			return request?.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const getAssignedWorkflowCategories = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/workflows/?expand=[team_workflows,categories,tenant_profile,user_profile]',
				method: 'get',
			});

			dispatch({
				type: SET_ASSIGNED_WORKFLOW_CATEGORIES,
				payload: request.data.map((workflow) => {
					return {
						workflowId: workflow?.id,
						id: workflow?.categories[0]?.id,
						name: workflow?.categories[0]?.name,
						tenant_profile: workflow?.tenant_profile,
						user_profile: workflow?.user_profile,
					};
				}),
			});
		} catch (error) {
			//
		}
	};
};

export const getArchievedWorkflows = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/workflows/archive_workflows?expand=[team_workflows,categories,tenant_profile,user_profile]',
				method: 'get',
			});

			dispatch({
				type: SET_ALL_ARCHIEVED_WORKFLOWS,
				payload: request.data,
			});
		} catch (error) {
			console.info(error);
		}
	};
};

export const archievedWorkflow = (workflowId) => {
	return async (dispatch) => {
		try {
			await secure_instance.request({
				url: `/v1/workflows/archive/${workflowId}`,
				method: 'delete',
			});

			dispatch(getArchievedWorkflows());
			dispatch(getWorkflowsList());
		} catch (error) {
			console.info(error);
		}
	};
};

export const retrieveWorkflow = (workflowId) => {
	return async (dispatch) => {
		try {
			await secure_instance.request({
				url: `/v1/workflows/retrieve/${workflowId}`,
				method: 'patch',
			});

			dispatch(getArchievedWorkflows());
		} catch (error) {
			console.info(error);
		}
	};
};

export const uploadWorkflowIcon = (image) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: UPLOAD_WORKFLOW_ICON_START,
			});

			const formData = new FormData();
			formData.append('image', image);

			const request = await secure_instance.request({
				url: 'v1/user_profiles/upload',
				method: 'Post',
				data: formData,
			});

			dispatch({
				type: UPLOAD_WORKFLOW_ICON,
				payload: request.data.data,
			});
		} catch (e) {
			dispatch({
				type: UPLOAD_WORKFLOW_ICON_FAILED,
			});
		}
	};
};

export const swapWorkflowTasks = (data) => {
	const swappedArray = new workflowReusableUtils(data.tasks, data.from, data.to).swapWorkflowtask;

	return (dispatch) => {
		dispatch({
			type: SWAP_WORKFLOW_TASKS,
			payload: [...swappedArray],
		});
	};
};

export const swapWorkflowTasksInClientProfile = (data) => {
	const swappedArray = new workflowReusableUtils(data.array, data.from, data.to).swapWorkflowtask;
	console.log(
		'🚀 ~ file: workflow-builder-actions.js ~ line 1236 ~ swapWorkflowTasks ~ swappedArray',
		swappedArray,
	);

	return (dispatch) => {
		dispatch({
			type: SWAP_WORKFLOW_TASKS_IN_CLIENT_PROFILE,
			payload: { result: [...swappedArray], id: data.id },
		});
	};
};
export const swapWorkflowTasksInWorkflowBuilder = (data) => {
	console.log(
		'🚀 ~ file: workflow-builder-actions.js ~ line 1267 ~ swapWorkflowTasksInWorkflowBuilder ~ swappedArray',
		data.array,
	);
	const swappedArray = new workflowReusableUtils(data.array, data.from, data.to).swapWorkflowtask;
	console.log(
		'🚀 ~ file: workflow-builder-actions.js ~ line 1267 ~ swapWorkflowTasksInWorkflowBuilder ~ swappedArray',
		swappedArray,
	);
	return (dispatch) => {
		dispatch({
			type: SWAP_WORKFLOW_TASKS_IN_WORKFLOW_BUILDER,
			payload: [...swappedArray],
		});
	};
};

// CLIENT PROFILE
export const addClientWorkflowTask = (task) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/client_workflow_tasks/`,
				method: 'POST',
				data: task,
			});

			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const updateClientWorkflowTask = (task) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/client_workflow_tasks/${task.id}`,
				method: 'PATCH',
				data: task,
			});

			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const setClientWorkflowTasks = (tasks) => {
	return {
		type: SET_CLIENT_WORKFLOW_TASK,
		payload: tasks,
	};
};

export const getClientWorkflowTasks = (client_id, client_workflow_id) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `v1/client_workflow_tasks/?client_id=${client_id}&client_workflow_id=${client_workflow_id}`,
				method: 'GET',
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const deleteClientWorkflowTask = (id) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `v1/client_workflow_tasks/${id}`,
				method: 'DELETE',
			});

			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const updateClientWorkflowTaskRecurrence = (clientWorkflow, data, callback) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/client_workflows/${data.id}`,
				method: 'PATCH',
				data: data,
			});

			// Merging updated reccurence with worflow tasks, builder and submission
			const updatedWorkflow = { ...clientWorkflow, ...request.data };

			dispatch({
				type: UPDATE_CLIENT_WORKFLOW_TASKS,
				payload: updatedWorkflow,
			});

			callback && callback();
		} catch (error) {
			console.info(error);
		}
	};
};

export const updateClientWorkflow = (clientWorkflow) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: SET_CLIENT_WORKFLOW_LOADING,
				payload: true,
			});

			const request = await secure_instance.request({
				url: `/v1/client_workflows/${clientWorkflow?.id}`,
				method: 'patch',
				data: clientWorkflow,
			});

			dispatch({
				type: UPDATE_CLIENT_WORKFLOW_RECURRENCE,
				payload: request.data,
			});

			dispatch({
				type: SET_CLIENT_WORKFLOW_LOADING,
				payload: false,
			});

			return request.data;
		} catch (error) {
			dispatch({
				type: SET_CLIENT_WORKFLOW_LOADING,
				payload: false,
			});

			throw new Error(error?.response?.data?.message || 'Something went wrong');
		}
	};
};

export const patchClientworkflow = (clientWorkflow, clientWorkflowId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/client_workflows/${clientWorkflowId}/name_color`,
				method: 'patch',
				data: clientWorkflow,
			});

			dispatch({
				type: UPDATE_CLIENT_WORKFLOW_RECURRENCE,
				payload: request.data,
			});

			return request.data;
		} catch (error) {
			throw new Error(error?.response?.data?.message || 'Something went wrong');
		}
	};
};

export const setSelectedClientWorkflow = (clientWorkflowId) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: SET_CLIENT_WORKFLOW_LOADING,
				payload: true,
			});

			const request = await secure_instance.request({
				url: `/v1/client_workflows/${clientWorkflowId}?expand=[client_workflow_tasks(defaultOrder),attachment]`,
				method: 'get',
			});

			dispatch({
				type: SET_CLIENT_WORKFLOW,
				payload: request.data,
			});
			dispatch({
				type: SET_CLIENT_WORKFLOW_LOADING,
				payload: false,
			});

			return request.data;
		} catch (error) {
			dispatch({
				type: SET_CLIENT_WORKFLOW_LOADING,
				payload: false,
			});
			throw new Error(error?.response?.data?.message || 'Something went wrong');
		}
	};
};

export const resetSelectedClientWorkflow = () => {
	return async (dispatch) => {
		dispatch({
			type: SET_CLIENT_WORKFLOW,
			payload: null,
		});
	};
};

export const updateClientWorkflowList = (clientWorkflow) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_CLIENT_WORKFLOWS_LIST,
			payload: clientWorkflow,
		});
	};
};

export const handleWorkflowAttachmentsReducer = (data) => {
	return (dispatch) => {
		dispatch({
			type: HANDLE_WORKFLOW_ATTACHMENTS,
			payload: data,
		});
	};
};

export const updateWorkflowTasksDependecyOrder = (tasks) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `v1/task_templates/reorder`,
				method: 'PATCH',
				data: tasks,
			});
			return request.response;
		} catch (error) {
			throw new Error(error?.response?.data?.message || 'Something went wrong');
		}
	};
};

export const updateClientWorkflowTasksDependencyOrder = (tasks) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `v1/client_workflow_tasks/reorder`,
				method: 'PATCH',
				data: tasks,
			});
			return request.response;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const downloadClientWorkflowFile = (client_workflow_id, fileFormat) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/client_workflows/${client_workflow_id}/export?format=${fileFormat}`,
				method: 'GET',
				responseType: 'blob',
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const downloadWorkflowFile = (workflow_id, fileFormat) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/workflows/${workflow_id}/export?format=${fileFormat}`,
				method: 'GET',
				responseType: 'blob',
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};
