import { forwardRef, useEffect, useState } from 'react';
import FormButtonOne from '../../Buttons/FormButtonOne';
import { CircularProgress } from '@mui/material';
import { ReactComponent as SaveIcon } from '../../../assets/icons/Check.svg';
import { ReactComponent as FailedIcon } from '../../../assets/icons/Warning.svg';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import * as clientActions from '../../../redux/actions/client-actions';
import * as Actions from '../../../redux/actions/workflow-builder-actions';
import { filterNullAttributes } from '../../../utilities/utilities';

const ClientPreviewSaveModal = forwardRef(function Modal({ onClose }, ref) {
    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();

    const client = location?.state;
    const clientLocation = client?.client_location;
    const client_profile = filterNullAttributes(client?.client_profile);
    const clientInfo = { ...client_profile };
    const clientWorkflows = client?.relevant_workflows?.filter(
        (item) => item?.metadata?.workflows?.length > 0,
    );

    const workflowsToAdd = useSelector(
        (state) => state.clientReducer.workflowsToAddForClientPreview,
    );

    const workflows = clientWorkflows
        ?.filter((item) => {
            const workflow = item?.metadata?.workflows[0];
            return workflowsToAdd?.includes(workflow?.id);
        })
        ?.map((item) => {
            const workflow = item?.metadata?.workflows[0];
            return {
                ...workflow,
                name: workflow?.name?.trim(),
                status: 'draft',
                task_templates: workflow.task_templates.map((task) => {
                    return {
                        ...task,
                        budgeted_hours: parseFloat(task.budgeted_hours) || 0.1,
                    };
                }),
            };
        });

    // Transform workflows into the desired format
    const workflowData = workflows.map((workflow) => ({
        client_id: client.clientId,
        workflow_id: workflow.id,
    }));

    const informationStatus = [
        {
            name: 'Client Information',
            action: () =>
                dispatch(
                    clientActions.saveClientPreviewInformation({
                        ...clientInfo,
                        id: client.clientId,
                    }),
                ),
        },
        {
            name: 'Client Locations',
            action: () =>
                dispatch(
                    clientActions.saveClientPreviewOfficeInformation({
                        ...clientLocation,
                        client_id: client.clientId,
                    }),
                ),
        },
        // {
        // 	name: 'Client Billing Details',
        // 	action: async () => {
        // 		await new Promise((resolve) => setTimeout(resolve, 2000));
        // 		throw new Error('Something went wrong');
        // 	},
        // },
        {
            name: 'Client Workflows',
            action: async () => dispatch(Actions.activateWorkflow(workflowData)),
        },
    ];

    return (
        <div tabIndex={-1} ref={ref} className='automate-input-modal'>
            <div className='container d-flex flex-column' style={{ gap: '24px' }}>
                <h2 className='HeadlineTwoBold'>Saving Client Information</h2>

                <div className='d-flex flex-column BodyTwoLight' style={{ gap: '16px' }}>
                    <span className=''>
                        {`Your client information is being saved. Please wait and do not close this modal.`}
                    </span>

                    {informationStatus?.map((item) => {
                        return <ClientInfoSection key={item.name} {...item} />;
                    })}
                </div>
            </div>
            <div className='action-buttons justify-content-between'>
                <FormButtonOne onClick={onClose} type='button' className='secondary-btn-light'>
                    Cancel
                </FormButtonOne>

                <FormButtonOne
                    onClick={() => history.push(`client-profile/${client?.clientId}`)}
                    type='button'
                >
                    View Client
                </FormButtonOne>
            </div>
        </div>
    );
});

export default ClientPreviewSaveModal;

const ClientInfoSection = ({ name, action }) => {
    const [controls, setControls] = useState({
        loading: true,
        error: undefined,
    });

    function perfromAction() {
        setControls({
            loading: true,
            error: undefined,
        });

        action()
            .then(() => {
                setControls({
                    loading: false,
                    error: undefined,
                });
            })
            .catch((error) => {
                setControls({
                    loading: false,
                    error: error?.message,
                });
            });
    }

    useEffect(() => {
        perfromAction();
    }, []);

    return (
        <div className='bg-white py-2 px-4 rounded-sm d-flex justify-content-between align-items-center'>
            <span className='pt-1'>{name}</span>
            <span className='d-flex align-items-center pointer' style={{ gap: '12px' }}>
                {controls.loading && (
                    <>
                        <CircularProgress size='20px' className='primary-text' />
                        <span className='pt-1'>In Progress</span>
                    </>
                )}

                {controls.error && (
                    <>
                        <FailedIcon size='20px' className='primary-text' />
                        <span className='pt-1'>Failed</span>
                    </>
                )}

                {!controls.loading && !controls.error && (
                    <>
                        <SaveIcon size='20px' className='primary-text' />
                        <span className='pt-1'>Saved</span>
                    </>
                )}
            </span>
        </div>
    );
};
