/* eslint-disable no-mixed-spaces-and-tabs */
import { memo, useEffect, useRef, useState } from 'react';
import { Avatar, Modal } from '@mui/material';

// Styled Components
import { lightGreen, backgroundGrey } from '../Style.jsx';

// Components
import ButtonSquare from '../Buttons/ButtonSquare.jsx';
import { ReactComponent as EllipseIcon } from '../../assets/icons/Ellipse.svg';

// Material Ui Components
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import { Box, CircularProgress } from '@mui/material';

import { useDispatch, useSelector } from 'react-redux';
import {
	Member,
	executivePermissions,
	featureFlagTenants,
	standardPermissions,
} from '../../utilities/utilities.js';
import Restricted from '../Permissions/Restricted.jsx';
import SubmitButtonsField from '../Buttons/SubmitButtonsField.jsx';
import TopBar from './TopBar.jsx';
import { useHistory } from 'react-router-dom';
import useBreadCrumb from '../../hooks/useBreadCrumb.jsx';
import { routes } from '../../utilities/routes.js';
import * as builderActions from '../../redux/actions/workflow-builder-actions.js';
import useModal from '../Modal/useModal.jsx';
import DummyModal from '../Modal/DummyModal.jsx';
import ArchiveModal from '../Modal/ArchiveModal.jsx';
import * as clientActions from '../../redux/actions/client-actions';
import GenerateInvoice from './Modals/GenerateInvoice.jsx';
import { ReactComponent as AddIcon } from '../../assets/icons/add.svg';
import FeatureFlag from '../Others/FeatureFlag.jsx';
import AutomateInputModal from './Modals/AutomateInputModal.jsx';
import posthog from 'posthog-js';

function ClientProfileBar({ clientName, teamName, clientId }) {
	const { BreadCrumbs } = useBreadCrumb();
	const history = useHistory();
	const dispatch = useDispatch();
	const ModalRef = useRef();

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const userTeamRole = useSelector((state) => state.clientReducer.userTeamRole);

	const activeWorkflowNames = useSelector(
		(state) => state.WorkflowBuilder.activeClientWorkflows,
	)?.map((workflow) => workflow.workflow.name);

	const handleArchiveClient = () => {
		ModalType('CLIENT_ARCHIVE');
		dispatch(builderActions.getActiveClientWorkflows(clientId));
		toggleModal();
	};

	const onDelete = () => {
		dispatch(clientActions.deActivateWorklowsList(history, clientId));
	};

	const navigateToBuilder = () => {
		history.push(`/workflow-builder`, {
			clientName: clientName,
			clientId: clientId,
		});
	};

	const generateInvoice = () => {
		dispatch(
			clientActions.setClientInvoice({
				start_date: null,
				end_date: null,
			}),
		);
		ModalType('GENERATE_INVOICE');
		toggleModal();
	};

	const openAutomateAiModal = () => {
		ModalType('AUTOMATE_INPUT_MODAL');
		toggleModal();
	};

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'flex-start',
			}}
		>
			<Box
				className='py-2'
				sx={{
					display: 'flex',
					flexDirection: 'row',
					alignItems: 'center',
					':hover': {
						cursor: 'pointer',
					},
					color: `${lightGreen}`,
				}}
			>
				<ArrowLeftIcon />
				<BreadCrumbs>
					<h2
						onClick={() => history.push(routes.clients)}
						className='HeadlineThreeBook text-left'
					>
						Clients
					</h2>
				</BreadCrumbs>
			</Box>
			<div className=' w-100' style={{ paddingBottom: '32px' }}>
				<div className='Profile-TopBar' style={{ height: '63px', borderRadius: '4px' }}>
					<div className='d-flex flex-row align-items-center'>
						<Avatar
							sx={{
								bgcolor: `#FED0A5`,
								color: `#333`,
								marginRight: '10px',
								height: '36px',
								width: '36px',
								fontSize: '0.8rem',
							}}
							alt={clientName}
							src='/broken-image.jpg'
						/>
						<div className='d-flex flex-column' style={{ gap: '1px' }}>
							<h6 className='HeadlineThreeBold pt-1 text-left font-weight-bolder light-dark'>
								{clientName}
							</h6>
							<h6 className='light-dark BodyTwoLight m-0 text-left'>{teamName}</h6>
						</div>
					</div>

					<div className='d-flex flex-row' style={{ gap: '1em' }}>
						<Restricted
							AllowedUsers={standardPermissions}
							userTeamRole={[userTeamRole]}
						>
							<div className='d-flex flex-row align-items-center pointer'>
								<h3
									onClick={handleArchiveClient}
									className='BodyTwoLight mb-0 primary-text ml-1'
									data-cy='client-archive-opener'
								>
									Archive Client
								</h3>
							</div>
							<div className='d-flex flex-row align-items-center'>
								<EllipseIcon sx={{ height: '30px', width: '30px' }} />
							</div>
						</Restricted>

						{/* {posthog?.isFeatureEnabled('invoices') && ( */}
							<>
								{/* <Restricted AllowedUsers={executivePermissions}>
									<div className='d-flex flex-row align-items-center pointer'>
										<h3
											// onClick={handleArchiveClient}
											className='BodyTwoLight mb-0 primary-text ml-1'
										>
											Payments
										</h3>
									</div>
									<div className='d-flex flex-row align-items-center'>
										<EllipseIcon sx={{ height: '30px', width: '30px' }} />
									</div>
								</Restricted> */}

								<Restricted
									AllowedUsers={executivePermissions}
									userTeamRole={[userTeamRole]}
								>
									{' '}
									<div className='d-flex flex-row align-items-center pointer'>
										<h3
											onClick={generateInvoice}
											className='BodyTwoLight mb-0 primary-text ml-1'
										>
											Generate Invoice
										</h3>
									</div>
									<div className='d-flex flex-row align-items-center'>
										<EllipseIcon sx={{ height: '30px', width: '30px' }} />
									</div>
								</Restricted>
							</>
						{/* )} */}

						<Restricted
							AllowedUsers={standardPermissions}
							userTeamRole={[userTeamRole]}
						>
							<button onClick={navigateToBuilder} className='rounded-button d-flex'>
								<AddIcon />
								<p className='BodyTwoBold pt-1'>Add Workflow</p>
							</button>
						</Restricted>

						{/* <FeatureFlag tenantIds={[10009, 10025, 10001, 10002]}> */}
						{posthog?.isFeatureEnabled('llm_onboarding') && (
							<Restricted
								AllowedUsers={standardPermissions}
								userTeamRole={[userTeamRole]}
							>
								<div
									onClick={openAutomateAiModal}
									className='d-flex align-items-center pointer'
									style={{ gap: '8px' }}
								>
									<div className='ai-input-circle'></div>

									<span className='ai-input-text BodyTwoLight pt-1'>
										Automate Input
									</span>
								</div>
							</Restricted>
						)}
						{/* </FeatureFlag> */}
					</div>
					{modalIsOpen && (
						<>
							{ModalName == 'AUTOMATE_INPUT_MODAL' && (
								<Modal
									// onClose={toggleModal}
									open={modalIsOpen}
									className='material-ui-modal'
								>
									<AutomateInputModal ref={ModalRef} onClose={toggleModal} />
								</Modal>
							)}
							{ModalName == 'GENERATE_INVOICE' && (
								<Modal
									onClose={toggleModal}
									open={modalIsOpen}
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<DummyModal
										title={'Generate Invoice'}
										onClose={() => {
											toggleModal();
										}}
									>
										<GenerateInvoice onCancel={toggleModal} />
									</DummyModal>
								</Modal>
							)}
							{ModalName == 'CLIENT_ARCHIVE' && (
								<Modal
									onClose={toggleModal}
									open={modalIsOpen}
									sx={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<DummyModal
										title='Archive'
										onClose={toggleModal}
										warningIcon={true}
									>
										<ArchiveModal
											client={clientName}
											onCancel={toggleModal}
											list={activeWorkflowNames}
											onArchive={onDelete}
											title={`Currently Active Workflows for ${clientName}`}
										/>
									</DummyModal>
								</Modal>
							)}
						</>
					)}
				</div>
			</div>
		</Box>
	);
}

export default memo(ClientProfileBar);
