/* eslint-disable no-mixed-spaces-and-tabs */
import { instance, secure_instance } from '../../../axios/axios-config';

export const SET_LOADING = '[WORKSPACE] SET_LOADING';
export const GET_TASKS = '[WORKSPACE] GET_TASKS';
export const GET_TASK_TYPE = '[WORKSPACE] GET_TASK_TYPE';
export const GET_ASSIGNEES = '[WORKSPACE] GET_ASSIGNEES';
export const GET_CLIENT_USERS = '[WORKSPACE] GET_CLIENT_USERS';
export const HANDLE_CHANGE = '[WORKSPACE] HANDLE_CHANGE';
export const HANDLE_DATE_CHANGE = '[WORKSPACE] HANDLE_DATE_CHANGE';
export const CREATE_TASK_START = '[WORKSPACE] CREATE_TASK_START';
export const CREATE_TASK_END = '[WORKSPACE] CREATE_TASK_END';
export const CREATE_TASK_FAILED = '[WORKSPACE] CREATE_TASK_FAILED';
export const HANDLE_START_DATE_CHANGE = '[WORKSPACE] HANDLE_START_DATE_CHANGE';
export const GET_WEEKLY_TASKS = '[WORKSPACE] GET_WEEKLY_TASKS';
export const UPDATE_TASK_CARD = '[WORKSPACE] UPDATE_TASK_CARD';
export const RESET_TASK_DETAILS = '[WORKSPACE] RESET_TASK_DETAILS';
export const RESET_CREATE_TASK_FORM = '[WORKSPACE] RESET_CREATE_TASK_FORM';
export const UPDATE_STATUS = '[WORKSPACE] UPDATE_STATUS';
export const POST_COMMENT = '[WORKSPACE] POST_COMMENT';
export const DELETE_COMMENT = '[WORKSPACE] DELETE_COMMENT';
export const GET_FILTERED_WORKSPACE = '[WORKSPACE] GET_FILTERED_WORKSPACE';
export const HANDLE_TASK_STATUS_CHECKBOX = '[WORKSPACE] HANDLE_TASK_STATUS_CHECKBOX';
export const HANDLE_CLEAR_TASK_STATUSES_CHECKBOX =
	'[WORKSPACE] HANDLE_CLEAR_TASK_STATUSES_CHECKBOX';
export const HANDLE_CLIENTS_CHECKBOX = '[WORKSPACE] HANDLE_CLIENTS_CHECKBOX';
export const HANDLE_CLEAR_CLIENT_CHECKBOX = '[WORKSPACE] HANDLE_CLEAR_CLIENT_CHECKBOX';
export const HANDLE_USERS_CHECKBOX = '[WORKSPACE] HANDLE_USERS_CHECKBOX';
export const HANDLE_CLEAR_USER_CHECKBOX = '[WORKSPACE] HANDLE_CLEAR_USER_CHECKBOX';
export const RESET_ERROR = '[WORKSPACE] RESET_ERROR';
export const SET_SOCKET_CONNECTION = '[WORKSPACE] SET_SOCKET_CONNECTION';
export const HANDLE_COMMENT_CHANGE = '[WORKSPACE] HANDLE_COMMENT_CHANGE';
export const RESET_COMMENT = '[WORKSPACE] RESET_COMMENT';
export const SET_SPECIFIC_TASK = '[WORKSPACE] SET_SPECIFIC_TASK';
export const REMOVE_SOCKET_CONNECTION = '[WORKSPACE] REMOVE_SOCKET_CONNECTION';
export const HANDLE_REPEAT_CHANGE = '[WORKSPACE] HANDLE_REPEAT_CHANGE';
export const GET_WORKSPACE_TEAMS = '[WORKSPACE] GET_WORKSPACE_TEAMS';
export const HANDLE_CLEAR_TEAM_CHECKBOX = '[WORKSPACE] HANDLE_CLEAR_TEAM_CHECKBOX';
export const GET_UNASSIGNED_CLIENT_TASKS = '[WORKSPACE] GET_UNASSIGNED_CLIENT_TASKS';
export const HANDLE_TEAM_CHECKBOX = '[WORKSPACE] HANDLE_TEAM_CHECKBOX';
export const HANDLE_WORKFLOWS_CHECKBOX = '[WORKSPACE] HANDLE_WORKFLOWS_CHECKBOX';
export const SET_ACTIVE_USERS_LIST = '[WORKSPACE] SET_ACTIVE_USERS_LIST';
export const UPDATE_TASK_INFO = '[WORKSPACE] UPDATE_TASK_INFO';
export const UPDATE_WEEKLY_TASK = '[WORKSPACE] UPDATE_WEEKLY_TASK';
export const UPDATE_MONTHLY_TASK = '[WORKSPACE] UPDATE_MONTHLY_TASK';
export const GET_SPECIFIC_TASK_ERROR = '[WORKSPACE] GET_SPECIFIC_TASK_ERROR';
export const SET_OPEN_ACTION_CENTER_MODAL = '[WORKSPACE] SET_OPEN_ACTION_CENTER_MODAL';
export const SET_WORKFLOW_NAME = '[WORKSPACE] SET_WORKFLOW_NAME';
export const POST_COMMENT_RESPONSE = '[WORKSPACE] POST_COMMENT_RESPONSE';
export const UPDATE_COMPLETE_STATUS = '[WORKSPACE] UPDATE_COMPLETE_STATUS';
export const UPDATE_WEEKLY_TASK_INFO = '[WORKSPACE] UPDATE_WEEKLY_TASK_INFO';
export const UPDATE_MEETING_TASKS = '[WORKSPACE] UPDATE_MEETING_TASKS';
export const RESET_WORKFLOW_NAME = '[WORKSPACE] RESET_WORKFLOW_NAME';
export const REFRESHED_TIMER = '[WORKSPACE] REFRESHED_TIMER';
export const GET_FILTERED_WORKFLOWS = '[WORKSPACE] GET_FILTERED_WORKFLOWS';
export const UPDATE_TASK_START = '[WORKSPACE] UPDATE_TASK_START';
export const HANDLE_CLEAR_WORKFLOWS_CHECKBOX = '[WORKSPACE] HANDLE_CLEAR_WORKFLOWS_CHECKBOX';
export const SET_SNACKBAR_IS_OPEN = '[WORKSPACE] SET_SNACKBAR_IS_OPEN';
export const UPDATE_MY_TASK_START = '[WORKSPACE] UPDATE_MY_TASK_START';
export const UPDATE_MY_TASK_END = '[WORKSPACE] UPDATE_MY_TASK_END';
export const UPDATE_MY_TASK_FAILED = '[WORKSPACE] UPDATE_MY_TASK_FAILED';
export const UPDATE_MY_TASK_INFO = '[WORKSPACE] UPDATE_MY_TASK_INFO';
export const DELETE_TASK = '[WORKSPACE] DELETE_TASK';
export const UPDATE_LOGGED_HOURS_START = '[WORKSPACE] UPDATE_LOGGED_HOURS_START';
export const UPDATE_LOGGED_HOURS_END = '[WORKSPACE] UPDATE_LOGGED_HOURS_END';
export const UPDATE_LOGGED_HOURS_FAILED = '[WORKSPACE] UPDATE_LOGGED_HOURS_FAILED';
export const HANDLE_TASK_TIMER = '[WORKSPACE] HANDLE_TASK_TIMER';
export const HANDLE_TIMER_STATUS = '[WORKSPACE] HANDLE_TIMER_STATUS';
export const RESET_TIMER_STATUS = '[WORKSPACE] RESET_TIMER_STATUS';
export const SET_TASK_LOADING = '[WORKSPACE] SET_TASK_LOADING';
export const SET_UPDATE_TASK_TIMER_LOADING = '[WORKSPACE] SET_UPDATE_TASK_TIMER_LOADING';
export const UPDATE_TASK_ERROR = '[WORKSPACE] UPDATE_TASK_ERROR';
export const RESET_TASK_DRAG_ERROR = '[WORKSPACE] RESET_TASK_DRAG_ERROR';
export const TRIGGER_GET_TASKS = '[WORKSPACE] TRIGGER_GET_TASKS';
export const TOGGLE_TEAM_MEETINGS = '[WORKSPACE TOGGLE_TEAM_MEETINGS]';
export const SET_MEETING_TASK_RECURRENCE = '[WORKSPACE] SET_MEETING_TASK_RECURRENCE';
export const DELETE_MEETING_TASK = '[WORKSPACE] DELETE_MEETING_TASK';
export const HANDLE_TASK_TYPE = '[WORKSPACE HANDLE_TASK_TYPE]';
export const TIME_TRACKING_ERROR = '[WORKSPACE TIME_TRACKING_ERROR]';
export const RESET_TIME_TRACKING_ERROR = '[WORKSPACE RESET_TIME_TRACKING_ERROR]';
export const SET_MONTHLY_VIEW = '[WORKSPACE SET_MONTHLY_VIEW]';
export const SET_WEEKEND = '[WORKSPACE SET_WEEKEND]';
export const SET_WEEK_DATE = '[WORKSPACE SET_WEEK_DATE]';
export const SET_WEEKLY_MEETING_TASKS = 'WORKSPACE SET_WEEKLY_MEETING_TASKS';
export const SET_WEEKLY_CLIENT_TASKS = '[WORKSPACE SET_WEEKLY_CLIENT_TASKS]';
export const RESET_WORKSPACE_TASKS = '[WORKSPACR RESET_WORKSPACE_TASKS]';
export const SET_MEETING_TASKS_LOADING = '[WORKSPACE SET_MEETING_TASKS_LOADING]';
export const SET_CLIENT_TASKS_LOADING = '[WORKSPACE SET_CLIENT_TASKS_LOADING]';
export const DELETE_CLIENT_AD_HOC_TASKS = 'DELETE_CLIENT_AD_HOC_TASKS';
export const SET_TASK_DETAILS = 'SET_TASK_DETAILS';
export const SET_CLIENT_SOFTWARE_STACKS = 'SET_CLIENT_SOFTWARE_STACKS';
export const SET_TASK_ASSIGNEES = 'SET_TASK_ASSIGNEES';
export const RESET_TASK_ASSIGNEES = '[WORKSPACE RESET_TASK_ASSIGNEES]';
export const REMOVE_TASK_ASSIGNEE = 'REMOVE_TASK_ASSIGNEE';
export const SET_WORKFLOW_SUBMISSION = 'SET_WORKFLOW_SUBMISSION';
export const SET_TASK_COMMENTS = 'SET_TASK_COMMENTS';
export const UPDATE_TASK_COMMENTS = 'UPDATE_TASK_COMMENTS';
export const SET_TASK_DETAILS_LOADING = 'SET_TASK_DETAILS_LOADING';
export const SET_EVENT_SOURCE = 'SET_EVENT_SOURCE';
export const SET_RESOURCE_LOADING = 'SET_RESOURCE_LOADING';
export const UPDATE_WEEKLY_CLIENT_TASKS = 'UPDATE_WEEKLY_CLIENT_TASKS';
export const FILTER_TASK_FROM_WEEKLY_ARRAY = 'FILTER_TASK_FROM_WEEKLY_ARRAY';
export const FILTER_MEETING_TASK_FROM_WEEKLY_ARRAY = 'FILTER_MEETING_TASK_FROM_WEEKLY_ARRAY';
export const UPDATE_TASK_INFO_SSE = '[WORKSPACE UPDATE_TASK_INFO_SSE]';
export const UPDATE_WEEKLY_CLIENT_TASK_ASSIGNEE = '[WORKSPACE UPDATE_WEEKLY_CLIENT_TASK_ASSIGNEE]';
export const REMOVE_WEEKLY_CLIENT_TASK_ASSIGNEE = '[WORKSPACE REMOVE_WEEKLY_CLIENT_TASK_ASSIGNEE]';
export const SET_ACTIVE_USER = '[WORKSPACE SET_ACTIVE_USER]';
export const SET_TASK_STATUS = 'WORKSPACE SET_TASK_STATUS';
export const RESET_STATE = 'RESET_STATE';
export const SET_MEETING_INVITE_STATUS = '[WORKSPACE SET_MEETING_INVITE_STATUS]';
export const SET_TEAM_MEMBERS_MEETINGS = '[WORKSPACE SET_TEAM_MEMBERS_MEETINGS]';
export const GET_MEETINGS = '[WORKSPACE GET_MEETINGS]';
export const SET_FILTERS_VALUES = 'SET_FILTERS_VALUES';
export const SET_TASK_MODAL_MINIMIZE = 'SET_TASK_MODAL_MINIMIZE';
export const HANDLE_TASK_CLICK = 'HANDLE_TASK_CLICK';
export const SET_IS_TIME_TRACKING = 'SET_IS_TIME_TRACKING';
export const UPDATE_TASK_TIMER = 'UPDATE_TASK_TIMER';
export const ADD_COMMENT = 'ADD_COMMENT';
export const UPDATE_COMMENTS_ATTACHMENTS = 'UPDATE_COMMENTS_ATTACHMENTS';
export const UPLOAD_FILE_PROGRESS = 'UPLOAD_FILE_PROGRESS';
export const UPLOAD_FILE_STATUS = 'UPLOAD_FILE_STATUS';
export const SET_BULK_CLIENT_TASKS = 'SET_BULK_CLIENT_TASKS';
export const SET_DRAG_OVER_DATE = 'SET_DRAG_OVER_DATE';
export const SET_DOCUMENT_REQUEST_INVITE_LINK = 'SET_DOCUMENT_REQUEST_INVITE_LINK';
export const SET_DRAGGING_TASK_DETAILS = 'SET_DRAGGING_TASK_DETAILS';
export const SET_MONTHLY_TASK_COUNT = 'SET_MONTHLY_TASK_COUNT';
export const SET_MONTHLY_TASK_COUNT_LOADING = 'SET_MONTHLY_TASK_COUNT_LOADING';
export const SET_CUSTOM_TASK_STATUSES = 'SET_CUSTOM_TASK_STATUSES';
export const ADD_MORE_WEEKLY_CLIENT_TASKS = 'ADD_MORE_WEEKLY_CLIENT_TASKS';

import axios from 'axios';
import moment from 'moment';
const CancelToken = axios.CancelToken;

import { saveTimeTrackingRequest } from '../../../utilities/TimeTrackingOfflineSyncHelpers';
import { UPLOAD_FILE, UPLOAD_IMAGE_PROGRESS } from '../client-actions';

export const setDraggingTaskDetails = (state) => {
	return {
		type: SET_DRAGGING_TASK_DETAILS,
		payload: state,
	};
};

export const resetState = () => {
	return {
		type: RESET_STATE,
	};
};

export const setFilters = (filters = undefined) => {
	return {
		type: SET_FILTERS_VALUES,
		payload: filters,
	};
};

export const deleteTask = (task, callBack) => {
	return async (dispatch) => {
		dispatch({
			type: SET_LOADING,
			payload: true,
		});
		try {
			const request = await secure_instance.request({
				url: `/v1/tasks/${task?.id}/?delete_future_tasks=${task?.DeleteFutureTasks}&group_key=${task?.group_key}`,
				method: 'delete',
			});

			dispatch({
				type: DELETE_TASK,
				payload: {
					taskId: task.id,
					...task,
				},
			});

			callBack && callBack();
		} catch {
			dispatch(setSnackBarIsOpen(false, true, 'Something went wrong. Try again!'));
			dispatch({
				type: SET_LOADING,
				payload: false,
			});
		}
	};
};

export const deleteMeetingMyTask = (task, callBack, clientId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/my_tasks/?task_id=${task.id}&delete_future=${task.delete_future}`,
				method: 'delete',
			});
			callBack && callBack(task.delete_future);
		} catch (error) {
			dispatch(setSnackBarIsOpen(false, true, 'Something went wrong. Try again!'));
			dispatch({
				type: SET_LOADING,
				payload: false,
			});
		}
	};
};

let getTasksCancel = undefined;
export const getTasks = (filters, start_date, end_date) => {
	return async (dispatch) => {
		if (getTasksCancel) {
			getTasksCancel();
		}

		try {
			const taskStatusQuery = filters.taskStatuses;
			const usersQuery = filters?.users;
			const clientsQuery = filters?.clients;
			const teamsQuery = filters?.teams;
			const workflowsQuery = filters?.workflows;

			dispatch({
				type: SET_MONTHLY_TASK_COUNT_LOADING,
				payload: true,
			});

			const request = await secure_instance.request({
				url: `/v1/workspace/?count=${true}&start_date=${start_date}&end_date=${end_date}&client_ids=${JSON.stringify(
					clientsQuery,
				)}&task_statuses=${JSON.stringify(taskStatusQuery)}&user_ids=${JSON.stringify(
					usersQuery,
				)}&team_ids=${JSON.stringify(teamsQuery)}&workflow_ids=${JSON.stringify(
					workflowsQuery,
				)}`,
				method: 'Get',
				cancelToken: new CancelToken((c) => {
					getTasksCancel = c;
				}),
			});

			dispatch({
				type: SET_MONTHLY_TASK_COUNT,
				payload: request.data,
			});
			dispatch({
				type: SET_MONTHLY_TASK_COUNT_LOADING,
				payload: false,
			});
		} catch (error) {
			if (!axios.isCancel(error)) {
				dispatch({
					type: SET_MONTHLY_TASK_COUNT_LOADING,
					payload: false,
				});
				dispatch(
					setSnackBarIsOpen(
						true,
						true,
						error?.response?.data?.message || error?.message || 'Something went wrong',
					),
				);
			}
		}
	};
};

// export const getComments = (id) => {
// 	return async (dispatch) => {
// 		try {
// 			const request = await secure_instance.request({
// 				url: `/v1/comments/${id}?expand=user_profile`,
// 				method: 'Get',
// 			});
// 			dispatch({
// 				type: GET_COMMENTS,
// 				payload: request.data,
// 			});
// 		} catch (error) {
// 			//
// 		}
// 	};
// };

// export const getWeeklyTasks = (filters, start_date, end_date) => {
// 	return async (dispatch) => {
// 		try {
// 			const taskStatusQuery = filters?.taskStatuses
// 				.map((arr, index) => {
// 					if (index === filters.taskStatuses.length - 1) {
// 						return 'task_status[]=' + arr;
// 					} else {
// 						return 'task_status[]=' + arr + '&';
// 					}
// 				})
// 				.join('');

// 			const usersQuery = filters?.users
// 				.map((arr, index) => {
// 					if (index === filters.users.length - 1) {
// 						return 'people[]=' + arr;
// 					} else {
// 						return 'people[]=' + arr + '&';
// 					}
// 				})
// 				.join('');

// 			const clientsQuery = filters?.clients
// 				.map((arr, index) => {
// 					if (index === filters.clients.length - 1) {
// 						return 'client[]=' + arr;
// 					} else {
// 						return 'client[]=' + arr + '&';
// 					}
// 				})
// 				.join('');

// 			const teamsQuery = filters?.teams
// 				.map((arr, index) => {
// 					if (index === filters.teams.length - 1) {
// 						return 'team[]=' + arr;
// 					} else {
// 						return 'team[]=' + arr + '&';
// 					}
// 				})
// 				.join('');

// 			const workflowsQuery = filters?.workflows
// 				.map((arr, index) => {
// 					if (index === filters.workflows.length - 1) {
// 						return 'workflow_ids[]=' + arr;
// 					} else {
// 						return 'workflow_ids[]=' + arr + '&';
// 					}
// 				})
// 				.join('');

// 			dispatch({
// 				type: SET_LOADING,
// 				payload: true,
// 			});

// 			const request = await secure_instance.request({
// 				url: `/v1/tasks/workflow/?expand=[client,assignee_users,workflow_recurrence,task_recurrence,client_workflow_task.client_workflow.workflow,user_profile,client.[client_contact_list, links , team],depend_on_task.user_profile,comments.user_profile, dependent_task, my_task.client_workflow(selectedFields)]&${clientsQuery}&${taskStatusQuery}&${usersQuery}&${teamsQuery}&${workflowsQuery}&start_date=${start_date}&end_date=${end_date}`,
// 				method: 'Get',
// 			});

// 			dispatch({
// 				type: GET_WEEKLY_TASKS,
// 				payload: request.data,
// 			});
// 		} catch (error) {
// 			//
// 			dispatch({
// 				type: SET_LOADING,
// 				payload: false,
// 			});
// 		}
// 	};
// };

export const setMeetingTasksLoading = (loading) => {
	return {
		type: SET_MEETING_TASKS_LOADING,
		payload: loading,
	};
};

export const setClientTasksLoading = (loading) => {
	return {
		type: SET_CLIENT_TASKS_LOADING,
		payload: loading,
	};
};

let getWeeklyMeetingTasksCancel = undefined;
export const getWeeklyMeetingTasks = (
	filters,
	start_date,
	end_date,
	show_team_members_meetings = false,
) => {
	return async (dispatch) => {
		if (getWeeklyMeetingTasksCancel) {
			getWeeklyMeetingTasksCancel();
		}
		try {
			dispatch(setMeetingTasksLoading(true));
			const new_start_date = moment(start_date).subtract(1, 'day').format('YYYY-MM-DD');
			const new_end_date = moment(end_date).add(1, 'day').format('YYYY-MM-DD');
			const usersQuery = filters?.users;
			const clientsQuery = filters?.clients;

			const request = await secure_instance.request({
				url: `/v1/workspace/meetings?start_date=${new_start_date}&end_date=${new_end_date}&show_team_members_meetings=${show_team_members_meetings}&client_ids=${JSON.stringify(
					clientsQuery,
				)}&user_ids=${JSON.stringify(usersQuery)}`,
				method: 'Get',
				cancelToken: new CancelToken((c) => {
					getWeeklyMeetingTasksCancel = c;
				}),
			});
			if (show_team_members_meetings) {
				dispatch({
					type: SET_TEAM_MEMBERS_MEETINGS,
					payload: request.data,
				});
			} else {
				dispatch({
					type: SET_WEEKLY_MEETING_TASKS,
					payload: request.data,
				});
			}
			dispatch(setMeetingTasksLoading(false));
		} catch (error) {
			if (!axios.isCancel(error)) dispatch(setMeetingTasksLoading(false));
		}
	};
};

let getWeeklyClientTasksCancel = undefined;
export const getWeeklyClientTasks = (filters, start_date, end_date) => {
	return async (dispatch) => {
		if (getWeeklyClientTasksCancel) {
			getWeeklyClientTasksCancel();
		}
		try {
			const taskStatusQuery = filters.taskStatuses;
			const usersQuery = filters?.users;
			const clientsQuery = filters?.clients;
			const teamsQuery = filters?.teams;
			const workflowsQuery = filters?.workflows;

			dispatch(setClientTasksLoading(true));

			const request = await secure_instance.request({
				url: `/v1/workspace/?start_date=${start_date}&end_date=${end_date}&client_ids=${JSON.stringify(
					clientsQuery,
				)}&task_statuses=${JSON.stringify(taskStatusQuery)}&user_ids=${JSON.stringify(
					usersQuery,
				)}&team_ids=${JSON.stringify(teamsQuery)}&workflow_ids=${JSON.stringify(
					workflowsQuery,
				)}`,
				method: 'Get',
				cancelToken: new CancelToken((c) => {
					getWeeklyClientTasksCancel = c;
				}),
			});

			dispatch({
				type: SET_WEEKLY_CLIENT_TASKS,
				payload: request.data,
			});
			dispatch(setClientTasksLoading(false));

		} catch (error) {
			if (!axios.isCancel(error)) dispatch(setClientTasksLoading(false));
		}
	};
};

let getClientTasksCancelForBulkActions = undefined;
export const getClientTasksForBulkActions = (filters, start_date, end_date, date_comparison) => {
	return async (dispatch) => {
		if (getClientTasksCancelForBulkActions) {
			getClientTasksCancelForBulkActions();
		}
		try {
			const usersQuery = filters?.users;
			const taskStatusQuery = filters?.taskStatuses;

			const request = await secure_instance.request({
				url: `/v1/workspace/?start_date=${start_date}&end_date=${end_date}&user_ids=${JSON.stringify(
					usersQuery,
				)}&task_statuses=${JSON.stringify(
					taskStatusQuery,
				)}&date_comparison=${date_comparison}`,
				method: 'Get',
				cancelToken: new CancelToken((c) => {
					getClientTasksCancelForBulkActions = c;
				}),
			});

			dispatch({
				type: SET_BULK_CLIENT_TASKS,
				payload: request.data,
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const updateWeeklyClientTasks = (data) => {
	return {
		type: UPDATE_WEEKLY_CLIENT_TASKS,
		payload: data,
	};
};

export const filterTaskFromWeeklyArray = (task) => {
	return {
		type: FILTER_TASK_FROM_WEEKLY_ARRAY,
		payload: task,
	};
};
export const filterMeetingTaskFromWeeklyArray = (task) => {
	return {
		type: FILTER_MEETING_TASK_FROM_WEEKLY_ARRAY,
		payload: task,
	};
};
export const getFilteredWorkspace = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/tasks/filter_workspace',
				method: 'Get',
			});

			dispatch({
				type: GET_FILTERED_WORKSPACE,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const getWorkspaceTeams = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: 'v1/teams/workspace_teams',
				method: 'Get',
			});

			dispatch({
				type: GET_WORKSPACE_TEAMS,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const getFilteredWorkflows = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: 'v1/tasks/filter_workflows/?expand=workflow',
				method: 'Get',
			});

			dispatch({
				type: GET_FILTERED_WORKFLOWS,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const createTask = (data, toggleModal, repeat_time) => {
	return async (dispatch) => {
		try {
			console.log(data);
			// dispatch({
			// 	type: CREATE_TASK_START,
			// });
			dispatch(setClientTasksLoading(true));
			const request = await secure_instance.request({
				url: repeat_time ? `/v1/tasks/?repeat_time=${repeat_time}` : '/v1/tasks/',
				method: 'Post',
				data: data,
			});

			console.log('------------>', request);
			dispatch({
				type: CREATE_TASK_END,
				payload: request.data,
			});
			dispatch(setClientTasksLoading(false));
			dispatch(triggerGetTasks());

			toggleModal();
			return request.data;
		} catch (error) {
			dispatch({
				type: CREATE_TASK_FAILED,
				payload: error?.response?.data?.message,
			});
			dispatch(setClientTasksLoading(true));
		}
	};
};

export const createMeetingTask = (data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/my_tasks/',
				method: 'Post',
				data: data,
			});

			dispatch({
				type: CREATE_TASK_END,
				payload: request.data,
			});
			dispatch(triggerGetTasks());
			dispatch(getMeetings());
			return request.data;
		} catch (error) {
			dispatch({
				type: CREATE_TASK_FAILED,
				payload: error?.response?.data?.message || 'Something went wrong!',
			});
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const updateMyTaskDetails = (formDetails, setEdit, socket) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: UPDATE_MY_TASK_START,
			});

			await socket.emit('my_task_update', formDetails, async (data) => {
				dispatch(updateMyTask(data));
			});

			setEdit && setEdit(false);
		} catch (error) {
			dispatch({
				type: UPDATE_MY_TASK_FAILED,
				payload: error?.response?.data?.message,
			});
		}
	};
};

export const updateMeetingTaskDetails = (formDetails, update_future) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/my_tasks/?task_id=${formDetails?.id}&update_future=${update_future}`,
				method: 'Patch',
				data: formDetails,
			});
			return request.data;
		} catch (error) {
			dispatch({
				type: UPDATE_MY_TASK_FAILED,
				payload: error?.response?.data?.message,
			});
			throw new Error(
				error?.response?.data?.message ||
					error?.message ||
					'Something went wrong. Try again!',
			);
		}
	};
};

export const updateLoggedTime = (body, socket) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: UPDATE_LOGGED_HOURS_START,
			});
			await socket.emit('my_task_update_logged_time', body, async (data) => {});
		} catch (error) {
			// dispatch({
			// 	type: UPDATE_LOGGED_HOURS_FAILED,
			// 	payload: error?.response?.data?.message,
			// });
		}
	};
};

export const updateMyTask = (data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: UPDATE_MY_TASK_END,
				payload: data,
			});
		} catch (error) {
			//
		}
	};
};

export const updateMyTaskInfo = (data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: UPDATE_MY_TASK_INFO,
				payload: data,
			});
		} catch (error) {
			//
		}
	};
};

export const setTaskCreatedError = (text) => {
	return (dispatch) => {
		dispatch({
			type: CREATE_TASK_FAILED,
			payload: text,
		});
	};
};

export const getTaskTypes = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: 'v1/task_types/',
				method: 'Get',
			});

			dispatch({
				type: GET_TASK_TYPE,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const getAssignees = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: 'v1/user_profiles/',
				method: 'Get',
			});

			dispatch({
				type: GET_ASSIGNEES,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const getUnassignedClientsTasks = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/clients/unassigned_tasks`,
				method: 'GET',
			});

			dispatch({
				type: GET_UNASSIGNED_CLIENT_TASKS,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const getSpecificUsers = (clientId) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: clientId
					? `/v1/clients/client_based/${clientId}`
					: `/v1/clients/client_based/0`,
				method: 'Get',
			});

			console.log(request);

			dispatch({
				type: GET_CLIENT_USERS,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const setTaskDetailsLoading = (taskLoading) => {
	return {
		type: SET_TASK_DETAILS_LOADING,
		payload: taskLoading,
	};
};

export const getTaskDetailsById = (task_id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/tasks/${task_id}?expand=[my_task.[client_workflow(selectedFields), workflow],task_assignees as assignee_users.[user_profile(selectFieldsForTaskAssignee)],task_recurrence, client_workflow.[workflow, attachment], client.[client_contact_list, links, team_client_permissions.team], user_profile, depend_on_task.user_profile, task_timers.user_profile]`,
				method: 'Get',
			});
			dispatch({
				type: SET_TASK_DETAILS,
				payload: request?.data,
			});
			return request.data;
		} catch (error) {
			dispatch(
				setSnackBarIsOpen(
					true,
					true,
					error?.response?.data?.message || error?.message || 'Something went wrong',
				),
			);
			return Promise.reject(
				error?.response?.data?.message || error.message || 'Something went wrong',
			);
		}
	};
};

export const updateTaskDetails = (task_id, taskDetails, query = '') => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/tasks/${task_id}?${query}`,
				method: 'Patch',
				data: taskDetails,
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went Wrong',
			);
		}
	};
};

export const setResourceLoading = (loading) => {
	return {
		type: SET_RESOURCE_LOADING,
		payload: loading,
	};
};

export const getClientSoftwareStacks = (client_id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/client_software_stack/?client_id=${client_id}`,
				method: 'GET',
			});
			dispatch({
				type: SET_CLIENT_SOFTWARE_STACKS,
				payload: request.data,
			});

			// return request.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
};

export const getClientWorkflowSubmission = (client_workflow_id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/workflow_submissions/client_workflow/?client_workflow_id=${client_workflow_id}`,
				method: 'GET',
			});

			dispatch({
				type: SET_WORKFLOW_SUBMISSION,
				payload: request?.data,
			});
		} catch (error) {
			//
		}
	};
};

export const getTaskAssignees = (task_id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/task_assignee/${task_id}`,
				method: 'GET',
			});
			dispatch({
				type: SET_TASK_ASSIGNEES,
				payload: request?.data,
			});
			return request.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
};
export const updateTaskAssignees = (task_assignee_id, task_assignees) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: task_assignee_id
					? `/v1/task_assignee/?task_assignee_id=${task_assignee_id}`
					: `/v1/task_assignee/`,
				method: 'POST',
				data: task_assignees,
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong!',
			);
		}
	};
};

export const updateTaskAssignee = (assignee) => {
	return {
		type: SET_TASK_ASSIGNEES,
		payload: assignee,
	};
};

export const removeTaskAssignee = (assignee) => {
	return {
		type: REMOVE_TASK_ASSIGNEE,
		payload: assignee,
	};
};

export const updateWeeklyClientTaskAssignee = (assignee) => {
	return {
		type: UPDATE_WEEKLY_CLIENT_TASK_ASSIGNEE,
		payload: assignee,
	};
};
export const removeWeeklyClientTaskAssignee = (assignee) => {
	return {
		type: REMOVE_WEEKLY_CLIENT_TASK_ASSIGNEE,
		payload: assignee,
	};
};

export const getTaskComments = (task_id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/comments/${task_id}?expand=user_profile`,
				method: 'GET',
			});
			dispatch({
				type: SET_TASK_COMMENTS,
				payload: request?.data,
			});
			return request.data;
		} catch (error) {
			throw new Error(error.message);
		}
	};
};

export const updateCommentsAttachments = (data) => {
	return {
		type: UPDATE_COMMENTS_ATTACHMENTS,
		payload: data,
	};
};

export const addComment = (id, userId, comment, attachedArr) => {
	return async (dispatch) => {
		try {
			await secure_instance.request({
				url: `/v1/comments/`,
				method: 'POST',
				data: {
					task_id: id,
					user_id: userId,
					message: comment,
					attachment: attachedArr,
				},
			});

			dispatch({
				type: ADD_COMMENT,
			});
		} catch (error) {
			//
		}
	};
};

export const updateTaskComments = (data) => {
	return (dispatch) => {
		dispatch({
			type: UPDATE_TASK_COMMENTS,
			payload: data,
		});
	};
};

export const stopTracking = (socket, userId, taskId) => {
	return async () => {
		try {
			await socket.emit('stop_tracking', { user_id: userId, task_id: taskId });
		} catch (error) {
			//
		}
	};
};

export const addCommentSocket = (id, userId, comment, taggedIds, socket, attachedArr) => {
	return async (dispatch) => {
		try {
			await socket.emit(
				'add_comment',
				{
					task_id: id,
					user_id: userId,
					message: comment,
					attachment: attachedArr,
					// tagged_user_ids: taggedIds,
				},
				(data) => {
					// dispatch(postComment(data));
				},
			);
		} catch (error) {
			//
		}
	};
};

// export const deleteCommentSocket = (id, userId, comment, socket) => {
// 	return async (dispatch) => {
// 		try {
// 			await socket.emit(
// 				'add_comment',
// 				{ task_id: id, user_id: userId, message: comment },
// 				(data) => {
// 					dispatch(postComment(data));
// 				},
// 			);
// 		} catch (error) {
// 			//
// 		}
// 	};
// };

export const updateWeeklyTasks = (data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: UPDATE_WEEKLY_TASK,
				payload: data,
			});

			dispatch({
				type: UPDATE_MONTHLY_TASK,
				payload: data,
			});
		} catch (error) {
			console.log(error);
		}
	};
};

export const resetTaskDetails = () => {
	return {
		type: RESET_TASK_DETAILS,
	};
};

export const setActiveUsersList = (data) => {
	return {
		type: SET_ACTIVE_USERS_LIST,
		payload: data,
	};
};

export const setActiveUser = (user) => {
	return {
		type: SET_ACTIVE_USER,
		payload: user,
	};
};

export const setTaskStatus = (data) => {
	const { id, client_id, task_status_id } = data;
	return {
		type: SET_TASK_STATUS,
		payload: {
			id: id,
			client_id: client_id,
			task_status_id: task_status_id,
		},
	};
};

export const resetCreateTaskForm = () => {
	return {
		type: RESET_CREATE_TASK_FORM,
	};
};

export const resetComment = () => {
	return {
		type: RESET_COMMENT,
	};
};

export const resetTaskAssignee = () => {
	return {
		type: RESET_TASK_ASSIGNEES,
		payload: [],
	};
};

export const handleCommentChange = (comment) => {
	return {
		type: HANDLE_COMMENT_CHANGE,
		payload: comment,
	};
};

export const resetTaskTimer = () => {
	return {
		type: RESET_TIMER_STATUS,
	};
};
export const handleTimerStatus = (data) => {
	return {
		type: HANDLE_TIMER_STATUS,
		payload: data,
	};
};

export const handleTaskTimer = (data, force_update, tracking_status) => {
	const error_codes = ['TASK_NOT_ASSIGNED', 'TASK_NOT_FOUND'];
	const queryParamsString = force_update
		? `?force_update=${force_update}`
		: `?tracking_status=${tracking_status}`;

	return async (dispatch) => {
		const requestConfig = {
			url: `/v1/task_timers/log_time${queryParamsString}`,
			method: 'Post',
			data: data,
		};

		try {
			const request = await secure_instance.request(requestConfig);
			dispatch({
				type: HANDLE_TASK_TIMER,
				payload: request.data,
			});
			return request.data;
		} catch (error) {
			if (error.request) saveTimeTrackingRequest(requestConfig);
			if (error_codes?.includes(error?.response?.data?.error_code)) {
				dispatch({
					type: TIME_TRACKING_ERROR,
					payload: error?.response?.data,
				});
			}
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const handleTaskTrackingStatus = (taskId, userId, taskStatus, socket) => {
	return async () => {
		try {
			if (socket) {
				if (!socket.connected) {
					socket.connect();
				}
				await socket.emit('task_tracking_status', {
					task_id: taskId,
					user_id: userId,
					status: taskStatus,
				});
			}
		} catch (error) {
			//
		}
	};
};

export const updateTaskCard = (data, isDragTask) => {
	var { task, dragOverContainer } = data;
	task = {
		...task,
		extended_date: dragOverContainer,
	};

	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/tasks/${task.id}?isDragTask=${isDragTask}`,
				method: 'PATCH',
				data: task,
			});

			dispatch({
				type: UPDATE_TASK_CARD,
				payload: data,
			});
			dispatch({
				type: UPDATE_TASK_ERROR,
				payload: null,
			});

			// dispatch(getWeeklyClientTasks(filters, start_date, end_date));
		} catch (error) {
			dispatch({
				type: UPDATE_TASK_ERROR,
				payload: error?.response?.data,
			});
			// alert(error.response.data.message);
		}
	};
};

export const rescheduleTasks = (data, isDragTask) => {
	var { task, dragOverContainer } = data;
	task = {
		...task,
		extended_date: dragOverContainer,
	};
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `v1/tasks/${task.id}?isDragTask=${isDragTask}`,
				method: 'PATCH',
				data: task,
			});
			return request.data;
		} catch (error) {
			throw error?.response?.data;
		}
	};
};

export const setDragOverDate = (date) => {
	return {
		type: SET_DRAG_OVER_DATE,
		payload: date,
	};
};

export const updateStatus = (status, id, socket) => {
	return async (dispatch) => {
		try {
			if (socket) {
				if (!socket.connected) {
					socket.connect();
				}
				await socket.emit(
					'task_update',
					{
						id: id,
						status: status,
						expand: '[client_workflow.[workflow, attachment], task_assignees(selectNone).user_profile(selectedFieldsForWorkspace), my_task.[client_workflow(selectedFields), workflow], task_recurrence, assignee_users, client_workflow_task.client_workflow.workflow, user_profile.leave_requests.leaves, client.[client_contact_list, links, team_client_permissions.team], depend_on_task.user_profile, comments.user_profile, task_timers.user_profile]',
					},
					(data) => {},
				);
			}

			dispatch({
				type: UPDATE_STATUS,
				payload: status,
			});
		} catch (error) {
			//
		}
	};
};

export const updateTaskSocket = (
	socket,
	id,
	inputs,
	userId,
	setEdit,
	futureTaskUpdatePreferences,
	// teamProp,
) => {
	console.log('inputs', inputs);
	return async (dispatch) => {
		try {
			dispatch({
				type: UPDATE_TASK_START,
			});
			dispatch({
				type: SET_LOADING,
				payload: true,
			});
			await socket.emit(
				'task_update',
				{
					id: id,
					title: inputs.title,
					budgeted_hours: parseFloat(inputs.budgeted_hours) || 0.1,
					assignee_user_id: inputs.assignee_user_id
						? Number(inputs.assignee_user_id)
						: null,
					approval_from: inputs.approval_from ? inputs.approval_from : null,
					is_approval_required: inputs.approval_from ? true : false,
					description: inputs.description,
					start_date: inputs.start_date,
					due_date: inputs.due_date,
					notes: inputs.notes,
					description_change_future: futureTaskUpdatePreferences.description,
					notes_change_future: futureTaskUpdatePreferences.notes,
					metadata: inputs.metadata,
					extended_date: inputs?.extended_date || null,
					expand: '[client_workflow.[workflow, attachment], task_assignees(selectNone).user_profile(selectedFieldsForWorkspace), my_task, client_workflow_task.client_workflow.workflow,user_profile.leave_requests.leaves,client.[client_contact_list, links, team_client_permissions.team],depend_on_task.user_profile,comments.user_profile, task_timers.user_profile]',
				},
				async (data) => {
					console.info('notehere', data);
					if (data?.status === 404) {
						alert(data?.message);
						dispatch({
							type: SET_TASK_LOADING,
							payload: false,
						});
						dispatch({
							type: SET_LOADING,
							payload: false,
						});
					} else {
						setEdit && setEdit(false);
						dispatch({
							type: SET_LOADING,
							payload: false,
						});
						dispatch({
							type: TRIGGER_GET_TASKS,
						});
					}
				},
			);

			// if (inputs.assignee_user_id) {
			// 	dispatch(stopTracking(socket, userId, id));
			// }
		} catch (error) {
			dispatch({
				type: SET_LOADING,
				payload: false,
			});
			//
			dispatch({
				type: SET_TASK_LOADING,
				payload: false,
			});
		}
	};
};

export const updateCompleteStatus = (status, id, socket, handleModalClose) => {
	return async (dispatch) => {
		try {
			if (socket) {
				if (!socket.connected) {
					socket.connect();
				}
				await socket.emit(
					'task_update',
					{
						id: id,
						status: status,
						expand: '[client_workflow.workflow, task_assignees(selectNone).user_profile(selectedFieldsForWorkspace), my_task.[client_workflow(selectedFields), workflow], task_recurrence, assignee_users, client_workflow_task.client_workflow.[workflow, attachment], user_profile.leave_requests.leaves, client.[client_contact_list, links, team_client_permissions.team], depend_on_task.user_profile, comments.user_profile, task_timers.user_profile]',
					},
					(data) => {},
				);
			}

			dispatch({
				type: UPDATE_COMPLETE_STATUS,
			});

			handleModalClose && handleModalClose();
		} catch (error) {
			//
		}
	};
};

export const postComment = (data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: POST_COMMENT,
				payload: data,
			});
		} catch (error) {
			//
		}
	};
};

export const postCommentResponse = (data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: POST_COMMENT_RESPONSE,
				payload: data,
			});
		} catch (error) {
			//
		}
	};
};

export const refreshedTimer = (data) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: REFRESHED_TIMER,
				payload: data,
			});
		} catch (error) {
			//
		}
	};
};

export const deleteComment = (id) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/comments/${id}`,
				method: 'DELETE',
			});

			dispatch({
				type: DELETE_COMMENT,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const updateMonthlyTask = (data, isDragTask) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `v1/tasks/${data.id}?isDragTask=${isDragTask}`,
				method: 'PATCH',
				data,
			});
			dispatch({
				type: UPDATE_TASK_ERROR,
				payload: null,
			});
			return request.data;
		} catch (error) {
			dispatch({
				type: UPDATE_TASK_ERROR,
				payload: error?.response?.data,
			});
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const handleChange = (data) => {
	return {
		type: HANDLE_CHANGE,
		payload: data,
	};
};

export const handleRepeatChange = (data) => {
	return {
		type: HANDLE_REPEAT_CHANGE,
		payload: data,
	};
};

export const setOpenActionCenterModal = (data) => {
	return {
		type: SET_OPEN_ACTION_CENTER_MODAL,
		payload: data,
	};
};

export const setSpecificTaskID = (task_id) => {
	return {
		type: SET_SPECIFIC_TASK,
		payload: task_id,
	};
};

export const resetError = () => {
	return {
		type: RESET_ERROR,
	};
};

export const handleDateChange = (data) => {
	return {
		type: HANDLE_DATE_CHANGE,
		payload: data,
	};
};

export const setWorkflowName = (workflow) => {
	return {
		type: SET_WORKFLOW_NAME,
		payload: workflow,
	};
};

export const resetWorkflowName = () => {
	return {
		type: RESET_WORKFLOW_NAME,
	};
};

export const handleTaskStatusCheckbox = (data) => {
	return {
		type: HANDLE_TASK_STATUS_CHECKBOX,
		payload: data,
	};
};

export const handleTeamCheckbox = (data) => {
	return {
		type: HANDLE_TEAM_CHECKBOX,
		payload: data,
	};
};

export const handleClientsCheckbox = (data) => {
	return {
		type: HANDLE_CLIENTS_CHECKBOX,
		payload: data,
	};
};

export const handleWorkflowsCheckbox = (data) => {
	return {
		type: HANDLE_WORKFLOWS_CHECKBOX,
		payload: data,
	};
};

export const handleClearWorkflowsCheckbox = () => {
	return {
		type: HANDLE_CLEAR_WORKFLOWS_CHECKBOX,
	};
};

export const handleUsersCheckbox = (data) => {
	return {
		type: HANDLE_USERS_CHECKBOX,
		payload: data,
	};
};

export const handleClearUsersCheckbox = (data) => {
	return {
		type: HANDLE_CLEAR_USER_CHECKBOX,
		// payload: data,
	};
};

export const handleClearClientsCheckbox = (data) => {
	return {
		type: HANDLE_CLEAR_CLIENT_CHECKBOX,
		// payload: data,
	};
};

export const handleClearTaskStatusesCheckbox = (data) => {
	return {
		type: HANDLE_CLEAR_TASK_STATUSES_CHECKBOX,
		// payload: data,
	};
};

export const handleClearTeamCheckbox = (data) => {
	return {
		type: HANDLE_CLEAR_TEAM_CHECKBOX,
		// payload: data,
	};
};

export const updateTaskInfo = (task) => {
	return {
		type: UPDATE_TASK_INFO,
		payload: task,
	};
};

export const updateWeeklyTaskInfo = (taskDetails) => {
	return {
		type: UPDATE_WEEKLY_TASK_INFO,
		payload: taskDetails,
	};
};

export const updateMeetingTasks = (taskDetails) => {
	return {
		type: UPDATE_MEETING_TASKS,
		payload: taskDetails,
	};
};

export const setSnackBarIsOpen = (success, state, text) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: SET_SNACKBAR_IS_OPEN,
				payload: {
					isSuccess: success,
					IsOpen: state,
					message: text,
				},
			});
		} catch (error) {
			//
		}
	};
};

export const resetTaskError = () => {
	return {
		type: RESET_TASK_DRAG_ERROR,
		payload: null,
	};
};

export const handleTaskRecurrence = (end_on) => {
	return {
		type: SET_MEETING_TASK_RECURRENCE,
		payload: end_on,
	};
};

export const handleTaskType = (form) => {
	return {
		type: HANDLE_TASK_TYPE,
		payload: form,
	};
};

export const triggerGetTasks = () => {
	return {
		type: TRIGGER_GET_TASKS,
	};
};

export const getMeetings = () => {
	return {
		type: GET_MEETINGS,
	};
};

export const toggleTeamMeetings = () => {
	return {
		type: TOGGLE_TEAM_MEETINGS,
	};
};

export const resetTimeTrackingError = () => {
	return {
		type: RESET_TIME_TRACKING_ERROR,
		payload: null,
	};
};
export const setMonthlyView = (monthlyView) => {
	return {
		type: SET_MONTHLY_VIEW,
		payload: monthlyView,
	};
};

export const setWeekend = (weekDaysOff) => {
	return {
		type: SET_WEEKEND,
		payload: weekDaysOff,
	};
};

export const setWeekData = (weekDate) => {
	return {
		type: SET_WEEK_DATE,
		payload: weekDate,
	};
};

export const resetWorkspaceTasks = () => {
	return {
		type: RESET_WORKSPACE_TASKS,
		payload: null,
	};
};

export const deleteAdhocTask = (taskDetails) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/my_tasks/?task_id=${taskDetails?.id}`,
				method: 'delete',
			});

			dispatch({
				type: DELETE_CLIENT_AD_HOC_TASKS,
				payload: request.data,
			});

			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const setEventSource = (eventSource) => {
	return {
		type: SET_EVENT_SOURCE,
		payload: eventSource,
	};
};

export const getTaskActivity = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/workspace/task_activity',
				method: 'GET',
			});
		} catch {
			//
		}
	};
};

export const updateTaskActivity = (task_id, status) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/workspace/task_activity',
				method: 'POST',
				data: {
					task_id: task_id,
					status: status,
				},
			});
		} catch {
			//
		}
	};
};
export const updateMeetingInvitationStatus = (data) => {
	const { task_id, update_all, ...body } = data;
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/my_tasks/${task_id}/accept?update_all=${update_all}`,
				method: 'PATCH',
				data: body,
			});
			dispatch({
				type: SET_MEETING_INVITE_STATUS,
				payload: request.data,
			});
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const setTaskModalMinimize = (isTaskMinimized) => {
	return {
		type: SET_TASK_MODAL_MINIMIZE,
		payload: isTaskMinimized,
	};
};

export const handleTaskClick = (task = null) => {
	return {
		type: HANDLE_TASK_CLICK,
		payload: task,
	};
};

export const setIsTimeTracking = (isTimeTracking) => {
	return {
		type: SET_IS_TIME_TRACKING,
		payload: isTimeTracking,
	};
};

export const getDocumentRequestInviteLink = (body) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/document_requests/`,
				method: 'POST',
				data: body,
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const setDocumentRequestInviteLink = (inviteLink) => {
	return {
		type: SET_DOCUMENT_REQUEST_INVITE_LINK,
		payload: inviteLink,
	};
};

export const getDocumentsRequestByClient = (id) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `v1/document_requests/child_requests?folder_id=${id}`,
				method: 'GET',
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const uploadDocument = (data, id, file) => {
	return async (dispatch) => {
		try {
			const request = await instance.request({
				url: `/v1/document_requests/upload/${id}`,
				method: 'POST',
				data: data,
				onUploadProgress: (event) => {
					const progress = parseFloat(((event.loaded / event.total) * 100).toFixed(0));
					dispatch({
						type: UPLOAD_FILE,
						payload: { file, pc: progress },
					});
				},
			});
			dispatch({
				type: UPLOAD_IMAGE_PROGRESS,
				payload: { file, status: 1, error: '' },
			});
			return request?.data;
		} catch (error) {
			dispatch({
				type: UPLOAD_IMAGE_PROGRESS,
				payload: {
					file,
					status: -1,
					error: error.response?.data?.message || error.message || 'File Upload Failed',
				},
			});
			throw new Error(
				error?.response?.data?.message || error?.message || 'File Upload Failed',
			);
		}
	};
};

export const getTenantProfile = (tenant_id) => {
	return async () => {
		try {
			const request = await instance.request({
				url: `/v1/public/tenant/${tenant_id}`,
				method: 'GET',
			});
			return request?.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};
export const getInviteData = (uuid) => {
	return async () => {
		try {
			const request = await instance.request({
				url: `/v1/document_requests/${uuid}`,
				method: 'GET',
			});
			return request?.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const sendDocumentUploadInvite = (data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/document_requests/invite`,
				method: 'POST',
				data: data,
			});
			return request?.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const updateClientDocumentRequestDetails = (payload, uuid) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/document_requests/${uuid}`,
				method: 'PATCH',
				data: payload,
			});
			return request?.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const updateTaskTimer = (data) => {
	return {
		type: UPDATE_TASK_TIMER,
		payload: data,
	};
};
export const updateBulkTasksAssignee = (payload) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: '/v1/task_assignee/reassign',
				method: 'PATCH',
				data: payload,
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const createCustomTaskStatus = (payload) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: '/v1/task_statuses/',
				method: 'POST',
				data: payload,
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const getCustomTaskStatus = () => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: '/v1/task_statuses/',
				method: 'GET',
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const updateCustomTaskStatuses = (task_status_id, task_status) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/task_statuses/${task_status_id}`,
				method: 'PATCH',
				data: task_status,
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const archiveCustomTaskStatus = (status_id, data = {}) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/task_statuses/${status_id}`,
				method: 'DELETE',
				data: data,
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const unArchiveCustomTaskStatus = (status_id) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/task_statuses/${status_id}/unarchive`,
				method: 'PATCH',
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const setCustomTaskStatuses = (taskStatuses) => {
	return {
		type: SET_CUSTOM_TASK_STATUSES,
		payload: taskStatuses,
	};
};

export const initiateAiChat = (prompt) => {
    return async (dispatch) => {
        try {
            const request = await secure_instance.request({
                url: `/v1/chatbot/?prompt=${prompt}`,
                method: 'GET',
            });
            return request.data;
        } catch (error) {
            throw new Error(
                error?.response?.data?.message || error?.message || 'Something went wrong',
            );
        }
    }
}
