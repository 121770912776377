import { Menu, MenuItem } from '@mui/material/';
import { makeStyles } from '@material-ui/core';
import React, { useState, memo, useRef } from 'react';
import { Input, InputAdornment } from '@mui/material';
import { ReactComponent as SearchIcon } from '../../assets/icons/MagnifyingGlassSecondary.svg';

const groupByAlphabet = (options) => {
	return options?.reduce((groups, option) => {
		const firstLetter = option?.label?.charAt(0).toUpperCase();
		if (!groups[firstLetter]) {
			groups[firstLetter] = [];
		}
		groups[firstLetter].push(option);
		return groups;
	}, {});
};

const useStyles = makeStyles(() => ({
	menuItem: {
		display: 'block',
		backgroundColor: 'white',
		fontFamily: 'CentraLight',
		fontWeight: 'light',
		fontSize: '14px !important',
		padding: '3px !important',
		lineHeight: '18px',
		'&:hover': {
			backgroundColor: '#fd7a03 !important',
			color: 'white !important',
		},
	},
	selectedMenuItem: {
		backgroundColor: '#fd7a03 !important',
		color: 'white !important',
	},
}));

function NewSearchDropdown({
	options,
	changeHandler,
	required,
	label,
	value,
	selectClassName,
	placeholder,
	width,
	disableNull,
	selecDisabled,
	disableLetters,
	customButton,
}) {
	const classes = useStyles();
	const [searchInput, setSearchInput] = useState('');
	const inputRef = useRef(null);
	const groupedOptions = groupByAlphabet(options);

	const [anchorEl, setAnchorEl] = useState(null);

	const handleMenuOpen = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleOptionSelect = (option) => {
		changeHandler(option);
		setAnchorEl(null);
	};
	const stopImmediatePropagation = (e) => {
		e.stopPropagation();
		e.preventDefault();
	};

	return (
		<div className='w-100'>
			<div
				className={`d-flex align-items-end w-100 justify-content-start ${
					selecDisabled && 'opacity-50'
				}`}
			>
				<input
					className={`NewSearchDropdown dropdown-text ${selectClassName}`}
					onClick={handleMenuOpen}
					value={value ? options?.find((item) => item.value == value)?.label : ''}
					placeholder={placeholder}
					disabled={selecDisabled}
					readOnly
					required={required}
				/>
			</div>
			<Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
				<MenuItem
					id='searchinput'
					name='searchinput'
					style={{
						background: 'transparent',
					}}
					className='d-flex justify-content-center'
					onClickCapture={stopImmediatePropagation}
					onKeyDown={(e) => e.stopPropagation()}
				>
					<Input
						ref={inputRef}
						autoFocus={true}
						onChange={(e) => {
							setSearchInput(e.target.value);
						}}
						value={searchInput}
						autoComplete='off'
						className='pl-1 BodyTwoLight'
						disableUnderline={true}
						inputProps={{ style: { paddingTop: '12px', color: '#333333' } }}
						startAdornment={
							<InputAdornment position='start'>
								<SearchIcon style={{stroke: '#FB7A03'}}/>
							</InputAdornment>
						}
						placeholder={`Search ${label}`}
						sx={{
							borderRadius: '4px',
							width: '95%',
							marginBottom: '5px',
							paddingX: '8px',
							background: '#EEF2F3',
						}}
					/>
				</MenuItem>

				<div
					className='d-flex flex-column'
					style={{
						padding: '6px',
						gap: '10px',
						maxHeight: '300px',
						overflowY: 'auto',
						width: `${width}`,
					}}
				>
					{customButton && (
						<MenuItem
							autoFocus={false}
							value={null}
							onClick={customButton?.onClick}
							disabled={customButton?.isDisable}
						>
							<span
								className='letter-menu-item w-100 primary-text'
								style={{
									// background: 'transparent !important',
									fontFamily: 'Centra-bold',
									fontWeight: 700,
									fontSize: '12px',
									lineHeight: '14px',
								}}
							>
								&#65291; {customButton?.label}
							</span>
						</MenuItem>
					)}
					{!disableNull && (
						<MenuItem
							autoFocus={false}
							value={null}
							className={classes.menuItem}
							onClick={() => handleOptionSelect({ label: '', value: null })}
						>
							<span> </span>
						</MenuItem>
					)}
					{Object.entries(groupedOptions).map(([letter, options]) => (
						<React.Fragment key={letter}>
							{!disableLetters && (
								<MenuItem>
									<span
										className='letter-menu-item w-100 dark-text'
										style={{
											// background: 'transparent !important',
											fontFamily: 'Centra-bold',
											fontWeight: 700,
											fontSize: '12px',
											lineHeight: '14px',
										}}
									>
										{letter}
									</span>
								</MenuItem>
							)}
							{options
								?.filter((item) => {
									if (searchInput != '') {
										return item?.label
											?.toLowerCase()
											?.includes(searchInput?.toLowerCase());
									}
									return true;
								})
								.map((option, index) => (
									<MenuItem
										autoFocus={false}
										key={option.value}
										value={option.value}
										type={option.type}
										style={
											{
												// background: 'transparent',
												// display: 'flex',
												// flexWrap: 'wrap',
											}
										}
										className={`${classes.menuItem} ${
											option.value == value && classes.selectedMenuItem
										}`}
										data-cy={`option-${index + 1}`}
										onClick={() => handleOptionSelect(option)}
									>
										<span
										// className={`${
										// 	option.value == value
										// 		? 'new-searchable-dropdown-selected-item'
										// 		: 'select-menu-item'
										// } w-100 BodyTwoLight`}
										>
											{' ' + option.label}
										</span>
									</MenuItem>
								))}
						</React.Fragment>
					))}
				</div>
			</Menu>
		</div>
	);
}

export default memo(NewSearchDropdown);
