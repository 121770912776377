import { useMemo } from 'react';
import { useSelector } from 'react-redux';

function CustomFieldValuesSection() {
	const customFieldValues = useSelector((state) => state.clientReducer.customFieldValues);
	const customFieldDefinitions = useSelector((state) =>
		state.profileReducer.customFieldDefinitions?.sort((a, b) => a?.list_order - b?.list_order),
	);

	const customFieldValuesMapping = useMemo(() => {
		if (!customFieldDefinitions?.length) return [];

		return customFieldDefinitions.map(({ id, name }) => {
			const mappedValue = customFieldValues?.find(
				({ property_definition_id }) => property_definition_id === id,
			);

			return {
				id: mappedValue?.id ?? id,
				name,
				value: mappedValue?.value ?? '',
			};
		});
	}, [customFieldValues, customFieldDefinitions]);

	return (
		customFieldValuesMapping?.length > 0 && (
			<div
				className='d-flex flex-column text-left'
				style={{ width: '18%', minHeight: '200px', gap: '0.8rem' }}
			>
				<span className='BodyOneBold'>Custom Fields</span>

				<div className='d-flex flex-column'>
					{customFieldValuesMapping.map((field) => {
						return (
							<div key={field?.id} className='text-left ProfilePage'>
								<label className='label BodyTwoLight m-0 text-capitalize'>
									{field?.name}
								</label>
								<h3
									className='BodyTwoLight'
									style={{ wordBreak: 'break-all' }}
									data-cy='refferedBy'
								>
									{field?.value}
								</h3>
							</div>
						);
					})}
				</div>
			</div>
		)
	);
}

export default CustomFieldValuesSection;
