import { useDispatch, useSelector } from 'react-redux';
import * as WorkSpaceActions from '../redux/actions/dashboard/workspaces-actions';
import { useEffect, useRef, useState } from 'react';
import { useSessionStorage } from '../hooks/useSessionStorage';
import NewTaskDetailsModal from '../components/WorkSpace/NewTaskDetailsModal';
import { useHistory, useLocation } from 'react-router';
import TaskAlert from '../components/Alerts/TaskAlert';
import MeetingMyTaskDetailsModal from '../components/WorkSpace/Task Modal/MeetingMyTaskDetailsModal';
import { TRACKING_STATUS, detailsModal } from '../utilities/utilities';
import { Modal } from '@mui/material';

function TaskModalHandler() {
	const history = useHistory();
	const dispatch = useDispatch();
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);

	const modalRef = useRef();
	const timeLoggedRef = useRef(0);
	const handleTaskTimerRef = useRef();
	const backGroundRef = useRef();

	const [openNewTaskAlert, setOpenNewTaskAlert] = useState({
		isAlert: false,
		openedTaskTitle: '',
		taskId: '',
		modalType: '',
	});

	const [taskId, setTaskId] = useState(searchParams.get('task_id'));
	const [activeModalName, setActiveModalName] = useState('');

	const active_task_id = useSessionStorage('active_task_id');
	const active_task_type = useSessionStorage('active_task_type');
	const timeTrackingError = useSelector((state) => state.workSpaceReducer.timeTrackingError);
	const isTaskMinimized = useSelector((state) => state.workSpaceReducer.isTaskMinimized);
	const taskClicked = useSelector((state) => state.workSpaceReducer.taskClicked);
	const user = useSelector((state) => state.authReducer.user);
	const taskDetails = useSelector((state) => state.workSpaceReducer.newTaskDetails);
	const isTimeTracking = useSelector((state) => state.workSpaceReducer.isTimeTracking);

	const handleTaskClick = (task_id, modalType) => {
		if (isTaskMinimized && active_task_id?.getSessionStorage() != task_id) {
			setOpenNewTaskAlert({
				isAlert: true,
				openedTaskTitle: taskDetails?.title,
				taskId: task_id,
				modalType: modalType,
			});
		} else {
			setTaskId(task_id);
			active_task_id.setSessionStorage(task_id);
			active_task_type.setSessionStorage(modalType);
			searchParams.set('task_id', task_id);
			history.replace(`${location.pathname}?${searchParams.toString()}`);
			setActiveModalName(modalType);
			dispatch(WorkSpaceActions.getTaskDetailsById(task_id)).catch(() => {
				handleModalClose();
			});
			dispatch(WorkSpaceActions.setTaskModalMinimize(false));
		}
		dispatch(WorkSpaceActions.handleTaskClick());
	};

	const handleModalClose = () => {
		if (timeTrackingError !== null) {
			dispatch(WorkSpaceActions.resetTimeTrackingError());
		} else handleTaskTimerRef?.current && handleTaskTimerRef.current(TRACKING_STATUS.ENDED);

		dispatch(WorkSpaceActions.setTaskModalMinimize(false));
		dispatch(WorkSpaceActions.resetTaskDetails());
		dispatch(WorkSpaceActions.resetTaskTimer());
		dispatch(WorkSpaceActions.updateTaskActivity(taskId, 'closed'));

		setTaskId(null);
		setActiveModalName('');

		active_task_id.removeSessionStorage();
		searchParams.delete('task_id');
		history.replace(`${location.pathname}?${searchParams.toString()}`);
		timeLoggedRef.current = 0;
	};

	const handleModalMinimze = () => {
		if (isTaskMinimized) return;
		dispatch(WorkSpaceActions.setTaskModalMinimize(true));
		searchParams.delete('task_id');
		history.replace(`${location.pathname}?${searchParams.toString()}`);
		!active_task_id?.getSessionStorage() && active_task_id.setSessionStorage(taskDetails?.id);
	};

	const onForceTaskOpen = (task_id, modalType) => {
		dispatch(WorkSpaceActions.resetTaskTimer());
		dispatch(WorkSpaceActions.resetTaskAssignee());
		handleTaskTimerRef?.current && handleTaskTimerRef.current(TRACKING_STATUS.ENDED);
		timeLoggedRef.current = 0;
		dispatch(WorkSpaceActions.getTaskDetailsById(task_id)).catch(() => {
			handleModalClose();
		});
		dispatch(WorkSpaceActions.setTaskModalMinimize(false));
		setActiveModalName(modalType);
		setOpenNewTaskAlert({
			isAlert: false,
			openedTaskTitle: '',
			taskId: null,
			modalType: '',
		});
		active_task_id.setSessionStorage(task_id);
		active_task_type.setSessionStorage(modalType);
		searchParams.set('task_id', task_id);
		history.replace(`${location.pathname}?${searchParams.toString()}`);
	};

	useEffect(() => {
		if (taskId !== null && user?.accessToken) {
			activeModalName == '' && setActiveModalName(active_task_type.getSessionStorage());
			searchParams.set('task_id', taskId);
			history.replace(`${location.pathname}?${searchParams.toString()}`);
			dispatch(WorkSpaceActions.getTaskDetailsById(taskId)).catch(() => {
				handleModalClose();
			});
		}
	}, [dispatch, user, setTaskId]);

	useEffect(() => {
		const task_id = searchParams.get('task_id') || active_task_id?.getSessionStorage();
		if (task_id) {
			searchParams.set('task_id', task_id);
			history.replace(`${location.pathname}?${searchParams.toString()}`);
			setTaskId(task_id);
		}
	}, []);

	useEffect(() => {
		if (taskClicked?.taskId) {
			handleTaskClick(taskClicked?.taskId, taskClicked?.modalType);
		}
	}, [taskClicked]);

	const handleClickOutside = (event) => {
		if (event.button !== 0) {
			return;
		}
		if (backGroundRef.current == event.target && !isTaskMinimized) {
			isTimeTracking ? handleModalMinimze() : handleModalClose();
		}
	};

	return (
		<>
			{Boolean(
				activeModalName == detailsModal.CLIENT && active_task_id?.getSessionStorage(),
			) && (
				<div
					style={{
						width: !isTaskMinimized && '100%',
						height: !isTaskMinimized && '100%',
						display: 'flex',
						position: 'absolute',
						zIndex: 1000,
						backgroundColor: !isTaskMinimized && 'rgba(0,0,0,0.5)',
						justifyContent: 'center',
						alignItems: 'center',
					}}
					ref={backGroundRef}
					onClick={handleClickOutside}
				>
					<NewTaskDetailsModal
						ref={modalRef}
						timeLoggedRef={timeLoggedRef}
						onModalClose={handleModalClose}
						id={taskId}
						handleTaskTimerRef={handleTaskTimerRef}
						handleTaskClick={handleTaskClick}
						handleModalMinimze={handleModalMinimze}
					/>
				</div>
			)}
			<Modal
				onClose={handleModalClose}
				open={Boolean(
					active_task_id?.getSessionStorage() && activeModalName == detailsModal.MY_TASK,
				)}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<MeetingMyTaskDetailsModal
					id={taskId}
					handleModalClose={handleModalClose}
					handleTaskTimerRef={handleTaskTimerRef && handleTaskTimerRef}
				/>
			</Modal>
			<TaskAlert
				message={
					'Opening this task will close the previous minimized task. Would you like to Proceed?'
				}
				header={`${openNewTaskAlert?.openedTaskTitle} is already opened`}
				open={openNewTaskAlert?.isAlert}
				onDone={() => {
					dispatch(WorkSpaceActions.resetTaskDetails());
					onForceTaskOpen(openNewTaskAlert?.taskId, openNewTaskAlert?.modalType);
				}}
				doneButton={'Confirm'}
				closeButton={'Cancel'}
				closeHandler={() => {
					setOpenNewTaskAlert({
						isAlert: false,
						openedTaskTitle: '',
						taskId: null,
						modalType: '',
					});
					dispatch(WorkSpaceActions.resetTaskError());
				}}
			/>
		</>
	);
}

export default TaskModalHandler;
