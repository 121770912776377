import { secure_instance } from '../../axios/axios-config';

export const GET_TENANT_PROFILE = '[COMPANY_PROFILE] GET_TENANT_PROFILE';
export const HANDLE_CHANGE = '[COMPANY_PROFILE] HANDLE_CHANGE';
export const UPDATE_PROFILE = '[COMPANY_PROFILE] UPDATE_PROFILE';
export const GET_CARDS = '[COMPANY_PROFILE] GET_CARDS';
export const DETACH_CARD = '[COMPANY_PROFILE] DETACH_CARD';
export const SUBSCRIPTION_END = '[COMPANY_PROFILE] SUBSCRIPTION_END';
export const GET_CUSTOMER_INVOICES = '[COMPANY_PROFILE] GET_CUSTOMER_INVOICES';
export const HANDLE_INDUSTRY_CHANGE = '[COMPANY_PROFILE] HANDLE_INDUSTRY_CHANGE';
export const HANDLE_BUSINESS_CHANGE = '[COMPANY_PROFILE] HANDLE_BUSINESS_CHANGE';
export const HANDLE_COUNTRY_CHANGE = '[COMPANY_PROFILE] HANDLE_COUNTRY_CHANGE';
export const HANDLE_CURRENCY_CHANGE = '[COMPANY_PROFILE] HANDLE_CURRENCY_CHANGE';
export const HANDLE_PACKAGE_CHANGE = '[COMPANY_PROFILE] HANDLE_PACKAGE_CHANGE';
export const UPLOAD_IMAGE = '[COMPANY_PROFILE] UPLOAD_IMAGE';
export const UPLOAD_IMAGE_START = '[COMPANY_PROFILE] UPLOAD_IMAGE_START';
export const UPLOAD_IMAGE_FAILED = '[COMPANY_PROFILE] UPLOAD_IMAGE_FAILED';
export const SET_ALL_DEFAULT_WORKFLOWS = '[COMPANY_PROFILE] SET_ALL_DEFAULT_WORKFLOWS';
export const SET_ERROR = 'SET_ERROR';
export const GET_TENANT_PROFILE_LOADING = 'GET_TENANT_PROFILE_LOADING';
export const GET_COUNTRIES_LIST = 'GET_COUNTRIES_LIST';
export const GET_COUNTRY_STATES = 'GET_COUNTRY_STATES';
export const GET_SECONDARY_COUNTRY_STATES = 'GET_SECONDARY_COUNTRY_STATES';
export const SET_CUSTOM_FIELDS = 'SET_CUSTOM_FIELDS';

export const getTenantProfile = (id) => {
	return async (dispatch) => {
		try {
			dispatch({ type: GET_TENANT_PROFILE_LOADING, payload: true });

			const request = await secure_instance.request({
				url: `/v1/tenant_profiles/${id}`,
				method: 'Get',
			});

			dispatch({
				type: GET_TENANT_PROFILE,
				payload: request.data,
			});
			dispatch({ type: GET_TENANT_PROFILE_LOADING, payload: false });
			console.log(request.data, 'requestdata123');
		} catch (error) {
			console.error('Error fetching tenants:', error);
			dispatch({ type: GET_TENANT_PROFILE_LOADING, payload: false });
		}
	};
};

export const handleChange = (data) => {
	return {
		type: HANDLE_CHANGE,
		payload: data,
	};
};

export const handleIndustryChange = (data) => {
	return {
		type: HANDLE_INDUSTRY_CHANGE,
		payload: data,
	};
};

export const handleBusinessChange = (data) => {
	return {
		type: HANDLE_BUSINESS_CHANGE,
		payload: data,
	};
};

export const handleCountryChange = (data) => {
	return {
		type: HANDLE_COUNTRY_CHANGE,
		payload: data,
	};
};

export const handleCurrencyChange = (data) => {
	return {
		type: HANDLE_CURRENCY_CHANGE,
		payload: data,
	};
};

export const handlePackageChange = (data) => {
	return {
		type: HANDLE_PACKAGE_CHANGE,
		payload: data,
	};
};

export const updateProfile = (id, data) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/tenant_profiles/${id}`,
				method: 'Patch',
				data: data,
			});

			dispatch({
				type: UPDATE_PROFILE,
				payload: request.data,
			});
		} catch (error) {
			//
		}
	};
};

export const getCards = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/stripe_customers/list_payment_methods/',
				method: 'Get',
			});
			dispatch({
				type: GET_CARDS,
				payload: request?.data?.data,
			});
		} catch (error) {
			//
		}
	};
};

export const detachCard = (id, setLoadingItems) => {
	return async (dispatch) => {
		try {
			await secure_instance.request({
				url: '/v1/stripe_customers/detach_payment_method/',
				method: 'Post',
				data: {
					payment_method_id: id,
				},
			});
			setLoadingItems([]);

			dispatch({
				type: DETACH_CARD,
				payload: id,
			});
		} catch (error) {
			//
			setLoadingItems([]);
		}
	};
};

export const getCustomerInvoices = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/stripe_customers/customer_invoices',
				method: 'Get',
			});

			dispatch({
				type: GET_CUSTOMER_INVOICES,
				payload: request?.data,
			});
		} catch (error) {
			//
		}
	};
};

export const createStripePaymentMethod = (paymentMethod, primaryCard, setLoad, handleClose) => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: '/v1/stripe_customers/attach_payment_method',
				method: 'POST',
				data: {
					payment_method_id: paymentMethod.paymentMethod.id,
					make_default_payment_method: primaryCard,
				},
			});

			setLoad(false);

			handleClose();

			dispatch(getCards());
			dispatch({
				type: SUBSCRIPTION_END,
			});

			return request.data;
		} catch (error) {
			setLoad(false);
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const setError = (error) => {
	return (dispatch) => {
		dispatch({
			type: SET_ERROR,
			payload: error,
		});
	};
};

export const uploadImage = (image, toggleModal) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: UPLOAD_IMAGE_START,
			});
			const formData = new FormData();
			formData.append('image', image);

			const request = await secure_instance.request({
				url: `v1/tenant_profiles/upload`,
				method: 'Post',
				data: formData,
			});

			dispatch({
				type: UPLOAD_IMAGE,
				payload: request.data,
			});
			toggleModal();
		} catch (e) {
			dispatch({
				type: UPLOAD_IMAGE_FAILED,
			});
		}
	};
};

export const getAllDefaultWorkflow = () => {
	return async (dispatch) => {
		try {
			const request = await secure_instance.request({
				url: `/v1/client_workflows/?expand=[client_workflow_tasks(defaultOrder)]`,
				method: 'get',
			});

			dispatch({
				type: SET_ALL_DEFAULT_WORKFLOWS,
				payload: request.data,
			});
		} catch (e) {
			//
		}
	};
};

export const getAllCountries = (countries = []) => {
	return {
		type: GET_COUNTRIES_LIST,
		payload: countries,
	};
};

export const getCountryStates = (states = []) => {
	return {
		type: GET_COUNTRY_STATES,
		payload: states,
	};
};

export const getSecondaryCountryStates = (states = []) => {
	return {
		type: GET_SECONDARY_COUNTRY_STATES,
		payload: states,
	};
};
export const createCustomFieldDefinitions = (data) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: '/v1/custom_property_definitions/',
				method: 'Post',
				data: data,
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const upateCustomFieldDefinitions = (fieldId, fieldData) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/custom_property_definitions/${fieldId}`,
				method: 'Patch',
				data: fieldData,
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const archiveCustomFieldDefinitions = (fieldId) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/custom_property_definitions/${fieldId}`,
				method: 'Delete',
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const unArchiveCustomFieldDefinitions = (fieldId) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/custom_property_definitions/${fieldId}/unarchive`,
				method: 'Patch',
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const fetchCustomFieldDefinitions = () => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: '/v1/custom_property_definitions/',
				method: 'Get',
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const setCustomFieldDefinitions = (customFieldDefinitions) => {
	return {
		type: SET_CUSTOM_FIELDS,
		payload: customFieldDefinitions,
	};
};
