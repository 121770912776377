/* eslint-disable no-mixed-spaces-and-tabs */
import moment from 'moment';
import { memo, useCallback, useState } from 'react';
import { mapTasks, TASK_STATUSES, TASK_STATUSES_CATEGORY } from '../../utilities/utilities';
import { motion } from 'framer-motion';
import NewTaskCard from './NewTaskCard';
import { ReactComponent as ArrowUp } from '../../assets/icons/up.svg';
import { ReactComponent as ArrowDown } from '../../assets/icons/down.svg';
import { CircularProgress, Tooltip } from '@mui/material';
import NewSearchDropdown from '../Inputs/NewSearchDropdown';
import { useDispatch, useSelector } from 'react-redux';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';

function ExpandableClientForReassignments({
	client,
	i,
	userProfiles,
	tasks,
	handleUpdateAssignee,
	addNewUser,
	disableCustomButton,
	clientToUpdate,
	isAssigneeUpdate,
	handleDragEnd,
}) {
	const dispatch = useDispatch();
	const [expandTasks, setExpandTasks] = useState('');
	const [isExpanded, setIsExpanded] = useState(true);
	const [assignee, setAssignee] = useState(null);

	const allTaskStatuses = useSelector((state) => state.workSpaceReducer.customTaskStatus);
	const allTaskStatusesMap = new Map(allTaskStatuses?.map((status) => [status.id, status]));

	const handleAssignee = (assignee) => {
		const { value } = assignee;
		if (value == '') {
			setAssignee(null);
			return;
		}
		setAssignee(Number(value));
		handleUpdateAssignee(Number(value), client?.client_id);
	};

	const dragStartHandle = useCallback((currentTask) => {
		if (!isAssigneeUpdate) dispatch(Actions.setDraggingTaskDetails(currentTask));
	}, []);

	return (
		<div className='expandable-client-tasks'>
			<div
				className='d-flex justify-content-between align-items-center'
				style={{ gap: '18px' }}
			>
				<div
					className='d-flex w-75 align-items-center pointer h-auto'
					style={{ gap: '16px' }}
					onClick={() => {
						setIsExpanded(!isExpanded);
					}}
				>
					<Tooltip title={client?.displayName}>
						<span className='BodyThreeBold text-left text-ellipsis'>
							{client?.displayName}
						</span>
					</Tooltip>
					<span className='BodyThreeBold'>
						{isExpanded ? <ArrowUp /> : <ArrowDown />}
					</span>
				</div>
				{isAssigneeUpdate && (
					<div className='improved-input-designs'>
						{clientToUpdate === client?.client_id ? (
							<CircularProgress
								style={{
									color: '#fa7b03',
									height: '18px',
									width: '18px',
								}}
							/>
						) : (
							<NewSearchDropdown
								selectClassName={'w-100'}
								width={'300px'}
								value={assignee}
								placeholder='Select assignee'
								disableLetters
								changeHandler={handleAssignee}
								options={
									userProfiles?.length > 0
										? userProfiles?.map((assignee) => {
												return {
													value: assignee?.user_id,
													label: assignee?.user_name,
													is_active: !assignee?.is_active,
												};
										  })
										: []
								}
								name={'assignee'}
								required={true}
								disableNull={true}
								label={'Assignee'}
								customButton={{
									label: 'Add New User',
									onClick: addNewUser(client?.team_id),
									isDisable: disableCustomButton,
								}}
							/>
						)}
					</div>
				)}
			</div>
			{isExpanded && <div className='' style={{ paddingBottom: '12px' }}></div>}

			{isExpanded && (
				<div className='w-100 d-flex justify-content-center'>
					<div className='overlap-container'>
						{mapTasks(tasks)?.map((tasksGroup, innerIndex) => {
							if (tasksGroup?.length > 0) {
								const des_budgeted_hours_tasks = tasksGroup;

								const completed_tasks = des_budgeted_hours_tasks?.filter(
									(item) => item.task_status_id === TASK_STATUSES.COMPLETED,
								);
								let not_completed_tasks = des_budgeted_hours_tasks?.filter(
									(item) => item.task_status_id !== TASK_STATUSES.COMPLETED,
								);

								if (
									des_budgeted_hours_tasks?.filter(
										(i_item) =>
											i_item?.workflow_recurrence_id ==
											tasksGroup[0]?.workflow_recurrence_id,
									)?.length == des_budgeted_hours_tasks?.length
								) {
									not_completed_tasks = not_completed_tasks?.reverse();
								} else {
									not_completed_tasks = not_completed_tasks
										?.reverse()
										?.sort((a, b) => {
											const a_due_date = moment(a?.due_date).format(
												'MM-DD-YYYY',
											);
											const b_due_date = moment(b?.due_date).format(
												'MM-DD-YYYY',
											);
											return moment(a_due_date).isAfter(moment(b_due_date))
												? -1
												: 0;
										});
								}
								const completed_filter_tasks = [
									...completed_tasks,
									...not_completed_tasks,
								];

								const height_category = [];
								des_budgeted_hours_tasks?.map((i_item) => {
									if (!height_category.includes(i_item.budgeted_hours)) {
										height_category.push(i_item.budgeted_hours);
									}
								});

								let task_max_height = Math.max(...height_category);
								task_max_height < 1.0
									? (task_max_height = 1)
									: task_max_height > 3.0
									? (task_max_height = 3)
									: (task_max_height = 2);

								return (
									<motion.div
										layout
										key={i + innerIndex + tasksGroup[0].id}
										onMouseEnter={() =>
											setExpandTasks(i + innerIndex + tasksGroup[0].id)
										}
										onMouseLeave={() => setExpandTasks('')}
										className={`overlap-tasks-container length-${task_max_height} ${
											expandTasks == i + innerIndex + tasksGroup[0].id &&
											'overlap-tasks-container-extended'
										} `}
									>
										{completed_filter_tasks?.map(
											(currentTask, currentIndex) => {
												const prevCompletedTasksLength =
													completed_filter_tasks
														?.filter((item, j) => j < currentIndex)
														?.filter(
															(item) =>
																item.task_status_id ==
																TASK_STATUSES.COMPLETED,
														)?.length;

												let budgeted_hours_height_category = 0;

												currentTask?.budgeted_hours < 1.0
													? (budgeted_hours_height_category = 3)
													: currentTask?.budgeted_hours > 3.0
													? (budgeted_hours_height_category = 1)
													: (budgeted_hours_height_category = 2);

												const dependecyOrder =
													currentTask?.client_workflow_task
														? `${
																currentTask?.client_workflow_task
																	?.dependency_order + 1
														  }/${currentTask?.sibling_count}`
														: '1/1';

												const taskStatus = allTaskStatusesMap.get(
													currentTask?.task_status_id,
												);

												const isBlockedCategoryStatus = Boolean(
													taskStatus?.category ===
														TASK_STATUSES_CATEGORY.BLOCKED,
												);
												const cardBorder = !isBlockedCategoryStatus
													? 'none'
													: taskStatus?.id === TASK_STATUSES.BLOCKED
													? '1px solid red'
													: `1px solid ${taskStatus?.color}`;

												return (
													<motion.div
														layout
														key={currentTask.id}
														id={currentTask.id}
														className={`task-${budgeted_hours_height_category}-height task-${
															currentIndex + 1
														} task${
															currentIndex + 1
														}-step-forward-${prevCompletedTasksLength}`}
														whileHover={{
															zIndex: 1,
															scale: 1.1,
															transition: {
																duration: 0.1,
																delay: 0.6,
															},
														}}
													>
														<NewTaskCard
															containerClassName={`task-${budgeted_hours_height_category}-height`}
															dependecyOrder={dependecyOrder}
															taskHeight={
																budgeted_hours_height_category
															}
															title={currentTask.title}
															taskDetails={currentTask}
															budgeted_hours={
																currentTask.budgeted_hours
															}
															onTaskClick={() => {}}
															cardBorder={cardBorder}
															statusId={taskStatus?.id}
															statusLabel={taskStatus?.label}
															assignee_name={
																currentTask?.task_assignees[0]
																	?.user_profile?.full_name
															}
															assignee_id={
																currentTask?.task_assignees[0]
																	?.user_profile?.id
															}
															approvalFrom={
																currentTask?.approval_from
															}
															image={
																currentTask?.task_assignees[0]
																	?.user_profile?.image_url
															}
															taskId={currentTask?.id}
															workflow_name={
																currentTask?.client_workflow
																	?.display_name ||
																currentTask?.my_task
																	?.client_workflow?.display_name
															}
															due_date={currentTask?.due_date}
															color={
																currentTask?.client_workflow
																	?.color ||
																currentTask?.my_task?.workflow
																	?.color ||
																'#fb7a03'
															}
															notes={currentTask?.notes}
															onDragEnd={
																isAssigneeUpdate
																	? () => {}
																	: handleDragEnd(currentTask)
															}
															onDragStart={dragStartHandle}
															ifTaskIsDraggable={Boolean(
																!isAssigneeUpdate,
															)}
														/>
													</motion.div>
												);
											},
										)}
									</motion.div>
								);
							}
						})}
					</div>
				</div>
			)}
		</div>
	);
}

export default memo(ExpandableClientForReassignments);
