import React, { useState } from 'react';
import SectionBar from '../Others/SectionBar';
import SkeletonLoading from '../Others/SkeletonLoading';
import { executivePermissions } from '../../utilities/utilities';
import Restricted from '../Permissions/Restricted';
import useModal from '../Modal/useModal';
import ArchivedTaskStatusTable from './ArchivedTaskStatusTable';
import ActiveTaskStatusTable from './ActiveTaskStatusTable';
import { Modal, Tooltip } from '@mui/material';
import CreateStatusModal from './CreateStatusModal';
import { useSelector } from 'react-redux';

function CustomTaskStatus() {
	const [showArchivedTable, setShowArchivedTable] = useState(false);
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const taskStatuses = useSelector((state) => state.workSpaceReducer.customTaskStatus);
	const activeStatusCount = taskStatuses?.reduce(
		(acc, status) => acc + (!status?.deleted && status?.tenant_id !== null),
		0,
	);
    
	const openModal = (type) => {
		toggleModal();
		ModalType(type);
	};
	const toggleTable = () => {
		setShowArchivedTable(!showArchivedTable);
	};
	return (
		<div className='profile-section-body border d-flex flex-column' style={{ gap: '16px' }}>
			<SectionBar
				header={
					<SkeletonLoading variant='text' width={100} height={45}>
						<div className='d-flex align-items-center' style={{ gap: '12px' }}>
							<h2 className='HeadlineThreeBold text-left'>Task Status</h2>
						</div>
					</SkeletonLoading>
				}
				headerActions={
					<Restricted AllowedUsers={executivePermissions}>
						<div
							className='d-flex align-items-center justify-content-center'
							style={{ gap: '12px' }}
						>
							<span
								className='BodyTwoLight primary-text pointer mt-1'
								onClick={toggleTable}
							>
								{showArchivedTable ? 'Show Task Status' : 'Show Archive'}
							</span>
							<Tooltip
								title='You can create up to 10 custom task statuses'
								disableHoverListener={activeStatusCount < 10}
							>
								<button
									className='rounded-btn primary-btn'
									onClick={() => openModal('CREATE_TASK_STATUS')}
									disabled={activeStatusCount >= 10}
								>
									+ Create Status
								</button>
							</Tooltip>
						</div>
					</Restricted>
				}
			/>
			{showArchivedTable ? <ArchivedTaskStatusTable /> : <ActiveTaskStatusTable />}
			{modalIsOpen && (
				<>
					{ModalName == 'CREATE_TASK_STATUS' && (
						<Modal
							onClose={toggleModal}
							open={modalIsOpen}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<CreateStatusModal toggleModal={toggleModal} />
						</Modal>
					)}
				</>
			)}
		</div>
	);
}

export default CustomTaskStatus;
