/* eslint-disable no-mixed-spaces-and-tabs */
import { Avatar, Tooltip } from '@mui/material';
import { useRef } from 'react';
import TimeTracking from '../components/WorkSpace/TimeTracking';
import { TASK__DISPLAY_STATUSES } from '../utilities/utilities';
import { ReactComponent as Close } from '../assets/icons/Closewhite.svg';

function TaskDetailsBar({
	isTimeTracking,
	taskStatus,
	consumedTime,
	timeLoggedRef,
	taskTitle,
	assigneeName,
	assigneeProfileUrl,
	handleModalClose,
}) {
	const closeButtonRef = useRef(null);

	const handleCloseTask = (event) => {
		event.stopPropagation();
		handleModalClose();
	};

	return (
		<div className='d-flex justify-content-between align-items-center w-100'>
			<div className='d-flex align-items-center w-50'>
				<Tooltip title={taskTitle}>
					<span
						style={{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
						className='BodyOneBold text-white'
					>
						{taskTitle}
					</span>
				</Tooltip>
				<div
					style={{
						marginLeft: '8px',
						marginRight: '8px',
					}}
				>
					<Avatar
						data-toggle='tooltip'
						data-placement='top'
						title={assigneeName}
						sx={{
							bgcolor: `#FEF2E7`,
							color: `#333`,
							width: '18px',
							height: '18px',
						}}
						alt='User'
						src={assigneeProfileUrl}
						srcSet={null}
					/>
				</div>
			</div>
			<div
				className='d-flex align-items-center w-50'
				style={{
					gap: '8px',
				}}
			>
				{isTimeTracking ? (
					<TimeTracking consumedTime={consumedTime} timeLoggedRef={timeLoggedRef} />
				) : (
					<span
						className='BodyOneLight text-white w-100'
						style={{
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis',
						}}
					>
						{TASK__DISPLAY_STATUSES[taskStatus] || taskStatus}
					</span>
				)}
				<Close
					width='18px'
					height='18px'
					ref={closeButtonRef}
					className='pointer'
					onMouseDown={handleCloseTask}
				/>
			</div>
		</div>
	);
}

export default TaskDetailsBar;
