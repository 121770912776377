/* eslint-disable no-mixed-spaces-and-tabs */
import { useCallback, useState } from 'react';

// Material Ui Components
import { Box, LinearProgress } from '@mui/material';

// Styled Components
import { lightGreen } from '../Style';

// Import Components
import TableRowItem from '../Others/TableRowItem';
import TableRowButton from '../Others/TableRowButton';

// Import Table Spacing Styles
import { TableItemWidth4, TableItemWidth5 } from '../TableSpacing/TableSpacingStyles';
import LoadingOverLay from '../Others/LoadingOverLay';
import { useHistory, useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import {
	TASK_STATUSES,
	TASK_STATUSES_CATEGORY,
	TASK__DISPLAY_STATUSES,
	convertDecimalToTime,
	detailsModal,
} from '../../utilities/utilities';
import { ReactComponent as Up } from '../../assets/icons/up.svg';
import { ReactComponent as Down } from '../../assets/icons/down.svg';
import * as WorkSpaceActions from '../../redux/actions/dashboard/workspaces-actions';
import NewTaskCard from '../WorkSpace/NewTaskCard';
import SectionBar from '../Others/SectionBar';
import HeadingThree from '../TypoGraphy/HeadingThree';
import SkeletonLoading from '../Others/SkeletonLoading';
import { ReactComponent as ClientsIcon } from '../../assets/icons/Handshake.svg';
import { routes } from '../../utilities/routes';
import TeamClientsDate from './TeamClientsDate';

function TeamClients({ teamName }) {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();
	const loading = useSelector((state) => state.clientReducer.loading);
	const userClients = useSelector((state) => state.clientReducer.clientsForTeamProfile);
	const allTaskStatuses = useSelector((state) => state.workSpaceReducer.customTaskStatus);
	const allTaskStatusesMap = new Map(allTaskStatuses?.map((status) => [status.id, status]));

	const [clientRender, setClientRender] = useState(null);
	const [selectedWorkflow, setSelectedWorkflow] = useState({
		workflowId: null,
		clientId: null,
	});

	const handleNavigateClick = (clientId) => {
		const from = location.state || [];
		from.push({
			link: location.pathname,
			label: teamName,
		});
		if (from.length > 2) from.shift();
		history.push(`${routes['client-profile']}${clientId}`, from);
	};

	const handleTaskClick = useCallback(
		(task_id) => () => {
			dispatch(
				WorkSpaceActions.handleTaskClick({
					taskId: task_id,
					modalType: detailsModal.CLIENT,
				}),
			);
		},
		[],
	);

	const changeSelectedWorkflow = (workflowId, clientId) => {
		workflowId === selectedWorkflow.workflowId && clientId === selectedWorkflow.clientId
			? setSelectedWorkflow({
					workflowId: null,
					clientId: null,
			  })
			: setSelectedWorkflow({
					workflowId: workflowId,
					clientId: clientId,
			  });
	};

	return (
		<>
			<Box className='profile-section' style={{ gap: 12 }}>
				<SectionBar
					header={
						<SkeletonLoading loading={loading} variant='text' width={100} height={45}>
							<div className='d-flex align-items-center' style={{ gap: '12px' }}>
								<ClientsIcon />
								<h2 className='HeadlineThreeBold text-left'>Clients</h2>
							</div>
						</SkeletonLoading>
					}
				/>
				<div
					style={{
						backgroundColor: 'white',
						width: '100%',
						height: '46px',
						padding: '20px 18px',
						borderRadius: '4px 4px 4px 4px',
						display: 'flex',
						alignItems: 'center',
						gap: '1rem',
					}}
				>
					<div className='d-flex justify-content-between'>
						<SkeletonLoading loading={loading} variant='text' width={100} height={45}>
							<div
								className='d-flex align-items-center'
								style={{ gap: '2em', height: '26px' }}
							>
								<select
									style={{ height: '26px' }}
									className='team-client-select BodyOneLight m-0'
									onChange={(e) => {
										e.target.value == 0
											? setClientRender(null)
											: setClientRender(e.target.value);
									}}
								>
									<option value={0}>All Clients</option>
									{userClients?.map(({ company_name, dba, id }, i) => {
										const ClientId = id;
										return (
											<option key={id} value={ClientId}>
												{dba?.trim() == '' ? company_name : dba}
											</option>
										);
									})}
								</select>
							</div>
						</SkeletonLoading>
					</div>
					<TeamClientsDate />
				</div>

				<div className='profile-section-body border'>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'column',
							gap: '1.5em',
							position: 'relative',
						}}
					>
						{loading ? (
							<div
								style={{
									minHeight: '25vh',
									position: 'relative',
									width: '100%',
								}}
							>
								<LoadingOverLay active={loading} />
							</div>
						) : userClients.length < 1 ? (
							<div className='TableRow h-100 w-100 d-flex align-items-center justify-content-center'>
								<SkeletonLoading
									loading={loading}
									variant='text'
									width='100%'
									height={45}
								>
									<h4 className='BodyTwoLight placeholder-text pt-2'>
										This team has no clients
									</h4>
								</SkeletonLoading>
							</div>
						) : (
							<>
								<div className='d-flex flex-column' style={{ gap: '32px' }}>
									{userClients?.map(
										({ company_name, client_workflows, dba, id }) => {
											console.log(client_workflows, 'clientWorkflows');
											const clientId = id;
											return (
												(clientRender == clientId ||
													clientRender == null) && (
													<div
														key={clientId}
														id={company_name}
														className='team-clients'
													>
														<Box
															sx={{
																backgroundColor: 'white',
																width: '100%',
																height: '46px',
																padding: '20px 18px',
																borderRadius: '4px 4px 4px 4px',
																display: 'flex',
																alignItems: 'center',
															}}
														>
															<Box>
																<SkeletonLoading
																	loading={loading}
																	variant='text'
																	width={100}
																	height={45}
																>
																	<h2
																		className='HeadlineThreeBold pointer'
																		onClick={() =>
																			handleNavigateClick(
																				clientId,
																			)
																		}
																	>
																		{dba?.trim() == ''
																			? company_name
																			: dba}
																	</h2>
																</SkeletonLoading>
															</Box>
														</Box>
														<div className='team-client-workflows-container'>
															{client_workflows?.length == 0 && (
																<h4 className='BodyTwoLight placeholder-text pt-2'>
																	No activated workflows against
																	this client in the give date
																	range
																</h4>
															)}
															{client_workflows
																?.map((clientWorkflow) => {
																	return {
																		...clientWorkflow,
																		tasks: clientWorkflow?.tasks?.map(
																			(task) => {
																				return {
																					...task,
																					workflow_submission:
																						{
																							componentName:
																								clientWorkflow
																									?.workflow_submission
																									?.data
																									?.length >
																									0 &&
																								clientWorkflow
																									?.workflow_submission
																									?.data[0]
																									?.componentName,
																							displayName:
																								clientWorkflow
																									?.workflow_submission
																									?.data
																									?.length >
																									0 &&
																								clientWorkflow
																									?.workflow_submission
																									?.data[0]
																									?.displayName,
																						},
																					client_workflow_task:
																						{
																							client_workflow:
																								{
																									display_name:
																										clientWorkflow?.display_name,
																								},
																						},
																				};
																			},
																		),
																	};
																})
																?.map(
																	(
																		{
																			id,
																			display_name,
																			repeat_type,
																			tasks,
																			mytasks,
																			color,
																		},
																		i,
																	) => {
																		let status;
																		let consumed_time = 0;
																		let budgeted_hours = 0;

																		tasks = mytasks
																			? mytasks
																			: tasks?.length > 0
																			? tasks
																			: [];
																		tasks &&
																			tasks?.map((task) => {
																				consumed_time +=
																					task.task_timers
																						?.length > 0
																						? parseInt(
																								task?.task_timers.reduce(
																									(
																										sum,
																										taskTimer,
																									) =>
																										sum +
																										parseInt(
																											taskTimer.consumed_time,
																										),
																									0,
																								),
																						  )
																						: 0;
																				budgeted_hours +=
																					parseFloat(
																						task.budgeted_hours,
																					);
																			});
																		const completedTasks =
																			tasks?.filter(
																				(task) =>
																					task.task_status_id ==
																					TASK_STATUSES.COMPLETED,
																			);

																		const inProgressTasks =
																			tasks?.filter(
																				(task) =>
																					task.task_status_id ==
																					TASK_STATUSES.IN_PROGRESS,
																			);

																		const blockedTasks =
																			tasks?.filter(
																				(task) =>
																					task.task_status_id ==
																					TASK_STATUSES.BLOCKED,
																			);

																		const newTasks =
																			tasks?.filter(
																				(task) =>
																					task.task_status_id ==
																					TASK_STATUSES.NOT_STARTED,
																			);

																		if (
																			tasks &&
																			completedTasks.length +
																				inProgressTasks.length ==
																				tasks?.length
																		) {
																			status = 'On_track';
																		}
																		if (
																			tasks &&
																			completedTasks.length ==
																				tasks?.length
																		) {
																			status = 'Complete';
																		}
																		if (
																			newTasks &&
																			newTasks.length > 0
																		) {
																			status = 'Started';
																		}
																		if (
																			tasks?.find(
																				(task) =>
																					task.task_status_id ==
																					TASK_STATUSES.PAST_DUE,
																			)
																		) {
																			status =
																				'Behind_Schedule';
																		}
																		if (
																			tasks &&
																			tasks?.find(
																				(task) =>
																					task.task_status_id ==
																					TASK_STATUSES.BLOCKED,
																			)
																		) {
																			status = 'Stalled';
																		}

																		const task_Progress =
																			(tasks &&
																				Math.round(
																					(completedTasks.length *
																						100) /
																						tasks?.length,
																				)) ||
																			0;

																		budgeted_hours =
																			budgeted_hours * 3600; // conversion of hours to seconds
																		consumed_time =
																			consumed_time / 1000; // conversion of miliseconds to seconds
																		const hourly_Progress =
																			Math.round(
																				(consumed_time *
																					100) /
																					budgeted_hours,
																			) || 0;
																		return (
																			<div
																				key={id}
																				className='d-flex flex-column'
																				style={{
																					backgroundColor:
																						'white',
																					borderRadius:
																						'4px 4px 4px 4px',
																				}}
																			>
																				<SkeletonLoading
																					loading={
																						loading
																					}
																					variant='text'
																					width='100%'
																					height={45}
																				>
																					<div
																						className='new-workflow-table-row pointer'
																						onClick={() =>
																							changeSelectedWorkflow(
																								id,
																								clientId,
																							)
																						}
																					>
																						<TableRowItem
																							textClass='BodyTwoLight'
																							ClassName=' text-left'
																							label={
																								display_name
																							}
																							width={
																								TableItemWidth4
																							}
																						/>
																						<TableRowItem
																							input={
																								true
																							}
																							ClassName='text-left'
																							width={
																								TableItemWidth5
																							}
																						>
																							<div className='d-flex flex-row align-items-center'>
																								<div className='BodyTwoLight text-left'>
																									{/* {moment(start_date).format('YYYY-MM-DD')} <span className='m-0 BodyTwoLight'>&nbsp;-&nbsp;</span> 
																						{moment(end_date).format('YYYY-MM-DD')} */}
																									{/* {workflow_recurrences.length <= 0
																								? 'Not Started Yet'
																								: new Date(
																										inputDateFormate(
																											workflow_recurrences[0]
																												?.recur_date,
																										),
																								  ).toLocaleDateString(
																										'en-US',
																										{
																											month: 'short',
																											day: 'numeric',
																											year: 'numeric',
																										},
																								  )} */}
																									{
																										'  '
																									}
																									<span className='BodyThreeLight light-text'>
																										{repeat_type
																											? repeat_type
																											: '-'}
																									</span>
																								</div>
																							</div>
																						</TableRowItem>

																						{/* <TableRowButton width={TableItemWidth3}>
																			<Box
																				sx={{
																					display: 'flex',
																					flexDirection: 'column',
																					gap: '0.3rem',
																					alignItems: 'flex-start',
																				}}
																			>
																				<div>
																					<h4
																						className='ClientsDataLabel text-left d-flex align-items-center p-0 m-0'
																						style={{ gap: '5px' }}
																						onClick={() => {
																							changeSelectedWorkflow(
																								id,
																								clientId,
																								TASK_STATUSES.PAST_DUE,
																							);
																							toggleShowTask(
																								TASK_STATUSES.PAST_DUE,
																							);
																						}}
																					>
																						<div
																						style={{
																								backgroundColor:
																									tasksStatusColors.Behind_Schedule,
																								height: '8px',
																								width: '8px',
																								borderRadius: '50%',
																							}}
																						></div>
																						Past Due:{' '}
																						{
																							tasks?.filter(
																								(task) =>
																								task.status ===
																								TASK_STATUSES.PAST_DUE,
																							)?.length
																						}
																						</h4>
																				</div>
																				<div>
																					<h4
																						className='ClientsDataLabel text-left d-flex align-items-center p-0 m-0'
																						style={{ gap: '5px' }}
																						onClick={() => {
																							changeSelectedWorkflow(
																								id,
																								clientId,
																								TASK_STATUSES.BLOCKED,
																							);
																							toggleShowTask(
																								TASK_STATUSES.BLOCKED,
																							);
																						}}
																					>
																						<div
																							style={{
																								backgroundColor:
																									tasksStatusColors.Blocked,
																								height: '8px',
																								width: '8px',
																								borderRadius: '50%',
																							}}
																						></div>
																						Blocked:{' '}
																						{
																							tasks?.filter(
																								(task) =>
																									task.status ===
																									TASK_STATUSES.BLOCKED,
																							)?.length
																						}
																					</h4>
																				</div>
																				<div>
																					<h4
																						className='ClientsDataLabel text-left d-flex align-items-center p-0 m-0'
																						style={{ gap: '5px' }}
																						onClick={() => {
																							changeSelectedWorkflow(
																								id,
																								clientId,
																								TASK_STATUSES.IN_PROGRESS,
																							);
																							toggleShowTask(
																								TASK_STATUSES.IN_PROGRESS,
																							);
																						}}
																					>
																						<div
																							style={{
																								backgroundColor:
																									tasksStatusColors.In_Progress,
																								height: '8px',
																								width: '8px',
																								borderRadius: '50%',
																							}}
																						></div>
																						In Progress:{' '}
																						{
																							tasks?.filter(
																								(task) =>
																									task.status ===
																									TASK_STATUSES.IN_PROGRESS,
																							)?.length
																						}
																					</h4>
																				</div>
																				<div>
																					<h4
																					className='ClientsDataLabel text-left d-flex align-items-center p-0 m-0'
																						style={{ gap: '5px' }}
																						onClick={() => {
																							changeSelectedWorkflow(
																								id,
																								clientId,
																								TASK_STATUSES.COMPLETED,
																							);
																							toggleShowTask(
																								TASK_STATUSES.COMPLETED,
																							);
																						}}
																					>
																						<div
																							style={{
																								backgroundColor:
																									tasksStatusColors.Complete,
																								height: '8px',
																								width: '8px',
																								borderRadius: '50%',
																							}}
																						></div>
																						Complete:{' '}
																						{
																							tasks?.filter(
																								(task) =>
																									task.status ===
																									TASK_STATUSES.COMPLETED,
																							)?.length
																						}
																					</h4>
																				</div>
																			</Box>
																		</TableRowButton> */}

																						<TableRowButton
																							width={
																								TableItemWidth4
																							}
																						>
																							<Box
																								className='mt-2'
																								sx={{
																									width: '100%',
																									position:
																										'relative',
																								}}
																							>
																								<LinearProgress
																									variant='determinate'
																									value={
																										hourly_Progress >
																										100
																											? 100
																											: hourly_Progress
																									}
																									sx={{
																										bgcolor: `#FED0A5`,
																										height: '0.375rem',
																										borderRadius:
																											'4px',
																										'& .MuiLinearProgress-barColorPrimary':
																											{
																												backgroundColor:
																													hourly_Progress >
																													100
																														? '#F05544'
																														: lightGreen,
																											},
																									}}
																								/>
																								{/* <h6
																										style={{
																											position: 'absolute',
																											top: 0,
																											bottom: 0,
																											left: 5,
																											fontSize: '0.59rem',
																										}}
																									>
																										{hourly_Progress > 100
																											? '100%'
																											: hourly_Progress + '%'}
																									</h6> */}
																								<h4 className='BodyTwoLight light-text text-left mt-1'>
																									{budgeted_hours /
																										3600 <
																									consumed_time /
																										3600
																										? convertDecimalToTime(
																												budgeted_hours /
																													3600,
																										  ) +
																										  ' hours task limit exceeded'
																										: (isNaN(
																												Math.round(
																													consumed_time /
																														3600,
																												),
																										  )
																												? convertDecimalToTime(
																														0,
																												  )
																												: convertDecimalToTime(
																														consumed_time /
																															3600,
																												  )) +
																										  ' of ' +
																										  convertDecimalToTime(
																												budgeted_hours /
																													3600,
																										  ) +
																										  ' hours completed'}
																								</h4>
																							</Box>
																						</TableRowButton>

																						<TableRowButton
																							width={
																								TableItemWidth4
																							}
																						>
																							<Box
																								className='mt-2'
																								sx={{
																									width: '100%',
																									position:
																										'relative',
																								}}
																							>
																								<LinearProgress
																									color='primary'
																									variant='determinate'
																									value={
																										task_Progress
																									}
																									sx={{
																										bgcolor: `#FED0A5`,
																										height: '0.375rem',
																										borderRadius:
																											'4px',
																										'& .MuiLinearProgress-barColorPrimary':
																											{
																												backgroundColor:
																													'#FB7A03',
																											},
																									}}
																								/>
																								{/* <h6
																										style={{
																											position: 'absolute',
																											top: 0,
																											bottom: 0,
																											left: 5,
																											fontSize: '0.59rem',
																										}}
																									>
																										{task_Progress + '%'}
																									</h6> */}
																								<h4 className='BodyTwoLight light-text text-left mt-1'>
																									{completedTasks?.length +
																										' of ' +
																										tasks?.length +
																										' tasks completed'}
																								</h4>
																							</Box>
																						</TableRowButton>

																						{selectedWorkflow.workflowId ===
																							id &&
																						selectedWorkflow.clientId ===
																							clientId ? (
																							<Up
																								className='pointer'
																								// onClick={() =>
																								// 	changeSelectedWorkflow(
																								// 		id,
																								// 		clientId,
																								// 	)
																								// }
																							/>
																						) : (
																							<Down
																								className='pointer'
																								// onClick={() =>
																								// 	changeSelectedWorkflow(
																								// 		id,
																								// 		clientId,
																								// 	)
																								// }
																							/>
																						)}
																					</div>
																				</SkeletonLoading>

																				{selectedWorkflow.workflowId ===
																					id &&
																					selectedWorkflow.clientId ===
																						clientId && (
																						<div
																							className='d-flex flex-column'
																							style={{
																								gap: '20px',
																								padding:
																									'8px 16px',
																								borderRadius:
																									'0px 0px 4px 4px',
																							}}
																						>
																							{tasks.length ==
																								0 && (
																								<div className='TableRow h-100 w-100 d-flex align-items-center justify-content-center'>
																									<h4 className='BodyTwoLight placeholder-text pt-2'>
																										This
																										workflow
																										has
																										no
																										tasks
																									</h4>
																								</div>
																							)}
																							{allTaskStatuses.map(
																								(
																									status,
																								) => {
																									const mapTasks =
																										tasks?.filter(
																											(
																												task,
																											) =>
																												task?.task_status_id ==
																												status?.id,
																										);
																									if (
																										mapTasks?.length
																									)
																										return (
																											<div
																												className='d-flex flex-column'
																												style={{
																													gap: '8px',
																												}}
																												key={
																													status?.id
																												}
																											>
																												<div
																													className='w-100 text-left d-flex flex-row align-items-center'
																													style={{
																														gap: '12px',
																													}}
																												>
																													<HeadingThree>
																														{TASK__DISPLAY_STATUSES[
																															status
																																?.label
																														] ||
																															status?.label}
																													</HeadingThree>
																													<div className='light-text BodyTwoLight'>
																														{
																															tasks?.filter(
																																(
																																	task,
																																) =>
																																	task?.task_status_id ==
																																	status?.id,
																															)
																																?.length
																														}
																													</div>
																												</div>

																												<div
																													className='d-flex flex-row flex-wrap'
																													style={{
																														gap: '68px',
																													}}
																												>
																													{mapTasks?.map(
																														(
																															task,
																															i,
																														) => {
																															const taskStatus =
																																allTaskStatusesMap.get(
																																	task?.task_status_id,
																																);

																															const isBlockedCategoryStatus =
																																Boolean(
																																	taskStatus?.category ===
																																		TASK_STATUSES_CATEGORY.BLOCKED,
																																);
																															const cardBorder =
																																!isBlockedCategoryStatus
																																	? 'none'
																																	: taskStatus?.id ===
																																	  TASK_STATUSES.BLOCKED
																																	? '1px solid red'
																																	: `1px solid ${taskStatus?.color}`;
																															return (
																																<div
																																	key={
																																		i
																																	}
																																	style={{
																																		width: '250px',
																																	}}
																																>
																																	<NewTaskCard
																																		onDragEnd={() => {}}
																																		title={
																																			task.title
																																		}
																																		budgeted_hours={
																																			task.budgeted_hours
																																		}
																																		onTaskClick={
																																			handleTaskClick
																																		}
																																		statusId={
																																			taskStatus?.id
																																		}
																																		statusLabel={
																																			taskStatus?.label
																																		}
																																		cardBorder={
																																			cardBorder
																																		}
																																		assignee_name={
																																			task
																																				?.user_profile
																																				?.full_name
																																		}
																																		assignee_id={
																																			task
																																				?.user_profile
																																				?.id
																																		}
																																		taskDetails={
																																			task
																																		}
																																		showDependentIcons={
																																			true
																																		}
																																		approvalFrom={
																																			task?.approval_from
																																		}
																																		image={
																																			task
																																				?.user_profile
																																				?.image_url
																																		}
																																		taskId={
																																			task?.id
																																		}
																																		workflow_name={
																																			// task
																																			// 	?.client_workflow_task
																																			// 	?.client_workflow
																																			// 	?.workflow
																																			// 	?.name
																																			display_name
																																		}
																																		color={
																																			color
																																			// task
																																			// 	?.client_workflow_task
																																			// 	?.client_workflow
																																			// 	?.workflow
																																			// 	?.color
																																		}
																																		due_date={
																																			task?.due_date
																																		}
																																		notes={
																																			task?.notes
																																		}
																																	/>
																																</div>
																															);
																														},
																													)}
																												</div>
																											</div>
																										);
																								},
																							)}
																						</div>
																					)}
																			</div>
																		);
																	},
																)}
														</div>
													</div>
												)
											);
										},
									)}
								</div>
							</>
						)}
					</Box>
				</div>
			</Box>
		</>
	);
}

export default TeamClients;
