import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import DocumentFolder from '../sub_components/DocumentStorage/DocumentFolder';
import DocumentFile from '../sub_components/DocumentStorage/DocumentFile';
import DocumentHeader from '../sub_components/DocumentStorage/DocumentHeader';
import * as clientActions from '../../redux/actions/client-actions';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';
import { sortFolderData, updateParentFolderIds } from '../../utilities/utilities';
import useOutsideClick from '../../hooks/useOutsideClick';

const DocumentStorage = ({
	headers,
	tempFolderData,
	hideFile,
	disabled,
	handleFolderClick,
	showCheckBox,
	defaultFolderId,
	selectedItem,
	setSelectedItem,
	disableDnd,
	taskModal,
	clientWorkflow,
    folderRef,
    handleAlertModal
}) => {
	const bodyRef = useRef();

	const dispatch = useDispatch();
	const attachedIds = clientWorkflow?.attachment?.sections?.folder?.attached_ids;

	const folderDataTaskModal = useSelector((state) => state.clientReducer.folderData);

	const updatedFolderData = updateParentFolderIds(folderDataTaskModal || []);
    const sortedData = sortFolderData(updatedFolderData);

	let folderData = taskModal ? sortedData : tempFolderData;
    
    folderData = folderData?.sort((a, b) => {
        const dateA = a?.updated_at ? new Date(a.updated_at) : 0;
        const dateB = b?.updated_at ? new Date(b.updated_at) : 0;
      
        return dateB - dateA;
      });

	const [attachmentLoading, setAttachmentLoading] = useState({});
	const [dragOverFolder, setDragOverFolder] = useState();
	const [dragOverContainer, setDragOverContainer] = useState(null);
	const [isChecked, setIsChecked] = useState([]);
	const DocumentFolderRef = useRef();

	const handleDragEnter = useCallback(
		(e, item) => {
			e.preventDefault();
			e.stopPropagation();
			setDragOverFolder(item);
			if (item.id !== dragOverContainer) {
				setDragOverContainer(item.id);
			}
			DocumentFolderRef.current = false;
		},
		[dragOverContainer],
	);

	const handleDragLeave = (e) => {
		e.preventDefault();
		e.stopPropagation();
		DocumentFolderRef.current = true;
	};

	const handleFolderCheckboxes = (folder) => {
		const updatedChecked = new Set(isChecked);

		const checkParentFolders = (folderId) => {
			const parentFolder = folderData.find((item) => item.id === folderId);
			if (parentFolder && parentFolder.parent_folder_id !== null) {
				updatedChecked.add(parentFolder.id);
				if (parentFolder.parent_folder_id) {
					checkParentFolders(parentFolder.parent_folder_id);
				}
			}
		};

		const uncheckChildFolders = (folderId) => {
			const childFolders = folderData.filter((item) => item.parent_folder_id === folderId);
			childFolders.forEach((child) => {
				updatedChecked.delete(child.id);
				uncheckChildFolders(child.id);
			});
		};

		const checkChildFolders = (folderId) => {
			const childFolders = folderData.filter((item) => item.parent_folder_id === folderId);
			childFolders.forEach((child) => {
				updatedChecked.add(child.id);
				checkChildFolders(child.id);
			});
		};

		if (updatedChecked.has(folder.id)) {
			updatedChecked.delete(folder.id);
			uncheckChildFolders(folder.id);
		} else {
			updatedChecked.add(folder.id);
			checkChildFolders(folder.id);
			if (folder.parent_folder_id !== null) {
				checkParentFolders(folder.parent_folder_id);
			}
		}

		setIsChecked(Array.from(updatedChecked));

		const tempObj = clientWorkflow?.attachment;
		const updatedAttachment = {
			...tempObj,
			sections: {
				...tempObj.sections,
				folder: {
					...tempObj.sections.folder,
					attached_ids: Array.from(updatedChecked),
				},
			},
		};
		setAttachmentLoading((prevStates) => ({ ...prevStates, [folder.id]: true }));
		dispatch(
			clientActions.handleWorkflowAttachments(
				clientWorkflow?.attachment?.id,
				updatedAttachment,
			),
		)
			.then(() => {
				setAttachmentLoading((prevStates) => ({ ...prevStates, [folder.id]: false }));
				dispatch(setSnackBarIsOpen(true, true, 'Workflow updated successfully'));
			})
			.catch(() => {
				setAttachmentLoading((prevStates) => ({ ...prevStates, [folder.id]: false }));
				dispatch(setSnackBarIsOpen(false, true, 'Workflow failed to update'));
			});
	};

	const isDescendant = (parentId, itemId) => {
		if (folderData?.length) {
			let currentItem = folderData?.find((item) => item.id === itemId);
			while (currentItem) {
				if (currentItem.id === parentId) {
					return true;
				}
				currentItem = folderData?.find((item) => item.id === currentItem.parent_folder_id);
			}
			return false;
		}
	};

	const handleDrop = (event, targetFolderId) => {
		event.preventDefault();
		event.stopPropagation();

		if (
			isDescendant(targetFolderId, dragOverFolder?.id) ||
			targetFolderId === dragOverFolder?.id ||
			dragOverFolder?.kind === 'File'
		) {
			return;
		}
		dispatch(
			clientActions.updateDocument(targetFolderId, { parent_folder_id: dragOverFolder?.id }),
		)
			.then(() => {
				dispatch(
					clientActions.updateFolderReducer(
						folderData?.length &&
							folderData?.map((item) =>
								item.id === targetFolderId
									? {
											...item,
											parent_folder_id: dragOverFolder?.id,
											expanded: true,
									}
									: item,
							),
						targetFolderId,
						dragOverFolder,
					),
				);
			})
			.catch((error) => {
				if (error.message == 'Name already exist') {
                    handleAlertModal && handleAlertModal();                        
				} else {
					dispatch(setSnackBarIsOpen(false, true, 'Update document failed'));
				}
			});
		setSelectedItem(null);
		setDragOverContainer(null);
	};

	useEffect(() => {
		setSelectedItem(null);
		const updatedFolderData = folderData
			?.filter((item) => item.parent_folder_id === null)
			.map((folder) => (folder?.kind !== 'File' ? { ...folder, expanded: false } : folder));
		dispatch(clientActions.updateFolderData(updatedFolderData));
	}, [clientWorkflow?.id]);

    useMemo(() => {
		if (attachedIds?.length) {
			setIsChecked(attachedIds);
		} else {
			setIsChecked([]);
		}
	}, [attachedIds]);

	useOutsideClick(folderRef, () => setSelectedItem(null));


	const renderFolders = (folderList, parentFolderId = null, depth = 0, disabled) => {
		return folderList?.length ? (
			folderList
				?.filter((folder) => folder?.parent_folder_id === parentFolderId)
				?.map((item) => (
					<div key={item.id} style={{ width: '100%' }}>
						{item?.kind !== 'File' ? (
							<DocumentFolder
								handleDragLeave={disabled ? null : handleDragLeave}
								dragOverContainer={disabled ? null : dragOverContainer}
								item={item}
								disabled={disabled}
								defaultChecked={defaultFolderId == item.id ? true : false}
								onFolderClick={(e) => handleFolderClick(e, item.id)}
								onSelectFolder={
									disabled
										? null
										: () => {
												setSelectedItem(item.id);
										}
								}
								onRightClick={(e) => {
									e.preventDefault();
								}}
								expanded={item.expanded}
								isSelected={selectedItem === item.id}
								depth={depth}
								onDragEnd={disabled ? null : (e) => handleDrop(e, item.id)}
								onDragEnter={disabled ? null : (e) => handleDragEnter(e, item)}
								headers={headers}
								showCheckBox={(showCheckBox && (folderData && folderData?.find((f) => f.id === parentFolderId)?.parent_folder_id === null))}
								disableDelete={parentFolderId === null ? true : false}
								handleFolderCheckboxes={handleFolderCheckboxes}
								isChecked={isChecked}
								attachmentLoading={attachmentLoading[item.id]}
								disableDnd={disableDnd}
                                folderRef={folderRef}
							>
								{item?.expanded
									? renderFolders(folderList, item.id, depth + 1, disabled)
									: null}
							</DocumentFolder>
						) : (
							!hideFile && (
								<DocumentFile
									item={item}
									depth={depth}
									onDragEnd={(e) => handleDrop(e, item.id)}
									onDragEnter={(e) => handleDragEnter(e, item)}
									onSelectFolder={() => setSelectedItem(item.id)}
									isSelected={selectedItem === item.id}
									onDragLeave={(e) => handleDragLeave(e, item.id)}
									headers={headers}
								/>
							)
						)}
					</div>
				))
		) : (
			<div
				style={{ backgroundColor: '#F7F9FA', height: '40px' }}
				className='d-flex align-items-center justify-content-center w-100'
			>
				<span className='BodyTwoLight light-text'>There is currently no folder & file</span>
			</div>
		);
	};
	return (
		<>
			<div className='document-storage-container'>
				<DocumentHeader headers={headers} />
				<div ref={bodyRef} className='w-100' style={{ opacity: disabled ? 0.5 : 1 }}>
					{renderFolders(folderData, null, 0, disabled)}
				</div>
			</div>
		</>
	);
};

export default DocumentStorage;
