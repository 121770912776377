/* eslint-disable no-mixed-spaces-and-tabs */
import { memo, useEffect, useMemo, useState } from 'react';
import { ReactComponent as Close } from '../assets/icons/Close.svg';
import { ReactComponent as Calendar } from '../assets/icons/Calendarcolor.svg';
import { ReactComponent as InfoIcon } from '../assets/icons/Infogrey.svg';
import { useDispatch, useSelector } from 'react-redux';
import * as userActions from '../redux/actions/user-management.actions';
import * as workspaceActions from '../redux/actions/dashboard/workspaces-actions';
import { KeyboardDatePicker } from '@material-ui/pickers';
import * as ActionCenterActions from '../redux/actions/action-center';
import moment from 'moment';
import {
	DATE_RANGE_FILTER,
	Member,
	TASK_STATUSES,
	executivePermissions,
} from '../utilities/utilities';
import { CircularProgress, Modal, Tooltip } from '@mui/material';
import NewSearchDropdown from '../components/Inputs/NewSearchDropdown';
import useModal from '../components/Modal/useModal';
import AddTeamMember from '../components/TeamManagement/Modal/AddTeamMember';
import ExpandableClientForReassignments from '../components/WorkSpace/ExpandableClientForReassignments';
import DropDown from '../components/Inputs/DropDown';

function ReassignTasks() {
	const dispatch = useDispatch();
	const today = moment();

	const dateRangeOptions = [
		{ label: 'is on', value: DATE_RANGE_FILTER.IS_ON },
		{ label: 'is between', value: DATE_RANGE_FILTER.IS_BETWEEN },
		{ label: 'is after', value: DATE_RANGE_FILTER.IS_AFTER },
	];
	const [dateRangeFilter, setDateRangeFilter] = useState(DATE_RANGE_FILTER.IS_ON);
	const [date, setDate] = useState({
		start_date: today,
		end_date: today,
	});
	const [selectedUser, setSelectedUser] = useState(null);
	const [loading, setLoading] = useState(false);
	const [teamForUser, setTeamForUser] = useState(null);
	const loggedInUser = useSelector((state) => state.authReducer.user);
	const [userProfiles, setUserProfiles] = useState([]);
	const [addTeamMemberLoading, setAddTeamMemberLoading] = useState(false);
	const [clientToUpdate, setClientToUpdate] = useState(null);

	const bulkClientTasks = useSelector((state) => state.workSpaceReducer.bulkClientTasks)?.filter(
		(client) => client?.tasks?.length > 0,
	);
	const allTaskStatuses = useSelector((state) => state.workSpaceReducer.customTaskStatus);

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const filteredUserProfiles = useMemo(() => {
		if (loggedInUser?.user_roles?.some((item) => item.role_id === Member.teamManagerId)) {
			const teamIds = loggedInUser.user_roles
				.filter((item) => item.role_id === Member.teamManagerId)
				.flatMap((item) => item?.role_scopes ?? [])
				.map((item) => item.team_id);

			return userProfiles?.filter(
				(user) =>
					user?.user_teams?.some((team) => teamIds.includes(team.team_id)) &&
					!executivePermissions.includes(user.role_id),
			);
		}
		return userProfiles;
	}, [loggedInUser, userProfiles]);

	const handleDateOption = (event) => {
		setDateRangeFilter(event.target.value);
	};

	const handleDateChange = (name) => (value) => {
		setDate({
			...date,
			[name]: value,
		});
	};

	const handleUserChange = (assignee) => {
		const { value } = assignee;
		if (value == '') {
			setSelectedUser(null);
			return;
		}
		setSelectedUser(value);
	};

	const handleUpdateAssignee = (date) => (assignee, client_id) => {
		const payload = {
			existing_task_assignee: selectedUser,
			new_task_assignee: assignee,
			date_comparison: dateRangeFilter,
			date: moment(date).format('YYYY-MM-DD'),
			client_id: client_id,
		};
		setClientToUpdate(client_id);
		dispatch(workspaceActions.updateBulkTasksAssignee(payload))
			.then(() => {
				getClientTasks();
				dispatch(workspaceActions.triggerGetTasks());
				setClientToUpdate(null);
			})
			.catch((error) => {
				dispatch(workspaceActions.setSnackBarIsOpen(false, true, error.message));
				setClientToUpdate(null);
			});
	};

	const handleClosePanle = () => {
		dispatch(ActionCenterActions.setActionSideBarComponent(null));

		setDateRangeFilter(DATE_RANGE_FILTER.IS_ON);
		setSelectedUser(null);
		setDate({
			start_date: today,
			end_date: today,
		});
	};

	const getDatesInRange = (start, end) => {
		const startDate = moment(start).startOf('day');
		const endDate = moment(end).startOf('day');

		const diffDays = endDate.diff(startDate, 'day');

		const dates = Array.from({ length: diffDays + 1 }, (_, index) =>
			startDate.clone().add(index, 'days').format('YYYY-MM-DD'),
		);

		return dates;
	};

	const dateRange = useMemo(() => {
		return dateRangeFilter == DATE_RANGE_FILTER.IS_BETWEEN
			? getDatesInRange(date.start_date, date.end_date)
			: [];
	}, [date.start_date, date.end_date, dateRangeFilter]);

	const getTeamMembers = (allUsers, teamId) => {
		return allUsers
			?.map((user) => {
				if (
					user?.role_id === Member.ownerId ||
					(user?.user_id != selectedUser &&
						user?.user_teams?.filter((team) => team?.team_id === teamId)?.length)
				)
					return user;
			})
			?.filter((user) => user !== undefined);
	};

	const getNewTeamMembers = (allUsers, teamId) => {
		return allUsers
			?.filter(
				(user) =>
					user?.user_id != selectedUser &&
					!getTeamMembers(allUsers, teamId)?.some(
						(member) => member?.user_id == user?.user_id,
					),
			)
			?.map((user) => {
				return {
					label: user?.user_name,
					value: user?.user_id,
					role_id: user?.role_id,
					email: user?.email,
					user_roles: user?.user_roles,
				};
			});
	};

	const addNewUser = (team) => () => {
		setTeamForUser(parseInt(team));
		ModalType('ADD_USER_IN_TEAM');
		toggleModal();
	};

	const addTeamMember = (assignee) => () => {
		setAddTeamMemberLoading(true);
		const payload = {
			user_id: assignee?.value,
			team_id: parseInt(teamForUser),
			role_id: assignee?.role_id,
		};
		dispatch(userActions.addUserToTeam(payload))
			.then(() => {
				dispatch(userActions.getTeamUsers())
					.then((response) => {
						setUserProfiles(setTeamUsers(response));
					})
					.catch((error) => {
						dispatch(workspaceActions.setSnackBarIsOpen(false, true, error.message));
					});
				toggleModal();
				setAddTeamMemberLoading(false);
			})
			.catch((error) => {
				setAddTeamMemberLoading(false);
				dispatch(workspaceActions.setSnackBarIsOpen(false, true, error.message));
			});
	};

	const setTeamUsers = (response) => {
		return response.users
			.map((user) => {
				const user_roles = response?.user_roles
					?.filter((item) => {
						if (item.user_id == user.id) {
							return item;
						}
					})
					?.reduce(
						(acc, role) => {
							acc.user_id = role.user_id;
							acc.scopes.push(
								...(role.role_scopes?.map((scope) => ({
									role_id: role?.role_id,
									team_id: scope?.team_id,
								})) || []),
							);
							return acc;
						},
						{ user_id: null, scopes: [] },
					);
				return {
					user_teams: user.user_teams,
					user_name: user?.full_name,
					email: user?.email,
					team_id: user?.user_teams[0]?.team?.id,
					user_id: user.id,
					is_active: user.is_active,
					role_id: response.user_roles.find(
						(role) => role.user_id === user.id && !role.deleted,
					)?.role_id,
					user_roles: user_roles?.scopes,
				};
			})
			?.filter((user) => user?.is_active);
	};

	const taskStatusQuery = allTaskStatuses
		?.map((status) => status?.id)
		?.filter((status) => status !== TASK_STATUSES.COMPLETED);

	const getClientTasks = () => {
		setLoading(true);
		dispatch(
			workspaceActions.getClientTasksForBulkActions(
				{
					users: [Number(selectedUser)],
					taskStatuses: taskStatusQuery,
				},
				moment(date.start_date).format('YYYY-MM-DD'),
				dateRangeFilter === DATE_RANGE_FILTER.IS_ON ||
					dateRangeFilter === DATE_RANGE_FILTER.IS_AFTER
					? moment(date.start_date).format('YYYY-MM-DD')
					: moment(date.end_date).format('YYYY-MM-DD'),
				dateRangeFilter,
			),
		)
			.then(() => setLoading(false))
			.catch((error) => {
				dispatch(workspaceActions.setSnackBarIsOpen(false, true, error.message));
				setLoading(false);
			});
	};

	useEffect(() => {
		dispatch(userActions.getTeamUsers())
			.then((response) => {
				setUserProfiles(setTeamUsers(response));
			})
			.catch((error) => {
				dispatch(workspaceActions.setSnackBarIsOpen(false, true, error.message));
			});
		dispatch(userActions.getRoles());
	}, []);

	useEffect(() => {
		if (selectedUser && dateRangeFilter) {
			getClientTasks();
		}
	}, [selectedUser, dateRangeFilter, date.start_date, date.end_date]);

	return (
		<>
			<div className='reassign-tasks-container'>
				<div>
					<div className='d-flex justify-content-between align-items-center mb-3'>
						<h2 className='HeadlineTwoBold text-left'>Reassign Tasks</h2>
						<span onClick={handleClosePanle} className='pointer'>
							<Close />
						</span>
					</div>
					<div className='improved-input-designs pb-3'>
						<label className='text-left'>{`Which user's tasks you want to reassign?`}</label>
						<NewSearchDropdown
							selectClassName={'w-100'}
							width={'300px'}
							value={selectedUser}
							placeholder='Please Select'
							disableLetters
							changeHandler={handleUserChange}
							options={
								filteredUserProfiles?.length > 0
									? filteredUserProfiles?.map((assignee) => {
											return {
												value: assignee?.user_id,
												label: assignee?.user_name,
											};
									  })
									: []
							}
							name={'assignee'}
							required={true}
							label={'Assignee'}
						/>
					</div>
					<div className='improved-input-designs pb-3'>
						<label className='text-left'>{`What are the date(s) of the tasks you want to reassign?`}</label>
						<DropDown
							onChange={handleDateOption}
							normalDropDown
							style={{ height: '32px', textAlign: 'left' }}
							options={dateRangeOptions}
							value={dateRangeFilter}
							disableNull
						/>
					</div>
					{dateRangeFilter !== '' &&
						(dateRangeFilter == DATE_RANGE_FILTER.IS_BETWEEN ? (
							<div className='d-flex flex-column' style={{ gap: '16px' }}>
								<div
									className='d-flex justi-content-between align-items-center'
									style={{ gap: '16px' }}
								>
									<label className='BodyTwoLight light-text mb-0'>From</label>
									<KeyboardDatePicker
										autoOk
										disableToolbar
										name='start_date'
										value={date.start_date || today}
										onChange={handleDateChange('start_date')}
										keyboardIcon={<Calendar />}
										InputProps={{
											style: {
												fontSize: 14,
												height: 32,
												fontFamily: 'CentraLight',
												fontWeight: 'normal',
												background: 'white',
											},
										}}
										style={{ width: '100%', textAlign: 'right' }}
										format={'MM/DD/YYYY'}
										className='BodyTwoLight'
									/>
								</div>
								<div
									className='d-flex justi-content-between align-items-center'
									style={{ gap: '16px' }}
								>
									<label className='BodyTwoLight light-text mb-0'>To</label>
									<KeyboardDatePicker
										autoOk
										disableToolbar
										name='end_date'
										value={
											date.end_date || moment(date.start_date).add(1, 'days')
										}
										onChange={handleDateChange('end_date')}
										keyboardIcon={<Calendar />}
										InputProps={{
											style: {
												fontSize: 14,
												height: 32,
												fontFamily: 'CentraLight',
												fontWeight: 'normal',
												background: 'white',
											},
										}}
										style={{ width: '100%' }}
										format={'MM/DD/YYYY'}
										className='BodyTwoLight'
										minDate={date.start_date}
									/>
								</div>
							</div>
						) : (
							<KeyboardDatePicker
								autoOk
								disableToolbar
								name='start_date'
								value={date.start_date || today}
								onChange={handleDateChange('start_date')}
								keyboardIcon={<Calendar />}
								InputProps={{
									style: {
										fontSize: 14,
										height: 32,
										fontFamily: 'CentraLight',
										fontWeight: 'normal',
										background: 'white',
									},
								}}
								style={{ width: '100%' }}
								format={'MM/DD/YYYY'}
								className='BodyTwoLight'
							/>
						))}
					{selectedUser !== null &&
						(dateRangeFilter !== DATE_RANGE_FILTER.IS_AFTER ? (
							<div className='pt-3 text-left'>
								<span className='BodyTwoLight'>{`${
									userProfiles?.find((user) => user?.user_id == selectedUser)
										?.user_name
								} has tasks assigned to the following clients `}</span>
								<Tooltip
									title='Completed tasks won’t be reassigned'
									componentsProps={{
										tooltip: {
											sx: {
												backgroundColor: '#FED0A5',
												color: '#333333',
												fontSize: 14,
												maxWidth: '400px',
											},
										},
									}}
								>
									<InfoIcon />
								</Tooltip>
							</div>
						) : (
							<div className='d-flex align-items-center pt-3'>
								<span className='BodyTwoLight text-left mt-1 mr-1'>
									Please select new assignee for each client
								</span>
								<Tooltip
									title='Completed tasks won’t be reassigned'
									componentsProps={{
										tooltip: {
											sx: {
												backgroundColor: '#FED0A5',
												color: '#333333',
												fontSize: 14,
												maxWidth: '400px',
											},
										},
									}}
								>
									<InfoIcon />
								</Tooltip>
							</div>
						))}
				</div>
				{selectedUser !== null ? (
					<div
						style={{
							height: '100%',
							marginTop: '16px',
							maxHeight: '100%',
							overflowY: 'auto',
							overflowX: 'hidden',
						}}
					>
						{loading ? (
							<div
								style={{
									height: '100%',
									width: '100%',
								}}
								className='d-flex justify-content-center align-items-center'
							>
								<CircularProgress
									style={{
										color: '#fa7b03',
										height: '50px',
										width: '50px',
									}}
								/>
							</div>
						) : (
							<>
								{dateRangeFilter == DATE_RANGE_FILTER.IS_AFTER && (
									<div className='d-flex flex-column'>
										<span className='BodyOneBold text-left pb-3'>Clients</span>
										{bulkClientTasks?.length > 0 ? (
											bulkClientTasks
												?.map((client) => {
													return {
														...client,
														displayName:
															client?.client_dba?.trim() == ''
																? client?.client_name
																: client?.client_dba,
													};
												})
												?.sort((a, b) => {
													if (a.displayName && b.displayName) {
														return a.displayName.localeCompare(
															b.displayName,
														);
													}
												})
												?.map?.((client) => {
													const teamUsers = getTeamMembers(
														filteredUserProfiles,
														client?.team_id,
													);
													return (
														<ClientsForBulkReassignments
															key={client?.client_id}
															client={client}
															selectedUser={selectedUser}
															teamUsers={teamUsers}
															handleUpdateAssignee={handleUpdateAssignee(
																date.start_date,
															)}
															addNewUser={addNewUser}
															disableCustomButton={
																getNewTeamMembers(
																	filteredUserProfiles,
																	client?.team_id,
																)?.length < 1
																	? true
																	: false
															}
															clientToUpdate={clientToUpdate}
														/>
													);
												})
										) : (
											<span className='BodyTwoLight'>No Clients</span>
										)}
									</div>
								)}
								{dateRangeFilter == DATE_RANGE_FILTER.IS_ON && (
									<div className='d-flex flex-column' style={{ gap: '12px' }}>
										<span className='BodyOneBold text-left'>
											{`${moment(date.start_date).format('dddd')} - ${moment(
												date.start_date,
											).format('MMM DD, yyyy')}`}
										</span>
										{bulkClientTasks?.length > 0 ? (
											bulkClientTasks
												?.map((client) => {
													return {
														...client,
														displayName:
															client?.client_dba?.trim() == ''
																? client?.client_name
																: client?.client_dba,
													};
												})
												?.sort((a, b) => {
													if (a.displayName && b.displayName) {
														return a.displayName.localeCompare(
															b.displayName,
														);
													}
												})
												?.map((client, i) => {
													const teamUsers = getTeamMembers(
														filteredUserProfiles,
														client?.team_id,
													);
													return (
														<ExpandableClientForReassignments
															key={i}
															client={client}
															i={i}
															tasks={client?.tasks?.filter((t) =>
																t.extended_date === null
																	? moment(t.start_date).format(
																			'MM-DD-YYYY',
																	  ) ===
																	  moment(
																			date.start_date,
																	  ).format('MM-DD-YYYY')
																	: moment(
																			t.extended_date,
																	  ).format('MM-DD-YYYY') ===
																	  moment(
																			date.start_date,
																	  ).format('MM-DD-YYYY'),
															)}
															userProfiles={teamUsers}
															selectedUser={selectedUser}
															handleUpdateAssignee={handleUpdateAssignee(
																date.start_date,
															)}
															addNewUser={addNewUser}
															disableCustomButton={
																getNewTeamMembers(
																	filteredUserProfiles,
																	client?.team_id,
																)?.length < 1
																	? true
																	: false
															}
															clientToUpdate={clientToUpdate}
															isAssigneeUpdate
														/>
													);
												})
										) : (
											<span className='BodyTwoLight text-left'>
												{
													userProfiles?.find(
														(user) => user?.user_id == selectedUser,
													)?.user_name
												}{' '}
												has no tasks on this date
											</span>
										)}
									</div>
								)}
								{dateRangeFilter == DATE_RANGE_FILTER.IS_BETWEEN && (
									<div className='d-flex flex-column' style={{ gap: '12px' }}>
										{dateRange?.map((date) => {
											const clientTasks = bulkClientTasks?.length
												? bulkClientTasks
														?.map((client) => {
															if (
																client?.tasks?.filter((t) =>
																	t.extended_date === null
																		? moment(
																				t.start_date,
																		  ).format('MM-DD-YYYY') ===
																		  moment(date).format(
																				'MM-DD-YYYY',
																		  )
																		: moment(
																				t.extended_date,
																		  ).format('MM-DD-YYYY') ===
																		  moment(date).format(
																				'MM-DD-YYYY',
																		  ),
																)?.length
															)
																return client;
															else return null;
														})
														?.filter((client) => client !== null)
												: [];
											return (
												<div
													className='d-flex flex-column text-left'
													key={date}
													style={{ gap: '12px' }}
												>
													<span className='BodyOneBold text-left'>
														{`${moment(date).format('dddd')} - ${moment(
															date,
														).format('MMM DD, yyyy')}`}
													</span>

													{clientTasks?.length > 0 ? (
														clientTasks
															?.map((client) => {
																return {
																	...client,
																	displayName:
																		client?.client_dba?.trim() ==
																		''
																			? client?.client_name
																			: client?.client_dba,
																};
															})
															?.sort((a, b) => {
																if (
																	a.displayName &&
																	b.displayName
																) {
																	return a.displayName.localeCompare(
																		b.displayName,
																	);
																}
															})
															?.map((client, i) => {
																if (
																	client?.tasks?.filter((t) =>
																		t.extended_date === null
																			? moment(
																					t.start_date,
																			  ).format(
																					'MM-DD-YYYY',
																			  ) ===
																			  moment(date).format(
																					'MM-DD-YYYY',
																			  )
																			: moment(
																					t.extended_date,
																			  ).format(
																					'MM-DD-YYYY',
																			  ) ===
																			  moment(date).format(
																					'MM-DD-YYYY',
																			  ),
																	)?.length
																) {
																	const teamUsers =
																		getTeamMembers(
																			filteredUserProfiles,
																			client?.team_id,
																		);
																	return (
																		<ExpandableClientForReassignments
																			key={i}
																			client={client}
																			i={i}
																			tasks={client?.tasks?.filter(
																				(t) =>
																					t.extended_date ===
																					null
																						? moment(
																								t.start_date,
																						  ).format(
																								'MM-DD-YYYY',
																						  ) ===
																						  moment(
																								date,
																						  ).format(
																								'MM-DD-YYYY',
																						  )
																						: moment(
																								t.extended_date,
																						  ).format(
																								'MM-DD-YYYY',
																						  ) ===
																						  moment(
																								date,
																						  ).format(
																								'MM-DD-YYYY',
																						  ),
																			)}
																			userProfiles={teamUsers}
																			selectedUser={
																				selectedUser
																			}
																			handleUpdateAssignee={handleUpdateAssignee(
																				date,
																			)}
																			addNewUser={addNewUser}
																			disableCustomButton={
																				getNewTeamMembers(
																					filteredUserProfiles,
																					client?.team_id,
																				)?.length < 1
																					? true
																					: false
																			}
																			clientToUpdate={
																				clientToUpdate
																			}
																			isAssigneeUpdate
																		/>
																	);
																}
															})
													) : (
														<span className='BodyThreeLight'>
															{
																userProfiles?.find(
																	(user) =>
																		user?.user_id ==
																		selectedUser,
																)?.user_name
															}{' '}
															has no tasks on this date
														</span>
													)}
												</div>
											);
										})}
									</div>
								)}
							</>
						)}
					</div>
				) : (
					<span className='BodyTwoLight pt-3'>Please Select an assignee</span>
				)}
			</div>

			{ModalName === 'ADD_USER_IN_TEAM' && (
				<Modal
					onClose={toggleModal}
					open={modalIsOpen}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
					}}
				>
					<AddTeamMember
						newTeamMebers={getNewTeamMembers(filteredUserProfiles, teamForUser)}
						onCancel={toggleModal}
						onSubmit={addTeamMember}
						loading={addTeamMemberLoading}
					/>
				</Modal>
			)}
		</>
	);
}

export default memo(ReassignTasks);

const ClientsForBulkReassignments = ({
	client,
	teamUsers,
	handleUpdateAssignee,
	addNewUser,
	disableCustomButton,
	clientToUpdate,
}) => {
	const [assignee, setAssignee] = useState(null);
	const handleAssignee = (assignee) => {
		const { value } = assignee;
		if (value == '') {
			setAssignee(null);
			return;
		}
		setAssignee(Number(value));
		handleUpdateAssignee(Number(value), client?.client_id);
	};
	return (
		<div className='improved-input-designs pb-3' key={client?.client_id}>
			<label className='text-left'>
				{client?.client_dba?.trim() == '' ? client?.client_name : client?.client_dba}
			</label>
			{clientToUpdate === client?.client_id ? (
				<div className='d-flex justify-content-center'>
					<CircularProgress
						style={{
							color: '#fa7b03',
							height: '18px',
							width: '18px',
						}}
					/>
				</div>
			) : (
				<NewSearchDropdown
					selectClassName={'w-100'}
					width={'300px'}
					value={assignee}
					placeholder='Select new assignee'
					disableLetters
					changeHandler={handleAssignee}
					options={
						teamUsers?.length > 0
							? teamUsers?.map((assignee) => {
									return {
										value: assignee?.user_id,
										label: assignee?.user_name,
									};
							  })
							: []
					}
					name={'assignee'}
					required={true}
					disableNull={true}
					label={'Assignee'}
					customButton={{
						label: 'Add New User',
						onClick: addNewUser(client?.team_id),
						isDisable: disableCustomButton,
					}}
				/>
			)}
		</div>
	);
};
