import { useCallback, useEffect, useRef } from 'react';
import { ReactComponent as LightBulb } from '../assets/icons/LightbulbFilament.svg';
import moment from 'moment';
import NewTaskCard from '../components/WorkSpace/NewTaskCard';
import * as workspaceActions from '../redux/actions/dashboard/workspaces-actions';
import { useDispatch } from 'react-redux';
import { detailsModal } from '../utilities/utilities';

function ChatBotMessages({ messages, responseLoading, handleQuestionClick }) {
	const dispatch = useDispatch();
	const mresponseIntent = {
		RAW_QUERY: 'raw_query',
		AGGREGATE: 'aggregate',
	};

	const messagesEndRef = useRef(null);

	const handleOnClick = useCallback(
		(task_id) => () => {
			dispatch(
				workspaceActions.handleTaskClick({
					taskId: task_id,
					modalType: detailsModal.CLIENT,
				}),
			);
		},
		[dispatch],
	);

	useEffect(() => {
		messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
	}, [messages]);
	return (
		<div className='bot-body d-flex flex-column align-items-start flex-column-reverse'>
			<div ref={messagesEndRef} />
			{responseLoading && (
				<div className='message-text'>
					<div className='typing-dots'></div>
				</div>
			)}

			{messages?.length > 0 ? (
				<div className='message-container w-100'>
					{messages.map((message, index) => {
						return (
							<div
								key={index}
								className={`d-flex flex-column ${
									message?.type === 'bot' ? 'text-left' : 'align-items-end'
								}`}
								style={{ gap: '5px' }}
							>
								<span className='BodyTwoLight light-text message-text'>
									{message.message}
								</span>
								<span className='BodyThreeLight placeholder-text'>
									{moment(message.createdAt).format('hh:mm A')}
								</span>
								{message.intent === mresponseIntent.RAW_QUERY &&
									message.data?.length > 0 && (
										<div
											className='d-flex w-100 flex-wrap'
											style={{ gap: '20px' }}
										>
											{message?.data
												?.flatMap((client) => client?.tasks)
												?.map((task, index) => {
													console.log(task, 'taskHerer');
													return (
														<div key={index}>
															<NewTaskCard
																// taskHeight={
																// 	budgeted_hours_height_category
																// }
																onTaskClick={handleOnClick}
																title={task.title}
																taskDetails={task}
																budgeted_hours={task.budgeted_hours}
																statusLabel={
																	task?.task_status?.label
																}
																statusId={task?.task_status_id}
																// cardBorder={cardBorder}
																assignee_name={
																	task?.task_assignees[0]
																		?.user_profile?.full_name
																}
																assignee_id={
																	task?.task_assignees[0]
																		?.user_profile?.id
																}
																approvalFrom={task?.approval_from}
																image={
																	task?.task_assignees[0]
																		?.user_profile?.image_url
																}
																taskId={task?.id}
																workflow_name={
																	task?.client_workflow
																		?.display_name ||
																	task?.my_task?.client_workflow
																		?.display_name
																}
																due_date={task?.due_date}
																color={
																	task?.client_workflow?.color ||
																	task?.my_task?.client_workflow
																		?.color ||
																	'#fb7a03'
																}
																notes={task?.notes}
															/>
														</div>
													);
												})}
										</div>
									)}
							</div>
						);
					})}
				</div>
			) : (
				<>
					<div className='message-container' onClick={handleQuestionClick}>
						<span className='suggestion-question BodyTwoLight dark-text'>
							Find all tasks for team Financial Services.
						</span>
						<span className='suggestion-question BodyTwoLight dark-text'>
							Give me new tasks assigned to Anna in this week?
						</span>{' '}
						<span className='suggestion-question BodyTwoLight dark-text'>
							How many tasks assigned to Jessica?
						</span>
					</div>
					<div className='d-flex align-items-center'>
						<LightBulb />
						<span className='primary-text BodyTwoLight mt-1 ml-1'>
							Need a quick answer? Try these!
						</span>
					</div>
				</>
			)}
		</div>
	);
}

export default ChatBotMessages;
