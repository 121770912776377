/* eslint-disable no-mixed-spaces-and-tabs */
// eslint-disable-next-line no-unused-vars
import React, { memo } from 'react';
import CustomFieldValuesSection from './CustomFieldValuesSection';
import posthog from 'posthog-js';

function ClientInformation({
	company_name,
	business_description,
	dba,
	industry,
	business_type,
	company_phone,
	website,
	address,
	city,
	state,
	zip,
	suit_floor,
	date,
	ein,
	ssn,
	fiscal_year_end,
	accounting,
	close_week,
	start_date,
	referral,
	clientTeam,
	country,
}) {
	return (
		<div
			className='d-flex row w-100 justify-content-between information-content'
			style={{
				borderTop: '1px solid  #EAEAEA ',
				padding: '1em',
				gap: '2%',
				background: 'white',
			}}
		>
			<div
				className='d-flex flex-column text-left'
				style={{
					width: '18%',
					minHeight: '200px',
					gap: '0.8rem',
				}}
			>
				<span className='BodyOneBold'>Client Overview</span>
				<div className='d-flex flex-column'>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>
							Name / Business Name
						</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy={company_name}
						>
							{company_name}
						</h3>
					</div>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>DBA</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy={dba}
						>
							{dba}
						</h3>
					</div>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>
							Business Description
						</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy='business_description'
						>
							{business_description}
						</h3>
					</div>
				</div>
			</div>

			<div
				className='d-flex flex-column text-left'
				style={{ width: '18%', minHeight: '200px', gap: '0.8rem' }}
			>
				<span className='BodyOneBold'>Business Details</span>

				<div className='d-flex flex-column'>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>Industry</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy='industry'
						>
							{industry}
						</h3>
					</div>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>
							Business Type
						</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy='business_description'
						>
							{business_type}
						</h3>
					</div>
					{ein && (
						<div className='text-left ProfilePage'>
							<label className='label BodyTwoLight m-0 text-capitalize'>EIN </label>
							<h3
								className='BodyTwoLight'
								style={{ wordBreak: 'break-all' }}
								data-cy='ein'
							>
								{ein}
							</h3>
						</div>
					)}

					{ssn && (
						<div className='text-left ProfilePage'>
							<label className='label BodyTwoLight m-0 text-capitalize'>SSN</label>
							<h3
								className='BodyTwoLight'
								style={{ wordBreak: 'break-all' }}
								data-cy='ssn'
							>
								{ssn}
							</h3>
						</div>
					)}
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>
							Company Phone
						</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy='company_phone'
						>
							{company_phone}
						</h3>
					</div>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>
							Company Formation Date{' '}
						</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy='company_phone'
						>
							{date ? date : ''}
						</h3>
					</div>

					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>Website</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy='website'
						>
							{website}
						</h3>
					</div>
				</div>
			</div>
			<div
				className='d-flex flex-column text-left'
				style={{ width: '18%', minHeight: '200px', gap: '0.8rem' }}
			>
				<span className='BodyOneBold'>Address</span>

				<div className='d-flex flex-column'>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>Country</label>
						<h3 className='BodyTwoLight' style={{ wordBreak: 'break-all' }}>
							{country}
						</h3>
					</div>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>Street</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy='address'
						>
							{address}
						</h3>
					</div>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>
							Floor/Suite
						</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy='suit_floor'
						>
							{suit_floor}
						</h3>
					</div>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>City/Town</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy='city'
						>
							{city}
						</h3>
					</div>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>
							State/Province/Region
						</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy='state'
						>
							{state}
						</h3>
					</div>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>
							Zip Code/Postal Code
						</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy='zip'
						>
							{zip}
						</h3>
					</div>
				</div>
			</div>

			<div
				className='d-flex flex-column text-left'
				style={{ width: '18%', minHeight: '200px', gap: '0.8rem' }}
			>
				<span className='BodyOneBold'>Additional Information</span>

				<div className='d-flex flex-column'>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>
							Referred by
						</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy='refferedBy'
						>
							{referral}
						</h3>
					</div>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>
							Fiscal Year End
						</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy='fiscal_year_end'
						>
							{fiscal_year_end}
						</h3>
					</div>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>
							Assigned Teams
						</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy='suit_floor'
						>
							{clientTeam}
						</h3>
					</div>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>
							Accounting Method
						</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy='accounting'
						>
							{accounting}
						</h3>
					</div>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>Close Week</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy='close_week'
						>
							{close_week}
						</h3>
					</div>
					<div className='text-left ProfilePage'>
						<label className='label BodyTwoLight m-0 text-capitalize'>
							Client Start Date
						</label>
						<h3
							className='BodyTwoLight'
							style={{ wordBreak: 'break-all' }}
							data-cy='start_date'
						>
							{start_date ? start_date : ''}
						</h3>
					</div>
				</div>
			</div>
			{posthog?.isFeatureEnabled('custom_fields') && <CustomFieldValuesSection />}
		</div>
	);
}

export default memo(ClientInformation);
