import React, { useEffect, useState, useImperativeHandle, useMemo, memo } from 'react';
import { useSelector } from 'react-redux';

const CustomFieldValuesModal = React.forwardRef(function ({ clientId }, ref) {
	const customFieldDefinitions = useSelector(
		(state) => state.profileReducer.customFieldDefinitions,
	);
	const customFieldValues = useSelector((state) => state.clientReducer.customFieldValues);
	const [formValues, setFormValues] = useState(new Map());

	const sortedCustomFieldDefinitions = useMemo(() => {
		return customFieldDefinitions.length
			? customFieldDefinitions.sort((a, b) => a.list_order - b.list_order)
			: [];
	}, [customFieldDefinitions]);

	useEffect(() => {
		if (!sortedCustomFieldDefinitions?.length) return;
		const newFormValues = new Map(
			sortedCustomFieldDefinitions.map(({ id }) => {
				const matchingValue =
					customFieldValues?.find((field) => field.property_definition_id === id)
						?.value || '';

				return [id, matchingValue];
			}),
		);

		setFormValues(newFormValues);
	}, [sortedCustomFieldDefinitions, customFieldValues]);

	const handleChange = (event, fieldType) => {
		const { name, value } = event.target;
		const key = Number(name);

		setFormValues((prev) => {
			const updatedMap = new Map(prev);
			const prevValue = updatedMap.get(key) || '';
			if (fieldType === 'number' && isNaN(Number(prevValue))) {
				updatedMap.set(key, '');
				return updatedMap;
			}

			if (fieldType === 'number' && !/^\d*$/.test(value)) {
				return prev;
			}

			updatedMap.set(key, value);
			return updatedMap;
		});
	};

	useImperativeHandle(ref, () => ({
		getValues: () => {
			return Array.from(formValues, ([property_definition_id, value]) => {
				const matchingField = customFieldValues.find(
					(field) => field.property_definition_id === property_definition_id,
				);

				return {
					object_id: Number(clientId),
					property_definition_id,
					value,
					...(matchingField ? { id: matchingField.id } : {}),
				};
			}).filter(
				({ id, value }) => id || (value !== null && value !== undefined && value !== ''),
			);
		},
		// hasError: () => {
		// 	return sortedCustomFieldDefinitions.some((field) =>
		// 		isInvalidNumber(field.type, formValues.get(field.id)),
		// 	);
		// },
	}));

	return (
		<div ref={ref}>
			{sortedCustomFieldDefinitions?.map((field) => {
				return (
					<div
						key={field?.property_definition_id}
						className='improved-input-designs pb-3'
					>
						<label className={field?.required && 'require'}>{field?.name}</label>
						<input
							type={field?.type}
							name={field.id}
							value={formValues.get(field?.id)}
							required={field?.required}
							onChange={(e) => handleChange(e, field.type)}
						/>
						{/* <span className='BodyThreeLight error-text'>
							{isInvalidNumber(field.type, formValues.get(field?.id)) &&
								formValues.get(field?.id) !== '' &&
								'Please enter a valid value'}
						</span>

						<span className='BodyThreeLight light-text pt-1'>
							{field?.type === 'text'
								? 'Enter any text, including letters, numbers and symbols (e.g., 123848$%asj)'
								: 'Enter numbers only  (e.g, 1234567890)'}
						</span> */}
					</div>
				);
			})}
		</div>
	);
});

CustomFieldValuesModal.displayName = 'CustomFieldValuesModal';

export default memo(CustomFieldValuesModal);
