/* eslint-disable no-mixed-spaces-and-tabs */
import { useState } from 'react';
import FormButtonOne from '../Buttons/FormButtonOne';
import { ReactComponent as ArrowRight } from '../../assets/icons/ArrowRight.svg';
import DropDown from '../Inputs/DropDown';
import { useDispatch, useSelector } from 'react-redux';
import * as workspaceActions from '../../redux/actions/dashboard/workspaces-actions';
import { TASK__DISPLAY_STATUSES, TASK_STATUSES } from '../../utilities/utilities';

function ArchiveTaskStatusModal({ toggleModal, oldStatus, errorClients }) {
	const dispatch = useDispatch();

	const [archiveLoading, setArchiveLoading] = useState(false);
	const [newStatus, setNewStatus] = useState('');

	const statusOptions = useSelector((state) => state.workSpaceReducer.customTaskStatus)
		?.filter(
			(status) =>
				!status?.deleted &&
				status?.id !== oldStatus?.id &&
				status?.id !== TASK_STATUSES.APPROVAL_PENDING,
		)
		?.map((status) => {
			return {
				label: TASK__DISPLAY_STATUSES[status?.label] || status?.label,
				value: status?.id,
			};
		});

	const fetchTaskStatuses = () => {
		dispatch(workspaceActions.getCustomTaskStatus())
			.then((response) => {
				dispatch(
					workspaceActions.setCustomTaskStatuses(
						response?.sort((a, b) => a.list_order - b.list_order),
					),
				);
			})
			.catch((error) => {
				dispatch(workspaceActions.setSnackBarIsOpen(false, true, error.message));
			});
	};
	const handleStatusChange = (event) => {
		const { value } = event.target;
		setNewStatus(value);
	};

	const handleArchiveStatus = () => {
		setArchiveLoading(true);
		const replacement_id = newStatus;
		dispatch(
			workspaceActions.archiveCustomTaskStatus(oldStatus?.id, {
				replacement_id: replacement_id,
			}),
		)
			.then(() => {
				setArchiveLoading(false);
				fetchTaskStatuses();
				toggleModal();
			})
			.catch((error) => {
				dispatch(workspaceActions.setSnackBarIsOpen(false, true, error.message));
				setArchiveLoading(false);
			});
	};
	return (
		<div className='new-modal' style={{ width: '540px' }}>
			<div className='new-modal-content p-4'>
				<div className='d-flex justify-content-between'>
					<span className='HeadlineTwoBold dark-text'>
						{`The status you're trying archive is currently in use`}
					</span>
				</div>
				<div className='improved-input-designs'>
					<label>
						The clients below have tasks with the status you’re trying to archive.
						Filter these tasks on the workspace to notify your team, or replace the
						status with a new one
					</label>
					<div
						className='d-flex flex-column'
						style={{ maxHeight: 300, overflow: 'auto' }}
					>
						{errorClients?.map((client) => {
							return (
								<span key={client} className='BodyTwoBold'>
									{client}
								</span>
							);
						})}
					</div>
				</div>
				<div className='d-flex align-items-center' style={{ gap: '24px' }}>
					<div className='improved-input-designs' style={{ width: '220px' }}>
						<label>Old Status</label>
						<span className='BodyTwoLight'>{oldStatus?.label}</span>
					</div>
					<ArrowRight />
					<div className='improved-input-designs w-50'>
						<label>New Status</label>
						<DropDown
							onChange={handleStatusChange}
							normalDropDown
							style={{ height: '32px', textAlign: 'left' }}
							options={statusOptions}
							value={newStatus}
							disableNull
						/>
					</div>
				</div>
			</div>
			<div className='new-modal-footer rounded-bottom'>
				<FormButtonOne
					onClick={toggleModal}
					type='button'
					className='rounded-btn secondary-btn-light'
				>
					Cancel
				</FormButtonOne>
				<FormButtonOne
					type='button'
					className='rounded-btn primary-btn'
					onClick={handleArchiveStatus}
					loading={archiveLoading}
				>
					Save
				</FormButtonOne>
			</div>
		</div>
	);
}

export default ArchiveTaskStatusModal;
