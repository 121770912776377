import { REPEAT_TYPE, sortTasks } from '../../utilities/utilities';
import { push, updateWithinColumnIndex } from '../../utilities/BuilderUtils';
import * as Actions from '../actions/workflow-builder-actions';

const initialState = {
	selectedWorkflow: {},
	selectedComponent: undefined,
	workflows: [],
	activeClientWorkflows: [],
	selectedWorkflowsId: [],
	workflowTask: {
		title: '',
		description: '',
		budgeted_hours: undefined,
		assignee_user_id: null,
		dependent_task_id: '',
		task_type_id: 2,
		is_approval_required: false,
		required_days: 0,
	},
	// allWorkflowTasks: [],
	taskRecurrence: [],
	workflowsToManage: [],
	workflowData: [],
	workflowTaskDetails: {
		title: '',
		description: '',
		assignee_user_id: null,
	},
	loading: false,
	activeWorkflows: [],
	recurrenceData: null,
	builderLoadSave: false,
	workflowClients: 0,
	newWorkflowForm: {},
	formError: '',
	task_templates: [],
	workflowCategories: [],
	assignedWorkflowCategories: [],
	prebuiltWorkflows: [],
	companyWorkflows: [],
	// draftWorkflows:[],
	activeTab: 0,
	builderLoading: false,
	publishWorkflowBuilderLoading: false,
	selectedClientWorkflow: undefined,
	setSelectedWorkflowLoading: false,
	workflowsToActivate: {
		duplicate_workflows: [],
		valid_workflows: [],
	},
	workflowsToDraft: {
		draft_duplicate_workflows: [],
		draft_valid_workflows: [],
	},
	clientSelectedForWorkflowActivation: [],
};

const WorkflowBuilder = (state = initialState, action) => {
	const { payload, type } = action;

	switch (type) {
		case Actions.SET_CLIENT_FOR_WORKFLOW_ACTIVATION:
			return (state = {
				...state,
				clientSelectedForWorkflowActivation: payload,
			});
		case Actions.SET_WORKFLOWS_TO_ACTIVATE:
			return (state = {
				...state,
				workflowsToActivate: payload,
			});
		case Actions.SET_WORKFLOWS_TO_DRAFT:
			return (state = {
				...state,
				workflowsToDraft: payload,
			});
		case Actions.REMOVE_WORKFLOW:
			return (state = {
				...state,
				selectedClientWorkflow: undefined,
				allWorkflowTasks: state?.allWorkflowTasks?.filter(
					(clientWorkflow) => clientWorkflow.id != payload,
				),
			});
		case Actions.UPDATE_CLIENT_WORKFLOWS_LIST:
			return (state = {
				...state,
				allWorkflowTasks: state?.allWorkflowTasks?.map((clientWorkflow) => {
					if (clientWorkflow.id == payload.id) {
						return {
							...clientWorkflow,
							...payload,
						};
					}
					return clientWorkflow;
				}),
			});
		case Actions.SET_CLIENT_WORKFLOW_LOADING:
			return (state = {
				...state,
				setSelectedWorkflowLoading: payload,
			});
		case Actions.UPDATE_CLIENT_WORKFLOW_RECURRENCE:
			return (state = {
				...state,
				selectedClientWorkflow: {
					...state?.selectedClientWorkflow,
					...payload,
				},
			});
		case Actions.SET_CLIENT_WORKFLOW:
			return (state = {
				...state,
				selectedClientWorkflow: payload,
			});
		case Actions.WORKFLOW_FORM_CHANGE_HANDLER:
			return (state = {
				...state,
				newWorkflowForm: {
					...state.newWorkflowForm,
					[payload.name]: payload.value,
				},
			});

		case Actions.SET_WORKFLOW_FORM:
			return (state = {
				...state,
				newWorkflowForm: payload,
			});

		case Actions.SET_ERROR:
			return (state = {
				...state,
				formError: payload,
			});

		case Actions.GET_ALL_WORKFLOWS:
			return (state = {
				...state,
				workflows: payload,
			});

		case Actions.UPDATE_WORKFLOWS:
			return (state = {
				...state,
				workflows: state?.workflows?.map((flow, i) => {
					if (flow?.componentName == state?.selectedWorkflow?.componentName) {
						return state?.selectedWorkflow;
					} else {
						return flow;
					}
				}),
			});

		case Actions.SET_SELECTED_COMPONENT:
			return (state = {
				...state,
				selectedComponent: payload,
			});

		case Actions.BUILDER_SAVE_LOAD:
			return (state = {
				...state,
				builderLoadSave: payload,
			});

		case Actions.PUBLISH_BUILDER_DATA_LOADING:
			return (state = {
				...state,
				publishWorkflowBuilderLoading: payload,
			});
		case Actions.GET_ACTIVE_CLIENT_WORKFLOW:
			return (state = {
				...state,
				activeClientWorkflows: payload,
			});

		case Actions.WORKFLOW_NAME_CHANGE_HANDLER:
			var newComponentName =
				payload.value === ' '
					? state?.selectedWorkflow?.componentName?.toUpperCase() + '_'
					: payload.value.split(' ').join('_').toUpperCase();
			return (state = {
				...state,
				selectedWorkflow: {
					...state.selectedWorkflow,
					componentName: newComponentName,
					displayName: payload.value,
					props: {
						...state?.selectedWorkflow?.props,
						sections: state?.selectedWorkflow?.props?.sections?.map((sec, index) => {
							if (state?.selectedWorkflow?.props?.sections?.length - 1 === index) {
								return {
									...sec,
									sectionName: 'WORKFLOW_' + newComponentName,
									componentName: 'Workflow: ' + payload.value,
								};
							}
							if (index === 0) {
								return {
									...sec,
									sectionName: newComponentName + '_INFORMATION',
									componentName: payload.value + ' Information',
								};
							}
							return sec;
						}),
					},
				},
			});

		case Actions.COLUMN_TITLE_CHANGE_HANDLER:
			return (state = {
				...state,
				selectedWorkflow: {
					...state.selectedWorkflow,
					props: {
						...state.selectedWorkflow?.props,
						sections: state.selectedWorkflow?.props.sections?.map((sec, i) => {
							if (i === 0) {
								return {
									...sec,
									columns: sec?.columns?.map((col, index) => {
										if (index === payload.columnIndex) {
											return {
												...col,
												columnName: payload.value,
											};
										}
										return col;
									}),
								};
							}
							return sec;
						}),
					},
				},
			});

		case Actions.SET_WORKFLOW_DATA:
			return (state = {
				...state,
				workflowsToManage: state.workflowsToManage.map((workflow) => {
					if (workflow.id === payload.id) {
						console.info(workflow);
						if (REPEAT_TYPE.MONTHLY == payload.repeat_type) {
							return {
								...workflow,
								task_templates: workflow.task_templates.map((task) => {
									return {
										...task,
										required_days: payload.required_days,
									};
								}),
								start_date: payload.start_date,
								repeat_type: payload.repeat_type,
								repeat_interval: payload.repeat_interval,
								is_monthly_on_day: payload.is_monthly_on_day,
								recur_preference: payload.recur_preference,
								end_on: payload.end_on,
								required_days: payload.required_days,
							};
						} else {
							return {
								...workflow,
								task_templates: workflow.task_templates.map((task) => {
									return {
										...task,
										required_days: payload.required_days,
									};
								}),
								start_date: payload.start_date,
								repeat_type: payload.repeat_type,
								repeat_interval: payload.repeat_interval,
								end_on: payload.end_on,
								required_days: payload.required_days,
							};
						}
					}

					return workflow;
				}),
			});

		case Actions.SET_ALL_WORKFLOW_TASKS:
			return (state = {
				...state,
				allWorkflowTasks: state.allWorkflowTasks?.map((workflowTask) => {
					if (workflowTask?.is_active === false && workflowTask?.status === 'draft') {
						if (workflowTask?.id === payload.id) {
							if (REPEAT_TYPE.MONTHLY == payload.repeat_type) {
								return {
									...workflowTask,
									client_workflow_tasks: workflowTask.client_workflow_tasks.map(
										(task) => {
											return {
												...task,
												required_days: payload.required_days,
											};
										},
									),
									start_date: payload.start_date,
									repeat_type: payload.repeat_type,
									repeat_interval: payload.repeat_interval,
									is_monthly_on_day: payload.is_monthly_on_day,
									recur_preference: payload.recur_preference,
									end_on: payload.end_on,
									required_days: payload.required_days,
								};
							} else {
								return {
									...workflowTask,
									client_workflow_tasks: workflowTask.client_workflow_tasks.map(
										(task) => {
											return {
												...task,
												required_days: payload.required_days,
											};
										},
									),
									start_date: payload.start_date,
									repeat_type: payload.repeat_type,
									repeat_interval: payload.repeat_interval,
									end_on: payload.end_on,
									required_days: payload.required_days,
								};
							}
						}
						return workflowTask;
					}
					return workflowTask;
				}),
			});
		case Actions.SET_DEACTIVATED_WORKFLOW_DATA:
			return (state = {
				...state,
				activeWorkflows: state?.activeWorkflows?.map((workflow) => {
					if (workflow.id === payload.id) {
						if (REPEAT_TYPE.MONTHLY == payload.repeat_type) {
							return {
								...workflow,
								start_date: payload.start_date,
								repeat_type: payload.repeat_type,
								repeat_interval: payload.repeat_interval,
								is_monthly_on_day: payload.is_monthly_on_day,
								recur_preference: payload.recur_preference,
							};
						} else {
							return {
								...workflow,
								start_date: payload.start_date,
								repeat_type: payload.repeat_type,
								repeat_interval: payload.repeat_interval,
							};
						}
					}

					return workflow;
				}),
			});

		case Actions.SET_WORKFLOW_TASK:
			return (state = {
				...state,
				workflowsToManage: state.workflowsToManage.map((workflow) => {
					if (workflow.id === payload.workflow_id) {
						return {
							...workflow,
							task_templates: [...workflow.task_templates, payload],
						};
					}

					return workflow;
				}),
			});
		case Actions.SET_SELECTED_WORKFLOWS:
			return (state = {
				...state,
				selectedWorkflowsId: payload,
			});

		case Actions.RESET_SELECTED_WORKFLOWS:
			return (state = {
				...state,
				selectedWorkflowsId: [],
			});

		case Actions.REMOVE_WORKFLOW_TASK:
			return (state = {
				...state,
				workflowsToManage: state.workflowsToManage.map((workflow) => {
					if (workflow.id === payload.workflowId) {
						return {
							...workflow,
							task_templates: workflow.task_templates.filter((task, i) => {
								if (i !== payload.taskIndex) {
									return task;
								}
							}),
						};
					}

					return workflow;
				}),
			});

		case Actions.HANDLE_TASK_DETAILS_CHANGE:
			return (state = {
				...state,
				workflowsToManage: state.workflowsToManage.map((workflow) => {
					return {
						...workflow,
						task_templates: workflow.task_templates.map((task, i) => {
							if (task.id === payload.taskId) {
								return {
									...task,
									[payload.name]: payload.value,
								};
							}
							return task;
						}),
					};
				}),
			});

		case Actions.SWAP_COMPONENT:
			var selected = null;
			return (state = {
				...state,
				selectedWorkflow: {
					...state.selectedWorkflow,
					props: {
						...state.selectedWorkflow?.props,
						sections: state.selectedWorkflow?.props.sections?.map((sec, i) => {
							if (i === payload.newIndex.sec) {
								return {
									...sec,
									columns: sec.columns?.map((col, i) => {
										if (i === payload.newIndex.col) {
											if (payload?.newIndex?.withinColumnIndex) {
												selected = {
													...state?.swapComponent,
													columnIndex: payload.newIndex.col,
													sectionIndex: payload.newIndex.sec,
													propsTypes: {
														...state?.swapComponent?.propsTypes,
														withinColumnIndex:
															payload.newIndex.withinColumnIndex,
													},
												};
												return {
													...col,
													columnComponents: push(
														col?.columnComponents,
														{
															...state?.swapComponent,
															columnIndex: payload.newIndex.col,
															sectionIndex: payload.newIndex.sec,
															propsTypes: {
																...state?.swapComponent?.propsTypes,
																withinColumnIndex:
																	payload.newIndex
																		.withinColumnIndex,
															},
														},
														payload.newIndex.withinColumnIndex,
													),
												};
											} else {
												selected = {
													...state?.swapComponent,
													columnIndex: payload.newIndex.col,
													sectionIndex: payload.newIndex.sec,
													propsTypes: {
														...state?.swapComponent?.propsTypes,
														withinColumnIndex:
															col.columnComponents.length > 0
																? col.columnComponents.length - 1
																: 0,
													},
												};
												return {
													...col,
													columnComponents: [
														...col.columnComponents,
														{
															...state?.swapComponent,
															columnIndex: payload.newIndex.col,
															sectionIndex: payload.newIndex.sec,
															propsTypes: {
																...state?.swapComponent?.propsTypes,
																withinColumnIndex:
																	col.columnComponents.length > 0
																		? col.columnComponents
																				.length - 1
																		: 0,
															},
														},
													],
												};
											}
										} else {
											return col;
										}
									}),
								};
							} else {
								return sec;
							}
						}),
					},
				},
				selectedComponent: selected,
			});

		case Actions.REMOVE_COMPONENT:
			var componentToSwap = {};
			return (state = {
				...state,
				selectedWorkflow: {
					...state.selectedWorkflow,
					props: {
						...state.selectedWorkflow?.props,
						sections: state.selectedWorkflow?.props.sections?.map((sec, i) => {
							return {
								...sec,
								columns: sec.columns?.map((col, i) => {
									return {
										...col,
										columnComponents: col.columnComponents
											?.filter((component, index) => {
												if (component.id !== payload.componentId) {
													return true;
												} else {
													componentToSwap = component;
													return false;
												}
											})
											?.map((col, index) => {
												return {
													...col,
													propsTypes: {
														...col.propsTypes,
														withinColumnIndex: index,
													},
												};
											}),
									};
								}),
							};
						}),
					},
				},
				swapComponent: componentToSwap,
			});

		case Actions.UPDATE_DRAFT_WORKFLOW_DISPLAY_NAME:
			return (state = {
				...state,
				allWorkflowTasks: state?.allWorkflowTasks?.map((clientWorkflow) => {
					console.log(clientWorkflow, 'inreducer');
					if (clientWorkflow.id == payload.clientWorkflowId) {
						return {
							...clientWorkflow,
							workflow_submission: {
								...clientWorkflow?.workflow_submission,
								data: clientWorkflow?.workflow_submission?.data?.map((data) => {
									return {
										...data,
										displayName: payload.barTextValue,
										componentName: payload.barTextValue
											.toUpperCase()
											.replace(/\s+/g, '_'),
									};
								}),
							},
						};
					}
					return clientWorkflow;
				}),
			});

		case Actions.UPDATE_SELECTED_COMPONENT:
			return (state = {
				...state,
				selectedComponent: {
					...state.selectedComponent,
					[payload.index]: payload.value,
					propsTypes: {
						...state.selectedComponent.propsTypes,
						propInputTypes: state.selectedComponent.propsTypes.propInputTypes.map(
							(propInput) => {
								if (propInput.name == payload.index) {
									return {
										...propInput,
										value: payload.value,
									};
								} else {
									return propInput;
								}
							},
						),
					},
				},
			});

		case Actions.SET_SELECTED_WORKFLOW:
			return (state = {
				...state,
				selectedWorkflow: payload,
			});

		case Actions.UPDATE_WORKFLOW_COMPONENT:
			return (state = {
				...state,
				selectedWorkflow: {
					...state.selectedWorkflow,
					props: {
						...state.selectedWorkflow?.props,
						sections: state.selectedWorkflow?.props.sections?.map((sec, i) => {
							if (i === state?.selectedComponent?.sectionIndex) {
								return {
									...sec,
									columns: sec.columns?.map((col, i) => {
										if (i === state?.selectedComponent?.columnIndex) {
											return {
												...col,
												columnComponents: col.columnComponents?.map(
													(component, index) => {
														if (
															component.id ==
															state?.selectedComponent.id
														) {
															return state?.selectedComponent;
														} else {
															return component;
														}
													},
												),
											};
										} else {
											return col;
										}
									}),
								};
							} else {
								return sec;
							}
						}),
					},
				},
			});

		case Actions.UPDATE_SELECTED_WORKFLOW:
			return (state = {
				...state,
				selectedWorkflow: {
					...state.selectedWorkflow,

					props: {
						...state.selectedWorkflow.props,
						sections: state.selectedWorkflow.props.sections?.map((sec, i) => {
							if (i === payload.sectionIndex) {
								return {
									...sec,
									columns: sec.columns?.map((col, i) => {
										if (i === payload.columnIndex) {
											if (payload.withinColumnIndex != undefined) {
												const array = push(
													col?.columnComponents,
													payload.Component,
													payload.withinColumnIndex,
												);
												return {
													...col,
													columnComponents:
														updateWithinColumnIndex(array),
												};
											} else {
												return {
													...col,
													columnComponents: [
														...col.columnComponents,
														payload.Component,
													],
												};
											}
										} else {
											return col;
										}
									}),
								};
							} else {
								return sec;
							}
						}),
					},
				},
			});

		case Actions.DELETE_COMPONENT_OPTIONS:
			return (state = {
				...state,
				selectedComponent: {
					...state.selectedComponent,
					options: state.selectedComponent.options.filter((option, j) => {
						if (payload != j) {
							return option;
						}
					}),
					propsTypes: {
						...state?.selectedComponent?.propsTypes,
						propInputTypes: state?.selectedComponent?.propsTypes?.propInputTypes.map(
							(prop, j) => {
								if (j == 1) {
									return {
										...prop,
										value: prop?.value?.filter((val, i) => {
											if (i != payload) {
												return val;
											}
										}),
									};
								} else {
									return prop;
								}
							},
						),
					},
				},
			});

		case Actions.WORKFLOW_ACTIVATE_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.WORKFLOW_ACTIVATE_END:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.WORKFLOW_ACTIVATE_FAILED:
			return (state = {
				...state,
				loading: false,
			});

		case Actions.UPDATE_WORKFLOW_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.UPDATE_WORKFLOW_END:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.UPDATE_WORKFLOW_SUBMISSION_DATA:
			return (state = {
				...state,
				allWorkflowTasks: state?.allWorkflowTasks.map((workflow) => {
					if (workflow.workflow_submission._id === payload._id) {
						// If IDs match, update the workflow_submission
						return {
							...workflow,
							workflow_submission: payload,
						};
					} else {
						// If IDs don't match, return the original workflow
						return workflow;
					}
				}),
			});

		case Actions.SET_CLIENT_WORKFLOW_TASK:
			return (state = {
				...state,
				selectedClientWorkflow: {
					...state?.selectedClientWorkflow,
					client_workflow_tasks: payload,
				},
			});

		case Actions.UPDATE_WORKFLOW_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.SET_NEW_WORKFLOW_SUBMISSION:
			return (state = {
				...state,
				selectedClientWorkflow: {
					...state.selectedClientWorkflow,
					workflow_submission: {
						...state.selectedClientWorkflow.workflow_submission,
						data: payload,
					},
				},
			});

		case Actions.SET_DUPLICATE_WORKFLOW_COMPONENT_VALUE:
			return (state = {
				...state,
				activeWorkflows: state?.activeWorkflows?.map(
					({ workflow_submission, ...clientWorkflow }, i) => {
						const workflow = workflow_submission?.data[0];
						if (workflow?.componentName == payload.workflowName) {
							return {
								...clientWorkflow,
								workflow_submission: {
									...workflow_submission,
									data: [
										{
											...workflow,
											props: {
												...workflow.props,
												sections: workflow.props.sections?.map((sec, i) => {
													if (i == payload?.sectionIndex) {
														return {
															...sec,
															columns: sec?.columns?.map((col, j) => {
																if (payload?.columnIndex == j) {
																	return {
																		...col,
																		columnComponents:
																			col.columnComponents.map(
																				(component, j) => {
																					if (
																						payload
																							?.propsTypes
																							?.withinColumnIndex ==
																						j
																					) {
																						return {
																							...component,
																							value: payload?.value,
																						};
																					} else {
																						return component;
																					}
																				},
																			),
																	};
																} else {
																	return col;
																}
															}),
														};
													} else {
														return sec;
													}
												}),
											},
										},
									],
								},
							};
						}
						return { ...clientWorkflow, workflow_submission };
					},
				),
			});

		case Actions.SET_WORKFLOW_COMPONENT_VALUE:
			return (state = {
				...state,
				workflowsToManage: state?.workflowsToManage.map((workflow, i) => {
					if (
						workflow?.workflow_builder?.data[0]?.componentName == payload.workflowName
					) {
						return {
							...workflow,
							workflow_builder: {
								...workflow.workflow_builder,
								data: workflow.workflow_builder.data.map((data, index) => {
									if (index === 0) {
										return {
											...data,
											props: {
												...workflow.workflow_builder.data[0].props,
												sections:
													workflow.workflow_builder.data[0].props?.sections?.map(
														(sec, i) => {
															if (i == payload?.sectionIndex) {
																return {
																	...sec,
																	columns: sec?.columns?.map(
																		(col, j) => {
																			if (
																				payload?.columnIndex ==
																				j
																			) {
																				return {
																					...col,
																					columnComponents:
																						col.columnComponents.map(
																							(
																								component,
																								j,
																							) => {
																								if (
																									payload
																										?.propsTypes
																										?.withinColumnIndex ==
																									j
																								) {
																									return {
																										...component,
																										value: payload?.value,
																									};
																								} else {
																									return component;
																								}
																							},
																						),
																				};
																			} else {
																				return col;
																			}
																		},
																	),
																};
															} else {
																return sec;
															}
														},
													),
											},
										};
									} else {
										return data;
									}
								}),
							},
						};
					} else {
						return workflow;
					}
				}),
			});

		case Actions.CHANGE_TABLE_VALUE:
			return (state = {
				...state,
				allWorkflowTasks: state.allWorkflowTasks.map((workflowTask, i) => {
					if (workflowTask?.id == payload.clientWorkflowId) {
						return {
							...workflowTask,
							workflow_submission: {
								...workflowTask.workflow_submission,
								data: workflowTask.workflow_submission.data.map(
									(innerData, index) => {
										if (index === 0) {
											return {
												...innerData,
												props: {
													...workflowTask.workflow_submission.data[0]
														.props,
													sections:
														workflowTask.workflow_submission.data[0].props.sections.map(
															(sec, secIndex) => {
																if (
																	secIndex ===
																	payload.sectionIndex
																) {
																	return {
																		...sec,
																		tableRows:
																			sec.tableRows.map(
																				(row, rowIndex) => {
																					if (
																						rowIndex ===
																						payload.rowIndex
																					) {
																						return {
																							...row,
																							values: row.values.map(
																								(
																									innerRow,
																									innerRowIndex,
																								) => {
																									if (
																										innerRowIndex ===
																										payload.withinRowIndex
																									) {
																										return payload.value;
																									} else {
																										return innerRow;
																									}
																								},
																							),
																						};
																					} else {
																						return row;
																					}
																				},
																			),
																	};
																} else {
																	return sec;
																}
															},
														),
												},
											};
										} else {
											return innerData;
										}
									},
								),
							},
						};
					} else {
						return workflowTask;
					}
				}),
				// allWorkflowTasks: state?.allWorkflowTasks.map(
				//     (workflowTask, i) => {
				//         if (workflowTask?.is_active) {
				//             if (
				//                 workflowTask?.workflow_submission?.data[0]
				//                     .componentName === payload.workflowName
				//             )
				//             {workflowTask?.workflow_submission?.data[0].props.sections.map(
				//                     (section, index) => {
				//                         if (payload.sectionIndex == index) {
				//                             section?.tableRows.map(
				//                                 (tableRow, j) => {
				//                                     if (payload.rowIndex == j) {
				//                                         tableRow?.values.map(
				//                                             (
				//                                                 rowItem,
				//                                                 itemIndex
				//                                             ) => {
				//                                                 if (
				//                                                     itemIndex == payload.withinRowIndex
				//                                                 ) {
				//                                                     console.log(rowItem, payload, itemIndex, 'checking row_item value')
				//                                                     rowItem = payload.value
				//                                                 }
				//                                             }

				//                                         );
				//                                     }
				//                                 }
				//                             );
				//                         }
				//                     }
				//                 );
				//             }
				//         }
				//         return workflowTask
				//     }
				// ),
				// allWorkflowTasks: state?.allWorkflowTasks.map((workflow, i) => {

				//     if(workflow?.is_active){

				//     if (workflow?.workflow_submission?.data[0].componentName == payload.workflowName) {
				//         return {

				//             ...workflow?.workflow_submission,
				//             props: {
				//                 ...workflow?.workflow_submission?.data[0],
				//                 sections: workflow?.workflow_submission?.data[0].props.sections?.map(
				//                     (sec, i) => {
				//                         if (i == payload?.sectionIndex) {
				//                             console.log(sec);
				//                             return {
				//                                 ...sec,
				//                                 tableRows: sec?.tableRows?.map(
				//                                     (row, j) => {
				//                                         if (
				//                                             payload?.rowIndex ==
				//                                             j
				//                                         ) {
				//                                             console.log(
				//                                                 row.values
				//                                             );
				//                                             return {
				//                                                 values: row?.values.map(
				//                                                     (
				//                                                         rowItem,
				//                                                         itemIndex
				//                                                     ) => {
				//                                                         if (
				//                                                             itemIndex ==
				//                                                             payload?.withinRowIndex
				//                                                         ) {
				//                                                             return payload.value;
				//                                                         } else {
				//                                                             return rowItem;
				//                                                         }
				//                                                     }
				//                                                 ),
				//                                             };
				//                                         } else {
				//                                             return row;
				//                                         }
				//                                     }
				//                                 ),
				//                             };
				//                         } else {
				//                             return sec;
				//                         }
				//                     }
				//                 ),
				//             },
				//         };
				//     } else {
				//         return workflow;
				//     }
				//     }
				// }),
			});

		case Actions.CHANGE_DEACTIVATE_WORKFLOW_TABLE_VALUE:
			return (state = {
				...state,
				activeWorkflows: state?.activeWorkflows?.map(
					({ workflow_submission, ...clientWorkflow }, i) => {
						const workflow = workflow_submission.data[0];
						if (workflow?.componentName == payload.workflowName) {
							console.log(workflow);
							return {
								...clientWorkflow,
								workflow_submission: {
									...workflow_submission,
									data: [
										{
											...workflow,
											props: {
												...workflow.props,
												sections: workflow.props.sections?.map((sec, i) => {
													if (i == payload?.sectionIndex) {
														console.log(sec);
														return {
															...sec,
															tableRows: sec?.tableRows?.map(
																(row, j) => {
																	if (payload?.rowIndex == j) {
																		console.log(row.values);
																		return {
																			values: row?.values.map(
																				(
																					rowItem,
																					itemIndex,
																				) => {
																					if (
																						itemIndex ==
																						payload?.withinRowIndex
																					) {
																						return payload.value;
																					} else {
																						return rowItem;
																					}
																				},
																			),
																		};
																	} else {
																		return row;
																	}
																},
															),
														};
													} else {
														return sec;
													}
												}),
											},
										},
									],
								},
							};
						}
						return { ...clientWorkflow, workflow_submission };
					},
				),
			});

		case Actions.CHANGE_WORKFLOW_TABLE_VALUE:
			return (state = {
				...state,
				workflowsToManage: state?.workflowsToManage.map((workflow, i) => {
					if (
						workflow?.workflow_builder?.data[0]?.componentName == payload.workflowName
					) {
						return {
							...workflow,
							workflow_builder: {
								...workflow.workflow_builder,
								data: workflow.workflow_builder.data.map((data, index) => {
									if (index === 0) {
										return {
											...data,
											props: {
												...workflow.workflow_builder.data[0].props,
												sections:
													workflow?.workflow_builder?.data[0]?.props?.sections?.map(
														(sec, i) => {
															if (i == payload?.sectionIndex) {
																return {
																	...sec,
																	tableRows: sec?.tableRows?.map(
																		(row, j) => {
																			if (
																				payload?.rowIndex ==
																				j
																			) {
																				return {
																					values: row?.values.map(
																						(
																							rowItem,
																							itemIndex,
																						) => {
																							if (
																								itemIndex ==
																								payload?.withinRowIndex
																							) {
																								return payload.value;
																							} else {
																								return rowItem;
																							}
																						},
																					),
																				};
																			} else {
																				return row;
																			}
																		},
																	),
																};
															} else {
																return sec;
															}
														},
													),
											},
										};
									} else {
										return data;
									}
								}),
							},
						};
					} else {
						return workflow;
					}
				}),
			});

		case Actions.UPDATE_COMPONENT_OPTIONS:
			return (state = {
				...state,
				selectedComponent: {
					...state.selectedComponent,
					options: state.selectedComponent.options.map((option, j) => {
						if (payload.index == j) {
							if (state.selectedComponent.componentType == 'pick-list') {
								return {
									...option,
									label: payload.value.label,
								};
							} else {
								return payload.value;
							}
						} else {
							return option;
						}
					}),
					propsTypes: {
						...state?.selectedComponent?.propsTypes,
						propInputTypes: state?.selectedComponent?.propsTypes?.propInputTypes.map(
							(prop, j) => {
								if (j == 1) {
									return {
										...prop,
										value: prop?.value?.map((val, i) => {
											if (i == payload.index) {
												if (
													state.selectedComponent.componentType ==
													'pick-list'
												) {
													return {
														...val,
														label: payload.value.label,
													};
												} else {
													return payload.value;
												}
											} else {
												return val;
											}
										}),
									};
								} else {
									return prop;
								}
							},
						),
					},
				},
			});

		case Actions.ADD_COMPONENT_OPTIONS:
			return (state = {
				...state,
				selectedComponent: {
					...state.selectedComponent,
					options: [...state?.selectedComponent?.options, payload],
					propsTypes: {
						...state?.selectedComponent?.propsTypes,
						propInputTypes: state?.selectedComponent?.propsTypes?.propInputTypes.map(
							(prop, j) => {
								if (j == 1) {
									return {
										...prop,
										value: [...prop?.value, payload],
									};
								} else {
									return prop;
								}
							},
						),
					},
				},
			});

		case Actions.ADD_NEW_TABLE_ROW:
			return (state = {
				...state,
				allWorkflowTasks: state?.allWorkflowTasks.map((workflowTask, i) => {
					const tempWorkflow = { ...workflowTask };
					const clientWorkflowId = tempWorkflow.id;
					if (clientWorkflowId === payload.clientWorkflowId) {
						tempWorkflow.workflow_submission?.data[0].props.sections.forEach(
							(section, index) => {
								if (payload.sectionIndex === index) {
									section.tableRows = [
										...section.tableRows,
										{
											values: Object.values(payload.data),
										},
									];
								}
							},
						);
					}

					return tempWorkflow;
				}),
			});

		case Actions.ADD_NEW_TABLE_ROW_DEACTIVATE_WORKFLOW:
			return (state = {
				...state,
				activeWorkflows: state?.activeWorkflows
					?.filter(({ is_active }) => !is_active)
					?.map((clientWorkflow, i) => {
						const workflow = clientWorkflow.workflow_submission.data[0];
						if (workflow?.componentName == payload.workflowName) {
							return {
								...clientWorkflow,
								workflow_submission: {
									...clientWorkflow.workflow_submission,
									data: [
										{
											...workflow,
											props: {
												...workflow.props,
												sections: workflow.props.sections?.map((sec, i) => {
													if (i == payload?.sectionIndex) {
														return {
															...sec,
															tableRows: [
																...sec.tableRows,
																{
																	values: Object.values(
																		payload.data,
																	),
																},
															],
														};
													} else {
														return sec;
													}
												}),
											},
										},
									],
								},
							};
						}
						return clientWorkflow;
					}),
			});

		case Actions.ADD_NEW_TABLE_WORKFLOW_ROW:
			return (state = {
				...state,
				workflowsToManage: state?.workflowsToManage.map((workflow, i) => {
					if (
						workflow?.workflow_builder?.data[0]?.componentName == payload.workflowName
					) {
						return {
							...workflow,
							workflow_builder: {
								...workflow.workflow_builder,
								data: workflow.workflow_builder.data.map((data, index) => {
									if (index === 0) {
										return {
											...data,
											props: {
												...workflow.workflow_builder.data[0].props,
												sections:
													workflow.workflow_builder.data[0].props?.sections?.map(
														(sec, i) => {
															if (i == payload?.sectionIndex) {
																return {
																	...sec,
																	tableRows: [
																		...sec.tableRows,
																		{
																			values: Object.values(
																				payload.data,
																			),
																		},
																	],
																};
															} else {
																return sec;
															}
														},
													),
											},
										};
									} else {
										return data;
									}
								}),
							},
						};
					} else {
						return workflow;
					}
				}),
			});

		case Actions.DELETE_TABLE_ROW:
			return (state = {
				...state,
				allWorkflowTasks: state.allWorkflowTasks.map((workflowTask, i) => {
					if (workflowTask?.id == payload.clientWorkflowId) {
						return {
							...workflowTask,
							workflow_submission: {
								...workflowTask.workflow_submission,
								data: workflowTask.workflow_submission.data.map(
									(innerData, index) => {
										if (index === 0) {
											return {
												...innerData,
												props: {
													...workflowTask.workflow_submission.data[0]
														.props,
													sections:
														workflowTask.workflow_submission.data[0].props.sections.map(
															(sec, secIndex) => {
																if (
																	secIndex ===
																	payload.sectionIndex
																) {
																	return {
																		...sec,
																		tableRows:
																			sec?.tableRows?.filter(
																				(state, i) =>
																					i !=
																					payload.rowIndex,
																			),
																	};
																} else {
																	return sec;
																}
															},
														),
												},
											};
										} else {
											return innerData;
										}
									},
								),
							},
						};
					} else {
						return workflowTask;
					}
				}),
			});
		// return (state = {
		//     ...state,
		//     allWorkflowTasks: state?.allWorkflowTasks.map((workflowTask, i) => {
		//         if (workflowTask?.id == payload.clientWorkflowId) {
		//             return {
		//                 ...workflow,
		//                 props: {
		//                     ...workflow.props,
		//                     sections: workflow.props.sections?.map(
		//                         (sec, i) => {
		//                             if (i == payload?.sectionIndex) {
		//                                 return {
		//                                     ...sec,
		//                                     tableRows:
		//                                         sec?.tableRows?.filter(
		//                                             (state, i) =>
		//                                                 i !=
		//                                                 payload.rowIndex
		//                                         ),
		//                                 };
		//                             } else {
		//                                 return sec;
		//                             }
		//                         }
		//                     ),
		//                 },
		//             };
		//         } else {
		//             return workflow;
		//         }
		//     }),
		// });

		case Actions.DELETE_DEACTIVATE_TABLE_ROW:
			return (state = {
				...state,
				activeWorkflows: state?.activeWorkflows.map(
					({ workflow_submission, ...clientWorkflow }, i) => {
						const workflow = workflow_submission?.data[0];

						if (workflow?.componentName == payload.workflowName) {
							return {
								...clientWorkflow,
								workflow_submission: {
									...workflow_submission,
									data: [
										{
											...workflow,
											props: {
												...workflow.props,
												sections: workflow.props.sections?.map((sec, i) => {
													if (i == payload?.sectionIndex) {
														return {
															...sec,
															tableRows: sec?.tableRows?.filter(
																(state, i) => i != payload.rowIndex,
															),
														};
													} else {
														return sec;
													}
												}),
											},
										},
									],
								},
							};
						}
						return { ...clientWorkflow, workflow_submission };
					},
				),
			});

		case Actions.DELETE_WORKFLOW_TABLE_ROW:
			return (state = {
				...state,
				workflowsToManage: state?.workflowsToManage.map((workflow, i) => {
					if (workflow?.workflow_builder?.data[0].componentName == payload.workflowName) {
						return {
							...workflow,
							workflow_builder: {
								...workflow.workflow_builder,
								data: workflow.workflow_builder.data.map((data, index) => {
									if (index === 0) {
										return {
											...data,
											props: {
												...workflow.workflow_builder.data[0].props,
												sections:
													workflow.workflow_builder.data[0].props.sections?.map(
														(sec, i) => {
															if (i == payload?.sectionIndex) {
																return {
																	...sec,
																	tableRows:
																		sec?.tableRows?.filter(
																			(state, i) =>
																				i !=
																				payload.rowIndex,
																		),
																};
															} else {
																return sec;
															}
														},
													),
											},
										};
									} else {
										return data;
									}
								}),
							},
						};
					} else {
						return workflow;
					}
				}),
			});

		case Actions.WORKFLOW_TASK_CHANGE_HANDLER:
			return (state = {
				...state,
				workflowTask: {
					...state?.workflowTask,
					[payload.name]: payload.value,
				},
			});

		case Actions.EDIT_WORKFLOW_TASK_HANDLER:
			return (state = {
				...state,
				workflowTask: {
					...state?.workflowTask,
					...payload,
				},
			});

		case Actions.GET_ALL_WORKFLOW_TASKS: {
			const client_workflows = payload?.map((client_workflow) => {
				// const tasks = sortTasks(client_workflow.client_workflow_tasks);
				return {
					...client_workflow,
					// client_workflow_tasks: tasks,
				};
			});
			return (state = {
				...state,
				allWorkflowTasks: client_workflows,
			});
		}

		case Actions.UPDATE_CLIENT_WORKFLOW_TASKS:
			console.info('tasks', state?.allWorkflowTasks);
			return (state = {
				...state,
				allWorkflowTasks: state?.allWorkflowTasks?.map((item) => {
					if (item.id === payload.id) {
						return {
							...payload,
							client_workflow_tasks: item?.client_workflow_tasks?.map((task) => {
								return {
									...task,
									required_days: payload.required_days,
								};
							}),
						};
					}
					return item;
				}),
			});

		case Actions.SET_CLIENTS_FOR_WORKFLOW:
			return (state = {
				...state,
				workflowClients: payload,
			});

		case Actions.WORKFLOW_TABLE_VALUE_CHANGE_HANDLER:
			return (state = {
				...state,
				allWorkflowTasks: state?.allWorkflowTasks?.map((clientWorkflow) => {
					if (clientWorkflow.id == payload.client_workflow_id) {
						const updated_tasks = clientWorkflow?.client_workflow_tasks?.map((task) => {
							if (task?.id == payload?.id) {
								return {
									...task,
									[payload.indexName]: payload.value,
								};
							} else {
								return task;
							}
						});
						return {
							...clientWorkflow,
							client_workflow_tasks:
								payload.indexName == 'dependent_task_id'
									? sortTasks(updated_tasks)
									: updated_tasks,
						};
					}
					return clientWorkflow;
				}),
			});

		case Actions.DEACTIVATE_WORKFLOW_TABLE_VALUE_CHANGE_HANDLER:
			return (state = {
				...state,
				activeWorkflows: state?.activeWorkflows?.map((clientWorkflow) => {
					return {
						...clientWorkflow,
						client_workflow_tasks: clientWorkflow?.client_workflow_tasks?.map(
							(task) => {
								if (task?.id == payload?.id) {
									return {
										...task,
										[payload.indexName]: payload.value,
									};
								} else {
									return task;
								}
							},
						),
					};
				}),
			});

		case Actions.ADD_WORKFLOW_TASK:
			return (state = {
				...state,
				allWorkflowTasks: state?.allWorkflowTasks?.map((item) => {
					if (item.id === payload.client_workflow_id) {
						return {
							...item,
							client_workflow_tasks: [...item.client_workflow_tasks, payload],
						};
					}
					return item;
				}),
			});

		case Actions.RESET_WORKFLOW_STATE:
			return (state = initialState);

		case Actions.CLEAR_WORKFLOW_TASK:
			return (state = {
				...state,
				workflowTask: {
					title: '',
					description: '',
					budgeted_hours: undefined,
					assignee_user_id: null,
					dependent_task_id: '',
					task_type_id: 2,
					required_days: 0,
				},
			});

		case Actions.SET_TASK_RECURRENCE:
			return (state = {
				...state,
				taskRecurrence: [...state?.taskRecurrence, payload],
			});

		case Actions.DELETE_WORKFLOW_TASK:
			return (state = {
				...state,
				allWorkflowTasks: state?.allWorkflowTasks?.map((client_workflow) => {
					return {
						...client_workflow,
						client_workflow_tasks: client_workflow?.client_workflow_tasks
							?.filter((task) => task.id !== payload)
							?.map((task) => {
								if (task.dependent_task_id == payload) {
									return {
										...task,
										dependent_task_id: null,
									};
								}
								return task;
							}),
					};
				}),
			});

		case Actions.DELETE_DEACTIVATED_WORKFLOW_TASK:
			return (state = {
				...state,
				activeWorkflows: state?.activeWorkflows?.map((client_workflow) => {
					return {
						...client_workflow,
						client_workflow_tasks: client_workflow?.client_workflow_tasks?.filter(
							(task) => task.id !== payload,
						),
					};
				}),
			});

		case Actions.REMOVE_COLUMN:
			return (state = {
				...state,
				selectedWorkflow: {
					...state.selectedWorkflow,
					props: {
						...state.selectedWorkflow?.props,
						sections: state.selectedWorkflow?.props.sections?.map((sec, i) => {
							return {
								...sec,
								columns: sec.columns
									?.filter((col, i) => i !== payload.columnIndex)
									.map((col, columnIndex) => {
										return {
											...col,
											columnComponents: col?.columnComponents?.map(
												(compo) => {
													return {
														...compo,
														columnIndex: columnIndex,
													};
												},
											),
										};
									}),
							};
						}),
					},
				},
			});

		case Actions.SET_MANAGE_WORKFLOWS:
			return (state = {
				...state,
				workflowsToManage: payload.map((workflow) => {
					return {
						...workflow,
						start_date: null,
						repeat_type: null,
						repeat_interval: null,
					};
				}),
			});

		case Actions.GET_ACTIVE_WORKFLOWS:
			return (state = {
				...state,
				loading: false,
				activeWorkflows: payload.map((workflow) => {
					if (!workflow.is_active) {
						return {
							...workflow,
							start_date: null,
							repeat_type: null,
							repeat_interval: null,
						};
					}
					return workflow;
				}),
			});
		case Actions.ADD_WORKFLOWS_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.ADD_WORKFLOWS_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.ADD_WORKFLOWS:
			return (state = {
				...state,
				loading: false,
				workflowsToManage: state.workflowsToManage.map((workflow) => {
					if (workflow.workflow_id == payload.workflow_id) {
						return payload;
					}
					return workflow;
				}),
			});

		case Actions.SET_TASK_TEMPLATES_ARRAY:
			return (state = {
				...state,
				task_templates: payload,
			});
		case Actions.REMOVE_FROM_TASK_TASK_TEMPLATE:
			return (state = {
				...state,
				task_templates: state?.task_templates
					?.filter((item, index) => {
						const id = item.id || item['#id'];
						return payload != id;
					})
					?.map((item) => {
						if (item.dependent_task_id == payload) {
							item.dependent_task_id = null;
						}
						return item;
					}),
			});
		case Actions.TASK_TEMPLATES_CHANGE_HANDLER:
			return (state = {
				...state,
				task_templates: state?.task_templates?.map((task) => {
					const taskId = task.id;
					if (taskId == payload.taskId) {
						return {
							...task,
							[payload.name]: payload.value,
						};
					}
					return task;
				}),
			});
		case Actions.UPDATE_TASK_TEMPLATE:
			console.log(payload, state.task_templates, 'payload1234');
			var taskIndex = state.task_templates.findIndex((task) => task.id == payload.taskId);
			var tempArr = [...state.task_templates];

			tempArr[taskIndex] = payload.data;
			console.log(taskIndex, 'taskIndex123', tempArr);

			return (state = {
				...state,
				task_templates: [...tempArr],
			});

		case Actions.SET_WORKFLOW_CATEGORIES:
			return (state = {
				...state,
				workflowCategories: payload,
			});

		case Actions.SET_ALL_ARCHIEVED_WORKFLOWS:
			return (state = {
				...state,
				archievedWorkflows: payload,
			});
		case Actions.SET_ASSIGNED_WORKFLOW_CATEGORIES:
			return (state = {
				...state,
				assignedWorkflowCategories: payload,
			});

		case Actions.UPLOAD_WORKFLOW_ICON_START:
			return (state = {
				...state,
				iconLoading: true,
			});

		case Actions.UPLOAD_WORKFLOW_ICON:
			return (state = {
				...state,
				newWorkflowForm: {
					...state.newWorkflowForm,
					icon_image: payload,
				},
				iconLoading: false,
			});

		case Actions.UPLOAD_WORKFLOW_ICON_FAILED:
			return (state = {
				...state,
				iconLoading: false,
			});
		case Actions.SWAP_WORKFLOW_TASKS:
			return (state = {
				...state,
				workflowsToManage: state.workflowsToManage.map((workflow) => {
					return {
						...workflow,
						task_templates: [...payload],
					};
				}),
			});
		case Actions.SWAP_WORKFLOW_TASKS_IN_CLIENT_PROFILE:
			return (state = {
				...state,
				allWorkflowTasks: state?.allWorkflowTasks?.map((item) => {
					if (item.workflow_id === payload.id) {
						return {
							...item,
							client_workflow_tasks: payload.result,
						};
					} else {
						return item;
					}
				}),
			});
		case Actions.SWAP_WORKFLOW_TASKS_IN_WORKFLOW_BUILDER:
			return (state = {
				...state,
				task_templates: [...payload],
			});

		case Actions.DEACTIVATE_WORKFLOW_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.DEACTIVATE_WORKFLOW_END:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.DEACTIVATE_WORKFLOW_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.DRAFT_WORKFLOW_DELETE_START:
			return (state = {
				...state,
				loading: true,
			});
		case Actions.DRAFT_WORKFLOW_DELETE_END:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.DRAFT_WORKFLOW_DELETE_FAILED:
			return (state = {
				...state,
				loading: false,
			});
		case Actions.GET_PREBUILT_WORKFLOWS:
			return (state = {
				...state,
				prebuiltWorkflows: payload,
			});
		case Actions.GET_COMPANY_WORKFLOWS:
			return (state = {
				...state,
				companyWorkflows: payload,
			});
		// case Actions.GET_DRAFT_WORKFLOWS:
		// 	return (state = {
		// 		...state,
		// 		draftWorkflows: payload,
		// 	});
		case Actions.WORKFLOW_LIBRARY_LOADING:
			return (state = {
				...state,
				loading: payload,
			});
		case Actions.WORKFLOW_BUILDER_LOADING:
			return (state = {
				...state,
				builderLoading: payload,
			});
		case Actions.GET_SPECIFIC_WORKFLOW:
			return (state = {
				...state,
				newWorkflowForm: payload,
			});
		case Actions.RESET_WORKFLOW_FORM:
			return (state = {
				...state,
				newWorkflowForm: initialState.newWorkflowForm,
			});
		case Actions.GET_TASK_TEMPLATES:
			return (state = {
				...state,
				task_templates: payload,
			});
		case Actions.GET_WORKFLOW_BUILDER_DATA:
			return (state = {
				...state,
				workflowData: payload[0],
			});
		case Actions.SET_ACTIVE_TAB:
			return (state = {
				...state,
				activeTab: payload,
			});
		case Actions.HANDLE_WORKFLOW_ATTACHMENTS:
			return (state = {
				...state,
				selectedClientWorkflow: {
					...state.selectedClientWorkflow,
					attachment: payload,
				},
			});
		case Actions.UPDATE_COMPANY_WORKFLOWS:
			return (state = {
				...state,
				companyWorkflows: state.companyWorkflows.map((category) => {
					if (category.id === payload?.category_id) {
						return {
							...category,
							workflows: category?.workflows?.map((workflow) => {
								if (workflow?.id === payload?.id) {
									return {
										...payload,
										workflow_builder: workflow?.workflow_builder,
										task_templates: workflow?.task_templates,
									};
								} else return workflow;
							}),
						};
					} else return category;
				}),
			});
		case Actions.FILTER_COMPANY_WORKFLOWS:
			return (state = {
				...state,
				companyWorkflows: state.companyWorkflows.map((category) => {
					if (category.id === payload?.category_id) {
						return {
							...category,
							workflows: category?.workflows?.filter(
								(workflow) => workflow?.id !== payload?.id,
							),
						};
					} else return category;
				}),
			});
		default:
			return state;
	}
};

export default WorkflowBuilder;
