import { useState, useEffect } from 'react';
import { Reorder } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import * as ProfileActions from '../../redux/actions/company-profile';
import { CircularProgress, Tooltip } from '@mui/material';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import { TableItemWidth5 } from '../TableSpacing/TableSpacingStyles';
import TableRowItem from '../Others/TableRowItem';
import { ReactComponent as DragIcon } from '../../assets/icons/dragIndicator.svg';
import { ReactComponent as UnarchiveIcon } from '../../assets/icons/ArrowBendUpLeft.svg';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';

function ArchiveCustomFieldsTable() {
	const dispatch = useDispatch();
	const customFieldDefinitions = useSelector(
		(state) => state.profileReducer.customFieldDefinitions,
	);
	const activeFieldsCount = customFieldDefinitions?.reduce(
		(acc, field) => acc + !field?.deleted,
		0,
	);
	const [customFields, setCustomFields] = useState([]);
	const [loadingIndex, setLoadingIndex] = useState(null);

	const fetchCustomFields = () => {
		dispatch(ProfileActions.fetchCustomFieldDefinitions()).then((response) => {
			dispatch(ProfileActions.setCustomFieldDefinitions(response));
		});
	};

	const handleUnArchiveStatus = (field, index) => {
		setLoadingIndex(index);
		dispatch(ProfileActions.unArchiveCustomFieldDefinitions(field?.id))
			.then(() => {
				fetchCustomFields();
				setLoadingIndex(null);
			})
			.catch((error) => {
				setLoadingIndex(null);
				dispatch(setSnackBarIsOpen(false, true, error.message));
			});
	};

	useEffect(() => {
		if (customFieldDefinitions?.length) {
			const archiveCustomFields = customFieldDefinitions?.filter((field) => field?.deleted);
			setCustomFields(archiveCustomFields);
		}
	}, [customFieldDefinitions]);

	return (
		<div>
			<TableHeader ClassName='new-table-header' border={false}>
				<TableHeaderItem
					labelClass='BodyTwoLight'
					label='Custom Field Name'
					width={TableItemWidth5}
				/>
				<TableHeaderItem
					labelClass='BodyTwoLight'
					label='Unarchive'
					width={TableItemWidth5}
				/>
			</TableHeader>

			<Reorder.Group
				axis='y'
				values={customFields}
				onReorder={setCustomFields}
				style={{
					listStyle: 'none',
					padding: '0',
					margin: '0',
					cursor: 'grab',
				}}
			>
				{customFields?.length > 0 ? (
					customFields?.map((field, index) => {
						return (
							<Reorder.Item
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								key={status?.id}
								value={status}
							>
								<div className='new-table-row d-flex align-items-center'>
									<TableRowItem
										ClassName='text-left'
										textClass='BodyTwoLight'
										input={true}
										width={TableItemWidth5}
									>
										<div
											className='d-flex align-items-center'
											style={{ gap: '8px' }}
										>
											<div>
												<DragIcon />
											</div>
											<div className='d-flex align-items-center'>
												<span className='BodyTwoLight dark-text m-0'>
													{field?.name}
												</span>
											</div>
										</div>
									</TableRowItem>
									<TableRowItem
										ClassName='text-left align-self-start'
										input={true}
										width={TableItemWidth5}
									>
										<Tooltip
											title='You already have 10 active statuses'
											disableHoverListener={activeFieldsCount < 10}
										>
											{loadingIndex === index ? (
												<CircularProgress
													size={20}
													sx={{ color: '#fb7a03' }}
													disableShrink
												/>
											) : (
												<UnarchiveIcon
													className={`pointer ${
														activeFieldsCount >= 10 ? 'opacity-50' : ''
													}`}
													onClick={
														activeFieldsCount >= 10
															? undefined
															: () =>
																	handleUnArchiveStatus(
																		field,
																		index,
																	)
													}
												/>
											)}
										</Tooltip>
									</TableRowItem>
								</div>
							</Reorder.Item>
						);
					})
				) : (
					<div className='pt-3 BodyTwoLight'>There is no archive custom field</div>
				)}
			</Reorder.Group>
		</div>
	);
}

export default ArchiveCustomFieldsTable;
