import { useState } from 'react';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ClearIcon from '@mui/icons-material/Clear';
import { CircularProgress, Menu, MenuItem } from '@mui/material';
import { useSelector } from 'react-redux';

import { TASK__DISPLAY_STATUSES, TASK_STATUSES } from '../../utilities/utilities';

function ChangeCustomBlockTaskStatus({
	label,
	id,
	handleCustomBlockStatus,
	handleClearStatus,
	buttonLoading,
}) {
	const isBlockedStatus = Boolean(id == TASK_STATUSES.BLOCKED);
	const [anchorStatusChanegEl, setAnchorStatusChangeEl] = useState(null);
	const statuses = useSelector((state) => state.workSpaceReducer.customTaskStatus);

	const statusOptions = statuses
		?.filter((status) => status?.tenant_id !== null && !status?.deleted)
		?.map((status) => {
			return {
				id: status.id,
				label: status?.label,
				tenant_id: status?.tenant_id,
				color: status.color,
			};
		});

	const handleStatusClick = () => {
		if (!isBlockedStatus) {
			handleClearStatus();
			return;
		}
		handleCustomBlockStatus({ id: id, label: label });
	};

	const handleStatusChange = (event) => {
		event.stopPropagation();
		setAnchorStatusChangeEl(anchorStatusChanegEl ? null : event.currentTarget);
	};

	const handleCloseStatusChangeDropDown = () => {
		setAnchorStatusChangeEl(null);
	};

	return (
		<div className='d-flex align-items-center' style={{ marginRight: '16px' }}>
			<div
				className='d-flex align-items-center justify-content-center pointer'
				style={{
					backgroundColor: '#FB7A03',
					borderRadius: '3px',
					minWidth: '100px',
					height: '36px',
					color: 'white',
					padding: '0 10px',
				}}
				onClick={handleStatusClick}
			>
				{buttonLoading ? (
					<CircularProgress
						size={20}
						sx={{
							color: 'white',
						}}
						disableShrink
					/>
				) : (
					<div
						className='d-flex align-items-center w-100'
						style={{
							gap: 12,
							justifyContent:
								statusOptions?.length == 0 || id == 'unblock'
									? 'center'
									: 'space-between',
						}}
					>
						<span className='BodyOneBold pointer'>{label}</span>
						{statusOptions?.length > 0 && (
							<>
								{isBlockedStatus ? (
									<span onClick={handleStatusChange}>
										<KeyboardArrowUpIcon />
									</span>
								) : (
									id !== 'unblock' && <ClearIcon fontSize='small' />
								)}
							</>
						)}
					</div>
				)}
			</div>
			<Menu
				open={Boolean(anchorStatusChanegEl)}
				onClose={handleCloseStatusChangeDropDown}
				anchorEl={anchorStatusChanegEl}
				transition
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				style={{
					marginBottom: '5px',
				}}
			>
				{statusOptions?.map((status) => {
					return (
						<MenuItem
							key={status?.id}
							sx={{
								'&:hover': {
									backgroundColor: '#fb7a03',
									color: 'white',
								},
							}}
						>
							<div className='d-flex align-items-center justify-content-between'>
								<div
									className='mb-1 mr-1'
									style={{
										width: '10px',
										height: '10px',
										borderRadius: '50%',
										backgroundColor: status?.color,
									}}
								></div>
								<span
									className='BodyTwoLight'
									name={status?.label}
									value={status?.id}
									onClick={() => {
										handleCustomBlockStatus({
											id: status?.id,
											label: status?.label,
										});
										handleCloseStatusChangeDropDown();
									}}
								>
									{TASK__DISPLAY_STATUSES[status?.label] || status?.label}
								</span>
							</div>
						</MenuItem>
					);
				})}
			</Menu>
		</div>
	);
}

export default ChangeCustomBlockTaskStatus;
