import { ReactComponent as ArrowExpand } from '../assets/icons/ArrowsOutLineVertical.svg';
import { ReactComponent as History } from '../assets/icons/Clock.svg';
import { ReactComponent as NewChat } from '../assets/icons/NotePencil.svg';
import { ReactComponent as Close } from '../assets/icons/Close.svg';
import { ReactComponent as PaperClip } from '../assets/icons/Paperclip.svg';
import { ReactComponent as Search } from '../assets/icons/MagnifyingGlass.svg';
import { ReactComponent as Insight } from '../assets/icons/ChartBar.svg';
import { ReactComponent as Boost } from '../assets/icons/Rocket.svg';
import { ReactComponent as Microphone } from '../assets/icons/Microphone.svg';
import * as WorkspaceActions from '../redux/actions/dashboard/workspaces-actions';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ChatBotMessages from './ChatBotMessages';
import moment from 'moment';
import { useLocalStorage } from '../hooks/useLocalStorage';

function ChatBotModal({ onClickAway }) {
	const dispatch = useDispatch();
	const [input, setInput] = useState('');
	const [messages, setMessages] = useState([]);
	const [loading, setLoading] = useState(false);
	const [activeTab, setActiveTab] = useState('explore');
	const user = useSelector((state) => state.authReducer.user);
	const chatBotHistory = useLocalStorage('chat_history');
	const storedChatHistory = chatBotHistory.getLocalStorage();

	const handleActivetab = (event) => {
		const { id } = event.currentTarget;
		setActiveTab(id);
	};

	const handleNewChat = () => {
		setMessages([]);
		setInput('');
		setActiveTab('explore');
		if (storedChatHistory) {
			const chatHistory = storedChatHistory;
			if (chatHistory[user?.userId]) {
				delete chatHistory[user?.userId];
				chatBotHistory.setLocalStorage(chatHistory);
			}
		}
	};

	const handleInitiateAiChat = (e) => {
		if (e.key === 'Enter' && input.trim()) {
			e.preventDefault();
			const timestamp = moment().valueOf();
			setMessages((prevMessages) => [
				...prevMessages,
				{ message: input.trim(), type: 'user', createdAt: timestamp },
			]);
			setInput('');
			setLoading(true);
			dispatch(WorkspaceActions.initiateAiChat(input.trim()))
				.then((response) => {
					setMessages((prevMessages) => [
						...prevMessages,
						{
							message: response.message,
							intent: response.intent,
							data: response.data,
							type: 'bot',
							createdAt: timestamp,
						},
					]);
					setLoading(false);
				})
				.catch((error) => {
					dispatch(WorkspaceActions.setSnackBarIsOpen(false, true, error.message));
					setMessages((prevMessages) => [
						...prevMessages,
						{
							message:
								'Sorry, I am not able to process your request at the moment. Please try again later.',
							type: 'bot',
							createdAt: timestamp,
						},
					]);
					setLoading(false);
				});
		}
	};

	const handleQuestionClick = (event) => {
		if (event.target.classList.contains('suggestion-question')) {
			const question = event.target.innerText;
			const timestamp = moment().valueOf();
			setMessages((prevMessages) => [
				...prevMessages,
				{ message: question, type: 'user', createdAt: timestamp },
			]);
			setLoading(true);
			dispatch(WorkspaceActions.initiateAiChat(question))
				.then((response) => {
					setMessages((prevMessages) => [
						...prevMessages,
						{
							message: response.message,
							intent: response.intent,
							data: response.data,
							type: 'bot',
							createdAt: timestamp,
						},
					]);
					setLoading(false);
				})
				.catch((error) => {
					dispatch(WorkspaceActions.setSnackBarIsOpen(false, true, error.message));
					setMessages((prevMessages) => [
						...prevMessages,
						{
							message:
								'Sorry, I am not able to process your request at the moment. Please try again later.',
							type: 'bot',
							createdAt: timestamp,
						},
					]);
					setLoading(false);
				});
		}
	};

	useEffect(() => {
		if (storedChatHistory) {
			setMessages(storedChatHistory[user?.userId] || []);
		}
	}, [user?.userId]);

	useEffect(() => {
		if (messages.length > 0) {
			const chatHistory = storedChatHistory ? storedChatHistory : {};
			chatHistory[user?.userId] = messages;
			chatBotHistory.setLocalStorage(chatHistory);
		}
	}, [messages, user?.userId]);

	return (
		<div className='chat-bot-modal'>
			<div className='chat-bot-conatiner'>
				<div className='bot-header'>
					<div className='d-flex align-items-center'>
						<div className='bot-logo mr-2'></div>
						<span className='primary-text HeadlineThreeBook'>Levvy AI Assistant</span>
					</div>
					<div className='d-flex align-items-center' style={{ gap: '16px' }}>
						{/* <ArrowExpand className='pointer' /> */}
						<History className='pointer' />
						<NewChat
							className='pointer'
							style={{ stroke: '#333333' }}
							onClick={messages?.length > 0 && !loading ? handleNewChat : () => {}}
						/>
						<Close onClick={onClickAway} className='pointer' />
					</div>
				</div>
				<ChatBotMessages
					messages={messages}
					responseLoading={loading}
					handleQuestionClick={handleQuestionClick}
				/>
				<div className='bot-footer d-flex flex-column justify-content-between'>
					<textarea
						className='w-100 text-input'
						rows={3}
						placeholder='Ask anything'
						value={input}
						name='name'
						onChange={(e) => setInput(e.target.value)}
						onKeyDown={handleInitiateAiChat}
						disabled={loading}
					/>

					<div className='d-flex aling-items-center justify-content-between'>
						<div style={{ gap: '8px' }} className='d-flex'>
							<div
								style={{
									width: '26px',
									height: '26px',
									borderRadius: '50%',
									border: '1px solid #EDEDED',
								}}
								className='d-flex align-items-center justify-content-center'
							>
								<PaperClip width={'16px'} height={'16px'} />
							</div>
							<div
								id='explore'
								className={`chat-tab pointer ${
									activeTab === 'explore' ? 'active-chat-tab' : ''
								}`}
								onClick={handleActivetab}
							>
								<Search
									style={{
										stroke: activeTab === 'explore' ? 'white' : '#696F6C',
									}}
								/>
								<span className='BodyTwoLight mt-1'>Explore</span>
							</div>
							<div
								id='smart-assist'
								className={`chat-tab pointer ${
									activeTab === 'smart-assist' ? 'active-chat-tab' : ''
								}`}
								onClick={handleActivetab}
							>
								<Boost
									style={{
										stroke: activeTab === 'smart-assist' ? 'white' : '#696F6C',
									}}
								/>
								<span className='BodyTwoLight mt-1'>Smart Assist</span>
							</div>{' '}
							<div
								id='insights'
								className={`chat-tab pointer ${
									activeTab === 'insights' ? 'active-chat-tab' : ''
								}`}
								onClick={handleActivetab}
							>
								<Insight
									style={{
										stroke: activeTab === 'insights' ? 'white' : '#696F6C',
									}}
								/>
								<span className='BodyTwoLight mt-1'>Insights</span>
							</div>
						</div>
						<div
							style={{
								width: '26px',
								height: '26px',
								borderRadius: '50%',
								border: '1px solid #EDEDED',
							}}
							className='d-flex align-items-center justify-content-center'
						>
							<Microphone width={'16px'} height={'16px'} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChatBotModal;
