import React, { useState } from 'react';
import FormButtonOne from '../Buttons/FormButtonOne';
import * as ProfileActions from '../../redux/actions/company-profile';
import { useDispatch } from 'react-redux';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';

function ArchiveCustomFieldModal({ toggleModal, customFieldId }) {
    console.log(customFieldId, 'customFieldId');
	const dispatch = useDispatch();
	const [archiveLoading, setArchiveLoading] = useState(false);

	const fetchCustomFields = () => {
		dispatch(ProfileActions.fetchCustomFieldDefinitions())
			.then((response) => {
				dispatch(ProfileActions.setCustomFieldDefinitions(response));
			})
			.catch((error) => {
				dispatch(setSnackBarIsOpen(false, true, error.message));
			});
	};
	const handleArchiveStatus = () => {
		setArchiveLoading(true);
		dispatch(ProfileActions.archiveCustomFieldDefinitions(customFieldId))
			.then(() => {
				setArchiveLoading(false);
				fetchCustomFields();
				toggleModal();
			})
			.catch((error) => {
				dispatch(setSnackBarIsOpen(false, true, error.message));
				setArchiveLoading(false);
			});
	};
	return (
		<div className='new-modal' style={{ width: '540px' }}>
			<div className='new-modal-content p-4'>
				<div className='d-flex justify-content-between'>
					<span className='HeadlineTwoBold dark-text'>Archive custom field?</span>
				</div>

				<div className='improved-input-designs'>
					<label>
						Are you sure you want to delete this custom field? Deleting this field will
						permanently remove it from all client profiles, including any data that has
						already been entered for this field.
					</label>
				</div>
			</div>
			<div className='new-modal-footer rounded-bottom'>
				<FormButtonOne
					onClick={toggleModal}
					type='button'
					className='rounded-btn secondary-btn-light'
				>
					Cancel
				</FormButtonOne>
				<FormButtonOne
					type='button'
					className='rounded-btn primary-btn'
					onClick={handleArchiveStatus}
					loading={archiveLoading}
				>
					Confirm
				</FormButtonOne>
			</div>
		</div>
	);
}

export default ArchiveCustomFieldModal;
