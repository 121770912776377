import axios from 'axios';
import { secure_instance } from '../../axios/axios-config';
import { setSnackBarIsOpen } from './dashboard/workspaces-actions';

export const GET_USERS_END = '[TIME_REPORTS VIEW] GET_USERS_END';
export const GET_USERS_FAILED = '[TIME_REPORTS VIEW] GET_USERS_FAILED';
export const GET_USERS_START = '[TIME_REPORTS VIEW] GET_USERS_START';
export const GET_CLIENTS_START = '[TIME_REPORTS VIEW] GET_CLIENTS_START';
export const GET_CLIENTS_FAILED = '[TIME_REPORTS VIEW] GET_CLIENTS_FAILED';
export const GET_CLIENTS_END = '[TIME_REPORTS VIEW] GET_CLIENTS_END';
export const GET_USERS_PROFILES_START = '[TIME_REPORTS VIEW] GET_USERS_PROFILES_START';
export const GET_USERS_PROFILES_END = '[TIME_REPORTS VIEW] GET_USERS_PROFILES_END';
export const GET_USERS_PROFILES_FAILED = '[TIME_REPORTS VIEW] GET_USERS_PROFILES_FAILED';
export const GET_CLIENT_PROFILES_START = '[TIME_REPORTS VIEW] GET_CLIENT_PROFILES_START';
export const GET_CLIENT_PROFILES_END = '[TIME_REPORTS VIEW] GET_CLIENT_PROFILES_END';
export const GET_CLIENT_PROFILES_FAILED = '[TIME_REPORTS VIEW] GET_CLIENT_PROFILES_FAILED';
export const GET_USER_DETAILS_START = '[TIME_REPORTS VIEW] GET_USER_DETAILS_START';
export const GET_USER_DETAILS_END = '[TIME_REPORTS VIEW] GET_USER_DETAILS_END';
export const GET_USER_DETAILS_FAILED = '[TIME_REPORTS VIEW] GET_USER_DETAILS_FAILED';
export const UPDATE_USER_DETAILS = '[TIME_REPORTS VIEW] UPDATE_USER_DETAILS';
export const GET_USER_ROLES_END = '[TIME_REPORTS VIEW] GET_USER_ROLES_END';
export const UPDATE_MEETING_TASK_LOGGED_HOURS =
	'[TIME_REPORTS view] UPDATE_MEETING_TASK_LOGGED_HOURS';
export const GET_CLIENT_DETAILS_START = '[TIME_REPORTS VIEW] GET_CLIENT_DETAILS_START';
export const GET_CLIENT_DETAILS_END = '[TIME_REPORTS VIEW] GET_CLIENT_DETAILS_END';
export const GET_CLIENT_DETAILS_FAILED = '[TIME_REPORTS VIEW] GET_CLIENT_DETAILS_FAILED';
export const SET_USER_BUDGETED_HOURS = '[TIME_REPORTS VIEW] SET_USER_BUDGETED_HOURS';
export const UPDATE_CLIENT_DETAILS = '[TIME_REPORTS VIEW] UPDATE_CLIENT_DETAILS';
export const SET_TASKS_LOADING = '[TIME_REPORTS VIEW] SET_TASKS_LOADING';
export const GET_TASKS_END = '[TIME_REPORTS VIEW] GET_TASKS_END';
export const UPDATE_LOGGED_TIME = '[TIME_REPORTS VIEW] UPDATE_LOGGED_TIME';
export const SET_LOOGED_TIME = '[TIME_REPORTS VIEW] SET_LOOGED_TIME';
export const SET_INITIAL_LOADING = '[TIME_REPORTS view] SET_INITIAL_LOADING';
export const SET_TIME_REPORTS_LOADING = '[TIME_REPORTS VIEW] SET_TIME_REPORTS_LOADING';
export const RESET_TASKS = '[TIME_REPORTS VIEW] RESET_TASKS';
const CancelToken = axios.CancelToken;

const clientStatsCancel = undefined;
export const getClientsStats = (startDate, endDate) => {
	return async () => {
		if (clientStatsCancel) {
			clientStatsCancel();
		}
		try {
			const request = await secure_instance.request({
				url: `/v1/time_reports/client_stats/time_logs/?start_date=${startDate}&end_date=${endDate}`,
				method: 'Get',
				cancelToken: new CancelToken((c) => {
					userStatsCancel = c;
				}),
			});

			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

let userStatsCancel = undefined;
export const getUserStats = (startDate, endDate, filters) => {
	return async (dispatch) => {
		if (userStatsCancel) {
			userStatsCancel();
		}
		try {
			const usersQuery = filters?.users || [];
			const teamsQuery = filters?.teams || [];

			dispatch({
				type: GET_USERS_START,
			});
			const request = await secure_instance.request({
				url: `/v1/time_reports/user_stats/?start_date=${startDate}&end_date=${endDate}&user_ids=${JSON.stringify(
					usersQuery,
				)}&team_ids=${JSON.stringify(teamsQuery)}`,
				method: 'Get',
				cancelToken: new CancelToken((c) => {
					userStatsCancel = c;
				}),
			});
			dispatch({
				type: GET_USERS_END,
				payload: request.data,
			});
			return request.data;
		} catch (error) {
			dispatch({
				type: GET_USERS_FAILED,
			});
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const getClientStats = (startDate, endDate) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: GET_CLIENTS_START,
			});

			const request = await secure_instance.request({
				url: `/v1/time_reports/client_stats/?start_date=${startDate}&end_date=${endDate}`,
				method: 'Get',
			});
			dispatch({
				type: GET_CLIENTS_END,
				payload: request.data,
			});
		} catch (e) {
			console.info(e);
			dispatch({
				type: GET_CLIENTS_FAILED,
			});
		}
	};
};
export const getUserProfiles = () => {
	return async (dispatch) => {
		try {
			dispatch({
				type: GET_USERS_PROFILES_START,
			});
			const request = await secure_instance.request({
				url: `/v1/user_profiles/`,
				method: 'Get',
			});
			dispatch({
				type: GET_USERS_PROFILES_END,
				payload: request.data,
			});
		} catch (e) {
			console.info(e);
			dispatch({
				type: GET_USERS_PROFILES_FAILED,
			});
		}
	};
};
export const getClientProfiles = () => {
	return async (dispatch) => {
		try {
			dispatch({
				type: GET_CLIENT_PROFILES_START,
			});
			const request = await secure_instance.request({
				url: `/v1/clients/`,
				method: 'Get',
			});
			dispatch({
				type: GET_CLIENT_PROFILES_END,
				payload: request.data,
			});
		} catch (e) {
			console.info(e);
			dispatch({
				type: GET_CLIENT_PROFILES_FAILED,
			});
		}
	};
};

export const getUserDetails = (userId, startDate, endDate, loading) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: GET_USER_DETAILS_START,
				payload: loading,
			});
			const request = await secure_instance.request({
				// url: `/v1/user_profiles/${userId}?expand=teams(selectNone).clients(selectedFields).client_workflows(selectNone).workflow(selectName).client_workflow_tasks(sumBugetedHours)&start_date=${startDate}&end_date=${endDate}`,
				url: `/v1/time_reports/user_details/?user_id=${userId}&start_date=${startDate}&end_date=${endDate}`,
				method: 'Get',
			});
			console.log(request.data, 'requested response');
			dispatch({
				type: GET_USER_DETAILS_END,
				payload: request.data,
			});
		} catch (e) {
			console.info(e);
			dispatch({
				type: GET_USER_DETAILS_FAILED,
			});
		}
	};
};
export const getClientDetails = (clientId, startDate, endDate, loading) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: GET_CLIENT_DETAILS_START,
				payload: loading,
			});
			// /v1/user_profiles/${userId}?expand=teams(selectedFields).clients(selectedFields).client_workflows(selectedFields).workflow.client_workflow_tasks.tasks(sumBugetedHours).task_timers(sumConsumedTime)&start_date=${startDate}&end_date=${endDate}
			const request = await secure_instance.request({
				url: `/v1/time_reports/client_details/?start_date=${startDate}&end_date=${endDate}&client_id=${clientId}`,
				method: 'Get',
			});
			const response = await secure_instance.request({
				url: '/v1/user_roles/?expand=role_scopes',
				method: 'Get',
			});
			console.log(request.data, response, 'requested response');
			dispatch({
				type: GET_CLIENT_DETAILS_END,
				payload: {
					data: request.data,
					user_roles: response.data,
				},
			});
		} catch (e) {
			console.info(e);
			dispatch({
				type: GET_CLIENT_DETAILS_FAILED,
			});
		}
	};
};

export const getUserRole = (id) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: GET_USER_DETAILS_START,
			});
			const request = await secure_instance.request({
				url: '/v1/user_roles/?expand=role_scopes',
				method: 'Get',
			});
			const roles = request.data.filter((item) => {
				if (item.user_id == id) {
					return item;
				}
			});
			console.log(roles, 'requestedResponse');
			dispatch({
				type: GET_USER_ROLES_END,
				payload: roles,
			});
		} catch (e) {
			console.info(e);
			dispatch({
				type: GET_USER_DETAILS_FAILED,
			});
		}
	};
};
export const setBudgetedHours = (budgeted_hours) => {
	return async (dispatch) => {
		dispatch({
			type: SET_USER_BUDGETED_HOURS,
			payload: budgeted_hours,
		});
	};
};
export const getTasksDetails = (
	client_workflow_id,
	client_id,
	assignee_user_id,
	is_meeting_task,
	start_date,
	end_date,
	add_only_billable,
) => {
	return async (dispatch) => {
		try {
			dispatch({
				type: SET_TASKS_LOADING,
				payload: true,
			});
			const request = await secure_instance.request({
				url: `/v1/time_reports/task_details?client_workflow_id=${client_workflow_id}&assignee_user_id=${assignee_user_id}&start_date=${start_date}&end_date=${end_date}&client_id=${client_id}&is_meeting_task=${is_meeting_task}&add_only_billable=${add_only_billable}`,
				method: 'Get',
			});
			dispatch({
				type: GET_TASKS_END,
				payload: request.data,
			});
			dispatch({
				type: SET_TASKS_LOADING,
				payload: false,
			});
		} catch (e) {
			console.info(e);
			dispatch({
				type: SET_TASKS_LOADING,
				payload: false,
			});
			dispatch(
				setSnackBarIsOpen(
					true,
					true,
					e?.response?.data?.message || e?.message || 'Something went wrong',
				),
			);
		}
	};
};
export const resetTasks = () => {
	return {
		type: RESET_TASKS,
	};
};
export const handleLoggedTime = (value, task_id) => {
	return {
		type: UPDATE_LOGGED_TIME,
		payload: {
			value: value,
			task_id: task_id,
		},
	};
};

export const setInitialLoading = (loading) => {
	return {
		type: SET_INITIAL_LOADING,
		payload: loading,
	};
};

export const setTimeReportsLoading = (payload) => {
	return {
		type: SET_TIME_REPORTS_LOADING,
		payload: payload,
	};
};

export const getClientTimeExportReports = (
	client_id,
	group_by = 'workflow',
	start_date,
	end_date,
) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/time_reports/client_details/exports/?start_date=${start_date}&end_date=${end_date}&&group_by=${group_by}&client_id=${client_id}`,
				method: 'Get',
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};

export const getUserTimeExportReports = (user_id, group_by = 'workflow', start_date, end_date) => {
	return async () => {
		try {
			const request = await secure_instance.request({
				url: `/v1/time_reports/user_details/exports/?start_date=${start_date}&end_date=${end_date}&&group_by=${group_by}&user_id=${user_id}`,
				method: 'Get',
			});
			return request.data;
		} catch (error) {
			throw new Error(
				error?.response?.data?.message || error?.message || 'Something went wrong',
			);
		}
	};
};
