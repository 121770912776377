import Sidebar from '../components/sidebar/SideBar';
import TimeReportsUsersV2 from '../components/TimeReportsV2/TimeReportsUsersV2';
import TimeReportsClientsV2 from '../components/TimeReportsV2/TimeReportsClientsV2';
import TimeReportsTopBarV2 from '../components/TimeReportsV2/TimeReportsTopBarV2';
import TimeReportsDateV2 from '../components/TimeReportsV2/TimeReportsDateV2';
import AlertBar from '../components/sub_components/AlertBar';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import NewSidebarItem from '../components/sidebar/NewSidebarItem';

function TimeReportsV2({ match }) {
	const { search, pathname } = useLocation();
	const history = useHistory();
	const activeTab = pathname == '/time-reports/users' ? 1 : 2;
	const searchParams = new URLSearchParams(search);

	const naviagteToUsers = () => () =>
		history.push(`/time-reports/users?${searchParams.toString()}`);
	const naviagteToClients = () => () =>
		history.push(`/time-reports/clients?${searchParams.toString()}`);

	return (
		<div className='AppBody'>
			<Sidebar title='Time Reports'>
				<div className='new-sidebar-content-wrapper'>
					<div className='User-filters'>
						<div className='new-side-menu BodyTwoLight-bold m-0 d-flex flex-column align-items-start'>
							<div
								style={{ padding: '8px' }}
								className={`w-100 main-sidebar-item ${
									activeTab == 1 ? 'prev' : ''
								} `}
							></div>
							<NewSidebarItem
								key={'users'}
								uniqueId={'users'}
								label={'Users'}
								isActive={activeTab == 1}
								setActiveTab={naviagteToUsers}
							/>
							<div
								style={{ padding: '8px' }}
								className={`w-100 main-sidebar-item ${
									activeTab == 2 ? 'prev' : ''
								}`}
							></div>
							<NewSidebarItem
								key={'clients'}
								uniqueId={'clients'}
								label={'Clients'}
								isActive={activeTab == 2}
								setActiveTab={naviagteToClients}
							/>
							<div
								style={{ padding: '8px' }}
								className={`w-100 main-sidebar-item`}
							></div>
						</div>
					</div>
				</div>
			</Sidebar>
			<div className='content-container'>
				<AlertBar />
				<div className='timeReports-main time-reports-main w-100'>
					<div className='time-reports-content'>
						<TimeReportsTopBarV2 timereports={'Time Reports'} />
						<TimeReportsDateV2 />
						<div
							className='d-flex flex-column'
							style={{
								gap: '3em',
							}}
						>
							<Switch>
								<Redirect exact from={match.path} to={`${match.path}/users`} />

								<Route
									path={`${match.path}/users`}
									render={() => <TimeReportsUsersV2 sectionId='users' />}
								/>
								<Route
									path={`${match.path}/clients`}
									render={() => <TimeReportsClientsV2 sectionId='clients' />}
								/>
							</Switch>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default TimeReportsV2;
