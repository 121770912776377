import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '../sidebar/SideBar';
import { getUserSettings } from '../../hooks/getUserSettings';
import DropDown from '../Inputs/DropDown';
import { getTimeRange, TIME_RANGE_OPTIONS } from '../../utilities/TimeRangeUtils';
import React, { useEffect, useMemo, useState } from 'react';
import { roundUpConsumedTimeInDecimal } from '../../utilities/utilities';
import * as TimeReportsActions from '../../redux/actions/time-reports-actions-v2';
import * as workspaceActions from '../../redux/actions/dashboard/workspaces-actions';
import SkeletonLoading from '../Others/SkeletonLoading';
import { useHistory } from 'react-router';
import { CSVLink } from 'react-csv';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import NewDateRangePicker from '../Inputs/NewDateRangePicker';

const UserTableHeader = [
	{ label: 'User', key: 'name' },
	{ label: 'Client', key: 'client_name' },
	{ label: 'Logged Time', key: 'total_logged_hours' },
];

function ExportUsersTimeLoggedForClients() {
	const history = useHistory();
	const dispatch = useDispatch();

	const [clientData, setClientData] = useState([]);
	const [dateRanges, setDateRanges] = useState(null);
	const [dateRange, setDateRange] = useState(TIME_RANGE_OPTIONS[2].value);
	const [loadingClientData, setLoadingClientData] = useState(true);

	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);

	const totalConsumedTimeSum = useMemo(() => {
		if (!clientData?.length) return 0;

		return roundUpConsumedTimeInDecimal(
			clientData.reduce((acc, userTimeData) => {
				return Number(acc) + Number(userTimeData.total_logged_hours);
			}, 0),
		);
	}, [clientData]);

	const navigateToTimeReports = () => {
		history.push('/time-reports/clients');
	};

	const onTimeChange = (event) => {
		setDateRange(event.target.value);
	};

	const loadData = () => {
		setLoadingClientData(true);
		dispatch(
			TimeReportsActions.getClientsStats(
				dateRanges.from.format('YYYY-MM-DD'),
				dateRanges.to.format('YYYY-MM-DD'),
			),
		)
			.then((result) => {
				setClientData(result);
				setLoadingClientData(false);
			})
			.catch((error) => {
				dispatch(workspaceActions.setSnackBarIsOpen(false, true, error?.message));
				setLoadingClientData(false);
			});
	};

	useEffect(() => {
		if (dateRanges) loadData();
	}, [dateRanges]);

	useEffect(() => {
		const selectedDateRanges = getTimeRange(dateRange);
		if (selectedDateRanges) setDateRanges(selectedDateRanges);
	}, [dateRange]);

	return (
		<div className='AppBody'>
			<Sidebar title='Time Reports'>
				<div className='SidebarContentWrapper'>
					<div className='User-filters'></div>
				</div>
			</Sidebar>
			<div className='time-report-preview'>
				<div className='time-report-preview-content-wrapper'>
					<div className='py-2 d-flex flex-row align-items-center pointer primary-text'>
						<ArrowLeftIcon />
						<h2 onClick={navigateToTimeReports} className='HeadlineThreeBook text-left'>
							Time Reports
						</h2>
					</div>

					<div
						className='d-flex flex-column'
						style={{
							background: 'white',
							padding: '18px',
							borderRadius: '4px',
							gap: '24px',
						}}
					>
						<div className='w-100 d-flex justify-content-between'>
							<span className='HeadlineThreeBold'>User Time Logged for Clients</span>

							<SkeletonLoading
								loading={loadingClientData}
								variant='button'
								className='rounded-1'
								width='15%'
								height={40}
							>
								<button className='rounded-button-1'>
									<CSVLink
										data={clientData}
										headers={UserTableHeader}
										filename={`${'user-time-logged-for-clients-'}_${dateRanges?.from?.format(
											date_format,
										)} - ${dateRanges?.to?.format(date_format)}.csv`}
										className=' text-white text-decoration-none'
									>
										Download
									</CSVLink>
								</button>
							</SkeletonLoading>
						</div>

						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								borderRadius: '4px',
								padding: '18px',
								gap: '24px',
								background: '#F7F9FA',
								width: '100%',
							}}
						>
							<div
								className='w-100 d-flex'
								style={{ gap: '24px', background: '#F7F9FA' }}
							>
								<div className='improved-input-designs text-left w-25'>
									<label>Date Range</label>
									<DropDown
										normalDropDown
										style={{
											height: '30px',
											borderRadius: '4px',
											minWidth: '200px',
										}}
										onChange={onTimeChange}
										options={TIME_RANGE_OPTIONS}
										value={dateRange}
										disableIcon
										disableNull
									/>
								</div>

								{dateRange === TIME_RANGE_OPTIONS[0].value && (
									<div className='d-flex w-25' style={{ gap: '24px' }}>
										<NewDateRangePicker
											label='Select Date Ranges'
											dateRanges={dateRanges}
											applyDates={(dates) => {
												setDateRanges(dates);
											}}
										/>
									</div>
								)}
							</div>

							{dateRanges && (
								<div className='date-range-bar d-flex justify-content-between'>
									<div className='HeadlineTwoBold'>
										{`${dateRanges.from.format(date_format)} -
										${dateRanges.to.format(date_format)}`}
									</div>

									<SkeletonLoading
										loading={loadingClientData}
										className='rounded-1'
										width='20%'
										height={34}
										variant='rectangular'
									>
										<div className='HeadlineTwoBold'>
											{`Total Hours ${totalConsumedTimeSum}`}
										</div>
									</SkeletonLoading>
								</div>
							)}

							<div
								style={{
									boxSizing: 'border-box',
								}}
								className='d-flex flex-grow-1'
							>
								<UsersTable data={clientData} loading={loadingClientData} />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ExportUsersTimeLoggedForClients;

const UsersTable = ({ data, loading = true }) => {
	return (
		<table className='TimeReportsPreviewTable'>
			<SkeletonLoading loading={loading} variant='text' width='100%' height={45}>
				<thead>
					<tr>
						{UserTableHeader.map((header) => (
							<React.Fragment key={header.key}>
								<th>{header.label}</th>
							</React.Fragment>
						))}
					</tr>
				</thead>
			</SkeletonLoading>

			<tbody>
				{data.length == 0 && (
					<SkeletonLoading loading={loading} variant='text' width='100%' height={45}>
						<tr>
							<td className='text-center light-text' colSpan={3}>
								No User Data in this period
							</td>
						</tr>
					</SkeletonLoading>
				)}
				{data?.map((timeData) => {
					return (
						<React.Fragment key={`${timeData?.name + timeData.total_logged_hours}`}>
							<SkeletonLoading
								loading={loading}
								variant='text'
								width='100%'
								height={45}
							>
								<tr>
									<td>{timeData?.name}</td>
									<td>{timeData?.client_name}</td>
									<td>{timeData?.total_logged_hours}</td>
								</tr>
							</SkeletonLoading>
						</React.Fragment>
					);
				})}
				<tr></tr>
			</tbody>
		</table>
	);
};
