/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useState } from 'react';

// Material Ui Component

// Import Components
import CompanyProfileInformation from '../components/CompanyProfile/CompanyProfileInformation';

import Sidebar from '../components/sidebar/SideBar';

import CompanyTopBar from '../components/CompanyProfile/CompanyTopBar';

import { useSelector, useDispatch } from 'react-redux';
import CompanyProfileBilling from '../components/CompanyProfile/CompanyProfileBilling';
import CompanyStats from '../components/CompanyProfile/CompanyStats';
import FeatureFlag from '../components/Others/FeatureFlag';
import AlertBar from '../components/sub_components/AlertBar';
import CompanySettings from '../components/CompanyProfile/CompanySettings';
import * as ProfileActions from '../redux/actions/company-profile';
import posthog from 'posthog-js';

function CompanyProfile() {
	const dispatch = useDispatch();
	const [activeTab, setActiveTab] = useState(1);

	const ChangeActiveTab = (e) => {
		setActiveTab(parseInt(e.target.id));
	};

	const companyProfileData = useSelector((state) => state.profileReducer.profileData);

	useEffect(() => {
        posthog?.isFeatureEnabled('custom_fields') && 
		dispatch(ProfileActions.fetchCustomFieldDefinitions()).then((response) => {
			dispatch(
				ProfileActions.setCustomFieldDefinitions(
					response?.sort((a, b) => a.list_order - b.list_order),
				),
			);
		});
	}, []);

	return (
		<div className='AppBody'>
			<Sidebar title='Company Profile'>
				<div className='SidebarContentWrapper'>
					<div className='User-filters'>
						{/* <SearchBar
							style={{
								color: 'white',
								backgroundColor: 'rgba(0, 0, 0, 0.25)',
							}}
						/> */}
						<div className='side-menu BodyTwoLight d-flex flex-column align-items-start'>
							<span
								className={
									activeTab === 1
										? 'active d-flex flex-row align-items-center'
										: 'side-menu-link d-flex flex-row align-items-center'
								}
								style={{ gap: '10px', paddingBlock: '10px' }}
							>
								<div className='dot'></div>
								<a onClick={ChangeActiveTab} href='#Profile' id='1' className=''>
									Company Details
								</a>
							</span>
							<FeatureFlag tenantIds={[10001, 10002, 10003, 10009]}>
								<span
									className={
										activeTab === 2
											? 'active d-flex flex-row align-items-center'
											: 'side-menu-link d-flex flex-row align-items-center'
									}
									style={{ gap: '10px', paddingBlock: '10px' }}
								>
									<div className='dot'></div>
									<a onClick={ChangeActiveTab} href='#Stats' id='2' className=''>
										Insights
									</a>
								</span>
							</FeatureFlag>
							<span
								className={
									activeTab === 3
										? 'active d-flex flex-row align-items-center'
										: 'side-menu-link d-flex flex-row align-items-center'
								}
								style={{ gap: '10px', paddingBlock: '10px' }}
							>
								<div className='dot'></div>
								<a onClick={ChangeActiveTab} href='#Settings' id='3' className=''>
									Company Settings
								</a>
							</span>
							<span
								className={
									activeTab === 4
										? 'active d-flex flex-row align-items-center'
										: 'side-menu-link d-flex flex-row align-items-center'
								}
								style={{ gap: '10px', paddingBlock: '10px' }}
							>
								<div className='dot'></div>
								<a onClick={ChangeActiveTab} href='#Billing' id='4' className=''>
									Billing
								</a>
							</span>
						</div>
					</div>
				</div>
			</Sidebar>
			<div className='content-container'>
				<AlertBar />
				<div
					className='Client-Profile w-100 d-flex flex-column'
					style={{ backgroundColor: '#EEF2F3', padding: '32px' }}
				>
					<CompanyTopBar
						clientInfo={{
							name: companyProfileData?.name ? companyProfileData?.name : '',
							image: companyProfileData?.image_url
								? companyProfileData.image_url
								: null,
						}}
					/>
					{/* <CompanyNavbar /> */}

					{activeTab === 3 && <CompanySettings />}

					{activeTab !== 3 && (
						<div className='Tabs-main'>
							<section id='Profile'>
								<CompanyProfileInformation />
							</section>
							<FeatureFlag tenantIds={[10001, 10002, 10003, 10009]}>
								<section id='Stats'>
									<CompanyStats />
								</section>
							</FeatureFlag>
							<section id='Billing'>
								<CompanyProfileBilling />
							</section>
						</div>
					)}
				</div>
			</div>
		</div>
	);
}

export default CompanyProfile;
