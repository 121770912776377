/* eslint-disable no-mixed-spaces-and-tabs */
import * as profileActions from '../../redux/actions/company-profile';
import * as ClientActions from '../../redux/actions/client-actions';
import ClientInformation from './ClientInformation';
import { Modal, Tooltip } from '@mui/material';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilEdit.svg';
import { ReactComponent as ClientDetails } from '../../assets/icons/client-details.svg';
import useModal from '../Modal/useModal';
import DummyModal from '../Modal/DummyModal';
import EditClientInfo from './Modals/EditClientInfoModal';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo, useState } from 'react';
import { REFERRAL_TYPE, standardPermissions } from '../../utilities/utilities';
import Restricted from '../Permissions/Restricted';
import { Country } from 'country-state-city';
import { getUserSettings } from '../../hooks/getUserSettings';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import posthog from 'posthog-js';

function ClientInformationSection() {
	const { id } = useParams();
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const [editDetailsModalTitle, setEditDetailsModalTitle] = useState('Edit Client Details');
	const client = useSelector((state) => state.clientReducer.client);
	const allClients = useSelector((state) => state.clientReducer.clients);
	const users = useSelector((state) => state.userReducer.users);
	const userTeamRole = useSelector((state) => state.clientReducer.userTeamRole);
	const teams = useSelector((state) => state.teamReducer.teams);

	const dispatch = useDispatch();
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);

	const refferedBy = useMemo(() => {
		let referel = client?.referral?.name;
		if (client?.referral?.type == REFERRAL_TYPE.CLIENT) {
			const clientToFind = allClients?.find(
				(item) => item.id == client?.referral?.ref_client_id,
			);
			referel = clientToFind?.dba == '' ? clientToFind?.company_name : clientToFind?.dba;
		}
		if (client?.referral?.type == REFERRAL_TYPE.CONTACT) {
			const clientContactToFind = client?.client_contact_list?.find(
				(item) => item.id == client?.referral?.ref_client_contact_id,
			);
			referel = clientContactToFind?.first_name + ' ' + clientContactToFind?.last_name;
		}
		if (client?.referral?.type == REFERRAL_TYPE.USER) {
			referel = users?.find(
				(item) => item.user_id == client?.referral?.ref_user_id,
			)?.user_name;
		}

		return referel;
	}, [allClients?.length, users?.length, client?.referral]);

	const clientTeam = useMemo(() => {
		if (client?.team_client_permissions?.length > 0) {
			return teams?.find((t) => t.id == client?.team_client_permissions[0]?.team_id)?.name;
		}
	}, [client, teams?.length]);

	useEffect(() => {
		dispatch(profileActions.getAllCountries(Country.getAllCountries()));
	}, []);

	useEffect(() => {
		if (posthog?.isFeatureEnabled('custom_fields')) {
			dispatch(profileActions.fetchCustomFieldDefinitions()).then((response) => {
				dispatch(
					profileActions.setCustomFieldDefinitions(
						response?.filter((field) => !field?.deleted),
					),
				);
			});

			dispatch(ClientActions.fetchCustomFieldValues(id)).then((response) => {
				dispatch(ClientActions.setCustomFieldValues(response));
			});
		}
	}, []);

	return (
		<div className='d-flex flex-column w-100 align-items-center' style={{ gap: '12px' }}>
			<div className='text-left w-100 d-flex align-items-center justify-content-between information-header'>
				<span className='d-flex' style={{ gap: '12px' }}>
					<ClientDetails />
					<h3 className='HeadlineThreeBold'>Client Details</h3>
				</span>

				<Restricted AllowedUsers={standardPermissions} userTeamRole={[userTeamRole]}>
					<Tooltip title='Edit Client Info'>
						<EditIcon
							style={{
								color: '#fb7a03',
								cursor: 'pointer',
							}}
							onClick={() => {
								ModalType('EDIT_CLIENT_INFO');
								toggleModal();
							}}
						/>
					</Tooltip>
				</Restricted>
			</div>
			<div className='Profile-Content'>
				<ClientInformation
					clientTeam={clientTeam}
					company_name={client?.company_name}
					business_description={client?.business_description}
					dba={client?.dba}
					industry={client?.industry}
					business_type={client?.business_type}
					company_phone={client?.company_phone}
					website={client?.website}
					address={client?.address}
					city={client?.city}
					state={client?.state}
					zip={client?.zip}
					suit_floor={client?.suit_floor}
					date={client?.date ? moment(client?.date).format(date_format) : ''}
					ein={client?.ein}
					ssn={client?.ssn}
					fiscal_year_end={client?.fiscal_year_end}
					accounting={client?.accounting}
					close_week={client?.close_week}
					start_date={
						client?.start_date ? moment(client?.start_date).format(date_format) : ''
					}
					referral={refferedBy || ''}
					country={Country.getCountryByCode(client?.country)?.name || ''}
				/>
			</div>

			{modalIsOpen && (
				<>
					<Modal
						onClose={toggleModal}
						open={modalIsOpen}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						{ModalName == 'EDIT_CLIENT_INFO' && (
							<DummyModal
								title={editDetailsModalTitle}
								onClose={toggleModal}
								style={posthog?.isFeatureEnabled('custom_fields') && { width: '600px' }}
							>
								<EditClientInfo
									closeModal={toggleModal}
									client={client}
									setEditDetailsModalTitle={setEditDetailsModalTitle}
								/>
							</DummyModal>
						)}
					</Modal>
				</>
			)}
		</div>
	);
}

export default ClientInformationSection;
