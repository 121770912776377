/* eslint-disable no-mixed-spaces-and-tabs */
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from '../../../redux/actions/auth-actions';
import moment from 'moment';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';
import useModal from '../../Modal/useModal';
import { Modal } from '@mui/material';
import DummyModal from '../../Modal/DummyModal';
import ConfirmModal from '../../Modal/ConfirmModal';
import { convertUnixDate } from '../../../utilities/utilities';
import RenewSubscriptionModal from '../../Modal/RenewSubscriptionModal';
import { setSnackBarIsOpen } from '../../../redux/actions/dashboard/workspaces-actions';
import { Elements } from '@stripe/react-stripe-js';
import { stripePublicKey } from '../../../utils';
import { loadStripe } from '@stripe/stripe-js';
import AddCardModal from './AddCardModal';

const CompanyBillPlan = ({ upgrade }) => {
	const dispatch = useDispatch();
	const subscriptionsData = useSelector((state) => state.authReducer.subscriptions);
	const stripePromise = loadStripe(stripePublicKey);
	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();
	const [loading, setLoading] = useState(false);
	const [subscriptionFlow, setSubscriptionFlow] = useState(false);
	// const [subscriptionData, setSubscriptionData] = useState({
	// 	subscriptionPackage: 'Professional',
	// 	packageDetails:
	// 		'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
	// 	paymentPerMonth: '-',
	// 	nextPaymentDate: '-',
	// 	numberOfUsers: '-',
	// });

	// const [billingPeriod, setBillingPeriod] = useState('-');
	// const billingPeriodList = ['Monthly', 'Annually'];
	// const [packageName, setPackageName] = useState('');

	const handleModalOpen = (type) => {
		ModalType(type);
		toggleModal();
	};

	const isSubscribed = Object.keys(subscriptionsData)?.length !== 0;
	const startDate = convertUnixDate(subscriptionsData?.current_period_start);
	const endDate = convertUnixDate(subscriptionsData?.current_period_end);
	const nextPaymentDate = moment(endDate, 'MMMM D, YYYY').add(1, 'days')?.format('MMMM D, YYYY');

	const cancelSubscription = () => {
		setLoading(true);
		dispatch(authActions.cancelSubscription(subscriptionsData?.id))
			.then((res) => {
				dispatch(setSnackBarIsOpen(true, true, 'Subscription successfully cancelled!'));
			})
			.catch((err) => {
				dispatch(setSnackBarIsOpen(false, true, err?.message));
			})
			.finally(() => {
				toggleModal();
				setLoading(false);
			});
	};

	const renewSubscription = () => {
		setLoading(true);

		dispatch(
			authActions.renewSubscription(
				subscriptionsData?.id,
				subscriptionsData?.stripe_pricing_id,
			),
		)
			.then((res) => {
				dispatch(setSnackBarIsOpen(true, true, 'Subscription successfully renewed!'));
			})
			.catch((err) => {
				dispatch(setSnackBarIsOpen(false, true, err?.message));
			})
			.finally(() => {
				toggleModal();
				setLoading(false);
			});
	};

	return (
		<>
			<div className='stats-container  '>
				<div className=' header-bar '>
					<span className='text-left w-100 d-flex align-items-center justify-content-between'>
						<h3 className='HeadlineThreeBold'>Plan Details</h3>
						{isSubscribed ? (
							<div className='d-flex align-items-center'>
								<span
									onClick={() => {
										`${
											subscriptionsData?.status == 'canceled'
												? handleModalOpen('RenewSubscription')
												: handleModalOpen('CancelSubscription')
										}`;
									}}
									className='BodyTwoLight primary-text pointer'
								>
									{`${
										subscriptionsData?.status == 'canceled'
											? 'Renew Subscription'
											: 'Cancel Subscription'
									}`}
								</span>
								{upgrade && (
									<SubmitButtonsField child={true}>
										<button
											data-cy='confirm-btn'
											className='d-flex align-items-center justify-content-center'
											id='Element'
											onClick={() => handleModalOpen('Upgrade')}
											style={{
												borderRadius: '50px',
												padding: '12px !important',
											}}
										>
											Upgrade to Annual
											<div
												style={{
													backgroundColor: '#FFF',
													padding: '0px 8px',
													borderRadius: '50px',
													marginLeft: '8px',
												}}
											>
												<span
													style={{ color: 'black' }}
													className='BodyTwoLight'
												>
													Save 20%
												</span>
											</div>
										</button>
									</SubmitButtonsField>
								)}
							</div>
						) : (
							<div className='d-flex align-items-center'>
								<span
									onClick={() => {
										handleModalOpen('AddCard');
										setSubscriptionFlow(true);
									}}
									className='BodyTwoLight primary-text pointer'
								>
									Add Subscription
								</span>
							</div>
						)}
					</span>
					{/* 
					{planEdit ? (
						<>
							<ButtonSquare
								clickHandler={() => {
									setPlanEdit(!planEdit);
								}}
							>
								Done{' '}
							</ButtonSquare>
						</>
					) : (
						<ButtonSquare
							clickHandler={() => {
								setPlanEdit(!planEdit);
							}}
						>
							Edit
						</ButtonSquare>
					)} */}
				</div>

				<div
					className='graph-container d-flex justify-content-between'
					style={{ padding: '18px', gap: '5rem' }}
				>
					{isSubscribed ? (
						<>
							<div
								className='d-flex flex-column'
								// style={{ width: '20%', height: 'auto', gap: '0.8rem' }}
							>
								<div className='d-flex flex-column' style={{ gap: '0.7em' }}>
									<div
										className='d-flex align-items-start'
										style={{ textAlign: 'start' }}
									>
										<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
											Plan Name
										</span>
									</div>
									<div className='d-flex align-items-start'>
										<span className='BodyTwoLight'>
											{subscriptionsData?.stripe_product?.name}
										</span>
									</div>
								</div>
							</div>

							<div
								className='d-flex flex-column'
								// style={{ width: '20%', height: 'auto', gap: '0.8rem' }}
							>
								{/* <h4 className='BodyTwoLight w-100 text-left'>Billing</h4> */}
								<div className='d-flex flex-column' style={{ gap: '0.7em' }}>
									<div
										className='d-flex align-items-start'
										style={{ textAlign: 'start' }}
									>
										<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
											Billing Period
										</span>
									</div>
									<div
										className='d-flex align-items-start'
										style={{ textAlign: 'start' }}
									>
										<span className='BodyTwoLight'>
											{startDate} - {endDate}
										</span>
									</div>
									{/* <EditableDropdown
								label='BILLING PERIOD'
								value={[
									{
										value: 'Monthly',
										label: 'Monthly',
									},
									{
										value: 'Annually',
										label: 'Annually',
									},
								].find(
									(opt) =>
										opt.value.toUpperCase() ==
										subscriptionsData?.stripe_pricing?.interval?.toUpperCase() +
											'LY',
								)}
								options={[
									{
										value: 'Monthly',
										label: 'Monthly',
									},
									{
										value: 'Annually',
										label: 'Annually',
									},
								]}
								onChange={handlePackageChange}
								Edit={planEdit}
							/> */}
								</div>
							</div>

							<div
								className='d-flex flex-column'
								// style={{ width: '20%', height: 'auto', gap: '0.8rem' }}
							>
								{/* <h4 className='BodyTwoLight w-100 text-left'>Payment</h4> */}
								<div className='d-flex flex-column' style={{ gap: '0.7em' }}>
									{/* <div
										className='d-flex align-items-start'
										style={{ textAlign: 'start' }}
									>
										<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
											Number of Users
										</span>
									</div>
									<div className='d-flex align-items-start'>
										<span className='BodyTwoLight'>
											{subscriptionsData?.quantity}
										</span>
									</div> */}

									<div
										className='d-flex align-items-start'
										style={{ textAlign: 'start' }}
									>
										<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
											Status
										</span>
									</div>
									<div className='d-flex align-items-start'>
										<span className='BodyTwoLight'>
											{subscriptionsData?.status &&
											subscriptionsData?.status == 'trialing'
												? 'Trial Period'
												: subscriptionsData?.status
														.charAt(0)
														.toUpperCase() +
												  subscriptionsData?.status.slice(1)}
										</span>
									</div>

									{/* <EditableText value={nextPaymentDate} label='Next payment date' /> */}
								</div>
							</div>

							<div
								className='d-flex flex-column'
								// style={{ width: '20%', height: 'auto', gap: '0.8rem' }}
							>
								{/* <h4 className='BodyTwoLight w-100 text-left'>Usage Limit</h4> */}
								<div className='d-flex flex-column' style={{ gap: '0.7em' }}>
									{/* <EditableText value={0} label='Workflows' />

							<EditableText value={0} label='Tasks' /> */}

									{/* <div
										className='d-flex align-items-start'
										style={{ textAlign: 'start' }}
									>
										<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
											Total Cost for this Billing Period
										</span>
									</div>
									<div className='d-flex align-items-start'>
										<span className='BodyTwoLight'>
											$
											{subscriptionsData?.stripe_pricing
												? subscriptionsData?.stripe_pricing
														?.price_per_unit *
												subscriptionsData?.quantity
												: '0'}
										</span>
									</div> */}

									<div
										className='d-flex align-items-start'
										style={{ textAlign: 'start' }}
									>
										<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
											Next Payment Date
										</span>
									</div>
									<div
										className='d-flex align-items-start'
										style={{ textAlign: 'start' }}
									>
										<span className='BodyTwoLight'>{nextPaymentDate}</span>
									</div>
								</div>
							</div>
						</>
					) : (
						<div className='d-flex align-items-center justify-content-center w-100'>
							<span className='BodyTwoLight w-100' style={{ color: '#696F6C' }}>
								No subscription plan activated.
							</span>
						</div>
					)}
				</div>

				{ModalName === 'CancelSubscription' && modalIsOpen && (
					<Modal
						onClose={toggleModal}
						open={modalIsOpen}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<>
							<DummyModal
								title='Are you sure you want to cancel?'
								onClose={toggleModal}
							>
								<ConfirmModal
									OnCancel={toggleModal}
									description={`We're sad to see you go! By canceling, you and all users will lose access. Your last day to access Levvy is ${endDate} . Are you sure you want to proceed?`}
									Onsubmit={cancelSubscription}
									title='Submit'
									isLoading={loading}
								/>
							</DummyModal>
						</>
					</Modal>
				)}

				{ModalName === 'RenewSubscription' && modalIsOpen && (
					<Modal
						onClose={toggleModal}
						open={modalIsOpen}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<>
							<DummyModal
								title='Renew Subscription'
								onClose={toggleModal}
								style={{ width: '40%', maxWidth: '750px', minWidth: '600px' }}
							>
								<RenewSubscriptionModal
									OnCancel={toggleModal}
									Onsubmit={renewSubscription}
									title='Confirm'
									subscriptionsData={subscriptionsData}
									isLoading={loading}
								/>
							</DummyModal>
						</>
					</Modal>
				)}

				{ModalName === 'AddCard' && modalIsOpen && (
					<Modal
						onClose={toggleModal}
						open={modalIsOpen}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<DummyModal title={'Activate Subscription'} onClose={toggleModal}>
							<Elements stripe={stripePromise}>
								<AddCardModal
									handleClose={toggleModal}
									edit={false}
									subscriptionFlow={subscriptionFlow}
								/>
							</Elements>
						</DummyModal>
					</Modal>
				)}

				{ModalName === 'Upgrade' && modalIsOpen && (
					<Modal
						onClose={toggleModal}
						open={modalIsOpen}
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
						}}
					>
						<>
							<DummyModal title='Confirm Annual Plan Upgrade' onClose={toggleModal}>
								<ConfirmModal
									OnCancel={toggleModal}
									description='You are set to enjoy significant savings for 12 months, starting the next billing period. Please confirm your decision below to proceed with the upgrade.'
									Onsubmit={toggleModal}
									title='Upgrade to Annual Plan'
								/>
							</DummyModal>
						</>
					</Modal>
				)}
			</div>
		</>
	);
};
export default CompanyBillPlan;
