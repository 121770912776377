/* eslint-disable no-mixed-spaces-and-tabs */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useMemo, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';
import { ReactComponent as CalendarIcon } from '../../../assets/icons/Calendarcolor.svg';
import * as clientActions from '../../../redux/actions/client-actions';
import * as profileActions from '../../../redux/actions/company-profile';
import { REFERRAL_TYPE, months } from '../../../utilities/utilities';
import { business_types2, identificationTypes, industries2 } from '../../DemoData';
import Select from '../../Inputs/Select';
import EditableLocationText from '../../Inputs/EditableLocationText';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { CircularProgress } from '@mui/material';
import { EditableSearchDropDown } from '../../Inputs/EditableSearchDropDown';
import moment from 'moment';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import PhoneNumberInput from '../../Inputs/PhoneNumberInput';
import { Country, State } from 'country-state-city';
import { getUserSettings } from '../../../hooks/getUserSettings';
import { useParams } from 'react-router-dom';
import CustomFieldValuesModal from './CustomFieldValuesModal';
import { setSnackBarIsOpen } from '../../../redux/actions/dashboard/workspaces-actions';
import posthog from 'posthog-js';

const customReferelInitialForm = {
	name: '',
	email: '',
	company_name: '',
	phone: '',
};

function EditClientInfo({ closeModal, client, setEditDetailsModalTitle }) {
	const dispatch = useDispatch();
	const { id } = useParams();
	const customFieldRef = useRef(null);
	const clients = useSelector((state) => state.clientReducer.clients);
	const users = useSelector((state) => state.userReducer.users);
	const teams = useSelector((state) => state.teamReducer.teams);
	const countries = useSelector((state) => state.profileReducer.countries);
	const states = useSelector((state) => state.profileReducer.states);

	const companyProfileData = useSelector((state) => state.profileReducer.profileData);
	const userDetails = useSelector((state) => state.authReducer.userDetails);
	const { date_format } = getUserSettings(userDetails);
	const [error, setError] = useState(null);
	const [clientForm, setClientForm] = useState(client);
	const [customReferel, setCustomReferel] = useState(customReferelInitialForm);
	const [isLoading, setIsLoading] = useState(false);
	const [currentTab, setCurrentTab] = useState('Overview');
	const [addCustomReferral, setAddCustomReferral] = useState(false);
	const [isPhoneFormatted, setIsPhoneFormatted] = useState(client?.is_phone_number_formated);
	const [identificationType, setIdentificationType] = useState(
		client.ssn ? 1 : client.ein ? 2 : null,
	);

	const customFieldDefinitions = useSelector(
		(state) =>
			state.profileReducer.customFieldDefinitions?.filter((field) => !field?.deleted) || [],
	);

	const editClientDetailsTabs = [
		{ value: 'overview', name: 'Overview' },
		{ value: 'business', name: 'Business' },
		{ value: 'address', name: 'Address' },
		{ value: 'additional', name: 'Additional' },
		...(customFieldDefinitions.length ? [{ value: 'custom field', name: 'Custom Field' }] : []),
	];

	const saveCustomReferel = () => {
		setClientForm({
			...clientForm,
			referral: {
				...clientForm.referral,
				type: REFERRAL_TYPE.CUSTOM,
				...customReferel,
			},
		});
		setAddCustomReferral(false);
	};

	const cancelCustomReferelForm = () => {
		setEditDetailsModalTitle('Edit Client Details');
		setAddCustomReferral(false);
	};

	const handleOnCancel = () => {
		setClientForm(null);
		setError(null);
		closeModal();
	};

	const openCustomReferelForm = () => {
		setAddCustomReferral(true);

		clientForm?.referral?.type == REFERRAL_TYPE.CUSTOM
			? setCustomReferel({
					name: clientForm?.referral?.name,
					email: clientForm?.referral?.email,
					company_name: clientForm?.referral?.company_name,
					phone: clientForm?.referral?.phone,
			  })
			: setCustomReferel(customReferelInitialForm);

		setEditDetailsModalTitle(
			<div className='BodyOneBold align-items-center'>
				<KeyboardBackspaceIcon
					sx={{ cursor: 'pointer' }}
					className='mr-2'
					fontSize='medium'
					onClick={() => {
						setAddCustomReferral(false);
						setEditDetailsModalTitle('Edit Client Details');
					}}
				/>
				Edit Client Information
			</div>,
		);
	};

	const chooseFromListClick = () => {
		const ref = { ...client?.referral };
		delete ref.company_name;
		delete ref.name;
		delete ref.email;
		delete ref.phone;

		setClientForm({
			...clientForm,
			referral: null,
		});
	};

	const handleReferelChange = (event) => {
		let { name, value } = event.target;

		if (name === 'phone') {
			value = value.replace(/\D/g, '').substring(0, 14);
			const match = value.match(/^(\d{0,3})(\d{0,3})(\d{0,4})$/);
			if (match) {
				value = !match[2]
					? `${match[1]}`
					: `(${match[1]}) ${match[2]}${match[3] ? `-${match[3]}` : ''}`;
			}
		}

		setCustomReferel({
			...customReferel,
			[name]: value,
		});
	};

	const handleCountryPhone = (event, country, e, phoneNumber) => {
		let phone;
		if (isPhoneFormatted) {
			phone = phoneNumber;
		} else {
			phone = event.target.value;
		}
		setClientForm({ ...clientForm, ['company_phone']: phone });
	};

	const resetNumber = () => {
		setClientForm({ ...clientForm, ['company_phone']: '' });
	};

	const toggleFormatting = (formatting) => {
		setIsPhoneFormatted(formatting);
	};

	const countryHandleChange = (event) => {
		event.preventDefault();

		const { name, value } = event.target;
		if (name == 'country') {
			setClientForm({
				...clientForm,
				country: value,
				state: '',
				zip: '',
				address: '',
				city: '',
				suit_floor: '',
			});
		}
	};

	const handleChange = (event) => {
		event.preventDefault();

		let { name, value } = event.target;

		if (name === 'ein') {
			var einVal = value.replace(/\D/g, '');
			var newEinVal = '';

			if (einVal.length > 3) {
				value = einVal;
			}
			if (einVal.length > 2) {
				newEinVal += einVal.substr(0, 2) + '-';
				einVal = einVal.substr(2);
			}
			if (value.length > 9) {
				return;
			}

			newEinVal += einVal;
			value = newEinVal;
		}

		if (name == 'refered_by') {
			if (isNaN(value) || parseInt(value) < 0) {
				return;
			} else {
				value = parseFloat(value);
			}
		}

		if (name === 'ssn') {
			var val = value.replace(/\D/g, '');
			var newVal = '';

			if (val.length > 4) {
				value = val;
			}
			if (val.length > 3 && val.length < 6) {
				newVal += val.substr(0, 3) + '-';
				val = val.substr(3);
			}
			if (val.length > 5) {
				newVal += val.substr(0, 3) + '-';
				newVal += val.substr(3, 2) + '-';
				val = val.substr(5);
			}
			if (value.length > 9) {
				return;
			}

			newVal += val;
			value = newVal;
		}

		if (name == 'industry' || name == 'state' || (name == 'business_type' && value == 'null')) {
			value = event.target.value;
		}

		if (name === 'team_id') {
			dispatch(clientActions.updateClientTeam(value));
			clientForm.team_client_permissions[0].team_id = parseInt(value);
		}

		setClientForm({ ...clientForm, [name]: value });
	};

	const clientUpdateSuccess = (updatedClient) => {
		const primaryLocationIndex = client?.client_office_locations?.findIndex(
			(ind) => ind?.is_primary,
		);
		const existingPrimaryLocation = client?.client_office_locations?.find(
			(ind) => ind?.is_primary,
		);
		if (updatedClient?.address) {
			if (primaryLocationIndex == -1) {
				// create new contact
				const contact = {
					is_primary: true,
					client_id: client?.id,
					order: client?.client_office_locations?.length,
					city: updatedClient?.city,
					hq: updatedClient?.address,
					state: updatedClient?.state,
					zip: updatedClient?.zip,
					street: updatedClient?.suit_floor || '',
					country: updatedClient?.country,
				};
				console.info('idr', updatedClient, contact);
				dispatch(clientActions.addClientLocation(contact));
			} else {
				// update the existing contact
				const contact = {
					...existingPrimaryLocation,
					city: updatedClient?.city,
					hq: updatedClient?.address,
					zip: updatedClient?.zip,
					state: updatedClient?.state,
					street: updatedClient?.suit_floor || '',
					country: updatedClient?.country,
				};
				console.info('idr', updatedClient, contact);
				dispatch(clientActions.updateClientLocation(contact));
			}
		}

		addCustomReferral ? closeModal() : setAddCustomReferral(false);

		// closeModal();
		setIsLoading(false);
		setError(null);
	};

	const clientUpdateFailed = (error) => {
		setIsLoading(false);
		setError(error);
	};

	const handleSubmit = () => {
		setIsLoading(true);

		let payload = {
			...clientForm,
			is_phone_number_formated: isPhoneFormatted,
		};

		if (clientForm?.referral && clientForm?.referral?.type !== REFERRAL_TYPE.CUSTOM) {
			delete payload.referral.company_name;
			delete payload.referral.name;
			delete payload.referral.email;
			delete payload.referral.phone;
		}

		if (clientForm?.referral && clientForm?.referral?.type == REFERRAL_TYPE.CUSTOM) {
			payload = {
				...payload,
				referral: {
					...payload.referral,
					ref_client_contact_id: null,
					ref_user_id: null,
					ref_client_id: null,
				},
			};
		} else if (clientForm?.referral && clientForm?.referral?.type == REFERRAL_TYPE.CLIENT) {
			payload = {
				...payload,
				referral: {
					...payload.referral,
					ref_client_contact_id: null,
					ref_user_id: null,
				},
			};
		} else if (clientForm?.referral && clientForm?.referral?.type == REFERRAL_TYPE.CONTACT) {
			payload = {
				...payload,
				referral: {
					...payload.referral,
					ref_client_id: null,
					ref_user_id: null,
				},
			};
		} else if (clientForm?.referral && clientForm?.referral?.type == REFERRAL_TYPE.USER) {
			payload = {
				...payload,
				referral: {
					...payload.referral,
					ref_client_id: null,
					ref_client_contact_id: null,
				},
			};
		}

		delete payload.client_contact_list;
		delete payload.client_office_locations;
		delete payload.links;
		delete payload.client_software_stacks;
		delete payload.team_client_permissions[0].team;
		delete payload.team_id;

		dispatch(
			clientActions.updateClient(payload, {
				clientUpdateSuccess,
				clientUpdateFailed,
			}),
		).then(() => {
			setIsLoading(false);
			closeModal();
		});
	};

	const submitCustomFieldValues = () => {
		const payload = customFieldRef.current.getValues();
		setIsLoading(true);
		dispatch(clientActions.patchCuatomFieldValues(payload))
			.then(() => {
				dispatch(clientActions.fetchCustomFieldValues(id)).then((response) => {
					dispatch(clientActions.setCustomFieldValues(response));
					setIsLoading(false);
					closeModal();
				});
			})
			.catch((error) => {
				setIsLoading(false);
				dispatch(setSnackBarIsOpen(false, true, error.message));
			});
	};

	const onAddressChange = (prop) => {
		if (prop?.target?.name == 'address') {
			setClientForm({
				...clientForm,
				address: prop?.target?.value,
			});
		} else {
			setClientForm((prevState) => ({
				...prevState,
				address: prop.fullAddress,
				zip: prop.zipCode || '',
				state: prop.state
					? String(states.find((state) => state.label == prop.state)?.value)
					: '',
				city: prop?.city || '',
				suit_floor: prop.suite || '',
			}));
		}
	};

	const teamList = useMemo(() => {
		return teams.map((team) => {
			return {
				label: team.name,
				value: team.id,
				...team,
			};
		});
	}, []);

	const onDateChange = (date) => {
		setClientForm({ ...clientForm, date: date });
	};

	const onStartDateChange = (date) => {
		setClientForm({ ...clientForm, start_date: date });
	};

	const referalChangeHandler = (option) => {
		let value = null;
		if (option?.type == REFERRAL_TYPE.CLIENT) {
			value = {
				ref_client_id: parseInt(option?.value?.replace(REFERRAL_TYPE.CLIENT, '')),
				type: REFERRAL_TYPE.CLIENT,
			};
		} else if (option?.type == REFERRAL_TYPE.CONTACT) {
			value = {
				ref_client_contact_id: parseInt(option?.value?.replace(REFERRAL_TYPE.CONTACT, '')),
				type: REFERRAL_TYPE.CONTACT,
			};
		} else if (option?.type == REFERRAL_TYPE.USER) {
			value = {
				ref_user_id: parseInt(option?.value?.replace(REFERRAL_TYPE.USER, '')),
				type: REFERRAL_TYPE.USER,
			};
		}

		if (client?.referral?.id) {
			setClientForm({
				...clientForm,
				referral: { ...value, id: client?.referral?.id },
			});
		} else {
			setClientForm({ ...clientForm, referral: value });
		}
	};

	const clientContactOptions = useMemo(() => {
		if (client) {
			return client?.client_contact_list
				? client?.client_contact_list?.map((item) => {
						return {
							value: `${REFERRAL_TYPE.CONTACT}${item?.id}`,
							label: item?.first_name + ' ' + item?.last_name,
							type: REFERRAL_TYPE.CONTACT,
						};
				  })
				: [];
		}
	});

	useEffect(() => {
		dispatch(profileActions.getCountryStates(State.getStatesOfCountry(clientForm?.country)));
	}, [clientForm?.country]);

	useEffect(() => {
		const clientCountry = clientForm?.country
			? clientForm?.country
			: companyProfileData?.country;
		setClientForm({
			...clientForm,
			['country']: clientCountry,
		});
	}, [companyProfileData?.country]);

	const onFormSubmit = (e) => {
		e.preventDefault();

		if (addCustomReferral) return saveCustomReferel();
		if (currentTab === 'Custom Field') return submitCustomFieldValues();

		handleSubmit();
	};
	return (
		<>
			<form autoComplete='off' onSubmit={onFormSubmit}>
				<div style={{ maxHeight: '80vh', overflow: 'auto' }} className='px-3'>
					{!addCustomReferral && (
						<div className='client-details-inner-body'>
							<div className=' my-task-types pb-1'>
								{editClientDetailsTabs.map((item, i) => {
									return (
										<span
											key={i}
											className={`dark-text  my-task-type ${
												item.name == currentTab && 'active-form-tab'
											} `}
											onClick={() => setCurrentTab(item.name)}
											data-cy={`recurrence-${item.name}`}
										>
											{item.name}
										</span>
									);
								})}
							</div>

							<div></div>
						</div>
					)}

					{currentTab === 'Overview' && (
						<div>
							<div className='improved-input-designs pb-3'>
								<label className='require'>Name / Business Name</label>
								<input
									className='w-100'
									type='text'
									name='company_name'
									value={clientForm?.company_name}
									onChange={handleChange}
									required
								/>
							</div>
							<div className='improved-input-designs pb-3'>
								<label>DBA</label>
								<input
									className='w-100'
									type='text'
									name='dba'
									value={clientForm?.dba}
									onChange={handleChange}
								/>
							</div>
							<div className='improved-input-designs pb-3'>
								<label>Business Description</label>
								<input
									className='w-100'
									type='text'
									name='business_description'
									value={clientForm?.business_description}
									onChange={handleChange}
								/>
							</div>
						</div>
					)}

					{currentTab === 'Business' && (
						<div>
							<div className='improved-input-designs pb-3'>
								<label>Industry</label>
								<Select
									value={industries2.find((i) => i.value == clientForm?.industry)}
									options={industries2}
									name='industry'
									onChange={handleChange}
								/>
							</div>

							<div className='improved-input-designs pb-3'>
								<label>Business Type</label>
								<Select
									value={business_types2.find(
										(b) => b.value == clientForm?.business_type,
									)}
									options={business_types2}
									name='business_type'
									onChange={handleChange}
								/>
							</div>

							<div className='improved-input-designs pb-3'>
								<label className=''>Identity Type</label>
								<Select
									value={
										identificationType
											? identificationTypes[identificationType - 1]
											: ''
									}
									options={identificationTypes}
									name='identification_type'
									onChange={(e) => {
										setClientForm({ ...clientForm, ssn: null, ein: null });
										setIdentificationType(parseInt(e.target.value));
									}}
								/>
							</div>

							{identificationType === 2 ? (
								<div className='improved-input-designs pb-3'>
									<label>EIN</label>
									<input
										pattern='[0-9]{2}[-]{1}[0-9]{7}'
										className='w-100'
										name='ein'
										onChange={handleChange}
										value={clientForm?.ein || ''}
										title='Match Ein format xx-xxxxxxx'
										placeholder='xx-xxxxxxx'
										data-cy='ein'
									/>
								</div>
							) : (
								identificationType === 1 && (
									<div className='improved-input-designs pb-3'>
										<label>SSN</label>
										<input
											pattern='\d{3}[\-]\d{2}[\-]\d{4}'
											className='w-100'
											name='ssn'
											onChange={handleChange}
											value={clientForm?.ssn || ''}
											title='Match SSN format xxx-xx-xxxx'
											placeholder='xxx-xx-xxxx'
											data-cy='ssn'
										/>
									</div>
								)
							)}

							<div className='phone-number-container pb-3'>
								<label className='pb-2'>Company Phone</label>
								<PhoneNumberInput
									value={clientForm?.company_phone}
									handleChange={handleCountryPhone}
									resetNumber={resetNumber}
									isPhoneFormatted={isPhoneFormatted}
									toggleFormatting={toggleFormatting}
								/>
							</div>
							<div className='improved-input-designs pb-3'>
								<label>Company Formation Date</label>
								<KeyboardDatePicker
									autoOk
									disableToolbar
									value={clientForm?.date || moment()}
									keyboardIcon={<CalendarIcon />}
									name='date'
									onChange={onDateChange}
									format={date_format}
									className='p-1'
								/>
							</div>

							<div className='improved-input-designs pb-3'>
								<label>Website</label>
								<input
									className='w-100'
									type='text'
									name='website'
									value={clientForm?.website}
									onChange={handleChange}
								/>
							</div>
						</div>
					)}

					{currentTab === 'Address' && (
						<div>
							<div className='improved-input-designs pb-3'>
								<label>Country</label>
								<Select
									value={{
										value: clientForm?.country || '',
									}}
									options={countries}
									name='country'
									onChange={countryHandleChange}
								/>
							</div>
							<div className='improved-input-designs pb-3'>
								<label>Street</label>
								<EditableLocationText
									name='address'
									placeSelected={onAddressChange}
									value={clientForm?.address || ''}
									onChange={onAddressChange}
									Edit={true}
									countryAbbr={clientForm?.country || ''}
								/>
							</div>
							<div className='improved-input-designs pb-3'>
								<label>Floor/Suite</label>
								<input
									className='w-100'
									type='text'
									name='suit_floor'
									value={clientForm?.suit_floor || ''}
									onChange={handleChange}
								/>
							</div>

							<div className='improved-input-designs pb-3'>
								<label>City/Town</label>
								<input
									className='w-100'
									type='text'
									name='city'
									value={clientForm?.city || ''}
									onChange={handleChange}
								/>
							</div>

							<div className='improved-input-designs pb-3'>
								<label>State/Province/Region</label>
								<Select
									value={{ value: clientForm?.state || '' }}
									options={states}
									name='state'
									onChange={handleChange}
								/>
							</div>

							<div className='improved-input-designs pb-3'>
								<label>Zip Code/Postal Code</label>
								<input
									className='w-100'
									type='text'
									name='zip'
									value={clientForm?.zip || ''}
									onChange={handleChange}
								/>
							</div>
						</div>
					)}

					{currentTab === 'Additional' && (
						<div>
							{addCustomReferral ? (
								<>
									<div>
										<h1 className='HeadlineTwoBold'>Add Custom Referral</h1>
									</div>

									<div className='improved-input-designs py-3'>
										<label className='text-capitalize require'>Name</label>
										<input
											className='w-100 p-5'
											type='text'
											name='name'
											onChange={handleReferelChange}
											value={customReferel?.name}
											required
										/>
									</div>

									<div className='improved-input-designs pb-3'>
										<label className=''>Company</label>
										<input
											className='w-100'
											type='text'
											name='company_name'
											onChange={handleReferelChange}
											value={customReferel?.company_name}
										/>
									</div>

									<div className='improved-input-designs pb-3'>
										<label>Email</label>
										<input
											className='w-100'
											type='text'
											name='email'
											onChange={handleReferelChange}
											value={customReferel?.email}
										/>
									</div>

									<div className='improved-input-designs pb-3'>
										<label>Tel Phone</label>
										<input
											className='w-100'
											type='tel'
											onChange={handleReferelChange}
											name='phone'
											value={customReferel?.phone}
											maxLength='14'
											pattern='^\(\d{3}\)\s\d{3}-\d{4}$'
										/>
									</div>
								</>
							) : (
								<>
									{clientForm?.referral?.name ? (
										<>
											<div className='improved-input-designs pb-3'>
												<div
													className='d-flex flex-row'
													style={{
														justifyContent: 'space-between',
														color: '#FB7A03',
													}}
												>
													<label>Referred by</label>
													<span
														onClick={chooseFromListClick}
														className='BodyTwoLight primary-text pointer text-capitalize'
													>
														Choose From List
													</span>
												</div>
												<input
													className='w-100'
													type='text'
													name='close_week'
													value={clientForm?.referral?.name}
													onClick={openCustomReferelForm}
													readOnly
												/>
											</div>
										</>
									) : (
										<EditableSearchDropDown
											width={'410px'}
											value={{
												value:
													(clientForm?.referral?.ref_client_contact_id &&
														REFERRAL_TYPE.CONTACT +
															clientForm?.referral
																?.ref_client_contact_id) ||
													(clientForm?.referral?.ref_client_id &&
														REFERRAL_TYPE.CLIENT +
															clientForm?.referral?.ref_client_id) ||
													(clientForm?.referral?.ref_user_id &&
														REFERRAL_TYPE.USER +
															clientForm?.referral?.ref_user_id),
											}}
											selectClassName='white-bg'
											onChange={referalChangeHandler}
											options={[
												...users?.map((item) => {
													return {
														value: `${REFERRAL_TYPE.USER}${item?.user_id}`,
														label: item?.user_name,
														type: REFERRAL_TYPE.USER,
													};
												}),
												...clients?.map((item) => {
													return {
														value: `${REFERRAL_TYPE.CLIENT}${item?.id}`,
														label:
															item?.dba?.trim() == ''
																? item?.company_name
																: item?.dba,
														type: REFERRAL_TYPE.CLIENT,
													};
												}),
												...clientContactOptions,
											]}
											name='referral'
											label='Referred by'
											placeholder='Choose Referal'
											searchLabel={''}
											Edit={true}
											disableNull
											toggleCustom={openCustomReferelForm}
										/>
									)}
									<div className='improved-input-designs py-3'>
										<label>Fiscal Year End</label>
										<Select
											value={months.find(
												(b) => b.value == clientForm?.fiscal_year_end,
											)}
											options={months}
											name='fiscal_year_end'
											onChange={handleChange}
										/>
									</div>
									<div className='improved-input-designs pb-3'>
										<label className='require'>Assigned Team</label>
										<Select
											value={teamList?.find(
												(b) =>
													b.value ==
													client?.team_client_permissions[0]?.team_id,
											)}
											options={teamList}
											name='team_id'
											onChange={handleChange}
											required={true}
										/>
									</div>
									<div className='improved-input-designs pb-3'>
										<label>Accounting Method</label>
										<Select
											name='accounting'
											onChange={handleChange}
											value={[
												{ value: 'Cash', label: 'Cash' },
												{ value: 'Accrual', label: 'Accrual' },
											].find((t) => t.value === clientForm?.accounting)}
											options={[
												{ value: 'Cash', label: 'Cash' },
												{ value: 'Accrual', label: 'Accrual' },
											]}
										/>
									</div>
									<div className='improved-input-designs pb-3'>
										<label>Close week</label>
										<input
											className='w-100'
											type='number'
											name='close_week'
											value={clientForm?.close_week}
											onChange={handleChange}
											min={0}
										/>
									</div>
									<div className='improved-input-designs pb-3'>
										<label>Client Start Date</label>
										<KeyboardDatePicker
											autoOk
											disableToolbar
											value={clientForm?.start_date || null}
											keyboardIcon={<CalendarIcon />}
											name='start_date'
											onChange={onStartDateChange}
											format={date_format}
											className='p-1'
										/>
									</div>
								</>
							)}
						</div>
					)}
					{posthog?.isFeatureEnabled('custom_fields') && (
						<div style={{ display: currentTab === 'Custom Field' ? 'block' : 'none' }}>
							<CustomFieldValuesModal ref={customFieldRef} clientId={id} />
						</div>
					)}
				</div>
				{error && (
					<div className=' px-3 py-2 rounded-bottom'>
						<span className='error-text BodyTwoLight'>{error || 'Update Failed'}</span>
					</div>
				)}
				<div className='VerticalSpacer bg-white p-3 rounded-bottom'>
					<SubmitButtonsField child={true}>
						<button
							id='Element'
							className='secondary'
							type='button'
							onClick={addCustomReferral ? cancelCustomReferelForm : handleOnCancel}
						>
							Cancel
						</button>

						<button
							type='submit'
							className='d-flex align-items-center'
							id='Element'
							data-cy='client-contant-submit-btn'
							disabled={isLoading}
						>
							{isLoading ? (
								<CircularProgress size={24} sx={{ color: '#fff' }} disableShrink />
							) : addCustomReferral ? (
								'Save & Continue'
							) : (
								'Update'
							)}
						</button>
					</SubmitButtonsField>
				</div>
			</form>
		</>
	);
}

export default EditClientInfo;
