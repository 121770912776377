import React, { useState } from 'react';
import SectionBar from '../Others/SectionBar';
import SkeletonLoading from '../Others/SkeletonLoading';
import Restricted from '../Permissions/Restricted';
import { executivePermissions } from '../../utilities/utilities';
import useModal from '../Modal/useModal';
import CreateCustomFieldModal from './CreateCustomFieldModal';
import ActiveCustomFieldsTable from './ActiveCustomFieldsTable';
import ArchiveCustomFieldsTable from './ArchiveCustomFieldsTable';
import { Modal } from '@mui/material';

function CustomFieldDefinitions() {
	const { modalIsOpen, ModalType, toggleModal } = useModal();
	const [showActiveTable, setShowActiveTable] = useState(true);

	const openModal = (modalType) => {
		ModalType(modalType);
		toggleModal();
	};

	const toggleTable = () => {
		setShowActiveTable(!showActiveTable);
	};

	return (
		<div className='profile-section-body border d-flex flex-column' style={{ gap: '16px' }}>
			<SectionBar
				header={
					<SkeletonLoading variant='text' width={100} height={45}>
						<div className='d-flex align-items-center' style={{ gap: '12px' }}>
							<h2 className='HeadlineThreeBold text-left'>Custom Fields</h2>
						</div>
					</SkeletonLoading>
				}
				headerActions={
					<Restricted AllowedUsers={executivePermissions}>
						<div
							className='d-flex align-items-center justify-content-center'
							style={{ gap: '12px' }}
						>
							<span
								className='BodyTwoLight primary-text pointer mt-1'
								onClick={toggleTable}
							>
								{showActiveTable ? 'Show Archive' : 'Show Custom Fields'}
							</span>
							<button
								className='rounded-btn primary-btn'
								onClick={() => openModal('CREATE_CUSTOM_FIELD')}
							>
								+ Create New Custom Field
							</button>
						</div>
					</Restricted>
				}
			/>
			{showActiveTable ? <ActiveCustomFieldsTable /> : <ArchiveCustomFieldsTable />}

			<Modal
				onClose={toggleModal}
				open={modalIsOpen}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<CreateCustomFieldModal toggleModal={toggleModal} />
			</Modal>
		</div>
	);
}

export default CustomFieldDefinitions;
