import SectionBar from '../Others/SectionBar';
import SkeletonLoading from '../Others/SkeletonLoading';
import CustomFieldDefinitions from './CustomFieldDefinitions';
import { ReactComponent as SettingIcon } from '../../assets/icons/Gear.svg';
import CustomTaskStatus from './CustomTaskStatus';
import posthog from 'posthog-js';

function CompanySettings() {
	return (
		<>
			<div className='profile-section' style={{ gap: '12px', marginTop: '32px' }}>
				<SectionBar
					header={
						<SkeletonLoading variant='text' width={100} height={45}>
							<div className='d-flex align-items-center' style={{ gap: '12px' }}>
								<SettingIcon />
								<h2 className='HeadlineThreeBold text-left'>Company Settings</h2>
							</div>
						</SkeletonLoading>
					}
				/>
				<CustomTaskStatus />
				{posthog?.isFeatureEnabled('custom_fields') && <CustomFieldDefinitions />}
			</div>
		</>
	);
}

export default CompanySettings;
