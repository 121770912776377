import { useEffect, useState } from 'react';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import { TableItemWidth5 } from '../TableSpacing/TableSpacingStyles';
import TableRowItem from '../Others/TableRowItem';
import { ReactComponent as DragIcon } from '../../assets/icons/dragIndicator.svg';
import { ReactComponent as UnarchiveIcon } from '../../assets/icons/ArrowBendUpLeft.svg';
import { Reorder } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import * as workspaceActions from '../../redux/actions/dashboard/workspaces-actions';
import { CircularProgress, Tooltip } from '@mui/material';

function ArchivedTaskStatusTable() {
	const dispatch = useDispatch();

	const taskStatuses = useSelector((state) => state.workSpaceReducer.customTaskStatus);
	const activeStatusCount = taskStatuses?.reduce(
		(acc, status) => acc + (!status?.deleted && status?.tenant_id !== null),
		0,
	);
	const [statuses, setStatuses] = useState([]);
	const [loadingIndex, setLoadingIndex] = useState(null);

	const fetchTaskStatuses = () => {
		dispatch(workspaceActions.getCustomTaskStatus())
			.then((response) => {
				dispatch(
					workspaceActions.setCustomTaskStatuses(
						response?.sort((a, b) => a.list_order - b.list_order),
					),
				);
			})
			.catch((error) => {
				dispatch(workspaceActions.setSnackBarIsOpen(false, true, error.message));
			});
	};

	const handleUnArchiveStatus = (status, index) => {
		setLoadingIndex(index);
		dispatch(workspaceActions.unArchiveCustomTaskStatus(status?.id))
			.then(() => {
				fetchTaskStatuses();
				setLoadingIndex(null);
			})
			.catch((error) => {
				setLoadingIndex(null);
				dispatch(workspaceActions.setSnackBarIsOpen(false, true, error.message));
			});
	};

	useEffect(() => {
		if (taskStatuses?.length) {
			const activeTaskStatuses = taskStatuses?.filter(
				(status) => status?.deleted && status?.tenant_id !== null,
			);
			setStatuses(activeTaskStatuses);
		}
	}, [taskStatuses]);
	return (
		<div>
			<TableHeader ClassName='new-table-header' border={false}>
				<TableHeaderItem
					labelClass='BodyTwoLight'
					label='Task Status'
					width={TableItemWidth5}
				/>

				<TableHeaderItem
					labelClass='BodyTwoLight'
					label='Created By'
					width={TableItemWidth5}
				/>

				<TableHeaderItem
					labelClass='BodyTwoLight'
					label='Unarchive'
					width={TableItemWidth5}
				/>
			</TableHeader>

			<Reorder.Group
				axis='y'
				values={statuses}
				onReorder={setStatuses}
				style={{
					listStyle: 'none',
					padding: '0',
					margin: '0',
					cursor: 'grab',
				}}
			>
				{statuses?.length > 0 ? (
					statuses?.map((status, index) => {
						return (
							<Reorder.Item
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								key={status?.id}
								value={status}
							>
								<div className='new-table-row d-flex align-items-center'>
									<TableRowItem
										ClassName='text-left'
										textClass='BodyTwoLight'
										input={true}
										width={TableItemWidth5}
									>
										<div
											className='d-flex align-items-center'
											style={{ gap: '8px' }}
										>
											<div>
												<DragIcon />
											</div>
											<div className='d-flex align-items-center'>
												<div
													style={{
														backgroundColor: status?.color,
														width: '12px',
														height: '12px',
														borderRadius: '50%',
														marginRight: '5px',
													}}
												></div>
												<span className='BodyTwoLight dark-text m-0'>
													{status?.label}
												</span>
											</div>
										</div>
									</TableRowItem>
									<TableRowItem
										ClassName='text-left'
										label={'Custom'}
										width={TableItemWidth5}
									/>
									<TableRowItem
										ClassName='text-left align-self-start'
										input={true}
										width={TableItemWidth5}
									>
										<Tooltip
											title='You already have 10 active statuses'
											disableHoverListener={activeStatusCount < 10}
										>
											{loadingIndex === index ? (
												<CircularProgress
													size={20}
													sx={{ color: '#fb7a03' }}
													disableShrink
												/>
											) : (
												<UnarchiveIcon
													className={`pointer ${
														activeStatusCount >= 10 ? 'opacity-50' : ''
													}`}
													onClick={
														activeStatusCount >= 10
															? undefined
															: () =>
																	handleUnArchiveStatus(
																		status,
																		index,
																	)
													}
												/>
											)}
										</Tooltip>
									</TableRowItem>
								</div>
							</Reorder.Item>
						);
					})
				) : (
					<div className='pt-3 BodyTwoLight'>There is no Task Status</div>
				)}
			</Reorder.Group>
		</div>
	);
}

export default ArchivedTaskStatusTable;
