import { useEffect } from 'react';
import ExpandableMyTasksView from './ExpandableMyTasksView';
import { useDispatch, useSelector } from 'react-redux';
import ExpanableClientMeetingsView from './ExpanableClientMeetingsView';
import * as Actions from '../../redux/actions/dashboard/workspaces-actions';
import { useLocation } from 'react-router';

function MeetingsAndMyTasksview({ weekDaysOff, days }) {
	const dispatch = useDispatch();
    const { search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const start_date = searchParams.get('start_date');
    const end_date = searchParams.get('end_date');
    
	const showTeamMembersMeetings = useSelector(
        (state) => state.workSpaceReducer.showTeamMembersMeetings,
	);
    const filters = useSelector((state) => state.workSpaceReducer.filters);
	const getMeetings = useSelector((state) => state.workSpaceReducer.getMeetings);
	const weeklyClientMeetingTasks = useSelector(
		(state) => state.workSpaceReducer.weeklyClientMeetingTasks,
	);

	useEffect(() => {
		if (start_date && end_date) {
			dispatch(
				Actions.getWeeklyMeetingTasks(
					filters,
					start_date,
					end_date,
					showTeamMembersMeetings,
				),
			);
		}
	}, [
		dispatch,
		filters?.users,
		filters?.clients,
		getMeetings,
		showTeamMembersMeetings,
		start_date,
		end_date,
	]);
	return (
		<>
			<div
				className='d-flex'
				style={{
					height: 'auto',
					width: '100%',
					borderRadius: '4px',
					background: '#FCFDFD',
					marginBottom: '24px',
					paddingBottom: '12px',
				}}
			>
				<ExpandableMyTasksView weekends={weekDaysOff} days={days} />
			</div>
			{showTeamMembersMeetings &&
				weeklyClientMeetingTasks
					?.map((item) => {
						return {
							...item,
							displayName:
								item?.client_dba?.trim() == ''
									? item?.client_name
									: item?.client_dba,
						};
					})
					?.sort((a, b) => {
						if (a.displayName && b.displayName) {
							return a.displayName.localeCompare(b.displayName);
						}
					})
					?.map((client, i) => {
						return (
							<div
								key={client?.client_id}
								className='d-flex'
								style={{
									height: 'auto',
									width: '100%',
									borderRadius: '4px',
									borderTopRightRadius: i === 0 ? '0px' : '4px',
									borderTopLeftRadius: i === 0 ? '0px' : '4px',
									background: i % 2 === 0 ? '#FCFDFD' : '#F7F9FA',
									marginBottom: '24px',
									paddingBottom: '12px',
								}}
							>
								<ExpanableClientMeetingsView
									client_name={client?.displayName}
									client_id={client?.client_id}
									tasks={client?.tasks}
									weekends={weekDaysOff}
									days={days}
								/>
							</div>
						);
					})}
		</>
	);
}

export default MeetingsAndMyTasksview;
