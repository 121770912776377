import { useLocalStorage } from './useLocalStorage';
import { TASK_STATUS_MAPPING } from '../utilities/utilities';
import { useSelector } from 'react-redux';

const useUpdateTaskStatuses = () => {
	const userId = useSelector((state) => state.authReducer.user.userId);
	const appliedFiltersStorage = useLocalStorage('workspace_applied_filters');
	const storage = useLocalStorage('workspace_filters_v1');

	const updateTaskStatuses = (statuses) => {
		return [...new Set(statuses.map((status) => TASK_STATUS_MAPPING[status] || status))];
	};

	const hasOutdatedTaskStatus = (statuses) =>
		(statuses || []).some((status) => TASK_STATUS_MAPPING[status]);

	const updateFilters = () => {
		const storedFilters = storage.getLocalStorage() || {};
		const loggedInUserFilters = storedFilters[userId] || [];

		const needsToUpdate = loggedInUserFilters.some((filter) =>
			hasOutdatedTaskStatus(filter.filters.taskStatuses),
		);

		if (needsToUpdate) {
			const updatedFilters = loggedInUserFilters.map((filter) => ({
				...filter,
				filters: {
					...filter.filters,
					taskStatuses: updateTaskStatuses(filter.filters.taskStatuses),
				},
			}));

			storage.setLocalStorage({ ...storedFilters, [userId]: updatedFilters });
		}

		const appliedFilters = appliedFiltersStorage.getLocalStorage() || {};
		if (hasOutdatedTaskStatus(appliedFilters.taskStatuses)) {
			const updatedStatuses = updateTaskStatuses(appliedFilters.taskStatuses);
			appliedFiltersStorage.setLocalStorage({
				...appliedFilters,
				taskStatuses: updatedStatuses,
			});
		}
	};

	return { updateFilters };
};

export default useUpdateTaskStatuses;
