import { useState } from 'react';
import ChatBotModal from './ChatBotModal';

function ChatBot() {
	const [isChatBotOpen, setIsChatBotOpen] = useState(false);
	return (
		<div>
			<div className='ai-icon-container' onClick={() => setIsChatBotOpen(!isChatBotOpen)}>
				<div className='ai-icon'>
					<div className='glow'></div>
				</div>
			</div>
			{isChatBotOpen && <ChatBotModal onClickAway={() => setIsChatBotOpen(!isChatBotOpen)} />}
		</div>
	);
}

export default ChatBot;
