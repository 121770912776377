import { useEffect, useState } from 'react';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import { TableItemWidth5 } from '../TableSpacing/TableSpacingStyles';
import TableRowItem from '../Others/TableRowItem';
import { ReactComponent as DragIcon } from '../../assets/icons/dragIndicator.svg';
import { ReactComponent as ArchiveIcon } from '../../assets/icons/Archive.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilSimpleColor.svg';
import CreateStatusModal from './CreateStatusModal';
import useModal from '../Modal/useModal';
import { CircularProgress, Modal } from '@mui/material';
import ArchiveTaskStatusModal from './ArchiveTaskStatusModal';
import { Reorder } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import * as workspaceActions from '../../redux/actions/dashboard/workspaces-actions';

function ActiveTaskStatusTable() {
	const dispatch = useDispatch();

	const [loadingIndex, setLoadingIndex] = useState(null);
	const taskStatuses = useSelector((state) => state.workSpaceReducer.customTaskStatus);
	const [statuses, setStatuses] = useState([]);
	const [previousOrder, setPreviousOrder] = useState([]);
	const [form, setForm] = useState({
		id: '',
		label: '',
		color: '',
	});
	const [statusTobeArchived, setStatusTobeArchived] = useState({
		status: null,
		errorClients: null,
	});
	const [draggedItem, setDraggedItem] = useState({
		id: '',
		index: null,
	});

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const openModal = (type) => {
		toggleModal();
		ModalType(type);
	};

	const fetchTaskStatuses = () => {
		dispatch(workspaceActions.getCustomTaskStatus())
			.then((response) => {
				dispatch(
					workspaceActions.setCustomTaskStatuses(
						response?.sort((a, b) => a.list_order - b.list_order),
					),
				);
			})
			.catch((error) => {
				dispatch(workspaceActions.setSnackBarIsOpen(false, true, error.message));
			});
	};

	const handleEditTaskStatus = (taskStatus) => {
		setForm({
			id: taskStatus?.id,
			label: taskStatus?.label,
			color: taskStatus?.color,
		});
		openModal('EDIT_TASK_STATUS');
	};

	const handleReorder = (updatedOrder) => {
		setStatuses(updatedOrder);
	};

	const handleDragStart = (draggedItem, index) => {
		setDraggedItem({
			id: draggedItem?.id,
			index: index,
		});
	};

	const handleDragEnd = () => {
		const updatedDraggedItemIndex = statuses?.findIndex(
			(status) => status?.id == draggedItem?.id,
		);

		const UpdatedDraggedItem = statuses?.find((status) => status?.id === draggedItem?.id);

		const isOrderUpdated = Boolean(updatedDraggedItemIndex !== draggedItem?.index);

		if (isOrderUpdated) {
			dispatch(
				workspaceActions.updateCustomTaskStatuses(UpdatedDraggedItem?.id, {
					label: UpdatedDraggedItem?.label,
					list_order: updatedDraggedItemIndex + 1,
				}),
			)
				.then(() => {
					fetchTaskStatuses();
				})
				.catch((error) => {
					dispatch(workspaceActions.setSnackBarIsOpen(false, true, error.message));
					setStatuses(previousOrder);
				});
		}
	};

	const handleArchiveStatus = (taskStatus, index) => {
		setLoadingIndex(index);
		dispatch(workspaceActions.archiveCustomTaskStatus(taskStatus?.id))
			.then(() => {
				fetchTaskStatuses();
				setLoadingIndex(null);
			})
			.catch((error) => {
				setLoadingIndex(null);
				setStatusTobeArchived({
					status: taskStatus,
					errorClients: JSON.parse(error.message),
				});
				openModal('ARCHIVE_TASK_STATUS');
			});
	};

	useEffect(() => {
		if (taskStatuses?.length) {
			const activeTaskStatuses = taskStatuses?.filter(
				(status) => !status?.deleted && status?.tenant_id !== null,
			);
			setStatuses(activeTaskStatuses);
			setPreviousOrder(activeTaskStatuses);
		}
	}, [taskStatuses]);

	return (
		<div>
			<TableHeader ClassName='new-table-header' border={false}>
				<TableHeaderItem
					labelClass='BodyTwoLight'
					label='Task Status'
					width={TableItemWidth5}
				/>

				<TableHeaderItem
					labelClass='BodyTwoLight'
					label='Created By'
					width={TableItemWidth5}
				/>

				<TableHeaderItem
					labelClass='BodyTwoLight'
					label='Archive'
					width={TableItemWidth5}
				/>
			</TableHeader>
			<Reorder.Group
				axis='y'
				values={statuses}
				onReorder={handleReorder}
				style={{
					listStyle: 'none',
					padding: '0',
					margin: '0',
					cursor: 'grab',
				}}
			>
				{statuses?.length > 0 ? (
					statuses?.map((status, index) => {
						return (
							<Reorder.Item
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								key={status?.id}
								value={status}
								drag='y'
								onDragStart={() => handleDragStart(status, index)}
								onDragEnd={handleDragEnd}
							>
								<div className='new-table-row d-flex align-items-center'>
									<TableRowItem
										ClassName='text-left'
										textClass='BodyTwoLight'
										input={true}
										width={TableItemWidth5}
									>
										<div
											className='d-flex align-items-center'
											style={{ gap: '8px' }}
										>
											<div>
												<DragIcon />
											</div>
											<div className='d-flex align-items-center'>
												<div
													style={{
														backgroundColor: status?.color,
														width: '12px',
														height: '12px',
														borderRadius: '50%',
														marginRight: '5px',
													}}
												></div>
												<span className='BodyTwoLight dark-text m-0'>
													{status?.label}
												</span>
											</div>
										</div>
									</TableRowItem>
									<TableRowItem
										ClassName='text-left'
										label={status?.tenant_id ? 'Custom' : 'Levvy'}
										width={TableItemWidth5}
									/>
									<TableRowItem
										ClassName='text-left align-self-start'
										input={true}
										width={TableItemWidth5}
									>
										<div
											className='d-flex align-items-center'
											style={{ gap: '5px' }}
										>
											<EditIcon
												onClick={() => handleEditTaskStatus(status)}
												className='pointer'
											/>
											{loadingIndex === index ? (
												<CircularProgress
													size={20}
													sx={{ color: '#fb7a03' }}
													disableShrink
												/>
											) : (
												<ArchiveIcon
													className='pointer'
													onClick={() =>
														handleArchiveStatus(status, index)
													}
												/>
											)}
										</div>
									</TableRowItem>
								</div>
							</Reorder.Item>
						);
					})
				) : (
					<div className='pt-3 BodyTwoLight'>There is no Task Status</div>
				)}
			</Reorder.Group>
			{modalIsOpen && (
				<>
					{ModalName == 'ARCHIVE_TASK_STATUS' && (
						<Modal
							onClose={toggleModal}
							open={modalIsOpen}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<ArchiveTaskStatusModal
								toggleModal={toggleModal}
								oldStatus={statusTobeArchived?.status}
								errorClients={statusTobeArchived?.errorClients}
							/>
						</Modal>
					)}
					{ModalName == 'EDIT_TASK_STATUS' && (
						<Modal
							onClose={toggleModal}
							open={modalIsOpen}
							sx={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<CreateStatusModal
								toggleModal={toggleModal}
								status_id={form?.id}
								label={form?.label}
								color={form?.color}
								edit={true}
							/>
						</Modal>
					)}
				</>
			)}
		</div>
	);
}

export default ActiveTaskStatusTable;
