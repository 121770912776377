import FormButtonOne from '../Buttons/FormButtonOne';
import { useEffect, useState } from 'react';
import { Checkbox } from '@mui/material';
import * as builderActions from '../../redux/actions/workflow-builder-actions';
import { useDispatch, useSelector } from 'react-redux';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const PreBuildDuplicateWorkflows = ({ onClose, handleDraftWorflowSuccess }) => {
	const dispatch = useDispatch();

	const [activeForm, setActiveForm] = useState(1);
	const [workflowsToRename, setWorkflowsToRename] = useState();
	const [formControls, setFormControls] = useState({
		error: '',
		loading: false,
	});

	const navigateToWorkflowRename = (workflows) => {
		setWorkflowsToRename(workflows);
		setActiveForm(2);
	};

	const handleSubmit = (workflows) => {
		setFormControls({
			loading: true,
			error: '',
		});
		dispatch(builderActions.moveWorkflowsToDraft({ workflows: workflows }))
			.then((response) => {
				setFormControls({
					loading: false,
					error: '',
				});
				handleDraftWorflowSuccess(response.saved_workflows, true);
			})
			.catch((error) => {
				setFormControls({
					loading: false,
					error: error.message,
				});
				setActiveForm(1);
			});
	};

	useEffect(() => {
		return () => {
			dispatch(builderActions.setWorkflowsToDraft());
		};
	}, []);

	return (
		<div style={{ width: '537px' }} className='custom-referrel-modal'>
			{activeForm == 1 && (
				<CreateCopy onClose={onClose} onContinue={navigateToWorkflowRename} />
			)}
			{activeForm == 2 && (
				<RenameWorkflows
					onClose={onClose}
					workflows={workflowsToRename}
					formControls={formControls}
					onContinue={handleSubmit}
				/>
			)}
		</div>
	);
};
export default PreBuildDuplicateWorkflows;

const CreateCopy = ({ onClose, onContinue }) => {
	const duplicateWorkflows = useSelector(
		(state) => state.WorkflowBuilder.workflowsToDraft,
	).draft_duplicate_workflows;

	const validWorkflows = useSelector(
		(state) => state.WorkflowBuilder.workflowsToDraft,
	).draft_valid_workflows;

	const [selectedWorkflows, setSelectedWorkflows] = useState([]);

	const handleChange = (workflow) => (event) => {
		const { checked } = event.target;

		if (checked) {
			setSelectedWorkflows([...selectedWorkflows, workflow]);
		} else {
			let array = [...selectedWorkflows];
			array = array?.filter((item) => item.name.trim() != workflow.name.trim());
			setSelectedWorkflows(array);
		}
	};

	return (
		<>
			<div style={{ height: '90%' }} className='my-task-body'>
				<div className='my-task-body-child'>
					<div className='HeadlineTwoBold dark-text'>
						Select the workflow(s) to create a copy
					</div>

					{validWorkflows?.length > 0 && (
						<div
							className='d-flex flex-column'
							style={{
								gap: '14px',
								background: '#F7F9FA',
								padding: '16px 24px',
								borderRadius: '4px',
							}}
						>
							<>
								<div className='d-flex flex-column' style={{ gap: '8px' }}>
									<span className='BodyOneBold dark-text'>Workflow(s) Added</span>
									<span className='BodyTwoLight'>
										The following workflow(s) have been added to Company
										Library.
									</span>
								</div>

								<div className='my-task-inner-body'>
									<div
										style={{
											height: '100%',
											boxSizing: 'border-box',
											overflow: 'auto',
											maxHeight: '200px',
											overflowY: 'auto',
										}}
									>
										<div
											className='UserAdd-Form d-flex flex-column pr-2'
											style={{ gap: '10px', height: 'inherit' }}
										>
											{validWorkflows?.map((item, index) => {
												return (
													<div
														key={index}
														style={{ gap: '4px' }}
														className=' d-flex flex-row align-items-center w-100'
													>
														<CheckCircleIcon
															fontSize='small'
															style={{ color: '#fb7a03' }}
														/>

														<span className='pt-2'>
															<label
																className='BodyTwoLight align-self-center'
																style={{
																	color: '#333333',
																}}
															>
																{item.name || 'Workflow'}
															</label>
														</span>
													</div>
												);
											})}
										</div>
									</div>
								</div>
							</>
						</div>
					)}

					<div
						className='d-flex flex-column'
						style={{
							gap: '14px',
							background: '#F7F9FA',
							padding: '16px 24px',
							borderRadius: '4px',
						}}
					>
						<div className='d-flex flex-column' style={{ gap: '8px' }}>
							<span className='BodyTwoLight'>
								The following workflow(s) already exist in the Company Library.
							</span>
						</div>

						<div className='my-task-inner-body'>
							<div
								style={{
									height: '100%',
									boxSizing: 'border-box',
									overflow: 'auto',
									maxHeight: '200px',
									overflowY: 'auto',
								}}
							>
								<div
									className='UserAdd-Form d-flex flex-column pr-2'
									style={{ gap: '10px', height: 'inherit' }}
								>
									{duplicateWorkflows?.map((item, index) => {
										return (
											<div
												key={index}
												className=' d-flex flex-row align-items-center justify-content-between w-100'
											>
												<span className='pt-2'>
													<label
														className='BodyTwoLight align-self-center'
														style={{
															color: '#333333',
														}}
													>
														{item.name}
													</label>
												</span>
												<Checkbox
													className='input-style bg-white'
													sx={{
														background: 'transparent !important',
														color: 'grey !important',
														'&.Mui-checked': {
															color: '#FB7A03 !important',
														},
														'&.MuiCheckbox-root': {
															'&:hover': {
																boxShadow: 'none',
															},
														},
													}}
													style={{
														paddingLeft: 0,
													}}
													name={item.name.trim()}
													onChange={handleChange(item)}
												/>
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className='pb-2 pt-2'></div>
			</div>

			<div style={{ height: '10%' }} className='my-task-form-actions'>
				<FormButtonOne onClick={onClose} className='secondary-btn-light'>
					Cancel
				</FormButtonOne>

				<FormButtonOne
					onClick={() => onContinue(selectedWorkflows)}
					disabled={Boolean(selectedWorkflows?.length == 0)}
					title={
						selectedWorkflows?.length == 0
							? 'Select at least one to proceed'
							: 'Click to proceed'
					}
				>
					Create Copy and Rename
				</FormButtonOne>
			</div>
		</>
	);
};

const RenameWorkflows = ({ onClose, workflows, onContinue, formControls }) => {

    const [selectedWorkflows, setSelectedWorkflows] = useState(
		workflows.map((workflow) => ({
			...workflow,
			name: `Copy of ${workflow.name}`,
		}))
	);

	const handleChange = (index) => (event) => {
		const { value } = event.target;

		setSelectedWorkflows(
			selectedWorkflows.map((item, i) => {
				if (index === i) {
					return {
						...item,
						name: value.startsWith('Copy of ') ? value : `Copy of ${value}`,
					};
				}
				return item;
			})
		);
	};

	const onSubmit = (event) => {
		event.preventDefault();
		onContinue(selectedWorkflows);
	};

	return (
		<form onSubmit={onSubmit}>
			<div style={{ height: '90%' }} className='my-task-body'>
				<div className='my-task-body-child'>
					<div className='HeadlineTwoBold dark-text'>Rename Duplicate Workflow</div>
					<div className='my-task-inner-body'>
						<div
							style={{
								height: '100%',
								boxSizing: 'border-box',
								overflow: 'auto',
								maxHeight: '400px',
								overflowY: 'auto',
							}}
						>
							<div
								className='UserAdd-Form d-flex flex-column pr-2'
								style={{ gap: '16px', height: 'inherit' }}
							>
								{selectedWorkflows?.map((item, index) => {
									return (
										<div key={index} className='improved-input-designs'>
											<label className=''>Workflow Name</label>
											<input
												className={`w-100 p-5 `}
												type='text'
												value={item.name}
												onChange={handleChange(index)}
												required
											/>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
				<div className='pb-2 pt-2'>
					<span className='error-text BodyTwoLight'>{formControls?.error}</span>
				</div>
			</div>

			<div style={{ height: '10%' }} className='my-task-form-actions'>
				<FormButtonOne onClick={onClose} className='secondary-btn-light'>
					Cancel
				</FormButtonOne>

				<FormButtonOne
					loading={formControls.loading}
					disabled={Boolean(selectedWorkflows?.length == 0)}
					title={
						selectedWorkflows?.length == 0
							? 'Select at least one to proceed'
							: 'Click to proceed'
					}
				>
					Continue
				</FormButtonOne>
			</div>
		</form>
	);
};
