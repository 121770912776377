import { Avatar } from '@mui/material';
import React from 'react';
import { getAvatarLabel } from '../../../utilities/utilities';
import { ReactComponent as Cross } from '../../../assets/icons/XCircleColor.svg';
import { ReactComponent as Search } from '../../../assets/icons/MagnifyingGlass.svg';

function NewMeetingGuests({
	search,
	handleSearch,
	assignees,
	form,
	handleAddGuest,
	handleRemoveGuest,
	placeholder,
	className,
	taskDetails,
}) {
	const Style = {
		backgroundColor: 'white',
		border: 'none',
		borderBottom: '1px solid #28383C',
	};
	let outsideAttendees = taskDetails?.metadata?.outside_attendee_emails || [];
	outsideAttendees = outsideAttendees.map((email) => ({
		full_name: email,
		id: Math.max(),
	}));
	const allGuests = [...assignees, ...outsideAttendees];
	return (
		<div className={className}>
			<label className='BodyTwoLight' style={{ color: '#7C7C7C' }}>
				Guests
			</label>
			<div style={{ backgroundColor: 'white' }} className='d-flex w-100'>
				<input
					className='w-100 BodyTwoLight'
					type='text'
					style={Style}
					onChange={handleSearch}
					value={search.value}
					placeholder={placeholder}
				/>
				<Search style={{stroke: '#FB7A03'}}/>
			</div>
			{search.show &&
				allGuests
					?.filter((user) => {
						if (typeof user == 'string') {
							return true;
						}
						return !form?.assignee_users?.some((assignee) => assignee?.id == user.id);
					})
					?.filter((assignee) => {
						if (typeof user == 'string') {
							return true;
						}
						return assignee?.full_name
							?.toLowerCase()
							.includes(search?.value?.toLowerCase());
					})?.length > 0 && (
					<div
						style={{
							width: '100%',
							height: 'auto',
							borderRadius: '4px',
							backgroundColor: '#FFFFFF',
							padding: '8px',
							marginTop: '8px',
						}}
					>
						{assignees
							?.filter(
								(user) =>
									!form?.assignee_users?.some(
										(assignee) => assignee?.id == user.id,
									),
							)
							?.filter((assignee) =>
								assignee?.full_name
									?.toLowerCase()
									.includes(search?.value?.toLowerCase()),
							)
							?.map((item, index, arr) => {
								return (
									<div
										key={index}
										className='d-flex align-items-center pointer'
										style={{
											gap: '1px',
											marginBottom: index === arr.length - 1 ? '0px' : '16px',
										}}
										onClick={() => handleAddGuest(item)}
									>
										<Avatar
											sx={{
												bgcolor: `#FEF2E7`,
												color: `#333`,
												marginRight: '10px',
												height: '20px',
												width: '20px',
												fontSize: '0.8rem',
											}}
											alt={item?.full_name}
											src={item?.image_url}
										>
											{getAvatarLabel(item?.full_name)}
										</Avatar>

										<div className='d-flex flex-column align-items-start'>
											<span className='BodyTwoLight'> {item?.full_name}</span>
											<span className='BodyThreeLight'>{item?.email}</span>
										</div>
									</div>
								);
							})}
					</div>
				)}
			<div style={{ width: '100%' }}>
				{[...form?.assignee_users, ...outsideAttendees]
					?.sort((a, b) => a.full_name.localeCompare(b.full_name))
					?.map((user, index) => {
						const currentMember = allGuests?.find((item) => item.id == user?.id);
						return (
							<div
								className='w-100 d-flex flex-row justify-content-between align-items-center'
								style={{ gap: '14px' }}
								key={index}
							>
								{console.log(form?.assignee_users, 'formsuer')}
								<div className='d-flex align-items-center'>
									<Avatar
										sx={{
											bgcolor: `#FEF2E7`,
											color: `#333`,
											marginRight: '8px',
											height: '16px',
											width: '16px',
											fontSize: '0.8rem',
										}}
										alt={currentMember?.full_name}
										src={currentMember?.image_url}
									>
										{getAvatarLabel(currentMember?.full_name)}
									</Avatar>

									<span className='BodyTwoLight pt-1 dark-text text-left'>
										{currentMember?.full_name}
									</span>
								</div>
								{currentMember?.id !== form?.my_task?.creator_id && (
									<div
										onClick={() => handleRemoveGuest(currentMember?.id)}
										style={{ cursor: 'pointer' }}
									>
										<Cross />
									</div>
								)}
							</div>
						);
					})}
			</div>
		</div>
	);
}

export default NewMeetingGuests;
