import { useState, useEffect } from 'react';
import FormButtonOne from '../Buttons/FormButtonOne';
import * as profileActions from '../../redux/actions/company-profile';
import { useDispatch, useSelector } from 'react-redux';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';

function CreateCustomFieldModal({
	toggleModal,
	name = '',
	type = 'text',
	required = false,
	edit = false,
	fieldId = null,
}) {
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		type: type,
		object_type: 'Client',
		required: required,
		name: name,
	});
	const customFieldDefinitions = useSelector(
		(state) => state.profileReducer.customFieldDefinitions,
	);

	// const fieldTypeOptions = [
	// 	{ label: 'Text (including letters, numbers and symbols)', value: 'text' },
	// 	{ label: 'Numbers only', value: 'number' },
	// ];

	const handleFormChange = (event) => {
		const { name, value, type, checked } = event.target;
		setForm({ ...form, [name]: type === 'checkbox' ? checked : value });
	};

	const submitForm = () => {
		setLoading(!loading);
		if (edit) {
			dispatch(profileActions.upateCustomFieldDefinitions(fieldId, form))
				.then((response) => {
					const updatedResponse = customFieldDefinitions.map((field) => {
						if (field.id === response.id) {
							return response;
						}
						return field;
					});
					toggleModal();
					setLoading(!loading);
					dispatch(profileActions.setCustomFieldDefinitions(updatedResponse));
				})
				.catch((error) => {
					setLoading(!loading);
					dispatch(setSnackBarIsOpen(false, true, error.message));
				});
		} else {
			dispatch(profileActions.createCustomFieldDefinitions(form))
				.then((response) => {
					let updatedCustomFields = [];
					if (customFieldDefinitions?.length) {
						updatedCustomFields = [
							...customFieldDefinitions,
							{ ...response, deleted: false },
						];
					} else updatedCustomFields = [{ ...response, deleted: false }];
					toggleModal();
					setLoading(!loading);
					dispatch(profileActions.setCustomFieldDefinitions(updatedCustomFields));
				})
				.catch((error) => {
					setLoading(!loading);
					dispatch(setSnackBarIsOpen(false, true, error.message));
				});
		}
	};

	useEffect(() => {
		setForm({
			...form,
			name: name,
			type: type,
			required: required,
		});
	}, [name, type, required]);
	return (
		<div className='new-modal' style={{ width: '600px' }}>
			<div className='new-modal-content p-3'>
				<div className='d-flex justify-content-between'>
					<span className='HeadlineTwoBold dark-text'>Create Custom Field</span>
				</div>
                <div className='improved-input-designs'>
					<label>Object</label>
					<input name='object_type' value={form.object_type} type='text' disabled className='opacity-50'/>
				</div>
				<div className='improved-input-designs'>
					<label className='require'>Custom Field Name</label>
					<input name='name' value={form?.name} onChange={handleFormChange} type='text' />
				</div>
				{/* <div className='new-input-designs'>
					<label className='require'>Custom Field Type</label>
					<DropDown
						onChange={handleFormChange}
						normalDropDown
						style={{ height: '32px', textAlign: 'left' }}
						options={fieldTypeOptions}
						value={form?.type}
						name={'type'}
						disableNull
					/>
					{form?.type === 'text' && (
						<span className='BodyThreeLight light-text pt-2'>
							Enter any text, including letters, numbers and symbols (e.g.,
							123848$%asj)
						</span>
					)}
				</div>
				<div className='d-flex align-items-center'>
					<input
						type='checkbox'
						name='required'
						className='multi-select-checkbox'
						onChange={handleFormChange}
						checked={form?.required}
					/>
					<span className='BodyTwoLight mt-1 ml-2'>Required Field?</span>
				</div> */}
			</div>
			<div className='new-modal-footer rounded-bottom'>
				<FormButtonOne
					onClick={toggleModal}
					type='button'
					className='rounded-btn secondary-btn-light'
				>
					Cancel
				</FormButtonOne>
				<FormButtonOne
					type='button'
					className='rounded-btn primary-btn'
					onClick={submitForm}
					loading={loading}
					disabled={!form?.name || !form?.type || loading}
				>
					Save
				</FormButtonOne>
			</div>
		</div>
	);
}

export default CreateCustomFieldModal;
