import { useEffect, useState } from 'react';
import FormButtonOne from '../Buttons/FormButtonOne';
import { SketchPicker } from 'react-color';
import { useDispatch, useSelector } from 'react-redux';
import * as workspaceActions from '../../redux/actions/dashboard/workspaces-actions';

function CreateStatusModal({ toggleModal, label, color = '#FB7A03', status_id, edit = false }) {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [form, setForm] = useState({
		id: '',
		label: '',
		color: '',
	});
	const taskStatus = useSelector((state) => state.workSpaceReducer.customTaskStatus);

	const handleStatusNameChange = (event) => {
		const { name, value } = event.target;
		setForm({
			...form,
			[name]: value,
		});
	};

	const handleColorChange = (color) => {
		setForm({
			...form,
			color: color,
		});
	};

	const submitForm = () => {
		setLoading(true);
		if (edit) {
			dispatch(
				workspaceActions.updateCustomTaskStatuses(form?.id, {
					label: form?.label,
					color: form?.color,
				}),
			)
				.then((response) => {
					setLoading(false);
					const updatedTaskStatuses = taskStatus?.map((status) => {
						if (status?.id === form?.id) {
							return response[0];
						} else return status;
					});
					dispatch(workspaceActions.setCustomTaskStatuses(updatedTaskStatuses));
					toggleModal();
				})
				.catch((error) => {
					setLoading(false);
					dispatch(workspaceActions.setSnackBarIsOpen(false, true, error.message));
				});
		} else {
			dispatch(
				workspaceActions.createCustomTaskStatus({
					label: form?.label,
					color: form?.color,
					category: 'Blocked',
				}),
			)
				.then((response) => {
					setLoading(false);
					let updatedTaskStatuses = [];
					if (taskStatus?.length) {
						updatedTaskStatuses = [...taskStatus, { ...response, deleted: false }];
					} else updatedTaskStatuses = [{ ...response, deleted: false }];
					dispatch(workspaceActions.setCustomTaskStatuses(updatedTaskStatuses));
					toggleModal();
				})
				.catch((error) => {
					setLoading(false);
					dispatch(workspaceActions.setSnackBarIsOpen(false, true, error.message));
				});
		}
	};

	useEffect(() => {
		setForm({
			id: status_id,
			label: label,
			color: color,
		});
	}, [label, color]);

	return (
		<div className='new-modal' style={{ width: '600px' }}>
			<div className='new-modal-content p-3'>
				<div className='d-flex justify-content-between'>
					<span className='HeadlineTwoBold dark-text'>Create Status</span>
				</div>
				<div className='improved-input-designs'>
					<label className='require'>Status Name</label>
					<input
						name='label'
						value={form?.label}
						onChange={handleStatusNameChange}
						type='text'
					/>
				</div>
				<div className='new-input-designs'>
					<SelectTaskStatusColor
						statusColor={form?.color}
						handleColorChange={handleColorChange}
					/>
				</div>
			</div>
			<div className='new-modal-footer rounded-bottom'>
				<FormButtonOne
					onClick={toggleModal}
					type='button'
					className='rounded-btn secondary-btn-light'
				>
					Cancel
				</FormButtonOne>
				<FormButtonOne
					type='button'
					className='rounded-btn primary-btn'
					onClick={submitForm}
					loading={loading}
					disabled={!form?.label || loading}
				>
					Save
				</FormButtonOne>
			</div>
		</div>
	);
}

export default CreateStatusModal;

const SelectTaskStatusColor = ({ statusColor, handleColorChange }) => {
	const colors = [
		'#FB7A03',
		'#FB1B1B',
		'#FFE55B',
		'#E4FF3E',
		'#60FFFF',
		'#9908DD',
		'#42FF00',
		'#9B0070',
		'#0B4FFF',
		'#FF7B7B',
	];

	const [showColorPicker, setShowColorPicker] = useState(false);

	const toggleColorPicker = () => {
		setShowColorPicker(!showColorPicker);
	};

	return (
		<>
			<div className='d-flex justify-content-between'>
				<label className='BodyTwoLight require'>Select Color</label>
				<span className='BodyTwoLight primary-text pointer' onClick={toggleColorPicker}>
					{showColorPicker ? 'Hide Color Picker' : 'Select Custom Color'}
				</span>
			</div>
			<div
				className='w-100 d-flex align-items-center justify-content-around position-relative'
				style={{
					backgroundColor: 'white',
					height: '32px',
					borderRadius: '4px',
				}}
			>
				{colors?.map((color) => {
					return (
						<div
							key={color}
							className='d-flex align-items-center justify-content-center'
							style={{
								border: statusColor == color ? `1px solid #FB7A03` : '',
								backgroundColor: 'white',
								width: '22px',
								height: '22px',
								borderRadius: '50%',
							}}
						>
							<div
								className='pointer'
								onClick={() => handleColorChange(color)}
								name='color'
								key={color}
								style={{
									width: '18px',
									height: '18px',
									backgroundColor: color,
									borderRadius: '50%',
								}}
							>
								{/*  */}
							</div>
						</div>
					);
				})}
			</div>
			{showColorPicker && (
				<div className='d-flex align-items-center mt-3'>
					<SketchPicker
						onChangeComplete={(color) => handleColorChange(color.hex)}
						onChange={(color) => handleColorChange(color.hex)}
						disableAlpha
						color={statusColor}
					/>
				</div>
			)}
		</>
	);
};
