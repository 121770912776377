import * as Actions from '../actions/company-profile';

const initialState = {
	profileData: {
		name: '-',
		dba: '-',
		companyPhoneNumber: '-',
		country: null,
		currency: null,
		businessType: null,
		industry: null,
		image_url: null,
		id: null,
	},
	packageName: '',
	subscriptionData: {},
	subscriptionCompleted: false,
	cards: [],
	customerInvoices: [],
	loading: false,
	defaultWorkflows: [],
	countries: [],
	states: [],
	secondaryStates: [],
	error: '',
	customFieldDefinitions: [],
};

const CompanyProfileReducer = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case Actions.GET_TENANT_PROFILE:
			return (state = {
				...state,
				profileData: {
					...state.profileData,
					name: payload.name,
					dba: payload.dba,
					companyPhoneNumber: payload.phone,
					companyLogo: payload.logo_url,
					industry: payload.industry,
					country: payload.country,
					currency: payload.currency,
					businessType: payload.business_type,
					image_url: payload.logo_url,
					id: payload.id,
					is_phone_number_formated: payload.is_phone_number_formated,
					created_at: payload?.created_at,
					onboarding_status: payload?.onboarding_status,
				},
			});
		case Actions.HANDLE_CHANGE:
			return (state = {
				...state,
				profileData: {
					...state.profileData,
					[payload.name]: payload.value,
				},
			});
		case Actions.HANDLE_INDUSTRY_CHANGE:
			console.log(payload, 'business');
			return (state = {
				...state,
				profileData: {
					...state.profileData,
					industry: payload,
				},
			});
		case Actions.HANDLE_BUSINESS_CHANGE:
			return (state = {
				...state,
				profileData: {
					...state.profileData,
					businessType: payload,
				},
			});
		case Actions.HANDLE_COUNTRY_CHANGE:
			return (state = {
				...state,
				profileData: {
					...state.profileData,
					country: payload,
				},
			});
		case Actions.HANDLE_CURRENCY_CHANGE:
			return {
				...state,
				profileData: {
					...state.profileData,
					currency: payload,
				},
			};
		case Actions.GET_CARDS:
			return (state = {
				...state,
				cards: payload,
			});
		case Actions.DETACH_CARD:
			return (state = {
				...state,
				cards: state.cards.filter((card) => {
					if (card.id !== payload) {
						return card;
					}
				}),
			});
		case Actions.SUBSCRIPTION_END: {
			return (state = {
				...state,
				subscriptionCompleted: true,
			});
		}
		case Actions.SET_ERROR: {
			return (state = {
				...state,
				error: payload,
			});
		}
		case Actions.GET_CUSTOMER_INVOICES: {
			return (state = {
				...state,
				customerInvoices: payload,
			});
		}
		case Actions.HANDLE_PACKAGE_CHANGE: {
			return (state = {
				...state,
				packageName: payload,
			});
		}
		case Actions.UPLOAD_IMAGE_START: {
			return (state = {
				...state,
				loading: true,
			});
		}
		case Actions.UPLOAD_IMAGE_FAILED: {
			return (state = {
				...state,
				loading: false,
			});
		}
		case Actions.UPLOAD_IMAGE: {
			return (state = {
				...state,
				loading: false,
				profileData: {
					...state.profileData,
					image_url: payload.data,
				},
			});
		}
		case Actions.SET_ALL_DEFAULT_WORKFLOWS: {
			return (state = {
				...state,
				defaultWorkflows: payload,
			});
		}
		case Actions.GET_TENANT_PROFILE_LOADING: {
			return (state = {
				...state,
				loading: payload,
			});
		}
		case Actions.GET_COUNTRIES_LIST: {
			return (state = {
				...state,
				countries: payload?.map((country) => {
					return {
						label: country?.name,
						value: country?.isoCode,
						iso2: country?.isoCode,
						currency: country?.currency,
					};
				}),
			});
		}
		case Actions.GET_COUNTRY_STATES: {
			return (state = {
				...state,
				states: payload?.map((state) => {
					return {
						label: state?.name,
						value: state?.name,
						iso2: state?.iso2,
					};
				}),
			});
		}
		case Actions.GET_SECONDARY_COUNTRY_STATES: {
			return (state = {
				...state,
				secondaryStates: payload?.map((state) => {
					return {
						label: state?.name,
						value: state?.name,
						iso2: state?.iso2,
					};
				}),
			});
		}
		case Actions.SET_CUSTOM_FIELDS: {
			return (state = {
				...state,
				customFieldDefinitions: payload,
			});
		}
		default:
			return state;
	}
};

export default CompanyProfileReducer;
