/* eslint-disable react/react-in-jsx-scope */
import { useEffect, useState } from 'react';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import { CircularProgress } from '@mui/material';
import {
	useStripe,
	useElements,
	CardNumberElement,
	CardExpiryElement,
	CardCvcElement,
} from '@stripe/react-stripe-js';
import { Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import * as profileActions from '../../../redux/actions/company-profile';
import * as authActions from '../../../redux/actions/auth-actions';
import SubmitButtonsField from '../../Buttons/SubmitButtonsField';


const CARD_ELEMENT_OPTIONS = {
	placeholder: '',
	style: {
		base: {
			height: '26px',
			background: 'red',
			borderRadius: '3px 3px 0px 0px',
			borderBottom: '1.5px solid black',
			width: '100%',
		},
		invalid: {
			color: '#fa755a',
			iconColor: '#fa755a',
		},
	},
};

export default function AddCardModal({ handleClose, edit, subscriptionFlow }) {
	const dispatch = useDispatch();
	const stripe = useStripe();
	const elements = useElements();

	const [Load, setLoad] = useState(false);
	const [error, setError] = useState('');

	const stripePricings = useSelector((state) => state.authReducer.stripe_pricings);
	const { interval, price_per_unit } = stripePricings[0]?.stripe_pricings[0];

	const [cardholderName, setCardholderName] = useState('');
	const [primaryCard, setPrimaryCard] = useState(true);

    let paymentMethod = null;

	const handleSubmit = async (event) => {
		event.preventDefault();
		setLoad(true);

		paymentMethod = await stripe.createPaymentMethod({
			type: 'card',
			card: elements.getElement(CardNumberElement),
			billing_details: {
				name: cardholderName,
			},
		});

		if (subscriptionFlow) {
			addSubscription();
		} else {
			dispatch(
				profileActions.createStripePaymentMethod(
					paymentMethod,
					primaryCard,
					setLoad,
					handleClose,
				),
			)
				.then((res) => {
					console.log(res);
				})
				.catch((err) => {
					setError(err?.message);
				});
		}
	};

	const addSubscription = () => {
		if (paymentMethod?.error) {
			setError(paymentMethod?.error?.type.replace(/_/g, ' '));
			setLoad(false);
		} else {
			dispatch(
				authActions.addSubscription(
					{
						payment_method_id: paymentMethod?.paymentMethod?.id,
						name_on_card: paymentMethod?.paymentMethod?.billing_details?.name,
						stripe_pricing_id: stripePricings[0]?.stripe_pricings[0]?.id,
					},
					setLoad,
				),
			)
				.then(() => {
					dispatch(authActions.getSubscription());
                    dispatch(profileActions.getCards());
                    dispatch(profileActions.getCustomerInvoices());
				})
				.catch((err) => {
					setError(err?.message);
				})
				.finally(() => {
					handleClose();
				});
		}
	};

	useEffect(() => {
		return () => {
			dispatch(profileActions.setError(''));
		};
	}, [dispatch]);

	return (
		<>
			<form onSubmit={handleSubmit}>
				<DialogContent style={{ margin: 0, padding: 0 }} className='ContactAdd-Form p-3'>
					<div className='improved-input-designs pb-3'>
						<label className='require'>Cardholder Name</label>
						<input
							className='w-100'
							type='text'
							id='cardholdername'
							onChange={(e) => setCardholderName(e.target.value)}
							value={cardholderName}
							title='Please enter text only'
							required={true}
							disabled={edit}
							style={{ opacity: edit ? '0.5' : '1' }}
						/>
					</div>

					{!edit && (
						<div className='improved-input-designs pb-3'>
							<label className='require'>Card Number</label>
							<CardNumberElement
								id='cc-number'
								className='input-style'
								options={CARD_ELEMENT_OPTIONS}
								disabled={edit}
							/>
						</div>
					)}

					<div className='improved-input-designs pb-3'>
						<label className='require'>EXPIRY (MM/YY)</label>
						<CardExpiryElement
							id='expiry'
							className='input-style'
							options={CARD_ELEMENT_OPTIONS}
						/>
					</div>

					{!edit && (
						<div className='improved-input-designs pb-3'>
							<label className='require'>CVV</label>
							<CardCvcElement
								id='cvc'
								className='input-style'
								options={CARD_ELEMENT_OPTIONS}
								disabled={edit}
							/>
						</div>
					)}

					{subscriptionFlow ? (
						<div
							className='graph-container d-flex justify-content-between mb-4'
							style={{ padding: '18px', gap: '5rem', backgroundColor:'white', borderRadius:'4px' }}
						>
							<div className='d-flex flex-column'>
								<div className='d-flex flex-column' style={{ gap: '0.7em' }}>
									<div
										className='d-flex align-items-start'
										style={{ textAlign: 'start' }}
									>
										<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
											Plan Name
										</span>
									</div>
									<div className='d-flex align-items-start'>
										<span className='BodyTwoLight'>
											{stripePricings[0]?.name}
										</span>
									</div>
								</div>
							</div>
							<div className='d-flex flex-column' style={{ gap: '0.7em' }}>
								<div
									className='d-flex align-items-start'
									style={{ textAlign: 'start' }}
								>
									<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
										Time Period
									</span>
								</div>
								<div className='d-flex align-items-start'>
									<span className='BodyTwoLight'>{interval}</span>
								</div>
							</div>
							<div className='d-flex flex-column' style={{ gap: '0.7em' }}>
								<div
									className='d-flex align-items-start'
									style={{ textAlign: 'start' }}
								>
									<span className='BodyTwoLight' style={{ color: '#696F6C' }}>
										Price Per Seat
									</span>
								</div>
								<div className='d-flex align-items-start'>
									<span className='BodyTwoLight'>${price_per_unit}</span>
								</div>
							</div>
						</div>
					) : (
						<></>
					)}
					<div className='d-flex align-items-center '>
						<Checkbox
							checked={primaryCard}
							onClick={() => setPrimaryCard(!primaryCard)}
							sx={{
								background: 'white',
								color: '#E9E7E1',
								'&.Mui-checked': {
									color: '#FB7A03',
								},
								width: '18px',
								height: '18px',
							}}
							style={{
								padding: 0,
								marginRight: '0.2rem',
							}}
						/>
						<span className='BodyTwoLight'>Mark as primary card</span>
					</div>

					<div className=''>
						<span style={{ color: 'red', fontSize: '0.9em' }}>{error}</span>
					</div>
				</DialogContent>

				<DialogActions className='p-0 m-0'>
					<div className='VerticalSpacer w-100 bg-white p-3 rounded-bottom'>
						<SubmitButtonsField child={true}>
							<button
								id='Element'
								type='button'
								className='secondary'
								onClick={handleClose}
							>
								Cancel
							</button>

							<button
								type='submit'
								className='d-flex align-items-center'
								id='Element'
								disabled={Load}
							>
								{Load ? (
									<CircularProgress
										size={24}
										sx={{ color: 'white' }}
										disableShrink
									/>
								) : (
									subscriptionFlow ? 'Activate' : 'Submit'
								)}
							</button>
						</SubmitButtonsField>
					</div>
				</DialogActions>
			</form>
		</>
	);
}
