import { useEffect, useState } from 'react';
import TableHeader from '../Others/TableHeader';
import TableHeaderItem from '../Others/TableHeaderItem';
import { TableItemWidth5 } from '../TableSpacing/TableSpacingStyles';
import TableRowItem from '../Others/TableRowItem';
import { ReactComponent as DragIcon } from '../../assets/icons/dragIndicator.svg';
import { ReactComponent as ArchiveIcon } from '../../assets/icons/Archive.svg';
import { ReactComponent as EditIcon } from '../../assets/icons/PencilSimpleColor.svg';
import CreateCustomFieldModal from './CreateCustomFieldModal';
import ArchiveCustomFieldModal from './ArchiveCustomFieldModal';
import useModal from '../Modal/useModal';
import { Modal } from '@mui/material';
import { Reorder } from 'framer-motion';
import { useDispatch, useSelector } from 'react-redux';
import * as ProfileActions from '../../redux/actions/company-profile';
import { setSnackBarIsOpen } from '../../redux/actions/dashboard/workspaces-actions';

function ActiveCustomFieldsTable() {
	const dispatch = useDispatch();

	const customFieldDefinitions = useSelector(
		(state) => state.profileReducer.customFieldDefinitions,
	);
	const [customFields, setCustomFields] = useState([]);
	const [previousOrder, setPreviousOrder] = useState([]);
	const [form, setForm] = useState({
		id: null,
		name: '',
		type: '',
		required: false,
		object_type: 'Client',
	});
	const [fieldToBeArchived, setFieldToBeArchived] = useState(null);

	const [draggedItem, setDraggedItem] = useState({
		id: '',
		index: null,
	});

	const { modalIsOpen, toggleModal, ModalName, ModalType } = useModal();

	const openModal = (type) => {
		toggleModal();
		ModalType(type);
	};

	const fetchCustomFields = () => {
		dispatch(ProfileActions.fetchCustomFieldDefinitions()).then((response) => {
			dispatch(ProfileActions.setCustomFieldDefinitions(response));
		});
	};

	const handleEditTaskStatus = (field) => {
		setForm({
			...form,
			id: field?.id,
			name: field?.name,
			type: field?.type,
			required: field?.required,
		});
		openModal('EDIT_CUSTOM_FIELD');
	};

	const handleReorder = (updatedOrder) => {
		setCustomFields(updatedOrder);
	};

	const handleDragStart = (draggedItem, index) => {
		setDraggedItem({
			id: draggedItem?.id,
			index: index,
		});
	};

	const handleDragEnd = () => {
		const updatedDraggedItemIndex = customFields?.findIndex(
			(field) => field?.id == draggedItem?.id,
		);

		const UpdatedDraggedItem = customFields?.find((field) => field?.id === draggedItem?.id);

		const isOrderUpdated = Boolean(updatedDraggedItemIndex !== draggedItem?.index);

		if (isOrderUpdated) {
			dispatch(
				ProfileActions.upateCustomFieldDefinitions(UpdatedDraggedItem?.id, {
					...UpdatedDraggedItem,
					list_order: updatedDraggedItemIndex + 1,
				}),
			)
				.then(() => {
					fetchCustomFields();
				})
				.catch((error) => {
					dispatch(setSnackBarIsOpen(false, true, error.message));
					setCustomFields(previousOrder);
				});
		}
	};

	const handleArchiveStatus = (field) => {
		setFieldToBeArchived(field?.id);
		openModal('ARCHIVE_CUSTOM_FIELD');
	};

	useEffect(() => {
		if (customFieldDefinitions?.length) {
			const activeCustomFields = customFieldDefinitions?.filter((filed) => !filed?.deleted);
			setCustomFields(activeCustomFields);
			setPreviousOrder(activeCustomFields);
		}
	}, [customFieldDefinitions]);

	return (
		<div>
			<TableHeader ClassName='new-table-header' border={false}>
				<TableHeaderItem
					labelClass='BodyTwoLight'
					label='Custom Field Name'
					width={TableItemWidth5}
				/>
				<TableHeaderItem
					labelClass='BodyTwoLight'
					label='Archive'
					width={TableItemWidth5}
				/>
			</TableHeader>
			<Reorder.Group
				axis='y'
				values={customFields}
				onReorder={handleReorder}
				style={{
					listStyle: 'none',
					padding: '0',
					margin: '0',
					cursor: 'grab',
				}}
			>
				{customFields?.length > 0 ? (
					customFields?.map((field, index) => {
						return (
							<Reorder.Item
								initial={{ opacity: 0 }}
								animate={{ opacity: 1 }}
								exit={{ opacity: 0 }}
								key={field?.id}
								value={field}
								drag='y'
								onDragStart={() => handleDragStart(field, index)}
								onDragEnd={handleDragEnd}
							>
								<div className='new-table-row d-flex align-items-center'>
									<TableRowItem
										ClassName='text-left'
										textClass='BodyTwoLight'
										input={true}
										width={TableItemWidth5}
									>
										<div
											className='d-flex align-items-center'
											style={{ gap: '8px' }}
										>
											<div>
												<DragIcon />
											</div>
											<div className='d-flex align-items-center'>
												<span className='BodyTwoLight dark-text m-0'>
													{field?.name}
												</span>
											</div>
										</div>
									</TableRowItem>
									<TableRowItem
										ClassName='text-left align-self-start'
										input={true}
										width={TableItemWidth5}
									>
										<div
											className='d-flex align-items-center'
											style={{ gap: '5px' }}
										>
											<EditIcon
												onClick={() => handleEditTaskStatus(field)}
												className='pointer'
											/>

											<ArchiveIcon
												className='pointer'
												onClick={() => handleArchiveStatus(field)}
											/>
										</div>
									</TableRowItem>
								</div>
							</Reorder.Item>
						);
					})
				) : (
					<div className='pt-3 BodyTwoLight'>There is no Custom Field</div>
				)}
			</Reorder.Group>
			<Modal
				onClose={toggleModal}
				open={modalIsOpen}
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
				}}
			>
				<>
					{ModalName == 'EDIT_CUSTOM_FIELD' && (
						<CreateCustomFieldModal
							toggleModal={toggleModal}
							name={form?.name}
							type={form?.type}
							required={form?.required}
							fieldId={form?.id}
							edit={true}
						/>
					)}
					{ModalName == 'ARCHIVE_CUSTOM_FIELD' && (
						<ArchiveCustomFieldModal
							toggleModal={toggleModal}
							customFieldId={fieldToBeArchived}
						/>
					)}
				</>
			</Modal>
		</div>
	);
}

export default ActiveCustomFieldsTable;
